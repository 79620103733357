import { useRequest } from 'ahooks';
import { useDispatch } from 'umi';

type ProvNode = {
  name: string;
  adcode: `${number}`;
  center: `${number},${number}`;
  districts: ProvNode[];
};
export function useProvData() {
  const dispatch = useDispatch();
  const { loading, data: provData } = useRequest<ProvNode[], never>(
    () =>
      dispatch({
        type: 'space/fetchProvData',
      }),
    {
      cacheKey: 'fetchProvData',
      cacheTime: 12 * 60 * 60 * 1000,
      staleTime: 6 * 60 * 60 * 1000,
    },
  );

  return { loading, provData };
}
