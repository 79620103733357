import type { ApiResponse } from '@linkpi/core';
import { toRecord } from '@linkpi/utils';
import { useMemo } from 'react';
import isEqual from 'react-fast-compare';
import { useSelector } from 'umi';

export const useOrgTemplatesSettingInfo = () => {
  const templates = useSelector((state: any) => state.space?.templates);

  return [templates as ApiResponse.CurrentUser.TemplateInfo[]];
};

export const useOrgTemplatesSettingInfoMap = () => {
  const [templates] = useOrgTemplatesSettingInfo();

  const tempMap = useMemo(() => {
    return toRecord((t: ApiResponse.CurrentUser.TemplateInfo) => ({
      [t.template_id]: t,
    }))(templates);
  }, [templates]);

  return tempMap;
};

/**
 * 配置界面获取当前正在编辑的主题类型
 */
export const useCurrentTemplateSetting = () => {
  const currentTemplate = useSelector((state: any) => state.space?.currentTemplate, isEqual);

  return [currentTemplate as ApiResponse.CurrentUser.TemplateInfo] as const;
};
