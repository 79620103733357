import { Modal, Radio, Select } from 'antd';
import { useEffect, useState } from 'react';

import { useCurrentUserInfo } from '@/hook';

import styles from './styles.less';

interface IProps {
  loading: boolean;
  closeModal: () => void;
  defaultDirection: string;
  ok: (v: string, i: string) => void;
  node: PiNode;
  templateList: any[];
}

const directions = [
  {
    label: '纵向',
    icon: 'iconiconvertical iconfont',
    value: 'vertical',
  },
  {
    label: '横向',
    icon: 'iconiconhorizontal iconfont',
    value: 'horizontal',
  },
];

export default (props: IProps) => {
  const { Option } = Select;
  const { defaultDirection, closeModal, loading, ok, node, templateList } = props;
  const userInfo = useCurrentUserInfo();

  const [direction, setDirection] = useState(defaultDirection);
  const [printSheetId, setPrintSheetId] = useState<undefined | string>(undefined);

  // 该主题的打印单数
  const getTemplatePrintSheets = () => {
    const nodeTemplate = templateList.find((t) => t.template_id === node.tempInfo.id);
    const printSheets = nodeTemplate?.temp_option?.printSheets || [];

    return printSheets.filter((p: any) => {
      const acl = p.acl || ['-1'];
      if (acl.includes('-1')) return true;

      let allow = false;

      userInfo.group_ids.forEach((gId: string) => {
        if (acl.includes(gId)) allow = true;
      });

      return allow;
    });
  };

  const changePrintSheetId = (id: string) => {
    setPrintSheetId(id);
  };

  useEffect(() => {
    setPrintSheetId(getTemplatePrintSheets()[0]?.id);
  }, []);

  const changeDirection = (v: any) => {
    setDirection(v);
  };
  return (
    <Modal
      visible
      title="请选择导出页面方向"
      onOk={() => ok(direction, printSheetId)}
      onCancel={closeModal}
      confirmLoading={loading}
    >
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        {directions.map((i) => (
          <div className={styles.card} key={i.icon} onClick={() => changeDirection(i.value)}>
            <Radio checked={direction === i.value} className={styles.radio} />
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <i className={`${i.icon} ${styles.icon}`} />
              <div style={{ color: '#242D3F', fontWeight: 500 }}>{i.label}</div>
            </div>
          </div>
        ))}
      </div>
      <Select
        value={printSheetId}
        style={{ width: '100%', marginTop: 12 }}
        placeholder="请选择打印单"
        onChange={changePrintSheetId}
        filterOption={(input, option) =>
          option?.props?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        showSearch
      >
        {getTemplatePrintSheets().map((sheet: any) => (
          <Option value={sheet.id} key={sheet.id}>
            {sheet.sheetName}
          </Option>
        ))}
      </Select>
    </Modal>
  );
};
