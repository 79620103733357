import { PROP_TYPE } from '@linkpi/core';

import { elecwattConfig } from '@/utils/utils';

import type { PiGridModules } from '../core';
import { menuConfig, resizerConfig, sortTriangleConfig } from '../helper/config';
import type { PositionType } from './_baseComponent';
import GridBaseComponent from './_baseComponent';
import HeaderMenu from './headerMenu';
import Resizer from './resizer';
import SortTriangle from './sortTriangle';
class HeaderCell extends GridBaseComponent {
  public data: any;
  public type: string = 'headerCell';
  captrue: boolean = false;

  public STYLE = {
    hoverBg: '#f0f0f0',
    darknessBg: 'rgba(17,197,167,0.2)',
    darknessFontColor: '#11C5A7',
  };
  constructor(position: PositionType, moduleInstances: PiGridModules, data: any, fixed: boolean) {
    super(position, moduleInstances, fixed);

    this.data = data;
  }

  // 画 icon
  public drawPropIcon(x: number, y: number, color: string, unicode: string, size: number) {
    const { Draw } = this.moduleInstances;
    const { ctx } = Draw;

    ctx.save();
    Draw.attr({
      fillStyle: color,
      textBaseline: 'middle',
      textAlign: 'start',
      font: `${Draw.npx(size)}px iconfont`,
    });

    Draw.fillText(Draw.iconFont(unicode), x, y);
    ctx.restore();
  }

  public click() {
    const { DataManager } = this.moduleInstances;
    if (DataManager.config.viewInfo.showLevelInFirstCol) return;
    DataManager.changeSortInfo(this.data.key);
    DataManager.config.onSort(DataManager.sortInfo);
  }
  public hover() {
    this.moduleInstances.DataManager.hoverHeaderCellKey = this.data.key;
  }
  public render() {
    //
    const { Draw, Render } = this.moduleInstances;
    const contentPageGeneralTableConfig = Render.config.contentPageGeneralTableConfig;
    const { x, y, width, height } = this.position;
    const hasMenu = this.data.key.indexOf('prop_') !== -1;
    const menuWidth = menuConfig.containerWidth;
    const styleMode = Draw.config.styleMode;

    const lineColor = styleMode === 'darkness' ? 'rgba(255,255,255,0.05)' : '#f6f6f6';
    Draw.line(
      [
        [x, y],
        [x, y + height],
      ],
      lineColor,
    );

    const attrsStyle = Draw.config.viewInfo.attrsStyle || {};
    let cellBgColor = attrsStyle[this.data.key]
      ? attrsStyle[this.data.key].header.backgroundColor
      : styleMode === 'darkness'
        ? this.STYLE.darknessBg
        : this.STYLE.hoverBg;
    const cellFontColor = attrsStyle[this.data.key]
      ? attrsStyle[this.data.key].header.color
      : styleMode === 'darkness'
        ? this.STYLE.darknessFontColor
        : '#242D3F';
    const cellFontBold = attrsStyle[this.data.key] ? attrsStyle[this.data.key].header.bold : false;

    if (Draw.config.navMode === 'elecwatt') cellBgColor = elecwattConfig.tableHeaderBgColor;
    Draw.fillRect(x, y, width, height, cellBgColor);
    // text
    Draw.attr({
      fillStyle: cellFontColor,
      textBaseline: 'middle',
      textAlign: 'start',
      font: `${cellFontBold ? 'bold ' : ''}${Draw.npx(14)}px sans-serif`,
    });

    let { name, type, key, config } = this.data;

    // 正文
    const viewTemplate = Render.config.viewTemplate;
    if (viewTemplate?.temp_option?.contentTemplate && key === 'mainbody') {
      name = viewTemplate?.temp_option?.contentTemplate.alias;
    }

    const isTemplateProp = key.slice(0, 4) === 'prop';
    let advance = 8;
    const rightGap = this.isHover ? 60 : 8;

    // 绘制属性的icon
    if (isTemplateProp) {
      this.drawPropIcon(
        x + advance,
        height / 2,
        styleMode === 'darkness' ? this.STYLE.darknessFontColor : '#767c88',
        PROP_TYPE[type].unicode,
        14,
      );
      advance += 18;
    }
    // 属性名称
    const propName = Draw.newFittingString(name, width - rightGap - advance);
    Draw.fillText(propName, x + advance, height / 2);
    let propNameWidth = Draw.measureTextWidth(propName);
    // 子表格属性必填
    const isChildTableAttrRequired =
      contentPageGeneralTableConfig.tab && isTemplateProp && config.required;
    if (isChildTableAttrRequired) {
      Draw.drawText(
        '*',
        '#f0665e',
        'middle',
        'start',
        14,
        x + advance + 3 + propNameWidth,
        height / 2 - 2,
      );
      propNameWidth += 8;
    }
    // 备注icon
    if (isTemplateProp && config.remark && propName === name) {
      const restWidth = width - advance - propNameWidth - rightGap;
      if (restWidth > 18)
        this.drawPropIcon(
          x + advance + propNameWidth + 4,
          height / 2,
          'rgb(49, 110, 245)',
          '&#xe6f9;',
          14,
        );
    }

    const gap = resizerConfig.width;
    // sort triangle
    const sortStartX = x + width - gap - 5 - menuWidth - 5 - sortTriangleConfig.width;
    const sortStartY = (height - sortTriangleConfig.containerHeight) / 2;
    const sortTriangle = new SortTriangle(
      {
        x: sortStartX,
        y: sortStartY,
        width: sortTriangleConfig.width,
        height: sortTriangleConfig.containerHeight,
      },
      this.moduleInstances,
      this.data,
    );
    if (this.isHover &&this.data.sorter && !Render.config.viewInfo.showLevelInFirstCol) sortTriangle.render();

    const resizer = new Resizer(
      {
        x:this.data.key === 'actions' ? x: x + width - resizerConfig.width,
        y: 0,
        height,
        width: resizerConfig.width,
      },
      this.moduleInstances,
      this.data,
    );
    resizer.render();

    if (
      this.data.key !== 'actions' &&
      !contentPageGeneralTableConfig.tab &&
      this.isHover &&
      !Render.config.viewInfo.allTemplates
    ) {
      // menu
      const headerMenu = new HeaderMenu(
        {
          x: sortStartX + sortTriangleConfig.width + 5,
          y: (height - menuConfig.containerHeight) / 2,
          width: menuWidth,
          height: menuConfig.containerHeight,
        },
        this.moduleInstances,
        this.data,
      );
      headerMenu.render();
    }

    // hover 展示属性类型
    if (this.isHover && isTemplateProp && config.remark) {
      Render.addToTriggerList(() => {
        const rectStartY = y + height + 5;
        const remarkWidth = Draw.measureTextWidth(config.remark);
        const limitWidth = 280;
        Draw.fillRoundRect(
          {
            x,
            y: rectStartY,
            width: remarkWidth + 20 > limitWidth ? limitWidth : remarkWidth + 20,
            height: 35,
          },
          5,
          '#4a4b4f',
        );
        Draw.drawText(
          Draw.newFittingString(
            config.remark,
            remarkWidth + 20 > limitWidth ? limitWidth - 20 : 99999,
          ),
          'white',
          'middle',
          'left',
          13,
          x + 10,
          rectStartY + 35 / 2,
        );
      });
    }
  }
}

export default HeaderCell;
