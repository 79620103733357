import type { ApiResponse } from '@linkpi/core';
import type { ViewList as ViewListType } from '@linkpi/core';
import type { ChartsInfo } from '@linkpi/core';
import type { GetterPiNode } from '@linkpi/core/web';
import type { FormInstance } from 'antd';
import { Button, Drawer, message, Modal } from 'antd';
import cls from 'classnames';
import { cloneDeep, random } from 'lodash';
import type { ForwardRefRenderFunction } from 'react';
import { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';

import { useOrgViewListRequest } from '@/hook/useOrgViewListRequest';
import { updateViewConfig } from '@/services/view';
import request from '@/utils/request';
import { dashboardCardContentStatNumColors } from '@/utils/utils';

import type { UseStatType } from '../../ListTable/useStat';
import AddForm from './AddForm';
import ViewList from './ViewList';

import styles from './styles.less';

export type TreeDataType = { value: string; title: string; children: TreeDataType[] };
export type ComponentTypeInfo = {
  type: 'more' | 'chart';
  cardBg: string;
  cardIcon: string;
  name: string;
  desc: string;
  color: string;
  namePlaceholder: string;
};
const getColor = () => {
  const index = random(dashboardCardContentStatNumColors.length - 1, false);
  return dashboardCardContentStatNumColors[index];
};
export const getViewIcon = (viewType: ApiResponse.ViewList.ViewType) => {
  switch (viewType) {
    case 1: // 看板
      return {
        icon: 'iconfont iconView_kanban',
        color: '#877ae9',
      };
    case 2: // 表格
      return {
        icon: 'iconfont iconView_table',
        color: '#67C2B7',
      };
    case 3: // 统计
      return {
        icon: 'iconfont iconView_Statistic',
        color: '#fda84a',
      };
    case 4: // gantt
      return {
        icon: 'iconfont icongantt',
        color: '#70bff3',
      };
    default:
      return {
        icon: 'iconfont iconView_kanban',
        color: '#877ae9',
      };
  }
};

export const DASHBOARD_COMPONENTS: Record<ComponentTypeInfo['type'], ComponentTypeInfo> = {
  more: {
    type: 'more',
    cardBg: 'http://ljp-static.oss-cn-hangzhou.aliyuncs.com/dashboard-card-bg.png',
    cardIcon: 'iconfont iconView_statisticstongjizhibiao',
    name: '统计&指标',
    desc: '展示指定主题类型的统计值或指定属性的统计值',
    color: '#5E80F7',
    namePlaceholder: '请输入统计指标的名称',
  },
  chart: {
    type: 'chart',
    cardBg: 'http://ljp-static.oss-cn-hangzhou.aliyuncs.com/dashboard-card-bg2.png',
    cardIcon: 'iconfont iconView_Statistic',
    name: '统计视图',
    desc: '以柱状图、条形图、折线图、多维统计图展示数据',
    color: '#FDA84A',
    namePlaceholder: '请输入视图名称',
  },
};

const DASHBOARD_COMPONENT_LIST = Object.values(DASHBOARD_COMPONENTS);

type ComponentFromPropsType = {
  orgInfo: ApiResponse.CurrentUser.OrgInfo;
  node: GetterPiNode;
  dashboardViewData: ApiResponse.ViewList.ViewListItem<5>;
  editType?: 'add' | 'edit';
  editViewData?: ApiResponse.ViewList.ViewListItem<any> | null;
  cardIndex?: number;
  containerHeight?: string | number;
  displayType?: 'modal' | 'drawer';
  userMap: Record<string, ApiResponse.OrgUser.OrgUserItem>;
  groups: any[];
  getNewCardLayout: (type: IDashboardComponentFrom['type']) => {
    x: number;
    y: number;
    w: number;
    h: number;
  };
  getViewList: () => void;
  onAddSuccess?: () => void;
};

export type ComponentFormRefType = {
  open: () => void;
};

export interface IDashboardComponentFrom {
  // 小组件类型 图表和其他统计
  type: 'more' | 'chart';
  name: string;
  // 数据来源 自定义和关联视图
  filterType: 'custom' | 'relation';
  // 自定义的视图筛选项
  conditions: ApiResponse.ViewList.Viewcondition[];
  // 筛选范围
  parentId: ApiResponse.ViewList.ViewInfo['parentId'];
  // 关联的视图的视图配置
  relation: null | ApiResponse.ViewList.ViewListItem<any>;
  viewId: string; // 关联视图的 id
  statType: 'count' | 'prop'; // 统计类型
  // 统计配置
  statProp: null | { key: string; value: UseStatType };
  /**
   * 关联了视图时，对应主题类型
   */
  statTempId?: string;
  matchings: Array<{ origin: string; target: string; key: string }>;
  // 配置
  group?: string;
  viewType?: ApiResponse.ViewList.ViewType;
  layout?: 'no-photo' | 'photo-col' | 'photo-row';
  groupImgList?: { key: string | 'default_img'; url: string }[];
  /**
   * 显示所有的分组
   *
   * 仅当根据选值属性分组时生效
   */
  getEmptyGroup?: boolean;
}

export const getInitForm = (
  type: IDashboardComponentFrom['type'] = 'more',
  viewType: ViewListType.ViewType = 1,
): IDashboardComponentFrom => {
  const res: IDashboardComponentFrom = {
    type,
    name: '',
    filterType: 'custom',
    conditions: [{ key: 'templateId', value: null }],
    parentId: null,
    relation: null,
    statType: 'count',
    statProp: null,
    viewType,
  };

  return res;
};

const ComponentForm: ForwardRefRenderFunction<ComponentFormRefType, ComponentFromPropsType> = (
  props,
  ref,
) => {
  useImperativeHandle(ref, () => ({
    open: () => {
      handleOpen();
    },
  }));

  const {
    getViewList,
    getNewCardLayout,
    onAddSuccess,
    orgInfo,
    node,
    dashboardViewData,
    editViewData,
    editType = 'add',
    cardIndex = 0,
    containerHeight = '100%',
  } = props;

  let displayType = 'modal';

  if (props.displayType) {
    displayType = props.displayType;
  } else if (editType === 'add') {
    displayType = 'modal';
  } else {
    displayType = 'drawer';
  }

  const addFormRef = useRef<any>();

  const [visible, setVisible] = useState(false);
  const [step, setStep] = useState(editType === 'add' ? 1 : 2);

  const [treeData, setData] = useState<TreeDataType[]>([]);
  const [componentForm, setComponentForm] = useState<IDashboardComponentFrom>(getInitForm());
  const componentFromRef = useRef<IDashboardComponentFrom>(componentForm);
  componentFromRef.current = componentForm;

  // 修改单个
  const setComponentFormItem = <U extends keyof IDashboardComponentFrom>(
    key: U,
    value: IDashboardComponentFrom[U],
  ) => {
    setComponentForm({
      ...componentFromRef.current,
      [key]: value,
    });
  };

  // 批量修改
  const setComponentFromItems = (items: Partial<IDashboardComponentFrom>) => {
    setComponentForm({
      ...componentFromRef.current,
      ...items,
    });
  };

  const getChildren = (item: PiNode, name?: string): TreeDataType => {
    return {
      value: item.id,
      title: name || item.title || '无标题',
      children: item.children ? item.children.map((x) => getChildren(x)) : [],
    };
  };
  // 设置树数据
  useEffect(() => {
    if (node && !treeData.length) {
      setData([getChildren(node.value.nodeManager.getRoot(), '根目录')]);
    }
  }, [node]);

  // 获取空间所有视图
  const { data: allViewList } = useOrgViewListRequest({ org_id: orgInfo.orgId });

  const modalTitle = useMemo(() => {
    if (step === 1) {
      return '添加小组件';
    }
    if (step === 2) {
      return (
        <Button type="text" onClick={() => setStep((step) => step - 1)} size="small">
          <i className="iconfont iconBack" />
        </Button>
      );
    }
    if (step === 3) {
      return (
        <div>
          <Button type="text" onClick={() => setStep((step) => step - 1)} size="small">
            <i className="iconfont iconBack" />
          </Button>
          请选择要关联的视图
        </div>
      );
    }
  }, [step]);

  const handleOpen = () => {
    if (editType === 'add') {
      setStep(1);
    } else {
      // 编辑
      setStep(2);
      const cardInfo = dashboardViewData.dashboard_info.view_list[cardIndex] || {};
      const type = editViewData?.view_type === 3 ? 'chart' : 'more';
      const name = editViewData?.view_type === 3 ? editViewData?.view_name : cardInfo.name;

      // let filterType: ComponentFromType['filterType'] = editViewData?.dash_view_id
      //   ? 'tempId'
      //   : 'relation';
      // const filterRelation = editViewData || null;
      // const filterTempId =
      //   filterType === 'tempId'
      //     ? editViewData?.view_info?.condition.find((x) => x.key === 'templateId')?.value
      //     : null;
      // if (type === 'more' && filterType === 'relation' && !filterRelation) {
      //   filterType = 'tempId';
      // }

      let filterType: IDashboardComponentFrom['filterType'];
      let conditions: any[] = [{ key: 'templateId', value: null }];
      let relation = null;
      let parentId = null;
      // 自定义视图
      if (editViewData?.dash_view_id) {
        filterType = 'custom';
        conditions = editViewData.view_info?.condition || [{ key: 'templateId', value: null }];
        parentId = editViewData.view_info?.parentId;
      } else {
        filterType = 'relation';
        // 关联视图
        relation = editViewData;
      }
      // ???
      if (type === 'more' && filterType === 'relation' && !relation) {
        filterType = 'custom';
      }

      let statType: IDashboardComponentFrom['statType'];
      let statProp;

      if (
        cardInfo.statTempId &&
        cardInfo.statTempId !==
          editViewData?.view_info?.condition.find((x) => x.key === 'templateId')?.value
      ) {
        statType = 'count';
        statProp = null;
      } else {
        statType = cardInfo.stat === null ? 'count' : 'prop';
        statProp =
          cardInfo.stat === null ? null : { key: cardInfo.stat.key, value: cardInfo.stat.value };
      }

      setComponentFromItems({
        type,
        name,
        filterType,
        conditions,
        parentId,
        relation,
        statType,
        statProp,
        group: editViewData?.view_info?.group,
        viewType: editViewData?.view_type,
      });
    }
    setVisible(true);
  };

  const handleComponentTypeCard = (info: ComponentTypeInfo) => {
    setComponentFromItems({
      type: info.type,
      name: '',
      filterType: 'custom',
      conditions: [{ key: 'templateId', value: null }],
      parentId: null,
      relation: null,
      statType: 'count',
      statProp: null,
    });
    setStep(2);
  };

  const onViewClick = (viewInfo: ApiResponse.ViewList.ViewListItem<any>) => {
    setComponentFromItems({
      filterType: 'relation',
      conditions: [{ key: 'templateId', value: null }],
      parentId: null,
      relation: viewInfo,
      statType: 'count',
      statProp: null,
      group: viewInfo.view_info?.group || undefined,
    });
    setStep(2);
  };

  const getViewPath = (nodeId: string) => {
    const path = node.value.nodeManager.findChildrenPathDoc(nodeId);
    return [orgInfo.orgName, ...(path || []).map((x) => x.title)].join(' > ');
  };

  const handleAdd = async () => {
    let relationViewId = '';
    let newDashBoardInfoViewList: ApiResponse.ViewList.DashboardInfoViewListItem[] = [];
    const customTempId = componentForm.conditions.find((x) => x.key === 'templateId')?.value;
    // 创建小组件时，小组件视图权限为公开
    if (componentForm.type === 'more') {
      // 先创建视图，再在组件信息里保存该视图
      if (componentForm.filterType === 'custom') {
        // 默认创建看板 1
        if (!componentForm.name) {
          return message.error('请输入组件名称');
        }
        if (!customTempId) {
          return message.error('请选择主题类型');
        }
        const createViewReq = {
          node_id: node.value.id,
          org_id: orgInfo.orgId,
          view_name: componentForm.name,
          view_type: 1,
          public: false,
          public_groups: ['-1'],
          modify_permission: 2,
          view_info: {
            condition: componentForm.conditions,
            parentId: componentForm.parentId,
            numColor: getColor(),
          },
          template_id: customTempId,
          dash_view_id: dashboardViewData.view_id,
        };

        const createViewRes = await request('/api/view/create', {
          method: 'POST',
          data: createViewReq,
        });
        if (createViewRes.status !== 'ok') {
          return message.error('组件添加失败');
        }

        relationViewId = createViewRes.data;
      }

      if (componentForm.filterType === 'relation') {
        if (!componentForm.name) {
          return message.error('请输入组件名称');
        }
        if (!componentForm.relation) {
          return message.error('请选择关联视图');
        }
        relationViewId = componentForm.relation.view_id;
      }

      const stat = componentForm.statType === 'count' ? null : componentForm.statProp;
      const statTempId = (
        componentForm.filterType === 'custom'
          ? customTempId
          : componentForm.relation?.view_info?.condition.find((x) => x.key === 'templateId')
              ?.value || ''
      ) as string;

      const componentReq: ApiResponse.ViewList.DashboardInfoViewListItem = {
        viewId: relationViewId,
        layout: getNewCardLayout(componentForm.type),
        name: componentForm.name,
        stat: stat,
        statTempId: statTempId,
      };

      newDashBoardInfoViewList = [
        ...(dashboardViewData.dashboard_info?.view_list || []),
        componentReq,
      ];
    }

    if (componentForm.type === 'chart') {
      if (componentForm.filterType === 'custom') {
        const chartForm = addFormRef.current?.getChartForm() as FormInstance;
        await chartForm.validateFields();
        const values = await addFormRef.current?.getChartFormData();

        const charts_info: ChartsInfo = {
          ...values,
          verticalTerms: values.verticalTerms || [],
          conditions: values.conditions || [],
          verticalAxes: values.verticalAxes || {},
        };

        const createViewReq = {
          template_id: values.template,
          org_id: orgInfo.orgId,
          charts_info,
          node_id: node.value.id,
          view_type: 3,
          view_name: values.viewName,
          dash_view_id: dashboardViewData.view_id,
          public: true,
          public_groups: ['-1'],
          modify_permission: 2,
        };

        const createViewRes = await request('/api/view/create', {
          method: 'POST',
          data: createViewReq,
        });
        if (createViewRes.status !== 'ok') {
          return message.error('组件添加失败');
        }

        relationViewId = createViewRes.data;
      }
      if (componentForm.filterType === 'relation' && componentForm.relation) {
        relationViewId = componentForm.relation.view_id;
      }

      const componentReq: ApiResponse.ViewList.DashboardInfoViewListItem = {
        viewId: relationViewId,
        layout: getNewCardLayout(componentForm.type),
        name: '',
        stat: null,
        statTempId: null,
      };
      newDashBoardInfoViewList = [
        ...(dashboardViewData.dashboard_info?.view_list || []),
        componentReq,
      ];
    }

    // 修改当前dashboard视图的dashboardInfo
    const updateDashboardInfoRes = await request('/api/view/update', {
      method: 'POST',
      data: {
        view_id: dashboardViewData.view_id,
        dashboard_info: {
          view_list: newDashBoardInfoViewList,
        },
      },
    });
    if (updateDashboardInfoRes.status !== 'ok') {
      return message.error('组件添加失败');
    }
    getViewList();
    message.success('添加成功');
    setVisible(false);

    if (onAddSuccess) {
      onAddSuccess();
    }
  };

  // 保存/编辑
  const handleEdit = async () => {
    const cardInfo = dashboardViewData.dashboard_info.view_list[cardIndex] || {};
    const beforeViewData = editViewData as ApiResponse.ViewList.ViewListItem<any>;
    const newDashBoardInfoViewList: ApiResponse.ViewList.DashboardInfoViewListItem[] = cloneDeep(
      dashboardViewData.dashboard_info.view_list || [],
    );

    const isTempIdBefore = beforeViewData?.dash_view_id;
    const isTempIdAfter = componentForm.filterType === 'custom';
    const isRelationBefore = !beforeViewData?.dash_view_id;
    const isRelationAfter = componentForm.filterType === 'relation' && componentForm.relation;

    const customTempId = componentForm.conditions.find((x) => x.key === 'templateId')?.value;

    /**
     * TODO 封装重新梳理一下 感觉还有问题
     */
    // more
    // 1. 从自定义改成关联 要删除之前的视图 , 修改dashboard viewlist
    // 2. 从关联改成自定义 要创建视图  默认创建看板 1
    // 3. 修改自定义，
    // 3.1 修改视图的 template_id , name , view_info
    // 3.2 修改component配置
    // 4 修改关联
    // 4.1 修改分组项
    // 4.2  和 修改Component配置

    // chart
    // 1. 从自定义改成关联 要删除之前的视图
    // 2. 从关联改成自定义 要创建视图
    // 3. 修改自定义, 修改关联视图的配置
    // 不需要修改dash信息
    // 4. 修改关联，修改component关联viewId

    if (componentForm.type === 'more') {
      if (componentForm.filterType === 'custom' && !customTempId) {
        return message.error('请选择主题类型');
      }

      if (isTempIdBefore && isRelationAfter && componentForm.relation) {
        // 1. 从自定义改成关联 要删除之前的视图 , 修改dashboard viewlist
        request('/api/view/delete', {
          method: 'POST',
          data: {
            view_id: cardInfo.viewId,
          },
        });
        newDashBoardInfoViewList[cardIndex] = {
          ...cardInfo,
          viewId: componentForm.relation.view_id,
          stat: componentForm.statType === 'count' ? null : componentForm.statProp,
          statTempId:
            componentForm.relation.view_info?.condition.find((x) => x.key === 'templateId')
              ?.value || '',
          name: componentForm.name,
        };
      } else if (isRelationBefore && isTempIdAfter) {
        // 2. 从关联改成自定义 要创建视图
        // 默认创建看板 1
        const createViewReq = {
          node_id: node.value.id,
          org_id: orgInfo.orgId,
          view_name: componentForm.name,
          view_type: componentForm.viewType || 1,
          public: false,
          public_groups: ['-1'],
          modify_permission: 2,
          view_info: {
            condition: componentForm.conditions,
            group: componentForm.group || null,
            parentId: componentForm.parentId,
          },
          template_id: customTempId,
          dash_view_id: dashboardViewData.view_id,
        };

        const createViewRes = await request('/api/view/create', {
          method: 'POST',
          data: createViewReq,
        });
        if (createViewRes.status !== 'ok') {
          return message.error('保存失败');
        }

        newDashBoardInfoViewList[cardIndex] = {
          ...cardInfo,
          viewId: createViewRes.data,
          stat: componentForm.statType === 'count' ? null : componentForm.statProp,
          statTempId: customTempId,
          name: componentForm.name,
        };
      } else {
        // 3.修改自定义，
        // 3.1 修改视图的 template_id , name , view_info
        if (componentForm.filterType === 'custom') {
          const req = {
            org_id: orgInfo.orgId,
            view_id: beforeViewData.view_id,
            view_name: componentForm.name,
            view_info: {
              condition: componentForm.conditions,
              group: componentForm.group || undefined,
              parentId: componentForm.parentId,
            },
            template_id: customTempId,
            view_type: componentForm.viewType || 1,
          };

          const res = await updateViewConfig(req);

          if (res.status !== 'ok') {
            return;
          }

          // 3.2 修改component配置
          newDashBoardInfoViewList[cardIndex] = {
            ...cardInfo,
            viewId: componentForm.relation?.view_id || cardInfo.viewId,
            stat: componentForm.statType === 'count' ? null : componentForm.statProp,
            statTempId: customTempId,
            name: componentForm.name,
          };
        } else {
          // 4 修改关联
          // 4.1 修改分组项
          // 不存在beforeViewData 不用修改
          if (beforeViewData) {
            const req = {
              org_id: orgInfo.orgId,
              view_id: beforeViewData.view_id,
              view_info: {
                ...beforeViewData.view_info,
                group: componentForm.group || undefined,
              },
              view_type: componentForm.viewType || 1,
            };
            updateViewConfig(req);
          }

          // 4.2  和 修改Component配置
          newDashBoardInfoViewList[cardIndex] = {
            ...cardInfo,
            viewId: componentForm.relation?.view_id || cardInfo.viewId,
            stat: componentForm.statType === 'count' ? null : componentForm.statProp,
            statTempId:
              componentForm.relation?.view_info?.condition.find((x) => x.key === 'templateId')
                ?.value || '',
            name: componentForm.name,
          };
        }
      }
    } else {
      // chart
      // 1. 从自定义改成关联 要删除之前的视图
      if (isTempIdBefore && isRelationAfter && componentForm.relation) {
        request('/api/view/delete', {
          method: 'POST',
          data: {
            view_id: cardInfo.viewId,
          },
        });
        newDashBoardInfoViewList[cardIndex] = {
          ...cardInfo,
          viewId: componentForm.relation.view_id,
          name: componentForm.name,
          stat: null,
        };
      } else if (isRelationBefore && isTempIdAfter) {
        // 2. 从关联改成自定义 要创建视图
        const chartForm = addFormRef.current?.getChartForm() as FormInstance;
        await chartForm.validateFields();
        const values = await addFormRef.current?.getChartFormData();

        const charts_info: ChartsInfo = {
          ...values,
          verticalTermsGroupMap: values?.verticalTermsGroupMap || [],
          verticalTerms: values.verticalTerms || [],
          conditions: values.conditions || [],
          verticalAxes: values.verticalAxes || {},
        };

        if (values.verticalGranularity) {
          charts_info.verticalGranularity = values.verticalGranularity;
        }
        const createViewReq = {
          template_id: values.template,
          org_id: orgInfo.orgId,
          charts_info,
          node_id: node.value.id,
          view_type: 3,
          view_name: values.viewName,
          dash_view_id: dashboardViewData.view_id,
          public: false,
          public_groups: ['-1'],
          modify_permission: 2,
        };

        const createViewRes = await request('/api/view/create', {
          method: 'POST',
          data: createViewReq,
        });
        if (createViewRes.status !== 'ok') {
          return message.error('保存失败');
        }

        newDashBoardInfoViewList[cardIndex] = {
          ...cardInfo,
          viewId: createViewRes.data,
          name: componentForm.name,
          stat: null,
        };
      } else {
        // 3. 修改自定义, 修改关联视图的配置
        if (componentForm.filterType === 'custom') {
          const chartForm = addFormRef.current?.getChartForm() as FormInstance;
          await chartForm.validateFields();
          const values = await addFormRef.current?.getChartFormData();

          const charts_info: ChartsInfo = {
            ...values,
            verticalTerms: values.verticalTerms || [],
            conditions: values.conditions || [],
            verticalAxes: values.verticalAxes || {},
            verticalTermsGroupMap: values?.verticalTermsGroupMap || [],
          };

          const reqData = {
            template_id: values.template,
            public: values.viewPublic,
            org_id: orgInfo.orgId,
            charts_info,
            view_id: beforeViewData.view_id,
            view_name: values.viewName,
          };

          const res = await updateViewConfig(reqData);

          if (res?.status === 'ok') {
            message.success(values.view_id ? '修改成功' : '新增成功');
            getViewList();
            setVisible(false);
          } else {
            message.error(values.view_id ? '修改失败' : '新增失败');
          }

          // 不需要修改dash信息
          return;
        } else {
          // 4. 修改关联，修改component关联viewId
          newDashBoardInfoViewList[cardIndex] = {
            ...cardInfo,
            viewId: componentForm.relation?.view_id || cardInfo.viewId,
          };
        }
      }
    }

    // 操作newDashBoardInfoViewList修改
    const res = await request('/api/view/update', {
      method: 'POST',
      data: {
        view_id: dashboardViewData.view_id,
        dashboard_info: {
          view_list: newDashBoardInfoViewList,
        },
      },
    });

    if (res.status === 'ok') {
      message.success('保存成功');
      getViewList();
      setVisible(false);
    } else {
      return message.error('保存失败');
    }
  };

  let modalContent = null;
  if (step === 1) {
    modalContent = (
      <div className={cls(styles.container, styles.step1)} style={{ height: containerHeight }}>
        {DASHBOARD_COMPONENT_LIST.map((x, i) => (
          <div
            key={i}
            className={styles.componentTypeCard}
            onClick={() => handleComponentTypeCard(x)}
          >
            <img className={styles.componentTypeCardBg} src={x.cardBg} />
            <div className={styles.componentTypeCardIcon} style={{ backgroundColor: x.color }}>
              <i className={x.cardIcon} />
            </div>
            <p className={styles.componentTypeCardName}>{x.name}</p>
            <p className={styles.componentTypeCardDesc}>{x.desc}</p>
          </div>
        ))}
      </div>
    );
  }
  if (step === 2) {
    modalContent = (
      <AddForm
        ref={addFormRef}
        editType={editType}
        node={node}
        componentForm={componentForm}
        setComponentFormItem={setComponentFormItem}
        setComponentFromItems={setComponentFromItems}
        componentTypeInfo={DASHBOARD_COMPONENTS[componentForm.type]}
        onRelation={() => setStep(3)}
        getViewPath={getViewPath}
        getViewIcon={getViewIcon}
        editViewData={editViewData}
      />
    );
  }

  if (step === 3) {
    modalContent = (
      <ViewList
        viewList={allViewList || []}
        componentType={componentForm.type}
        onViewClick={onViewClick}
        getViewPath={getViewPath}
        getViewIcon={getViewIcon}
        containerHeight={containerHeight}
      />
    );
  }

  let modalFooter = null;
  if (step === 2) {
    modalFooter = (
      <Button type="primary" onClick={editType === 'add' ? handleAdd : handleEdit}>
        {editType === 'add' ? '添加小组件' : '保存'}
      </Button>
    );
  }

  if (displayType === 'modal') {
    return (
      <Modal
        wrapClassName="dashboard-modal"
        title={modalTitle}
        open={visible}
        width={634}
        footer={modalFooter}
        bodyStyle={{ height: 670 }}
        onCancel={() => setVisible(false)}
        destroyOnClose
        centered
      >
        {modalContent}
      </Modal>
    );
  } else {
    return (
      <Drawer
        className="dashboard-drawer"
        mask={false}
        getContainer={false}
        open={visible}
        onClose={() => setVisible(false)}
        title="设置小组件"
        destroyOnClose
        footer={
          <div style={{ textAlign: 'right' }}>
            <Button onClick={() => setVisible(false)}>取消</Button>
            <Button type="primary" style={{ marginLeft: 24 }} onClick={handleEdit}>
              确认
            </Button>
          </div>
        }
      >
        {modalContent}
      </Drawer>
    );
  }
};

export default forwardRef(ComponentForm);
