import type { ApiResponse } from '@linkpi/core';
import { DEFAULT_TEMPLATE } from '@linkpi/core';
import { fixConditionV2ToFilterConfig } from '@linkpi/core';
import type { GetterPiNode } from '@linkpi/core/web';
import { useSize, useThrottleFn } from 'ahooks';
import { message, Spin } from 'antd';
import cls from 'classnames';
import { memo, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'umi';

import { PiButton } from '@/components/Button/index';
import { conditionConvertConditionV2 } from '@/components/ConditionFilter';
import LinkPiPop from '@/components/LinkPiPop';
import type { IWidgetInstanceData } from '@/components/PageModelEditor';
import type { IStatisticFormValue } from '@/components/PageModelEditor/src/components/StatisticComponents/Form';
import { usePageThemeMode } from '@/hook';
import request from '@/utils/request';

import Charts from '../Charts';
import { getModifyPermission } from '../hooks';
import type { statDataType } from '../ListTable/useStat';
import { getStatUnitConfig } from '../ListTable/useStat';
import type { DashBoardCardType } from '.';
import { CardContentStat, CardContentStatByGroup } from './CardContentStat';
import type { ComponentFormRefType } from './ComponentForm';
import ComponentForm from './ComponentForm';
import PreviewComponent from './PreviewComponent';

import styles from './styles.less';

type DashBoardCardPropsType = {
  currentUser: ApiResponse.CurrentUser;
  node: GetterPiNode;
  cardViewInfo: DashBoardCardType;
  index: number;
  curViewData: ApiResponse.ViewList.ViewListItem<5>;
  orgInfo: ApiResponse.CurrentUser.OrgInfo;
  userMap: Record<string, ApiResponse.OrgUser.OrgUserItem>;
  groups: ApiResponse.CurrentUser.orgGroup[];
  onRename: (dashboardInfo: DashBoardCardType, index: number) => void;
  getViewList: () => void;
  getNewCardLayout: (type: 'more' | 'chart') => {
    x: number;
    y: number;
    w: number;
    h: number;
  };
  dashboardEditPermission: boolean;
  templateMap: Record<string, ApiResponse.CurrentUser.TemplateInfo>;
  data: IWidgetInstanceData & {
    widgetId:
      | 'PieChart'
      | 'LineChart'
      | 'HorizontalBarChart'
      | 'BarChart'
      | 'Indicator'
      | 'MultidimensionalStatistic';
  };
};

const DashBoardCard = (props: DashBoardCardPropsType) => {
  const {
    index,
    node,
    cardViewInfo,
    orgInfo,
    userMap,
    onRename,
    curViewData,
    getViewList,
    getNewCardLayout,
    groups,
    currentUser,
    dashboardEditPermission,
    templateMap,
    data,
  } = props;
  const [loading, setLoading] = useState(true);

  const { isDark } = usePageThemeMode();

  const template: ApiResponse.CurrentUser.TemplateInfo | null = useMemo(() => {
    const viewInfo = cardViewInfo.config?.view_info;
    const condition = viewInfo?.condition || [];
    const tempCondition = condition.find((x) => x.key === 'templateId');
    const tempId = tempCondition ? tempCondition.value : DEFAULT_TEMPLATE;
    const temp = templateMap[tempId];
    return temp;
  }, [cardViewInfo, templateMap]);

  const [statConfig, statUnitConfig] = useMemo(() => {
    if (!cardViewInfo.stat || !template) {
      return [undefined, null];
    } else {
      const config = { [cardViewInfo.stat.key]: cardViewInfo.stat.value };
      const unitConfig = getStatUnitConfig(
        cardViewInfo.stat.key,
        cardViewInfo.stat.value,
        template.template_id,
        templateMap,
      );
      return [config, unitConfig];
    }
  }, [cardViewInfo, template, templateMap]);

  // 统计
  // 没分组的时候是number
  const [statData, setStatData] = useState<number | Record<string, number>>(0);
  // 预览
  const [visiblePreview, setVisiblePreview] = useState(false);

  const componentFromRef = useRef<ComponentFormRefType>(null);

  const dispatch = useDispatch();

  // 模板id
  const { viewTemplatePropList, templateName } = useMemo(() => {
    return {
      viewTemplatePropList: template ? template.prop : [],
      templateName: template ? template.name : '',
    };
  }, [template]);

  useEffect(() => {
    if (!cardViewInfo.config || cardViewInfo.config.view_type === 3 || !template) {
      setLoading(false);
    } else {
      getViewData();
    }
  }, [node, orgInfo, cardViewInfo, userMap, template]);

  const [viewName, isRelationViewName] = useMemo(() => {
    // 如果删除被删了没法判断，直接显示cardViewInfo的名字
    if (!cardViewInfo.config) {
      return [cardViewInfo.name || '', false];
    }

    // 统计视图，显示视图的名字
    // 如果是固定的视图，显示带蓝线的名字
    if (cardViewInfo.config.view_type === 3) {
      if (cardViewInfo.config.dash_view_id === null) {
        return [cardViewInfo.config.view_name, true];
        // return <div className={styles.cardHeaderNameLine}>{}</div>;
      } else {
        return [cardViewInfo.config.view_name, false];
      }
    } else {
      // 其他视图，显示cardViewInfo的名字
      return [cardViewInfo.name, false];
    }
  }, [cardViewInfo.config]);

  // 组件视图编辑权限
  const cardViewEditPermission = useMemo(() => {
    if (!cardViewInfo.config) return false;
    return getModifyPermission(
      cardViewInfo.config.modify_permission,
      orgInfo.role,
      currentUser.userid === cardViewInfo.config.account_id,
    );
  }, [currentUser, orgInfo, cardViewInfo]);

  // 计算视图数据
  const { run: getViewData } = useThrottleFn(
    async () => {
      if (!cardViewInfo.config) return;
      const viewConfig = cardViewInfo.config;

      let filterReq: any = {
        recycle: false,
        temp_id: template?.template_id,
        keyWords: [],
        onlyTitle: false,
        org_id: orgInfo.orgId,
        tempProps: [],
        groupBy: [],
        filterTime: {},
      };

      // 筛选条件 直接给conditionV2
      const viewInfo = cardViewInfo.config.view_info as ApiResponse.ViewList.ViewInfo;
      if (!viewInfo) return;
      const conditionV2 = viewInfo.conditionV2
        ? viewInfo.conditionV2
        : conditionConvertConditionV2(viewInfo.condition, null, orgInfo.rootId, templateMap);
      filterReq.conditionV2 = fixConditionV2ToFilterConfig(conditionV2, currentUser.userid);

      // 统计
      let retStat = { prop: -1, type: 'count' };
      if (cardViewInfo?.stat) {
        retStat = {
          prop: parseInt(cardViewInfo?.stat?.key.split('_')[1]),
          type: cardViewInfo?.stat?.value,
        };
      }
      filterReq.retStat = retStat;

      // target 是当前主题类型。 就是当前节点的属性index
      const tempProps = node.value.tempInfo.prop;
      const matchings = (viewConfig.view_info?.matchings || []).map((m: any) => {
        const { origin, target } = m;
        const propValue = tempProps[target];
        return {
          op: 'intersect',
          key: 'prop',
          input: Array.isArray(propValue) ? propValue : [propValue],
          index: origin,
        };
      });

      filterReq.conditionV2 = [...filterReq.conditionV2, ...matchings];

      //分组条件
      filterReq = handleGroupCondition(filterReq, getViewInfo(viewConfig.view_info));

      const sortBy = getOrderByParam(viewConfig.view_info);

      if (sortBy) {
        filterReq.sortBy = sortBy;
        filterReq.arrayGroup = true;
      } else {
        delete filterReq.sortBy;
        delete filterReq.arrayGroup;
      }

      if (viewInfo.getEmptyGroup) {
        filterReq.getEmptyGroup = viewInfo.getEmptyGroup;
      }

      const resFilter: any = await request('/docapi/getNodeByFilter', {
        method: 'POST',
        data: filterReq,
      });

      if (resFilter.status === 'ok') {
        setStatData(resFilter.data);
      } else {
        setStatData(0);
      }
      setLoading(false);
    },
    { wait: 5000 },
  );

  const onPreview = () => {
    // Modal预览
    setVisiblePreview(true);
  };
  const onSetting = () => {
    // 设置
    componentFromRef.current?.open();
  };

  const onJumpLinkView = () => {
    // 跳转关联视图
    if (!cardViewInfo.config) return;
    dispatch({
      type: 'workspace/setCurrentSelection',
      payload: {
        selectNode: cardViewInfo.config.node_id,
        selectFocus: orgInfo.rootId,
        selectViewId: cardViewInfo.config.view_id,
      },
    });
  };

  // 删除
  const onDelete = async () => {
    // 通过dashboard创建的，并且没有固定的视图（dash_view_id）,删除源视图
    // 其他的，只要移除dashboard_info中的view，就可以了
    if (cardViewInfo.config && cardViewInfo.config.dash_view_id !== null) {
      request('/api/view/delete', {
        method: 'POST',
        data: {
          view_id: cardViewInfo.config.view_id,
        },
      });
    }

    // 修改cardViewInfo
    const newDashBoardInfoViewList = curViewData.dashboard_info.view_list.filter(
      (x, i) => i !== index,
    );
    const updateDashboardInfoRes = await request('/api/view/update', {
      method: 'POST',
      data: {
        view_id: curViewData.view_id,
        dashboard_info: {
          view_list: newDashBoardInfoViewList,
        },
      },
    });

    if (updateDashboardInfoRes.status === 'ok') {
      // 删除成功
      getViewList();
    }
  };

  // 固定
  const onFix = async () => {
    const updateDashboardInfoRes = await request('/api/view/update', {
      method: 'POST',
      data: {
        view_id: cardViewInfo.viewId,
        public: curViewData.public,
        public_groups: curViewData.public_groups,
        modify_permission: curViewData.modify_permission,
        dash_view_id: null,
      },
    });

    if (updateDashboardInfoRes.status === 'ok') {
      message.success('操作成功');
      getViewList();
    }
  };

  return (
    <Spin spinning={loading} wrapperClassName={styles.cardWrapper}>
      <div
        className={cls(
          styles.card,
          !cardViewInfo.config ? styles.deleteCard : '',
          'dashboard-card',
        )}
      >
        <CardHeader
          viewName={viewName}
          isRelationViewName={isRelationViewName}
          cardViewInfo={cardViewInfo}
          onPreview={onPreview}
          onRename={() => onRename(cardViewInfo, index)}
          onJumpLinkView={onJumpLinkView}
          onDelete={onDelete}
          onSetting={onSetting}
          onFix={onFix}
          isDark={isDark}
          cardViewEditPermission={cardViewEditPermission}
          dashboardEditPermission={dashboardEditPermission}
        />
        <div className={styles.cardContentWrap}>
          <CardContent
            cardViewEditPermission={cardViewEditPermission}
            cardViewInfo={cardViewInfo}
            orgInfo={orgInfo}
            statData={statData}
            isDark={isDark}
            propConfigList={viewTemplatePropList}
            userMap={userMap}
            templateName={templateName}
            onSetting={onSetting}
            statUnitConfig={statUnitConfig}
            onPreview={onPreview}
            node={node}
            data={data}
          />
        </div>
      </div>
      {cardViewInfo.config && (
        <PreviewComponent
          orgInfo={orgInfo}
          viewName={viewName}
          curViewData={cardViewInfo.config}
          node={node}
          visible={visiblePreview}
          onClose={setVisiblePreview}
          onFix={onFix}
          onJump={onJumpLinkView}
          groups={groups}
          currentUser={currentUser}
          cardContent={
            <CardContent
              cardViewEditPermission={cardViewEditPermission}
              cardViewInfo={cardViewInfo}
              orgInfo={orgInfo}
              statData={statData}
              isDark={isDark}
              propConfigList={viewTemplatePropList}
              userMap={userMap}
              templateName={templateName}
              onSetting={onSetting}
              statUnitConfig={statUnitConfig}
              node={node}
              data={data}
            />
          }
          getViewList={getViewList}
          dashboardViewInfo={curViewData}
          cardIndex={index}
          cardViewEditPermission={cardViewEditPermission}
          dashboardEditPermission={dashboardEditPermission}
          getNewCardLayout={getNewCardLayout}
        />
      )}

      <ComponentForm
        groups={groups}
        ref={componentFromRef}
        orgInfo={orgInfo}
        node={node}
        getViewList={getViewList}
        dashboardViewData={curViewData}
        editType="edit"
        displayType="modal"
        editViewData={cardViewInfo.config}
        cardIndex={index}
        userMap={userMap}
        getNewCardLayout={getNewCardLayout}
      />
    </Spin>
  );
};

/**
 * card header
 */
type CardHeaderPropsType = {
  viewName: string;
  isRelationViewName: boolean;
  isDark: boolean;
  onPreview: () => void;
  onRename: () => void;
  onJumpLinkView: () => void;
  onDelete: () => void;
  onSetting: () => void;
  onFix: () => void;
  cardViewInfo: DashBoardCardType;
  cardViewEditPermission: boolean;
  dashboardEditPermission: boolean;
};
const CardHeader = (props: CardHeaderPropsType) => {
  const {
    viewName,
    isRelationViewName,
    cardViewInfo,
    onPreview,
    onRename,
    onJumpLinkView,
    onDelete,
    onSetting,
    onFix,
    isDark = false,
    cardViewEditPermission,
    dashboardEditPermission,
  } = props;

  const [visibleMenu, setVisibleMenu] = useState(false);

  const menus = [];
  // 未固定的组件内创建的视图，可以固定
  const enableFix =
    cardViewInfo.config && cardViewInfo.config.dash_view_id !== null && dashboardEditPermission;

  // 关联视图可以跳转
  const enableJump =
    cardViewInfo.config && cardViewInfo.config.dash_view_id === null && cardViewEditPermission;

  if (enableFix) {
    menus.push({
      label: '设为固定视图',
      handler: () => {
        setVisibleMenu(false);
        onFix();
      },
    });
  }
  if (dashboardEditPermission) {
    menus.push({
      label: '重命名',
      handler: () => {
        setVisibleMenu(false);
        onRename();
      },
    });
    menus.push({
      label: '设置小组件',
      handler: () => {
        setVisibleMenu(false);
        onSetting();
      },
    });
  }
  if (enableJump) {
    menus.push({
      label: '跳转到关联视图',
      handler: () => {
        setVisibleMenu(false);
        onJumpLinkView();
      },
    });
  }
  if (dashboardEditPermission) {
    menus.push({
      label: '删除',
      handler: () => {
        setVisibleMenu(false);
        onDelete();
      },
    });
  }
  return (
    <div className={styles.cardHeader}>
      <div className="draggableHandle">
        <i className="iconfont icondrag" />
      </div>
      <div
        className={cls(
          styles.cardHeaderName,
          'text-omit',
          isRelationViewName ? 'cardHeaderNameLine' : '',
        )}
        style={{ color: !isDark ? '#242D3F' : '#FFFFFF' }}
      >
        {viewName}
      </div>
      <div className={styles.cardHeaderTools}>
        {cardViewInfo.config ? (
          <div
            className={!isDark ? styles.cardHeaderToolsItem : styles.cardHeaderDarkToolsItem}
            onClick={onPreview}
          >
            <PiButton icon={<i className={'iconfont iconneirongye-dakai2'} />} type="secondary" />
          </div>
        ) : null}

        {enableJump ? (
          <div
            className={!isDark ? styles.cardHeaderToolsItem : styles.cardHeaderDarkToolsItem}
            onClick={onJumpLinkView}
          >
            <PiButton icon={<i className="iconfont iconchangyong-tiaozhuan" />} type="secondary" />
          </div>
        ) : null}

        {menus.length ? (
          <LinkPiPop
            trigger={'click'}
            insideDom={
              <div className={cls(styles.cardHeaderToolsItem, 'dashboard-card-menus')}>
                <PiButton type="secondary" icon={<i className="iconfont iconcommunity_more" />} />
              </div>
            }
            visible={visibleMenu}
            onVisibleChange={(e: boolean) => setVisibleMenu(e)}
            placement={'bottom'}
            menus={menus}
            menuWidth={164}
            noPadding={true}
          />
        ) : null}
      </div>
    </div>
  );
};

type CardContentPropsType = {
  orgInfo: ApiResponse.CurrentUser.OrgInfo;
  cardViewInfo: DashBoardCardType;
  cardViewEditPermission: boolean;
  statData: statDataType;
  templateName: string;
  isDark: boolean;
  propConfigList: ApiResponse.CurrentUser.TemplateProp[];
  userMap: Record<string, ApiResponse.OrgUser.OrgUserItem>;
  onSetting: () => void;
  statUnitConfig: null | ApiResponse.CurrentUser.numberExtend;
  onPreview: () => void;
  node: GetterPiNode;
  data: IWidgetInstanceData & {
    widgetId:
      | 'PieChart'
      | 'LineChart'
      | 'HorizontalBarChart'
      | 'BarChart'
      | 'Indicator'
      | 'MultidimensionalStatistic';
  };
};
const CardContent = memo(function _CardContent(props: CardContentPropsType) {
  const {
    cardViewInfo,
    orgInfo,
    cardViewEditPermission,
    statData,
    propConfigList,
    userMap,
    isDark = false,
    templateName,
    onSetting,
    onPreview,
    statUnitConfig,
    node,
    data,
  } = props;

  const cardContentElementRef = useRef<HTMLDivElement>(null);

  const size = useSize(cardContentElementRef) || { width: 0, height: 0 };
  let content = null;
  if (size?.width && size?.height) {
    if (!cardViewInfo.config) {
      content = (
        <div className={styles.deleteCardContent}>
          <div className={styles.deleteCardContentIcon} />
          <div className={styles.deleteCardContentText}>
            关联视图已被删除/无权限
            {cardViewEditPermission && (
              <div className={styles.deleteCardContentBtn} onClick={onSetting}>
                关联其他视图
              </div>
            )}
          </div>
        </div>
      );
    } else if (
      cardViewInfo.config.view_type !== 3 &&
      cardViewInfo.config.dash_view_id === null &&
      cardViewInfo.statTempId !==
        cardViewInfo.config.view_info?.condition.find((x) => x.key === 'templateId')?.value
    ) {
      content = (
        <div className={styles.deleteCardContent}>
          <div className={styles.deleteCardContentIcon} />
          <div className={styles.deleteCardContentText}>
            关联视图的主题类型修改，统计指标已失效，
            <div className={styles.deleteCardContentBtn} onClick={onSetting}>
              设置组件
            </div>
          </div>
        </div>
      );
    } else if (cardViewInfo.config.view_type === 3) {
      // 多维统计视图 只显示表格
      const showTable = cardViewInfo.config.charts_info?.type === 4;
      content = (
        <Charts
          curViewData={cardViewInfo.config}
          orgInfo={orgInfo}
          showTable={showTable}
          showTools={false}
          isDark={isDark}
          showDataZoom={false}
          contentWidth={size.width}
          height={size.height}
          userMap={userMap}
          node={node}
        />
      );
    } else {
      const viewInfo = (cardViewInfo.config as ApiResponse.ViewList.ViewListItem<1>).view_info;
      const hasGroupData = !!statData && !!Object.keys(statData).length;

      if (viewInfo.group && hasGroupData) {
        content = (
          <CardContentStatByGroup
            config={data?.config.statistic as IStatisticFormValue<'more'>}
            cardViewInfo={cardViewInfo}
            width={size.width}
            height={size.height}
            statData={statData}
            propConfigList={propConfigList}
            userMap={userMap}
            statUnitConfig={statUnitConfig}
            onPreview={onPreview}
          />
        );
      } else {
        content = (
          <CardContentStat
            config={data?.config.statistic as IStatisticFormValue<'more'>}
            templateName={templateName}
            cardViewInfo={cardViewInfo}
            statData={statData}
            propConfigList={propConfigList}
            statUnitConfig={statUnitConfig}
            onPreview={onPreview}
          />
        );
      }
    }
  }

  return (
    <div className={styles.cardContent} ref={cardContentElementRef}>
      {content}
    </div>
  );
});

export default DashBoardCard;

const handleViewType = (type: string) => {
  switch (type) {
    /**
     * templateStatusPropBase 存在第二个数字
     * 就说明是按成员组进行分组
     */
    case 'templateStatusPropBase_0':
      return 'templateStatusPropBase_0';
    case 'templateStatusPropBase_0_0':
      return 'templateStatusPropBase_0_0';
    case 'templateStatusPropBase_0_1':
      return 'templateStatusPropBase_0_1';
    case 'templateStatusPropBase_1':
      return 'templateStatusPropBase_1';
    case 'templateStatusPropBase_1_0':
      return 'templateStatusPropBase_1_0';
    case 'templateStatusPropBase_1_1':
      return 'templateStatusPropBase_1_1';
    case 'templateStatus':
      return 'status';
    case 'sysCreator':
      return 'sysCreator';
    case '_sys_tag':
      return 'sysTag';
    case 'historyStatus':
      return 'historyStatus';
    case 'templateStatusOwnerAndParticipant':
      return 'templateStatusOwnerAndParticipant';
    case 'templateStatusOwnerAndParticipant_0':
      return 'templateStatusOwnerAndParticipant_0';
    case 'templateStatusOwnerAndParticipant_1':
      return 'templateStatusOwnerAndParticipant_1';
    default:
      return 'temp';
  }
};

function getViewInfo(viewConfig: any) {
  if (viewConfig.group && viewConfig.condition && viewConfig.condition.length) {
    const type = handleViewType(viewConfig.group);
    const tid = viewConfig.condition[0].value;
    const val = {
      type,
      tempId: tid,
      index: type === 'temp' ? viewConfig.group.split('_')[2] : -1,
      group: type === 'temp' ? viewConfig.group : null,
    };
    return val;
  }
  return null;
}

//处理分组信息==分组信息
function handleGroupCondition(filterReq: any, viewInfo: any) {
  if (viewInfo) {
    if (viewInfo.type === 'status') {
      filterReq.groupBy.push(`si`);
    } else if (viewInfo.type === 'temp') {
      const param = viewInfo.group.split('_');
      if (param.length) {
        filterReq.groupBy.push(`p${(param && param[3] == 1 ? 'g' : '') + viewInfo.index}`);
      }
    } else if (viewInfo.type === 'sysCreator') {
      filterReq.groupBy.push(`createUser`);
    } else if (viewInfo.type === 'sysTag') {
      filterReq.groupBy.push(`t`);
    } else if (viewInfo.type === 'templateStatusPropBase_0') {
      filterReq.groupBy.push(`s0`);
    } else if (viewInfo.type === 'templateStatusPropBase_0_0') {
      filterReq.groupBy.push(`s0`);
    } else if (viewInfo.type === 'templateStatusPropBase_0_1') {
      filterReq.groupBy.push(`sg0`);
    } else if (viewInfo.type === 'templateStatusPropBase_1') {
      filterReq.groupBy.push(`s1`);
    } else if (viewInfo.type === 'templateStatusPropBase_1_0') {
      filterReq.groupBy.push(`s1`);
    } else if (viewInfo.type === 'templateStatusPropBase_1_1') {
      filterReq.groupBy.push(`sg1`);
    } else if (
      viewInfo.type === 'templateStatusOwnerAndParticipant' ||
      viewInfo.type === 'templateStatusOwnerAndParticipant_0'
    ) {
      filterReq.groupBy.push(`so`);
    } else if (viewInfo.type === 'templateStatusOwnerAndParticipant_1') {
      filterReq.groupBy.push(`sog`);
    }
  } else {
    delete filterReq.groupBy;
  }
  return filterReq;
}

export const getOrderByParam = (viewConfig: any) => {
  let val;
  const param = viewConfig.orderBy;
  /**
   * 视图的排序根据
   * 目前排序比较准确的规则：创建时间  更新时间  主题树排序
   */
  if (/^templateProp_/.test(param)) {
    val = `p${param.split('_')[2]}`;
  } else if (/^_sys_createTime$/.test(param)) {
    val = `createTime`;
  } else if (/^templateStatus$/.test(param)) {
    val = `si`;
  } else if (/^templateStatusPropBase_2$/.test(param)) {
    val = `startTime`;
  } else if (/^templateStatusPropBase_3$/.test(param)) {
    val = `endTime`;
  } else if (/^_sys_updateTime$/.test(param)) {
    val = 'modifyTime';
  } else if (/^treeLevel$/.test(param)) {
    val = 'treeLevel';
  }

  return val ? (viewConfig && viewConfig.orderDesc ? [`-${val}`] : [`+${val}`]) : null;
};
