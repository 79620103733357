import type { ApiResponse } from '@linkpi/core';
import { FUNCTION_SET } from '@linkpi/core';
import { propIsNull } from '@linkpi/core';
import { Form, Input, message, Modal, TreeSelect } from 'antd';
import type { FC } from 'react';
import { useMemo } from 'react';
import { useRef } from 'react';
import { useEffect, useState } from 'react';
import React from 'react';

import { useOrgConnection, useOrgInfo } from '@/hook';

import ScanAddModalItem from '../ScanAddModalItem';

import styles from './styles.less';

interface treeNode {
  value: string;
  title: string;
  children: treeNode[];
}

interface ScanAddData {
  isEditingCol: false | undefined;
  editFrequency: false;
  editRepeatStep: false;
  key: string | undefined;
  recordProp: string | undefined;
  frequency: number | undefined;
  repeatStep: number | undefined;
}

const ScanAddModal: FC<{
  open: boolean;
  orgId: string;
  onCancel: () => void;
  initParentId: string;
  initTempId: string;
  currentNodeTempId: string;
  buttonId: string;
}> = (props) => {
  const { open, orgId, onCancel, initParentId, initTempId, currentNodeTempId, buttonId } = props;
  const [orgInfo] = useOrgInfo(orgId);
  const orgConnection = useOrgConnection(orgId);

  const [treeNode, setTreeNode] = useState<treeNode[]>([]);
  const [parentId, setParentId] = useState(initParentId);
  const [saveLoading, setSaveLoading] = useState(false);

  const [form] = Form.useForm();
  const [recordList, setRecordList] = useState<ScanAddData[]>([]);
  const [initStatus, setInitStatus] = useState<number | undefined>();
  const [repeatType, setRepeatType] = useState<number | undefined>();
  const [repeatStep, setRepeatStep] = useState<number | undefined>();
  const [marginRight, setMarginRight] = useState<number>();
  const [recordProp, setRecordProp] = useState<string | number | undefined>();
  const [countProp, setCountProp] = useState<string | number | undefined>();
  const [scanAllPropList, setScanAllPropList] = useState<number[] | undefined>();
  const [titleList, setTitleList] = useState<string[]>();
  const [widths, setWidths] = useState([]);
  const parentRef = useRef(null);
  const titleParentRef = useRef(null);

  function createRefObject(length) {
    return Array.from({ length }).reduce((acc, _, index) => {
      acc[index] = React.createRef();
      return acc;
    }, {});
  }
  const [titleRefs, setTitleRefs] = useState(createRefObject(titleList?.length));
  useEffect(() => {
    setTitleRefs(createRefObject(titleList?.length));
  }, [titleList]);

  useEffect(() => {
    setTimeout(() => {
      const newWidths = Object.values(titleRefs).map(
        (ref) => ref?.current?.getBoundingClientRect().width,
      );
      setWidths(newWidths);

      const totalWidth = newWidths.reduce((sum, width) => sum + width, 0);
      const realTotalWidth = totalWidth + (titleList?.length - 1) * 40;
      const parentWidth = parentRef?.current?.getBoundingClientRect().width - 48;
      if (titleParentRef.current) {
        if (realTotalWidth > parentWidth) {
          titleParentRef.current.style.justifyContent = 'flex-start';
          titleParentRef.current.style.minWidth = 'min-content';
          Object.values(titleRefs).forEach((ref, index) => {
            if (ref?.current) {
              if (index !== titleList?.length - 1) ref.current.style.marginRight = '40px';
            }
          });
          setMarginRight(40);
        } else {
          titleParentRef.current.style.justifyContent = 'space-between';
          titleParentRef.current.style.minWidth = '100%';
          setMarginRight(0);
        }
      }
    }, 0);
  }, [titleRefs, titleList]);

  const [t] = useMemo(() => {
    const temp = orgInfo?.templateList?.find((f) => f.template_id === initTempId);
    return [temp];
  }, [orgInfo, initTempId]);

  const templateRef = useRef(t);
  templateRef.current = t;
  const [propList] = useMemo(() => {
    if (!t) return [[]];
    const template = { ...t };
    const tp: ApiResponse.CurrentUser.TemplateProp[] = [];
    if (Array.isArray(template?.prop)) {
      template?.prop.map((x, i) => {
        if (x?.type) {
          tp.push({
            ...x,
            sort: 'sort' in x ? x.sort : i,
            index: i,
          });
        }
      });
    }
    if (template) template.prop = tp.sort((b, a) => b.sort - a.sort);
    const newPropList: {
      label: string;
      value: number;
      type: ApiResponse.CurrentUser.propType;
      item: TemplateProp;
      index: number;
    }[] = [];
    template?.prop.map((x, index) => {
      if (!x.type || x.hide) return;
      newPropList.push({
        label: x.name,
        value: index,
        type: x.type,
        item: x,
        index: x.index,
      });
    });
    return [newPropList];
  }, [t]);
  // 获取树
  useEffect(() => {
    const getChildren = (root: PiNode) => {
      const children: treeNode[] = [];
      if (Array.isArray(root.children) && root.children.length) {
        root.children.map((f) => {
          children.push({
            value: f.key,
            title: f.title || '无标题',
            children: getChildren(f),
          });
        });
      }
      return children;
    };

    if (orgConnection) {
      setTreeNode([
        {
          value: orgConnection.nodeManager._rootId,
          title: '根目录',
          children: getChildren(orgConnection.nodeManager.getRoot()),
        },
      ]);
      if (!orgConnection.nodeManager.findChildren(initParentId)) {
        setParentId(
          (orgConnection.nodeManager.getRoot().prop._sys_function & FUNCTION_SET.CLEARING) === 0
            ? orgConnection.nodeManager._rootId
            : orgConnection.nodeManager._toBeSortId,
        );
      } else {
        setParentId(initParentId);
      }
    }
  }, [orgConnection, initParentId]);

  // 初始化表单
  useEffect(() => {
    if (open && templateRef.current) {
      let customButton: ApiResponse.CurrentUser.CustomButton | undefined;
      if (currentNodeTempId === initTempId) {
        customButton = templateRef.current.custom_button?.find((item) => item.id === buttonId);
      } else {
        customButton = orgInfo?.templateList
          ?.find((f) => f.template_id === currentNodeTempId)
          ?.custom_button?.find((item) => item.id === buttonId);
      }
      setInitStatus(customButton?.node?.initialStatus);
      // 默认录入属性和计数属性
      form.setFieldsValue({
        tempName: templateRef.current.name,
      });
      setRecordProp(customButton?.scanEntryPropIndex || undefined);
      setCountProp(customButton?.countPropIndex || undefined);
      setRepeatType(customButton?.duplicateProcess || 1);
      setRepeatStep(customButton?.stepValue || 1);
      setScanAllPropList(customButton?.scanAllPropList || undefined);

      const list: string[] = [];
      customButton?.scanAllPropList?.map((itemIndex: number, index: number) => {
        const i = propList.findIndex((i) => itemIndex === i.index);
        if (i !== -1) list.push(propList[i].label);
      });
      if (
        typeof customButton?.duplicateProcess === 'undefined' ||
        customButton?.duplicateProcess === 1
      ) {
        setTitleList([
          '序号',
          '扫码值(' +
            propList.find((i) => i.index === customButton?.scanEntryPropIndex)?.label +
            ')',
          '扫码次数',
          '步进值',
          '计数属性(' + propList.find((i) => i.index === customButton?.countPropIndex)?.label + ')',
          ...list,
          '操作',
        ]);
      } else {
        setTitleList([
          '序号',
          '扫码值(' +
            propList.find((i) => i.index === customButton?.scanEntryPropIndex)?.label +
            ')',
          '扫码次数',
          ...list,
          '操作',
        ]);
      }

      setRecordList([{ isEditingCol: true }]);
    }
  }, [open]);

  const modalProps = {
    open,
    onCancel,
    maskClosable: false,
    keyboard: false,
    width: 880,
    maskStyle: { backgroundColor: 'rgba(0, 0, 0, 0.2)' },
    closable: true,
    wrapClassName: 'drafts-node-modal',
    style: { top: 'calc((100% - 720px) / 2 + 32px)', left: 32 },
    title: (
      <div>
        <div className="node-modal-title">
          <div
            className="node-modal-title-des"
            style={{
              fontSize: '14px',
              color: 'rgba(0,0,0,0.88)',
            }}
          >
            添加至:
          </div>
          <div
            style={{
              paddingLeft: '12px',
              alignItems: 'center',
              justifyContent: 'center',
              display: 'flex',
              background: '#FFFFFF',
              borderRadius: '6px',
              border: '1px solid #D9D9D9',
            }}
          >
            <div
              className="node-modal-title-main"
              style={{ color: '#767C88', fontSize: '14px', fontWeight: 400 }}
            >
              {orgInfo?.orgName}
            </div>
            <span
              style={{
                marginLeft: '5px',
                color: '#C9D0D9',
              }}
            >
              {'>'}
            </span>
            <TreeSelect
              className={styles.myTreeSelect}
              treeData={treeNode}
              bordered={false}
              disabled
              style={{ width: 146, color: '#767C88', fontSize: '14px' }}
              suffixIcon={<i />}
              value={parentId}
              onChange={(e) => setParentId(e)}
              showSearch={true}
              filterTreeNode={(e, node) =>
                typeof node?.title === 'string' &&
                !!~node.title.toLowerCase().indexOf(e.toLowerCase())
              }
              treeDefaultExpandedKeys={[parentId]}
            />
          </div>

          <div
            style={{
              alignItems: 'center',
              justifyContent: 'center',
              display: 'flex',
            }}
          >
            <div style={{ color: 'rgba(0,0,0,0.88)', fontSize: '14px', marginLeft: '55px' }}>
              主题类型：
            </div>
            <div
              style={{
                marginLeft: '12px',
                background: '#FFFFFF',
                borderRadius: '6px',
                border: '1px solid #D9D9D9',
                color: '#767C88',
                fontSize: '14px',
                padding: '6px 14px 6px 12px',
              }}
            >
              {templateRef?.current?.name}
            </div>
          </div>
        </div>
        <div style={{ marginTop: '16px', borderBottom: '1px solid #f0f0f0' }} />
      </div>
    ),
  };

  const onClear = () => {
    recordList.map((item) => {
      if (!item.isEditingCol) orgConnection?.nodeManager?.delete(item.key, false);
    });
    recordList.splice(0, recordList.length);
    onCancel();
  };

  const onSave = async () => {
    if (!t) return;
    if (recordList.length === 1) {
      return message.warn('暂无数据');
    }

    setSaveLoading(true);
    const template = { ...t };
    const requiredProps = template?.prop
      .map((x, index) => {
        return x && x.type && x.required && x.hide !== true ? index : -1;
      })
      .filter((x) => x !== -1);
    for (let i = 0; i < recordList.length; i++) {
      if (!recordList[i].isEditingCol) {
        const node = orgConnection?.nodeManager.getNode(recordList[i].key);
        const missRequired = requiredProps?.find((x) => propIsNull(node?.tempInfo.prop[x]));
        node?.moveNode(parentId, 0);
      }
    }
    setSaveLoading(false);
    recordList.splice(0, recordList.length);
    onCancel();
  };

  return (
    <Modal
      {...modalProps}
      onOk={onSave}
      onCancel={onClear}
      confirmLoading={saveLoading}
      width="80%"
    >
      <div className={styles.scanAddModalBody} ref={parentRef}>
        <div className={styles.titleParent} ref={titleParentRef}>
          {titleList?.map((item, index) => {
            return <span ref={titleRefs[index]}>{item}</span>;
          })}
        </div>

        {recordList.map((item, index) => {
          return (
            <ScanAddModalItem
              key={index}
              data={item}
              index={index}
              orgId={orgId}
              scanAllPropList={scanAllPropList}
              recordList={recordList}
              setRecordList={setRecordList}
              widths={widths}
              marginRight={marginRight}
              initStatus={initStatus}
              repeatType={repeatType}
              repeatStep={repeatStep}
              countProp={countProp}
              recordProp={recordProp}
              initTempId={initTempId}
              initParentId={initParentId}
              propList={propList}
              t={t}
            />
          );
        })}
      </div>
    </Modal>
  );
};
export default ScanAddModal;
