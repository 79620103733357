import type { GetterPiNode } from '@linkpi/core/web';
import cls from 'classnames';
import { isEmpty } from 'ramda';
import type { FC } from 'react';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'umi';

import { ConditionFilterButton } from '@/components/ConditionFilter/ConditionFilterButton';
import { conditionV2ConvertCondition } from '@/components/ConditionFilter/utils';
import type { IWidgetInstanceData } from '@/components/PageModelEditor';
import {
  useCurrentUser,
  useDisabledEvents,
  useOrgInfo,
  useOrgUserMap,
  useSectionInstanceConfig,
} from '@/hook';
import { groupingNodes } from '@/pages/home/components/TabContent/utils';
import Board from '@/pages/home/components/View/Board';
import { DividerList } from '@/pages/home/components/View/ViewSettingV2/components';
import { loadNodes } from '@/utils/utils';

import AddButtonForView from '../../viewsettingComponents/AddButtonForView';
import GroupForView from '../../viewsettingComponents/GroupForView';
import SearchNodesForView from '../../viewsettingComponents/SearchNodesForView';
import { ViewerButton } from '../ComponentViewer';

import universalStyles from '../styles.less';
import styles from './styles.less';

export const ModelBoard: FC<{
  data: IWidgetInstanceData & {
    widgetId: 'Kanban';
  };
  getterPiNode: GetterPiNode;
  isDark?: boolean;
  isDrafts: boolean;
  inViewer: boolean;
  readOnly?: boolean;
}> = (props) => {
  const { data, getterPiNode, isDark: _isDark, isDrafts, inViewer, readOnly } = props;
  const isDark = _isDark && !inViewer;

  const sectionConfig = useSectionInstanceConfig();
  const backgroundColor = sectionConfig.config.bgColor || '#fff';
  const { config, id } = data;
  const currentUser = useCurrentUser();

  const [orgInfo] = useOrgInfo();
  const userMap = useOrgUserMap();
  const { groups } = useSelector((state: any) => state.space);
  const spaceUsers = Object.values(userMap);

  const [groupData, setGroupData] = useState<any>({});
  const [nodeList, setNodeList] = useState<any>([]);
  const [curViewData, setCurViewData] = useState<any>({});
  const searchDataRef = useRef<any>(undefined);

  const nowGroupRef = useRef<any>(config.group);
  const nowConditionV2Ref = useRef<any>(config.conditions);
  const targetTheme = nowConditionV2Ref.current.find((i: any) => i.key === 'templateId')?.input[0];
  const nowOrderByRef = useRef<any>({
    orderBy: config.orderBy || '_sys_createTime',
    orderDesc: config.orderDesc || true,
  });

  const getCurViewData = () => {
    const { conditions: condition, parentId } = conditionV2ConvertCondition(
      nowConditionV2Ref.current,
    );
    return {
      view_info: {
        condition,
        conditionV2: nowConditionV2Ref.current,
        group: nowGroupRef.current,
        orderBy: nowOrderByRef.current.orderBy,
        orderDesc: nowOrderByRef.current.orderDesc,
        hideTool: false,
        parentId,
        hideAdd: config.hideAdd,
      },
      view_type: 1, // table
    };
  };

  const init = async () => {
    if (!orgInfo) return;
    const nodes = await loadNodes(getterPiNode.value, {
      ...config,
      conditions: nowConditionV2Ref.current,
      currentUserId: currentUser.userid,
    });

    setNodeList(nodes);
    setGroupData(
      groupingNodes(
        getNodeList(nodes),
        nowGroupRef.current,
        spaceUsers,
        groups,
        targetTheme,
        orgInfo.templateList,
      ),
    );
    setCurViewData(getCurViewData());
  };
  const changeGroup = (g: string) => {
    if (!orgInfo) return;
    const newCurViewData = { ...curViewData };
    newCurViewData.view_info.group = g;
    nowGroupRef.current = g;
    setGroupData(
      groupingNodes(
        getNodeList(nodeList),
        g,
        spaceUsers,
        groups,
        targetTheme,
        orgInfo.templateList,
      ),
    );
    setCurViewData(newCurViewData);
  };

  const changeSearchData = (v: any) => {
    if (!orgInfo) return;
    searchDataRef.current = v;
    setGroupData(
      groupingNodes(
        getNodeList(nodeList),
        nowGroupRef.current,
        spaceUsers,
        groups,
        targetTheme,
        orgInfo.templateList,
      ),
    );
  };

  const changeCondition = (v: any) => {
    nowConditionV2Ref.current = v;
    init();
  };

  const changeSort = (v: { orderBy: string; orderDesc: boolean }) => {
    nowOrderByRef.current = v;
    init();
  };

  const getNodeList = (nodes: any[]) => {
    if (searchDataRef.current === undefined) return nodes;
    if (searchDataRef.current.length === 0) return [];

    return nodes.filter((n: any) => searchDataRef.current.includes(n.id));
  };

  useEffect(() => {
    init();
    getterPiNode.value.nodeManager.listen('change', init);
    return () => {
      getterPiNode.value.nodeManager.unListen('change', init);
    };
  }, []);

  const [pointerEvents, onMouseDown] = useDisabledEvents(readOnly);

  if (isEmpty(curViewData) || !orgInfo) return null;

  return (
    <div
      className={cls(styles.container, isDark ? styles.dark : '')}
      onMouseDown={onMouseDown}
      style={{ pointerEvents }}
    >
      <div className={styles.header}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {!isDrafts && (
            <SearchNodesForView
              isDark={isDark}
              orgInfo={orgInfo}
              changeSearchData={changeSearchData}
            />
          )}
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }} className="dividerListWrapper">
          <DividerList>
            {!isDrafts && (
              <ConditionFilterButton
                className={universalStyles.forModelView}
                orgId={orgInfo.orgId}
                value={nowConditionV2Ref.current}
                onChange={changeCondition}
                enableRoot
              />
            )}
            {!isDrafts && (
              <GroupForView
                template={orgInfo.templateList.find((t: any) => t.template_id === targetTheme)}
                curViewData={curViewData}
                orgInfo={orgInfo}
                templateList={orgInfo.templateList}
                onChange={changeGroup}
                isDark={isDark}
              />
            )}
            {/* 排序 现在中间件视图方法还不支持排序？先隐藏 */}
            {/* {!isDrafts && (
              <OrderByForView
                orgInfo={orgInfo}
                template={orgInfo.templateList.find((t: any) => t.template_id === targetTheme)}
                curViewData={curViewData as any}
                value={{
                  orderBy: curViewData.view_info.orderBy,
                  orderDesc: curViewData.view_info.orderDesc,
                }}
                onChange={changeSort}
                changeCustomTableChildNodesOrder={() => {}}
              />
            )} */}
            <ViewerButton />
            <AddButtonForView
              template={orgInfo.templateList.find((t: any) => t.template_id === targetTheme)}
              curViewData={curViewData}
              getterPiNode={getterPiNode}
              templateList={orgInfo.templateList}
              userMap={userMap}
            />
          </DividerList>
        </div>
      </div>
      <div style={{ flex: 1, height: 0, position: 'relative' }}>
        <Board
          userMap={userMap}
          curViewDataInfo={curViewData.view_info}
          templateList={orgInfo.templateList}
          groupData={groupData}
          groups={groups}
          onDraftAddOk={() => {}}
          isDark={isDark}
        />
      </div>
    </div>
  );
};
