import qs from 'query-string';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

type UrlQuery = Record<string, string>;

export const useUrlQuerys = <T extends UrlQuery = UrlQuery>() => {
  const location = useLocation();

  const querys = useMemo(() => qs.parse(location.search), [location.search]);

  return querys as T;
};
