import { elecwattConfig } from '@/utils/utils';

import type { PiGridModules } from '../core';
import { checkboxConfig } from '../helper/config';
import { splitArray } from '../helper/utils';
import type { HeaderCellType } from '../modules/dataManager';
import type { PositionType } from './_baseComponent';
import GridBaseComponent from './_baseComponent';
import CheckboxCell from './checkboxCell';
import HeaderCell from './headerCell';
export default class Header extends GridBaseComponent {
  constructor(position: PositionType, moduleInstances: PiGridModules) {
    super(position, moduleInstances);
  }

  public click() {}

  public render() {
    const { DataManager, Draw, Render } = this.moduleInstances;
    const { x, y, height, width } = this.position;
    const styleMode = DataManager.config.styleMode;
    const containerBgColor =
      Draw.config.contentPageGeneralTableConfig?.containerBgColor || '#f8f9fb';
    const bg = styleMode === 'darkness' ? containerBgColor : '#f0f0f0';

    const [fixedCells, notFixedCells] = splitArray(
      DataManager.headerCells,
      DataManager.config.freeze + 1,
    );

    // 画行
    Draw.fillRect(x, y, width, height, bg);

    notFixedCells.forEach((cell: HeaderCellType) => {
      this.renderCell(cell, false);
    });

    // fixed row bg
    Draw.fillRect(x, y, Render.getFixedWidth(), height, bg);

    fixedCells.forEach((cell, index) => {
      index === 0 ? this.renderCheckbox(cell) : this.renderCell(cell, true);
    });
  }

  public renderCheckbox(cell: HeaderCellType) {
    if (cell.disable) return;
    const { y, height } = this.position;
    const { size } = checkboxConfig;
    const startX = 10;
    const startY = (height - size) / 2 + y;
    const styleMode = this.moduleInstances.ChildNodesLoader.config.styleMode;

    const navMode = this.moduleInstances.DataManager.config.navMode;
    // checkbox bg 背景色 f0f0f0
    this.moduleInstances.Draw.fillRect(
      0,
      0,
      cell.width,
      height,
      navMode === 'elecwatt'
        ? elecwattConfig.tableHeaderBgColor
        : styleMode === 'darkness'
          ? 'rgba(17,197,167,0.2)'
          : '#f0f0f0',
    );

    const checkboxCell = new CheckboxCell(
      {
        x: startX,
        y: startY,
        width: size,
        height: size,
      },
      this.moduleInstances,
      { type: 'headerCheckbox' },
      () => {
        const { DataManager } = this.moduleInstances;
        if (DataManager.config.checkId.length) DataManager.preCheckId = null;
        DataManager.config.onCheck('all');
      },
    );

    checkboxCell.render(true);
  }

  private renderCell(cell: HeaderCellType, fixed: boolean) {
    const { HorizontalScrollbar, Draw } = this.moduleInstances;
    const currentLeft = fixed ? 0 : HorizontalScrollbar.getScrollLeft();

    // 操作列固定在右侧
    const x = cell.type === 'actions' ? Draw.canvasWidth - cell.width : cell.x - currentLeft;
    const headerCell = new HeaderCell(
      {
        x,
        y: 0,
        width: cell.width,
        height: this.position.height,
      },
      this.moduleInstances,
      cell,
      fixed,
    );

    headerCell.render();
  }
}
