import { useState } from 'react';

import ChooseExportPdfDirection from '@/components/ChooseExportPdfDirection';
import { exportPDF, genPDF, getDefaultExportPDFDirection } from '@/utils/utils';

import '../GroupForView/styles.less';
export default (props: any) => {
  const { getterPiNode, templateList, checkId, setCheckId, orgInfo, userMap } = props;
  if (checkId.length !== 1) return null;
  const [showChooseExportPdfDirectionModal, setShowChooseExportPdfDirectionModal] = useState(false);
  const [genPDFLoading, setGenPDFLoading] = useState(false);

  const chooseExportPdfDirectionOk = async (direction: any, sheetId: any) => {
    setGenPDFLoading(true);

    const n = getterPiNode.value.nodeManager.findChildren(checkId[0]);
    const html = await exportPDF(n, templateList, orgInfo, userMap, sheetId);
    await genPDF(html, direction === 'horizontal', n.title || '无标题');

    setShowChooseExportPdfDirectionModal(false);
    setGenPDFLoading(false);
    setCheckId([]);
  };

  return (
    <>
      <div
        onClick={() => setShowChooseExportPdfDirectionModal(true)}
        className="view-setting-block"
      >
        <i className="iconfont iconfuzhizhuti" />
        &nbsp;导出主题
      </div>
      {showChooseExportPdfDirectionModal && (
        <ChooseExportPdfDirection
          closeModal={() => setShowChooseExportPdfDirectionModal(false)}
          loading={genPDFLoading}
          ok={chooseExportPdfDirectionOk}
          defaultDirection={getDefaultExportPDFDirection(
            getterPiNode.value.nodeManager.findChildren(checkId[0]),
            templateList,
          )}
          templateList={templateList}
          node={getterPiNode.value.nodeManager.findChildren(checkId[0])}
        />
      )}
    </>
  );
};
