import type { ApiResponse } from '@linkpi/core';
import { useQuery } from '@tanstack/react-query';
import { create, keyResolver, windowScheduler } from '@yornaath/batshit';
import { useRequest } from 'ahooks';
import { memoize } from 'lodash';
import { isNil } from 'ramda';

import { getViewList, getViewListByManager } from '@/services/view';

import { useCurrentOrgId } from './useOrg';

type GetViewListParams = Parameters<typeof getViewList>;
type RequestOption = Parameters<typeof useRequest<ApiResponse.ViewList, GetViewListParams>>[1];

/**
 * TODO react query
 */
export function useOrgViewListRequest(params: GetViewListParams[0], options: RequestOption = {}) {
  const deps = [
    'org_id' in params ? params.org_id : 'none',
    'node_id' in params ? params.node_id : 'none',
  ];

  return useRequest<ApiResponse.ViewList, GetViewListParams>(getViewList, {
    cacheKey: ['view_list', ...deps].join('@@'),
    defaultParams: [params],
    refreshDeps: deps,
    staleTime: 30,
    ...options,
  });
}

export function useOrgViewListByManagerRequest(params: GetViewListParams[0]) {
  const deps = [
    'org_id' in params ? params.org_id : 'none',
    'node_id' in params ? params.node_id : 'none',
  ];

  return useQuery({
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: ['view_list', ...deps],
    queryFn: () => getViewListByManager(params),
    placeholderData: [],
    staleTime: 30,
  });
}

/**
 * 支持批量请求
 */
const viewConfig = memoize((orgId: string) =>
  create({
    fetcher: async (viewIds: string[]) => {
      const res = await getViewList({
        org_id: orgId,
        view_id: viewIds.filter(Boolean),
      });
      return res;
    },
    resolver: keyResolver('view_id'),
    scheduler: windowScheduler(30), // Default and can be omitted.
  }),
);

export const useViewConfig = (viewId?: string) => {
  const orgId = useCurrentOrgId();

  return useQuery({
    staleTime: 200,
    queryKey: ['viewConfig', orgId, viewId],
    queryFn: () =>
      viewId
        ? viewConfig(orgId)
            .fetch(viewId)
            .then((res) => {
              if (isNil(res)) return Promise.reject(new Error('viewConfig' + viewId));
              return res;
            })
        : undefined,
  });
};
