import { antdModalV5, create, show, useModal } from '@ebay/nice-modal-react';
import type { FC } from 'react';
import { useState } from 'react';

import type { ViewContentProp } from './ViewContent';
import { ViewContent } from './ViewContent';

type ViewContentModalProps = Pick<ViewContentProp, 'dataList' | 'showNodeSaved' | 'nodeIndex'>;

const ViewContentModal: FC<ViewContentModalProps> = ({
  dataList,
  showNodeSaved,
  nodeIndex: defaultNodeIndex,
}) => {
  const modal = useModal();
  const modalProps = antdModalV5(modal);
  const [nodeIndex, setNodeIndex] = useState<number>(defaultNodeIndex || 0);

  return (
    <ViewContent
      nodeIndex={nodeIndex}
      setNodeIndex={setNodeIndex}
      contentVisible={modalProps.open}
      setContentVisible={modalProps.onCancel}
      afterClose={modalProps.afterClose}
      dataList={dataList}
      showNodeSaved={showNodeSaved}
    />
  );
};

export const ViewContentNiceModal = create(ViewContentModal);
export const DEFAULT_NODE_MODAL_ID = '@@VIEW_NODE_MODAL_ID';

export const viewNodeContent = (props: ViewContentModalProps, id: string = DEFAULT_NODE_MODAL_ID) =>
  show(id, props);
