import type { CurrentUser, OrgUser } from '@linkpi/core';
import { SIDE_MODE, SPACE_TAG_COLORS } from '@linkpi/core';
import type { PiConnectionOptionsType } from '@linkpi/core/web';
import {
  ClientList,
  getNodeToppingViews,
  getShareDBConnectionInstance,
  PiConnection,
} from '@linkpi/core/web';
import { getPiSDK } from '@linkpi/sdk';
import { message } from 'antd';
import { isEqual, throttle } from 'lodash';
import queryString from 'query-string';
import type { Reducer } from 'umi';
import { getDvaApp, history } from 'umi';

import {
  fetchNodeLatestUpdateInfo,
  fetchNodeUpdateLog,
  insertVisited,
  searchNodeByFilter,
  updateCurrentSelection,
} from '@/services/home';
import {
  collectView,
  fetchAllUserList,
  fetchUserList,
  getAllNotices,
  getCollectViewList,
  getNewNoticesSum,
  getShareList,
  getSubscribeList,
  getSubscribeNodeLog,
  queryTemplates,
  subscribeNode,
} from '@/services/space';
import { queryCurrent } from '@/services/user';
import request from '@/utils/request';
import { delay } from '@/utils/utils';

const piSDK = getPiSDK();

type Put = <A extends { type: string; [k: string]: any }>(action: A) => any;
export interface EffectsCommandMap {
  put: Put & { resolve: Put };
  call: <T, P>(cb: (arg: P) => T, rest?: P) => T extends Promise<any> ? T : Promise<T>;
  select: <T>(cb: (s: any) => T) => T;
  take: (T: { type: string }) => any;
  cancel: (T: { type: string }) => void;
  [key: string]: any;
}

export type Effect = (
  action: { type: string; [k: string]: any },
  effects: EffectsCommandMap,
) => Generator<any, any, any>;

const localSpaceTagMap = window.localStorage.getItem('localSpaceTagMap');

let updateCurrentSelectionTimer: null | string = null;

// 当前正在连接的空间 防止重复连接
let connectioningOrgId: null | string = null;

const throttleChange = throttle((put, instance, noticeListener) => {
  setTimeout(
    () =>
      getDvaApp()._store.runSaga(function* () {
        yield put({
          type: 'handleNoticeInstance',
          payload: { data: instance?.data, noticeListener },
        });
      }),
    Math.random() * 10000,
  );
}, 5_000);

const WorkspaceModel: {
  namespace: 'workspace';
  state: any;
  reducers: Record<string, Reducer<any>>;
  effects: Record<string, Effect | [Effect, { type: string }]>;
} = {
  namespace: 'workspace',
  state: {
    // 运维模式
    maintenanceMode: false,
    // 只读模式（模板广场iframe打开）
    readOnlyMode: false,
    // 当前选中
    currentSelection: {
      // 当前空间
      selectSpace: null,
      // 当前专注节点
      selectFocus: null,
      // 当前节点
      selectNode: null,
      // 当前视图
      // content 正文主题树;其他 视图id
      selectViewId: '',
      // 当前side mode
      selectSideMode: SIDE_MODE.TREE,
    },
    settingSpace: '',
    // 空间标签颜色map{spaceId:color}
    spaceTagColorMap: localSpaceTagMap ? JSON.parse(localSpaceTagMap) : {},
    // 空间列表
    spaceList: [],
    // 成员列表
    spaceUserBaseData: [],
    spaceUserList: {}, //account_id, group_list, nick_name, avatar
    // 所有空间的事件
    actionList: [],
    //节点更新记录
    nodeUpdateLog: {},
    // 关注视图列表
    collectViewList: [],
    //分享给我列表
    shareList: [],
    //我收藏的
    subscribeList: [],
    //通知数据
    noticeInstance: {},
    //空间模板匹配数据
    spaceMatchProps: {},
    //节点更新信息
    nodeUpdateInfo: {},
    // 聊天消息数
    chatMessageNum: 0,
    // 通知数
    noticeNum: 0,
    // 空间连接Map
    piConnectionMap: {},
    // 空间连接loading
    connectionLoading: false,
    // 搜索keywords
    searchKeyWords: [],
    // 搜索结果
    searchNodes: [],
    // 最新的一条消息
    latestNotice: [],
    contactsSelectedOrg: '',
  },
  // 只允许同步操作数据
  reducers: {
    setLatestNotice(state, { payload: latestNotice }) {
      return { ...state, latestNotice };
    },
    setConnectionLoading(state, { payload }) {
      return { ...state, connectionLoading: payload };
    },
    setChatMessageNum(state, { payload }) {
      return {
        ...state,
        chatMessageNum: payload,
      };
    },
    setNoticeNum(state, { payload }) {
      return {
        ...state,
        noticeNum: payload,
      };
    },
    setMaintenanceMode(state) {
      return {
        ...state,
        maintenanceMode: true,
      };
    },
    setReadOnlyMode(state) {
      return {
        ...state,
        readOnlyMode: true,
      };
    },
    // 设置节点更新记录
    setNodeUpdateLog(state, { payload }) {
      const newNodeUpdateLog = state.nodeUpdateLog;
      newNodeUpdateLog[payload.nodeId] = payload.nodeUpdateLog;
      return {
        ...state,
        nodeUpdateLog: newNodeUpdateLog,
      };
    },
    // 设置当前选中
    _setCurrentSelection(state, { payload }) {
      return {
        ...state,
        currentSelection: {
          ...payload,
        },
      };
    },

    // 设置space list
    setSpaceList(state, { payload }) {
      return { ...state, spaceList: payload };
    },
    setSpaceUserBaseData(state, { payload }) {
      return { ...state, spaceUserBaseData: payload };
    },
    setSpaceUserList(state, { payload }) {
      return { ...state, spaceUserList: payload };
    },
    setSettingSpace(state, { payload }) {
      return { ...state, settingSpace: payload };
    },
    setSpaceMatchProps(state, { payload }) {
      return { ...state, spaceMatchProps: payload };
    },
    setSpaceTagColorMap(state, { payload }) {
      window.localStorage.setItem('localSpaceTagMap', JSON.stringify(payload));
      return { ...state, spaceTagColorMap: payload };
    },
    setCollectViewList(state, { payload }) {
      return { ...state, collectViewList: payload };
    },
    setShareList(state, { payload }) {
      return { ...state, shareList: payload };
    },
    setSubscribeList(state, { payload }) {
      return { ...state, subscribeList: payload };
    },
    setNoticeInstance(state, { payload }) {
      return { ...state, noticeInstance: payload };
    },
    setNodeUpdateInfo(state, { payload }) {
      return { ...state, nodeUpdateInfo: payload };
    },
    // 添加Piconnection到piConnectionMap
    addPiConnection(state, { payload }) {
      return { ...state, piConnectionMap: { ...state.piConnectionMap, ...payload } };
    },
    setContactsSelectedOrg(state, { payload }) {
      return { ...state, contactsSelectedOrg: payload };
    },
    setSearchNodes(state, { payload }) {
      return { ...state, searchNodes: payload };
    },
    setSearchKeyWords(state, { payload }) {
      return { ...state, searchKeyWords: payload };
    },
  },
  // 异步数据
  effects: {
    /**
     * 切换当前节点、视图、side mode
     */
    *setCurrentSelection({ payload }, { put, select }) {
      const { spaceList, currentSelection, readOnlyMode } = yield select(
        (state: { workspace: any }) => state.workspace,
      );
      const defaultSelection: any = {};
      let { byInit, ...newSelection } = payload;

      if (isEqual(newSelection, currentSelection)) {
        return;
      }

      // 切换空间时，重置选中节点和视图
      let org;
      if (newSelection.selectSpace && newSelection.selectSpace !== currentSelection.selectSpace) {
        org = spaceList.find((x: { orgId: any }) => x.orgId === newSelection.selectSpace);
        if (org) {
          defaultSelection.selectSpace = newSelection.selectSpace;
          defaultSelection.selectFocus = org.rootId;
          defaultSelection.selectNode = org.rootId;
          defaultSelection.selectViewId = 'content';
        }
      }

      // 切换节点时，选中置顶的视图
      if (newSelection.selectNode) {
        const { piConnectionMap } = yield select((state: { workspace: any }) => state.workspace);
        const orgConnection = piConnectionMap[org?.orgId || currentSelection.selectSpace];
        const node = orgConnection?.nodeManager.findChildren(newSelection.selectNode);
        defaultSelection.selectViewId = getNodeDefaultView(node);
      }
      // 从视图中点击切换节点 需要记录从哪个视图跳转过来
      if ('viewRedirect' in newSelection) {
        if (newSelection.viewRedirect) {
          if (newSelection.selectNode !== currentSelection.selectNode) {
            newSelection.prevNode = currentSelection.selectNode;
            newSelection.prevViewId = currentSelection.selectViewId;
          }
          newSelection.selectViewId = 'content';
        } else {
          delete currentSelection.prevNode;
          delete currentSelection.prevViewId;
        }
        delete newSelection.viewRedirect;
      }
      newSelection = {
        ...currentSelection,
        ...defaultSelection,
        ...newSelection,
      };

      yield put({
        type: '_setCurrentSelection',
        payload: newSelection,
      });

      if (!readOnlyMode) {
        yield put({ type: 'updateCurrentSelection' });
      }
      if (!byInit) {
        yield put({ type: 'updateHistoryPath' });
      }
    },
    *updateHistoryPath(_, { select }) {
      const { currentSelection } = yield select((state: { workspace: any }) => state.workspace);
      const queryObj = queryString.parse(window.location.search);
      const querySearch = queryString.stringify({
        ...queryObj,
        side: currentSelection.selectSideMode,
        vid: currentSelection.selectViewId,
      });

      const path = `/home/${currentSelection.selectSpace}/${currentSelection.selectFocus}/${currentSelection.selectNode}?${querySearch}`;

      history.push(path);
    },
    /**
     * 创建连接空间
     * 创建空间连接前 需要先获取空间用户列表
     *
     * @param {Object} param0
     * @param {*} param1
     */
    *connectionOrg({ payload }, { select, call, put }) {
      const orgId = payload;

      const { piConnectionMap, spaceUserBaseData, spaceTagColorMap, readOnlyMode, spaceUserList } =
        yield select((state: { workspace: any }) => state.workspace);

      if (piConnectionMap[orgId]) return;

      if (connectioningOrgId === orgId) return;

      const latestOrgId = yield select((state) => state.user.latestOrgId);

      if (latestOrgId !== orgId) {
        // 更新指定空间模板数据
        yield put.resolve({ type: 'user/fetchCurrent', payload: { orgId } });
      }

      const currentUser = (yield select(
        (state: { user: any }) => state.user.currentUser,
      )) as CurrentUser;

      connectioningOrgId = orgId;
      yield put({ type: 'setConnectionLoading', payload: true });
      /**
       * @type {import('@linkpi/core').CurrentUser['organization'][number]}
       */
      const orgData = currentUser.organization.find((x: { orgId: any }) => x.orgId === orgId);

      if (!orgData) {
        return message.error('该空间不存在');
      }

      // 设置在待办里的颜色
      if (!spaceTagColorMap[orgId]) {
        const index = Object.keys(spaceTagColorMap).length % SPACE_TAG_COLORS.length;
        yield put({
          type: 'setSpaceTagColorMap',
          payload: {
            ...spaceTagColorMap,
            [orgId]: SPACE_TAG_COLORS[index],
          },
        });
      }

      const options = {
        orgDataType: orgData.dataType,
        docId: orgData.orgDocId,
        orgId: orgData.orgId,
        userId: currentUser.userid,
        request: request,
        groupList: orgData.groupList,
        role: orgData.role,
        rootId: orgData.rootId,
        toBeSortId: orgData.toBeSortId,
        clientType: ClientList.web,
        loadByLayer: false,
        templateList: orgData.templateList,
        userList: spaceUserBaseData,
        dataType: orgData.dataType,
        org_option: orgData.org_option,
        userDetailList: Object.values(spaceUserList[orgData.orgId] || {}),
      };

      if (readOnlyMode) {
        options.readonly = true;
      }
      const newPiConnection = new PiConnection(options);

      yield call(newPiConnection.connection.bind(newPiConnection));

      piSDK.piConnectionManager.addPiConnection(orgId, newPiConnection);
      yield put({ type: 'addPiConnection', payload: { [orgId]: newPiConnection } });
      yield put({ type: 'setConnectionLoading', payload: false });
      connectioningOrgId = null;
      return newPiConnection;
    },
    /**
     * 创建连接空间
     * 创建空间连接前 需要先获取空间用户列表
     */
    *connectionOrg2({ payload }, { select, call, put }) {
      yield put({ type: 'setContactsSelectedOrg', payload });
      const orgId = payload;

      // 更新指定空间模板数据
      yield put.resolve({ type: 'user/fetchCurrent', payload: { orgId } });

      const { currentUser } = yield select((state: { user: any }) => state.user);
      const { piConnectionMap, spaceUserBaseData, spaceTagColorMap, readOnlyMode } = yield select(
        (state: { workspace: any }) => state.workspace,
      );

      if (piConnectionMap[orgId]) return;

      if (connectioningOrgId === orgId) return;
      connectioningOrgId = orgId;
      yield put({ type: 'setConnectionLoading', payload: true });
      /**
       * @type {import('@linkpi/core').CurrentUser['organization'][number]}
       */
      const orgData = currentUser.organization.find((x: { orgId: any }) => x.orgId === orgId);

      if (!orgData) {
        return message.error('该空间不存在');
      }

      // 设置在待办里的颜色
      if (!spaceTagColorMap[orgId]) {
        const index = Object.keys(spaceTagColorMap).length % SPACE_TAG_COLORS.length;
        yield put({
          type: 'setSpaceTagColorMap',
          payload: {
            ...spaceTagColorMap,
            [orgId]: SPACE_TAG_COLORS[index],
          },
        });
      }
      const options = {
        orgDataType: orgData.dataType,
        docId: orgData.orgDocId,
        orgId: orgData.orgId,
        userId: currentUser.userid,
        request: request,
        groupList: orgData.groupList,
        role: orgData.role,
        rootId: orgData.rootId,
        toBeSortId: orgData.toBeSortId,
        clientType: ClientList.web,
        loadByLayer: false,
        templateList: orgData.templateList,
        userList: spaceUserBaseData,
        dataType: orgData.dataType,
        org_option: orgData.org_option,
      } as PiConnectionOptionsType;
      if (readOnlyMode) {
        options.readonly = true;
      }
      const newPiConnection = new PiConnection(options);

      yield call(newPiConnection.connection.bind(newPiConnection));

      piSDK.piConnectionManager.addPiConnection(orgId, newPiConnection);
      yield put({ type: 'addPiConnection', payload: { [orgId]: newPiConnection } });
      yield put({ type: 'setConnectionLoading', payload: false });
      connectioningOrgId = null;
      return newPiConnection;
    },

    *switchMaintenanceMode(_, { put }) {
      yield put({ type: 'setMaintenanceMode' });
    },
    *switchReadOnlyMode(_, { put }) {
      yield put({ type: 'setReadOnlyMode' });
    },
    *fetchNodeLatestUpdateInfo({ payload }, { call, put }) {
      const response = yield call(fetchNodeLatestUpdateInfo, payload);
      if (response?.status === 'ok' && response.data?.length) {
        yield put({
          type: 'setNodeUpdateInfo',
          payload: {
            updateTime: response.data[0].updateTime,
            accountId: response.data[0].accountId,
            nodeId: payload.nodeId,
          },
        });
      }
      return response;
    },
    // 获取节点更新记录
    *fetchNodeUpdateLog({ payload }, { call, put }) {
      const response = yield call(fetchNodeUpdateLog, payload);

      if (response && response.status === 'ok') {
        yield put({
          type: 'setNodeUpdateLog',
          payload: { nodeId: payload.nodeId, nodeUpdateLog: response.data },
        });
      } else {
        console.log(`fetchNodeUpdateLog failed. `);
        console.log(JSON.stringify(response));
      }
    },
    *initNoticeInstance({ payload }, { select, put }) {
      const { spaceList, readOnlyMode } = yield select(
        (state: { workspace: any }) => state.workspace,
      );

      const noticeListener = payload?.noticeListener;
      const userSpace = spaceList.find((x: { orgType: number }) => x.orgType === -1);
      if (readOnlyMode || !userSpace) return;

      const shareDBConnectionInstance = getShareDBConnectionInstance();
      const instance = shareDBConnectionInstance.get('testJ1', userSpace.orgDocId)!;
      // 更新
      instance.on('op', () => {
        throttleChange(put, instance, noticeListener);
      });

      return new Promise((r) => {
        instance.subscribe(() => {
          r(instance);
          throttleChange(put, instance, noticeListener);
        });
      });
    },
    *handleNoticeInstance({ payload }, { select, put, call }) {
      const { noticeInstance, currentSelection, chatMessageNum, settingSpace } = yield select(
        (state: { workspace: any }) => state.workspace,
      );
      const {
        selectSpace,
        // selectFocus, selectNode
      } = currentSelection;
      let org_user = false,
        org_template = false,
        org_prop = false;
      if ('org_user' in noticeInstance && noticeInstance.org_user !== payload.data.org_user) {
        org_user = true;
        yield put({
          type: 'updateSpaceUsers',
          payload: {
            org_id: selectSpace,
            avatar: true,
            role: true,
          },
        });
        if (settingSpace) {
          yield put({
            type: 'updateSpaceUserList',
            payload: {
              org_id: settingSpace,
              avatar: true,
              role: true,
            },
          });
        }
      }
      if (
        'org_template' in noticeInstance &&
        noticeInstance.org_template !== payload.data.org_template
      ) {
        org_template = true;
        /*if (settingSpace) {
          yield put({
            type: 'updateTemplates',
            payload: {
              org_id: settingSpace,
            },
          });
        }*/
      }
      if ('org_prop' in noticeInstance && noticeInstance.org_prop !== payload.data.org_prop) {
        org_prop = true;
      }
      if (org_user || org_template || org_prop) {
        /**
         * @type {import('@linkpi/core').CurrentUser}
         */
        const user_response = yield call(queryCurrent);
        if (user_response.status !== 'ok') {
          return;
        }
        const { piConnectionMap } = yield select((state: { workspace: any }) => state.workspace);
        /**
         * @type {PiConnection}
         */
        const orgConnection = piConnectionMap[selectSpace];

        const currentOrgInfo = user_response.organization.find(
          (i: { orgId: any }) => i.orgId === selectSpace,
        );

        const templateList = currentOrgInfo?.templateList;
        if (Array.isArray(templateList) && orgConnection.nodeManager) {
          console.log('🛎️ 中间件模板更新：', user_response);
          orgConnection.nodeManager.setTemplate(templateList);
        }

        yield put({
          type: 'user/saveCurrentUser',
          payload: user_response,
        });
        yield put({
          type: 'space/saveCurrentUser',
          payload: user_response,
        });
        if (payload.noticeListener) {
          payload.noticeListener('currentUser', user_response);
        }
      }
      if ('org_chat' in noticeInstance && noticeInstance.org_chat !== payload.data.org_chat) {
        yield put({ type: 'setChatMessageNum', payload: chatMessageNum + 0 });
      }
      yield put({ type: 'setNoticeInstance', payload: payload.data });
    },
    *updateTemplates({ payload }, { call, put, select }) {
      const { currentTemplate } = yield select((state: { space: any }) => state.space);
      const response = yield call(queryTemplates, payload);
      const templates = Array.isArray(response.data) ? response.data : [];
      yield put({
        type: 'space/queryTemplates',
        payload: templates,
      });
      if (currentTemplate?.template_id) {
        const template = templates.find(
          (x: { template_id: any }) => x.template_id === currentTemplate.template_id,
        );
        if (template) {
          yield put({
            type: 'space/setCurrentTemplate',
            payload: template,
          });
        }
      }
    },
    *updateSpaceUserList({ payload }, { call, put }) {
      const response = yield call(fetchUserList, payload);
      if (response.status === 'error') return;
      const userList = Array.isArray(response.data) ? response.data : [];
      yield put({
        type: 'space/setUserList',
        payload: userList,
      });
    },
    *updateSpaceUsers({ payload }, { call, put, select }) {
      const { spaceUserList } = yield select((state: { workspace: any }) => state.workspace);
      const response = yield call(fetchUserList, payload);
      if (response.status === 'error') return;
      const userList = Array.isArray(response.data) ? response.data : [];
      yield put({
        type: 'space/setUserList',
        payload: userList,
      });
      const spaceUsers = {
        [payload.org_id as string]: {} as any,
      };
      userList.forEach((user: { account_id: string | number }) => {
        if (
          !spaceUsers[payload.org_id][user.account_id] ||
          spaceUsers[payload.org_id][user.account_id].group_ids?.includes('-8')
        ) {
          spaceUsers[payload.org_id][user.account_id] = user;
        }
      });
      spaceUserList[payload.org_id] = spaceUsers[payload.org_id];
      yield put({
        type: 'setSpaceUserList',
        payload: {
          ...spaceUserList,
        },
      });
    },
    *fetchSpaceUsers({ payload }, { call, put, select }) {
      let { spaceUserList } = yield select((state: { workspace: any }) => state.workspace);
      let userList = [];
      if (payload.org_id) {
        if (!spaceUserList[payload.org_id]) {
          // 指定org_id时，需要获取用户group_id
          const response = yield call(fetchUserList, payload);
          if (response && response.status === 'ok') {
            userList = response.data;
          }
        }
      } else if (!Object.keys(spaceUserList).length) {
        const response = yield call(fetchAllUserList);
        if (response && response.status === 'ok') {
          userList = response.data;
        }
      }
      if (userList.length) {
        yield put({
          type: 'setSpaceUserBaseData',
          payload: userList,
        });
        const allSpaceUserList: Record<string, Record<string, OrgUser.OrgUserItem>> = {};
        for (const user of userList) {
          // 以指定org_id为准
          const org_id = payload.org_id || user.org_id;
          if (!allSpaceUserList[org_id]) {
            allSpaceUserList[org_id] = { [user.account_id]: user };
          } else {
            if (
              !allSpaceUserList[org_id][user.account_id] ||
              allSpaceUserList[org_id][user.account_id].group_ids?.includes('-8')
            ) {
              allSpaceUserList[org_id] = { ...allSpaceUserList[org_id], [user.account_id]: user };
            }
          }
        }

        if (payload.org_id) {
          spaceUserList[payload.org_id] = allSpaceUserList[payload.org_id];
        } else if (Object.keys(spaceUserList).length === 0) {
          spaceUserList = allSpaceUserList;
        }
        yield put({
          type: 'setSpaceUserList',
          payload: {
            ...spaceUserList,
          },
        });
      }
    },

    fetchMatchProps() {
      return [];
    },

    *insertVisited({ payload }, { call, select }) {
      const { spaceList, readOnlyMode } = yield select(
        (state: { workspace: any }) => state.workspace,
      );
      const userSpace = spaceList.find((x: { orgType: number }) => x.orgType === -1);
      if (readOnlyMode || !userSpace) return;

      const visitedData = {
        ...payload,
        org_doc_id: userSpace.orgDocId,
      };
      yield call(insertVisited, visitedData);
    },
    // 更新服务器默认路径
    *updateCurrentSelection(_, { call, select, fork, cancel }) {
      if (updateCurrentSelectionTimer) {
        yield cancel(updateCurrentSelectionTimer);
      }
      updateCurrentSelectionTimer = yield fork(handleData);

      function* handleData() {
        // 500ms 防抖动
        yield delay(500);

        const { selectSpace, selectFocus, selectNode } = yield select(
          (state: { workspace: { currentSelection: any } }) => state.workspace.currentSelection,
        );

        if (!!selectSpace && !!selectFocus && !!selectNode) {
          yield call(updateCurrentSelection, {
            select_space: selectSpace,
            select_root: selectFocus,
            select_node: selectNode,
          });
        }
      }
    },

    // 获取节点id下所有更新记录
    *getSubscribeNodeLog({ payload }, { call }) {
      const res = yield call(getSubscribeNodeLog, { id: payload.id, time: payload.timelineTime });
      if (res && res.status === 'ok') {
        return res.data;
      } else {
        return [];
      }
    },

    // 获取消息数
    // 在极简模式获取最新一条通知
    getSubScribeNum: [
      function* (_: any, { put, call }) {
        // const { layoutMode } = yield select((state) => state.user.currentUser);
        const res = yield call(getNewNoticesSum);

        // if (layoutMode === 'simple') {
        // }

        const noticeRes = yield call(getAllNotices, { limit: 1, offset: 0 });
        yield put({ type: 'setLatestNotice', payload: noticeRes?.data ?? [] });

        let noticeSum = 0,
          chatSum = 0;
        if (res.status === 'ok' && res.data) {
          noticeSum = res.data.notice;
          chatSum = res.data.chat;
          yield put({ type: 'setChatMessageNum', payload: chatSum });
          yield put({ type: 'setNoticeNum', payload: noticeSum });
        }
      },
      { type: 'takeLatest' },
    ],

    // 获取收藏的视图
    *getCollectViewList(_, { put, call }) {
      //todo
      const res = yield call(getCollectViewList);
      if (res && res.status === 'ok') {
        yield put({
          type: 'setCollectViewList',
          payload: res.data,
        });
      }
    },
    // 操作视图收藏
    *collectView({ payload }, { put, call }) {
      const { viewId, nodeId, status } = payload;
      const res = yield call(collectView, { viewId, nodeId, status });
      if (res && res.status === 'ok') {
        message.success(status ? '收藏成功' : '取消收藏成功');
        yield put({ type: 'getCollectViewList' });
      }
    },

    // 获取分享给我的列表记录
    *getShareList({ payload }, { put, call }) {
      const res = yield call(getShareList);
      if (res && res.status === 'ok') {
        yield put({
          type: 'setShareList',
          payload: res.data,
        });
      }
    },
    // 获取我收藏的节点记录
    *getSubscribeList({ payload }, { put, call }) {
      const res = yield call(getSubscribeList);
      if (res && res.status === 'ok') {
        yield put({
          type: 'setSubscribeList',
          payload: res.data,
        });
      }
    },
    // 取消收藏
    *subscribeNode({ payload }, { put, call }) {
      const res = yield call(subscribeNode, payload);
      if (res && res.status === 'ok') {
        const subRes = yield call(getSubscribeList);
        if (subRes && subRes.status === 'ok') {
          yield put({
            type: 'setSubscribeList',
            payload: subRes.data,
          });
        }
      }
      return res;
    },
    // 搜索节点
    *searchNode({ payload }, { put, call }) {
      const res = yield call(searchNodeByFilter, {
        hardLimit: 500,
        themeTreeSearch: true,
        ...payload,
      });
      let nodes = [];
      if (res && res.status === 'ok' && Array.isArray(res.data)) {
        nodes = res.data.map((node: { n: any; t: any; e: { _sys_createTime: number } }) => {
          return {
            item: {
              id: node.n,
              title: node.t,
              createTime:
                node.e._sys_createTime > 160000000000
                  ? node.e._sys_createTime
                  : node.e._sys_createTime * 1000,
            },
          };
        });
        nodes.sort(
          (a: { item: { createTime: number } }, b: { item: { createTime: number } }) =>
            b.item.createTime - a.item.createTime,
        );
      }
      yield put({
        type: 'setSearchNodes',
        payload: nodes,
      });
    },
    *deleteSearchNode({ payload }, { put, select }) {
      const { searchNodes } = yield select((state: { workspace: any }) => state.workspace);
      if (payload.node_id) {
        const index = searchNodes.findIndex(
          (node: { item: { id: any } }) => node.item.id === payload.node_id,
        );
        if (~index) {
          searchNodes.splice(index, 1);
          yield put({
            type: 'setSearchNodes',
            payload: searchNodes,
          });
        }
      }
    },
  },
};

function getNodeDefaultView(node: PiNode) {
  let selectViewId = 'content';
  if (node) {
    const hideContent = node.prop._sys_hideChildren || node.prop._sys_hideWorkAreaChildren;
    let toppingViews = getNodeToppingViews(node);
    if (hideContent) {
      toppingViews = toppingViews.filter((x) => x !== 'content');
    }
    if (toppingViews.length) {
      selectViewId = toppingViews[0];
    }
  }

  return selectViewId;
}

export default WorkspaceModel;
