import { RightOutlined } from '@ant-design/icons';
import type { CurrentUser } from '@linkpi/core';
import type { OrgUser } from '@linkpi/core';
import { ErrorCodeMapKey } from '@linkpi/core';
import { DEFAULT_TEMPLATE, NODE_PERMISSION, NODE_TYPE, STATUS_ICON } from '@linkpi/core';
import { displayCascadeValue, propIsNull, setNodeAction, updateProp } from '@linkpi/core';
import { genDefaultFlowSetting } from '@linkpi/core';
import { getEnumOptions } from '@linkpi/core';
import { useMemoizedFn } from 'ahooks';
import type { PopoverProps } from 'antd';
import { Button, Divider, Form, message, Popover, Space, Tooltip } from 'antd';
import { intersection, isEmpty, isNil, keys, uniq, without } from 'ramda';
import type { ForwardRefRenderFunction } from 'react';
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useSelector } from 'umi';

import { PiButton } from '@/components';
import { useCurrentUser, useOrgInfo, useOrgTempInfo, useOrgUserMap } from '@/hook';
import { useOrgDepartmentNodes } from '@/hook/useOrgStructure';
import HistoryStatus from '@/pages/home/components/StatusMenu/HistoryStatus';
import StatusModal from '@/pages/home/components/StatusModal';
import type { StatusItemType } from '@/pages/home/components/TempStatus';
import { getStatusItems } from '@/pages/home/components/TempStatus';
import { fetchUserList } from '@/services/space';
import request from '@/utils/request';
import type { UploadFileInOrgNodeResSuccessType } from '@/utils/utils';
import {
  allowChangeStatus,
  filterFlowSetting,
  getUserNickName,
  isEmptyObj,
  toArray,
  uploadFileInOrgNode,
} from '@/utils/utils';

import './StatusMenu.less';
import styles from './HistoryStatus/index.module.less';

const STATUS_TYPE = {
  STATUS_TRANSFORM: '状态转换',
  STATUS_MODIFY: '状态属性修改',
} as const;

type StatusType = (typeof STATUS_TYPE)[keyof typeof STATUS_TYPE];

// 模板状态菜单
type StatusMenuProps = {
  data?: PiNode;
  spaceId: string;
  btnClassName?: string;
  statusName: string;
  entrance: string;
  useModal?: boolean;
  btnSize?: 'middle' | 'small';
  reloadTimeline?: () => void;
  autoOpen?: boolean;
  isDrafts?: boolean;
  readOnly?: boolean;
  onCancel?: () => void;
  trigger?: PopoverProps['trigger'];
  type?: 'list' | 'button';
  hiddenStatusRecord?: boolean;
  hiddenSwitchStatus?: boolean;
};

type MenuOption = {
  name: string;
  handle: () => void;
  icon: keyof typeof STATUS_ICON;
  current: boolean;
  disabled: boolean;
  sort: number;
  hasDisplay: boolean;
};

type SetStatusArgs = [
  task_status: CurrentUser.taskStatus,
  index: number,
  type: StatusType,
  currentTemplate: CurrentUser.TemplateInfo,
];

export type StatusMenuRef = {
  setStatus: (...args: SetStatusArgs) => Promise<void>;
  getCurrentFlowSetting: () => CurrentUser.FlowRoute[] | undefined;
};

const _StatusMenu: ForwardRefRenderFunction<StatusMenuRef, StatusMenuProps> = (props, ref) => {
  useImperativeHandle(ref, () => ({
    setStatus: (...args) => {
      return handleStatus(...args);
    },
    getCurrentFlowSetting: () => currentFlowSetting,
  }));

  const [form] = Form.useForm();
  const {
    data,
    spaceId,
    btnClassName = 'status-btn',
    statusName,
    entrance,
    useModal,
    btnSize,
    reloadTimeline,
    autoOpen,
    isDrafts,
    readOnly,
    onCancel,
    trigger = 'hover',
    type,
    hiddenStatusRecord,
    hiddenSwitchStatus,
  } = props;

  const { spaceUserList, readOnlyMode } = useSelector((state: any) => state.workspace);

  const currentUser = useCurrentUser();
  const userMap = useOrgUserMap();

  const [modalTitle, setModalTitle] = useState(<span>{STATUS_TYPE.STATUS_TRANSFORM}</span>);
  const [scheduleShow, setScheduleShow] = useState(false);
  const [scheduleLoading, setScheduleLoading] = useState(false);
  const [menuOption, setMenuOption] = useState<MenuOption[]>([]);
  const [showIcon, setShowIcon] = useState<keyof typeof STATUS_ICON>('iconlinggan');
  const [formItems, setFormItems] = useState<StatusItemType[]>([]);
  const [transformIndex, setTransformIndex] = useState(-1);
  const [transformStatus, setTransformStatus] = useState<CurrentUser.taskStatus | {}>({});
  const [transformType, setTransformType] = useState<StatusType | ''>('');
  const [curTemplate, setCurTemplate] = useState<CurrentUser.TemplateInfo | null>(null);
  const [dataTemplate, setDataTemplate] = useState<CurrentUser.TemplateInfo | null>(null);
  const [historyVisible, setHistoryVisible] = useState(false);
  const [visibleMenuPopover, setVisibleMenuPopover] = useState(!!autoOpen);

  // TODO: 外部成员
  const [org] = useOrgInfo(spaceId) as unknown as [CurrentUser.OrgInfo];
  const currentTemplate = useOrgTempInfo(data?.prop._sys_temp?.[0] || '');
  const departmentNodes = useOrgDepartmentNodes();

  const currentStatusConfig = useMemo(() => {
    if (isNil(data) || isNil(data.tempInfo) || isNil(currentTemplate)) return null;
    return currentTemplate.task_status[data.tempInfo.status];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTemplate, data, data?.tempInfo.status]);

  const countersignStatus = useMemo(() => {
    if (
      isNil(data) ||
      isNil(data.tempInfo) ||
      isNil(data.prop._sys_voting_results) ||
      isEmpty(data.prop._sys_voting_results) ||
      isNil(currentTemplate)
    ) {
      return null;
    }
    // 目前仅有一个会签；存在会签时，以会签为主
    const nanimouslyNode = currentStatusConfig?.flowSetting?.filter(
      ({ transType }) => transType?.type === 'nanimously',
    )[0];
    const nanimouslyIndex = nanimouslyNode?.index;
    if (typeof nanimouslyIndex !== 'number') {
      return null;
    }

    // const countersignStatusIndex = keys(data.prop._sys_voting_results)[0];
    const statusConfig = currentTemplate.task_status[nanimouslyIndex];

    // const statusResult = values(data.prop._sys_voting_results)[0];
    const statusResult = data.prop._sys_voting_results[nanimouslyIndex];
    const [owner, participant] = data.tempInfo.statusProp;
    const allUsers = uniq([...toArray(owner), ...toArray(participant)]).filter(Boolean);
    const submitedUsers = intersection(allUsers, keys(statusResult));

    return { allUsers, submitedUsers, statusConfig };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, data?.prop?._sys_voting_results, currentStatusConfig?.flowSetting, currentTemplate]);

  const getRouteConfig = useMemoizedFn((statusIndex: number) => {
    const routeConfig = currentStatusConfig?.flowSetting?.find((f) => f.index === statusIndex);
    if (!routeConfig) return undefined;

    return routeConfig;
  });

  const routeConfig = useMemo(() => {
    if (isEmptyObj(transformStatus)) return undefined;

    return getRouteConfig((transformStatus as CurrentUser.taskStatus).index!);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getRouteConfig, transformStatus, currentStatusConfig, data?.tempInfo.status]);

  const checkStatusDisabled = (flow: unknown, index: number, status: CurrentUser.taskStatus) => {
    if (isDrafts) return false;
    if (org?.role !== 7 && flow) return false;
    if (!status) return false;
    if (!status.flow && status.newAddState) return true;
    if (Array.isArray(status.flow) && !status.flow.includes(index)) return true;
    if (Array.isArray(status.flowSetting)) {
      const fs = status.flowSetting.find((f) => f.index === index);
      if (fs) {
        return !checkTransferable(fs);
      }
    }
    return false;
  };

  const isNodeDisabled = useCallback(() => {
    return countersignStatus?.statusConfig && currentUser?.userid
      ? countersignStatus.submitedUsers.includes(currentUser.userid)
      : false;
  }, [currentUser, countersignStatus]);

  useEffect(() => {
    if (!data?.prop || !Array.isArray(data.prop._sys_temp)) return;
    if (!org) return;
    const templates = org.templateList || [];
    if (templates.length) {
      const template =
        templates.find((temp) => temp.template_id === data.prop._sys_temp[0]) || null;
      setDataTemplate(template);
      const options: any[] = [];
      const nodeIndex = data.prop._sys_task_status?.index || 0;
      if (template && Array.isArray(template.task_status) && template.task_status.length) {
        const currentStatus = template.task_status[nodeIndex];
        setShowIcon((currentStatus?.icon as keyof typeof STATUS_ICON) || 'iconlinggan');
        template.task_status.map((status, index) => {
          if (status && !status.delete) {
            if (index !== nodeIndex) {
              const disabled =
                checkStatusDisabled(
                  // QUESTION: what's flow?
                  // @ts-ignore
                  template.flow,
                  index,
                  template.task_status[nodeIndex],
                ) ||
                !allowChangeStatus(data, template.task_status[nodeIndex]?.flowSetting || [], index);
              options.push({
                name: status.name,
                handle: () => {
                  if (isNodeDisabled(index) || disabled) {
                    return;
                  }
                  setVisibleMenuPopover(false);
                  disabled || handleStatus(status, index, STATUS_TYPE.STATUS_TRANSFORM, template);
                },
                icon: status.icon,
                current: false,
                disabled: isNodeDisabled(index) || disabled,
                sort: 'sort' in status ? status.sort : index,
              });
            } else {
              let hasDisplay = false;
              status.prop.some((prop) => {
                if (prop?.display) {
                  hasDisplay = true;
                  return true;
                }
              });
              options.push({
                name: status.name,
                handle: () => {
                  if (isNodeDisabled(index)) {
                    return;
                  }
                  setVisibleMenuPopover(false);
                  handleStatus(status, nodeIndex, STATUS_TYPE.STATUS_MODIFY, template);
                },
                icon: status.icon,
                current: true,
                hasDisplay: hasDisplay,
                sort: 'sort' in status ? status.sort : index,
                disabled: isNodeDisabled(index),
              });
            }
          }
        });
      } else {
        setShowIcon('iconlinggan');
      }
      options.sort((b, a) => b.sort - a.sort);
      setMenuOption(options);
    }
  }, [org, data?.prop]);

  const handleStatusPromise = useRef({
    resolve: (v: unknown) => {},
    reject: (v: unknown) => {},
  });
  const resetHandleStatusPromise = () => {
    handleStatusPromise.current.resolve = () => {};
    handleStatusPromise.current.reject = () => {};
  };

  const handleStatus = useMemoizedFn(async (...args: SetStatusArgs): Promise<void> => {
    const [task_status, index, setSTatusType, currentTemplate] = args;
    setCurTemplate(currentTemplate);
    form.resetFields();
    setScheduleShow(false);

    if (isNil(data)) {
      console.warn(data);
      return;
    }

    const actionReq = {
      org_id: spaceId,
      node: [data.id || data.node_id],
      temp_id: Array.isArray(data?.prop?._sys_temp) ? data.prop._sys_temp[0] : DEFAULT_TEMPLATE,
      status: index,
      owner: null,
      user: null,
      startTime: null,
      endTime: null,
      record: true,
      statusCommit: undefined,
    };

    const tempProp = currentTemplate?.prop || [];

    let historyStatus = [
      {
        status_name: currentStatusConfig?.name,
        task_status: currentStatusConfig?.prop.map((p, sIndex) => ({
          ...p,
          value: data.tempInfo.statusProp[sIndex],
        })),
        status: data.tempInfo.status,
        create_time: new Date().valueOf(),
      },
    ];

    if (setSTatusType === STATUS_TYPE.STATUS_MODIFY) {
      // //状态属性修改，设置开始时间和结束时间
      // startTime = data.prop?._sys_task_status?.prop[2] || null;
      // endTime = data.prop?._sys_task_status?.prop[3] || null;
    } else {
      const res = await request<any[]>('/api/status/query', {
        method: 'POST',
        data: { node_id: data.id, temp_id: data.prop._sys_temp[0], org_id: spaceId },
      });
      if (res?.status === 'ok') {
        historyStatus = [...historyStatus, ...res.data.reverse()];
      }
    }

    let spaceUsers: OrgUser.OrgUserItem[] = [];

    const res = await fetchUserList({
      org_id: spaceId,
    });

    if (res?.status === 'ok') spaceUsers = res.data as any;

    const enumOptions = await getEnumOptions({
      nodeId: data.id || data.node_id,
      orgId: spaceId,
      request: request,
      template: currentTemplate,
    });

    const propItems = getStatusItems(
      spaceId,
      task_status,
      spaceUsers,
      spaceUserList[spaceId],
      null,
      null,
      data,
      currentUser,
      true,
      null,
      // FIXME: currentTemplate 可能为空
      // @ts-ignore
      currentTemplate?.template_id,
      null,
      setSTatusType === STATUS_TYPE.STATUS_MODIFY,
      tempProp,
      null,
      null,
      form,
      currentTemplate,
      historyStatus,
      index,
      enumOptions,
      departmentNodes,
    );

    const requiredProps = propItems.filter((item) => {
      let propConfig: CurrentUser.statusProp | undefined = task_status.prop[Number(item.key)];

      if (typeof item.key === 'string' && item.key.startsWith('tempProp')) {
        const key = item.key.replace('tempProp', 'prop');
        propConfig = task_status.prop.find((i) => i.quoteProp === key);
      }

      if (isNil(propConfig)) return false;
      if (propConfig.readOnly) {
        if (propConfig.required) {
          if (propIsNull(item.props.initialValue)) return true;

          return false;
        }
        return false;
      }
      return true;
    });

    const currentRouteConfig = getRouteConfig(task_status.index!);
    const needStatusForm = currentRouteConfig?.transType?.commit || requiredProps.length;

    /**
     * 状态有条件公式，并且需要填写状态属性的时候，
     * 通过后端检查节点是否满足条件公式
     */
    if (needStatusForm && !isNil(currentRouteConfig?.conditionFormula)) {
      const [, _res] = await setNodeAction(request, { ...actionReq, dry_run: true });
      if (_res.status !== 'ok') {
        throw new Error(_res);
      }
    }

    if (needStatusForm) {
      setModalTitle(
        <div className="status-header" style={{ display: 'flex' }}>
          <div style={{ maxWidth: '50%' }} className="text-omit" title={data!.title}>
            {data!.title}
          </div>
          <span style={{ color: '#EBEDF0', margin: '0 5px' }}> | </span> {task_status.name}
        </div>,
      );
      setFormItems(propItems);
      setScheduleShow(true);
      setTransformIndex(index);
      setTransformStatus(task_status);
      setTransformType(setSTatusType);
      return new Promise((resolve, reject) => {
        handleStatusPromise.current.resolve = resolve;
        handleStatusPromise.current.reject = reject;
      });
    }

    if (onCancel) onCancel();

    propItems.forEach((p) => {
      if (Number(p.key) < 5) {
        switch (Number(p.key)) {
          case 0:
            actionReq.owner = p.props.initialValue;
            break;
          case 1:
            actionReq.user = p.props.initialValue;
            break;
          case 2:
            actionReq.startTime = p.props.initialValue;
            break;
          case 3:
            actionReq.endTime = p.props.initialValue;
            break;
          case 4:
            actionReq.statusCommit = p.props.initialValue;
            break;

          default:
            break;
        }
      }
    });

    transformStatusAction(actionReq, setSTatusType);
  });

  const onSubmit = useMemoizedFn(
    async (
      values: Record<`prop${number}` | 'commit', unknown>,
      fileMap: Record<
        number,
        {
          exist: boolean;
          originFileObj?: File;
          name?: string;
          url?: string;
        }[]
      >,
      cascadeValue: Record<number, unknown>,
    ) => {
      const nowNodeStatusIndex = data?.tempInfo.status;
      if (
        !allowChangeStatus(
          data,
          data?.template?.task_status?.[nowNodeStatusIndex]?.flowSetting || [],
          transformIndex,
        )
      ) {
        return message.warning('无法流转至该状态，请重新选择');
      }
      if (isNil(curTemplate) || isNil(data)) {
        console.warn('🫵 currentTemplate is nil');
        return;
      }

      const cascadeIndex = Object.keys(cascadeValue);
      // 附件上传逻辑
      await Promise.allSettled(
        Object.keys(fileMap).map(async (index) => {
          const propIndex = Number(index);
          const newList = fileMap[propIndex].filter((f) => !f.exist) as {
            exist: false;
            originFileObj: File;
          }[]; // 新增的附件
          const oldList = fileMap[propIndex].filter((f) => f.exist) as {
            exist: true;
            name: string;
            url: string;
          }[]; // 老的附件

          const uploadReq = newList.map((x) => {
            return uploadFileInOrgNode(x.originFileObj, spaceId, data.node_id);
          });
          const uploadRes = (await Promise.all(uploadReq)) as UploadFileInOrgNodeResSuccessType[];
          // 更改节点属性
          const newValue = uploadRes.map((x) => x.fileName).concat(oldList.map((f) => f.name));
          const newAttachment = uploadRes.map((x) => x.fileUrl).concat(oldList.map((f) => f.url));
          const req = {
            org_id: spaceId,
            temp_id: curTemplate.template_id,
            node_id: data.node_id,
            index: [Number(index)],
            value: [newValue],
            attachment: {
              [index]: newAttachment,
            },
          };

          await updateProp(request, req);
        }),
      );
      // ---------------------------------
      // 多级选值逻辑
      cascadeIndex.forEach((index) => {
        const propIndex = Number(index);
        const cascadeTitles = displayCascadeValue({
          value: cascadeValue[propIndex],
          cascadeNodes: curTemplate.prop[propIndex].cascade.nodes,
          multiple: curTemplate.prop[propIndex].multiple,
          hideRoutes: curTemplate.prop[propIndex].hideRoutes,
        });

        const req = {
          org_id: spaceId,
          temp_id: curTemplate.template_id,
          node_id: data.node_id,
          index: [Number(index)],
          value: [cascadeValue[propIndex]],
          cascade: {
            [`${index}`]: cascadeTitles.length ? cascadeTitles : null,
          },
        };
        updateProp(request, req);
      });
      // ---------------------------------
      if (isEmpty(transformStatus) || !('prop' in transformStatus)) {
        console.warn('🫵 transformStatus is empty');
        return;
      }
      let status_prop: unknown[] = [],
        hasTempProp = false;
      transformStatus.prop.map(() => {
        status_prop.push(null);
      });
      const newTempProp = Array.isArray(data.prop._sys_temp)
        ? JSON.parse(JSON.stringify(data.prop._sys_temp[1]))
        : [];
      const tempIndex: number[] = [];
      if (status_prop.length < 4) return;
      setScheduleLoading(true);

      Object.keys(values).map((x) => {
        const propValue = values[x as keyof typeof values];
        if (!propIsNull(propValue)) {
          if (x.startsWith('prop')) {
            const index = Number(x.split('prop')[1]);
            status_prop[index] = transformStatus.prop[index]?.type?.startsWith('date')
              ? (propValue as Date).valueOf()
              : propValue;
          }
          if (x.startsWith('tempProp') && Array.isArray(newTempProp)) {
            const index = Number(x.split('tempProp')[1]);
            if (!(propValue as any).fileList && !cascadeIndex.includes(index.toString())) {
              hasTempProp = true;
              const changedFields = [index];
              while (newTempProp.length <= index) {
                newTempProp.push(null);
              }
              newTempProp[index] = curTemplate.prop[index]?.type.startsWith('date')
                ? (propValue as Date).valueOf()
                : propValue;
              tempIndex.push(index);
              changedFields.forEach((i) => !tempIndex.includes(i) && tempIndex.push(i));
            }
          }
        }
      });

      if (hasTempProp) {
        const tempReq = {
          org_id: spaceId,
          node_id: data.id || data.node_id,
          temp_id: Array.isArray(data?.prop?._sys_temp) ? data.prop._sys_temp[0] : DEFAULT_TEMPLATE,
          index: tempIndex,
          value: tempIndex.map((index) => newTempProp[index]),
        };
        await updateProp(request, tempReq);
      }
      const actionReq: Record<string, unknown> = {
        org_id: spaceId,
        node: [data?.id || data?.node_id],
        temp_id: Array.isArray(data?.prop?._sys_temp) ? data?.prop._sys_temp[0] : DEFAULT_TEMPLATE,
        status: transformIndex,
        owner: status_prop[0],
        user: status_prop[1],
        startTime: status_prop[2],
        endTime: status_prop[3],
        statusCommit: typeof status_prop[4] === 'string' ? status_prop[4] : '',
        record: true,
      };
      if (values.commit) {
        actionReq.votingCommit = values.commit;
      }
      await transformStatusAction(actionReq, transformType);

      // 通知 handleStatusPromise 转换完成
      handleStatusPromise.current.resolve(null);
      resetHandleStatusPromise();

      if (onCancel) onCancel();
    },
  );

  /**
   * 设置状态属性或[转换状态]
   * @param actionReq 请求body
   * @param type 转换类型
   * @returns {Promise<void>}
   */
  const transformStatusAction = async (actionReq: unknown, type: StatusType | '') => {
    const [err, res] = await setNodeAction(request, actionReq);
    if (res?.status === 'ok') {
      setScheduleLoading(false);
      setScheduleShow(false);
      if (type === STATUS_TYPE.STATUS_TRANSFORM) {
        !isDrafts && message.success(type + '成功');
      }
      reloadTimeline && reloadTimeline();
    } else {
      setScheduleLoading(false);
      if (res.errCode === ErrorCodeMapKey.Logical_ConditionMismatch) return;

      if (type === STATUS_TYPE.STATUS_TRANSFORM) {
        message.error(type + '失败');
      }
    }
  };

  const isStatusListStyle = () => {
    if (type === 'button') return false;
    if (!currentTemplate) return;
    return currentTemplate?.temp_option?.contentStatusStyle === 'list' || type === 'list';
  };

  const _ActionHandleMenuOptions = (
    // <PerfectScrollbar options={{ suppressScrollX: true }}>
    <ul className="menu-list action-list" onClick={(e) => e.stopPropagation()}>
      {dataTemplate ? (
        <>
          {!hiddenSwitchStatus &&
            isStatusListStyle() &&
            menuOption.map((item, i) => {
              const dom = (
                <>
                  {item.current ? (
                    <Divider
                      type="vertical"
                      style={{
                        margin: 0,
                        paddingLeft: 0,
                        height: 24,
                        borderLeft: '4px solid #316ef5',
                      }}
                    />
                  ) : null}
                  <div
                    className={`menu-item ${item.disabled ? 'menu-disabled' : ''} ${
                      item.current ? 'current-item' : ''
                    }`}
                  >
                    <div className={'normal-item'} title={item.name}>
                      <i
                        className={`iconfont ${item.icon}`}
                        style={{ color: STATUS_ICON[item.icon]?.color }}
                      />
                      <div className="text-omit">{item.name}</div>
                    </div>
                    {item.current &&
                      (item.hasDisplay ? (
                        <Tooltip placement="top" title={'修改当前状态属性'}>
                          <i className="iconfont iconshuxingxiangqing edit-btn" />
                        </Tooltip>
                      ) : (
                        <></>
                      ))}
                  </div>
                </>
              );
              return (
                <li
                  key={i}
                  className={`${item.current ? 'current-menu' : ''}`}
                  onClick={item.handle}
                >
                  {dom}
                </li>
              );
            })}
          {!hiddenStatusRecord && (
            <li
              key={'historical'}
              className={'menu-item'}
              onClick={() => {
                setHistoryVisible(true);
                setVisibleMenuPopover(false);
              }}
            >
              <div className={'normal-item status'}>
                <i className={'iconfont iconhistorical_state'} />
                处理记录
                {countersignStatus &&
                  ': ' +
                    [
                      countersignStatus.submitedUsers.length,
                      countersignStatus.allUsers.length,
                    ].join('/')}
              </div>
            </li>
          )}
        </>
      ) : (
        <li key={0} className={'menu-item menu-disabled'}>
          <div className={'normal-item'}>应用的模板被删除</div>
        </li>
      )}
    </ul>
    // </PerfectScrollbar>
  );

  const valueInclude = (value: unknown) => {
    return (
      !propIsNull(value) &&
      (value === currentUser.userid || (Array.isArray(value) && value.includes(currentUser.userid)))
    );
  };

  const checkTransferable = useMemoizedFn((f: CurrentUser.taskStatus['flowSetting'][number]) => {
    if (!data) return false;
    if (
      f.transferable &&
      !f.transferable.includes('-1') &&
      !(f.transferable.includes('-2') && (org.role === 1 || org.role === 3)) &&
      !org.groupList?.find((id) => f?.transferable?.includes(id))
    ) {
      return (
        (f.transferable.includes('owner') &&
          valueInclude(data.prop?._sys_task_status?.prop?.[0])) ||
        (f.transferable.includes('participant') &&
          valueInclude(data.prop?._sys_task_status?.prop?.[1])) ||
        (f.transferable.includes('creator') && valueInclude(data.prop?._sys_creator)) ||
        (f.transferable.includes('updater') && valueInclude(data.data?.d)) ||
        f.transferable
          .filter((x) => x.startsWith('prop'))
          .some((x) => valueInclude(data.prop?._sys_temp?.[1]?.[Number(x.split('-')?.[1])]))
      );
    }
    return true;
  });

  const currentFlowSetting = useMemo(() => {
    if (isNil(data) || isNil(currentTemplate) || !currentTemplate?.task_status) {
      return [];
    }

    const statusIndex = Number(data.tempInfo?.status);
    let flowSetting = genDefaultFlowSetting(
      currentTemplate.task_status,
      statusIndex,
      undefined,
      undefined,
    );
    if (!flowSetting || !flowSetting.length) return [];

    // QUESTION: what's flow?
    // @ts-ignore
    if (!currentTemplate.flow) {
      flowSetting = flowSetting.filter((f) => checkTransferable(f));
    }
    return flowSetting.filter((f) => !isNodeDisabled(f.index));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    checkTransferable,
    currentTemplate,
    data?.tempInfo?.status,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    data?.tempInfo?.statusProp?.[1],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    data?.tempInfo?.statusProp?.[0],
    isNodeDisabled,
  ]);

  if (!data) return null;

  const iconButton = (
    <Button
      className={btnClassName || 'buttons-item'}
      style={{ cursor: readOnly ? 'default' : 'pointer' }}
      type="text"
      size={btnSize || 'small'}
      shape="circle"
      icon={<i className={`iconfont ${showIcon}`} style={{ color: STATUS_ICON[showIcon].color }} />}
    />
  );

  const dynamicBackgroundColor = STATUS_ICON[showIcon as keyof typeof STATUS_ICON]?.color;
  const rgbaBackgroundColor = () => {
    const hexToRgb = (hex) => {
      hex = hex.replace(/^#/, '');
      const r = parseInt(hex.slice(0, 2), 16);
      const g = parseInt(hex.slice(2, 4), 16);
      const b = parseInt(hex.slice(4, 6), 16);
      return { r, g, b };
    };
    if (typeof dynamicBackgroundColor !== 'undefined' && dynamicBackgroundColor !== null) {
      const { r, g, b } = hexToRgb(dynamicBackgroundColor);
      const opacity = 0.2;
      return `rgba(${r}, ${g}, ${b}, ${opacity})`;
    } else {
      return '';
    }
  };

  const contentPageButton = (
    <div
      className="status_list"
      style={{
        marginLeft: '8px',
        background: rgbaBackgroundColor(),
      }}
    >
      <i
        className={`iconfont ${showIcon}`}
        style={{ marginLeft: '5px', fontSize: '13px', color: dynamicBackgroundColor }}
      />
      <span
        style={{
          marginLeft: '4px',
          fontSize: '14px',
          color: dynamicBackgroundColor,
          lineHeight: '30px',
          marginRight: '4px',
        }}
      >
        {' '}
        {statusName}{' '}
      </span>
    </div>
  );

  const renderFlowButtons = () => {
    if (
      isNil(currentTemplate) ||
      !currentTemplate?.task_status ||
      btnClassName === 'card-status-btn' ||
      isNodeDisabled()
    )
      return null;

    let f = data.template?.task_status[data.tempInfo.status].flowSetting || [];
    if (f.length === 0) f = currentFlowSetting;
    else f = filterFlowSetting(data, org, currentUser);

    return f.map((btn) => {
      const btnRender = (disabled: boolean = false, target?: number) => (
        <div
          key={btn.index}
          style={{
            width: 'fit-content',
            minWidth: 'min-content',
            whiteSpace: 'nowrap',
            padding: '0 8px',
            color: 'white',
            backgroundColor: btn.color,
            borderRadius: 8,
            fontSize: 14,
            marginLeft: 6,
            height: 30,
            lineHeight: '30px',
            cursor: 'pointer',
          }}
          onClick={() => {
            if (disabled) return;
            if (isNil(currentTemplate)) return;
            handleStatus(
              currentTemplate.task_status[target ?? btn.index],
              target ?? btn.index,
              '状态转换',
              currentTemplate,
            );
          }}
        >
          {btn.name}
        </div>
      );

      const targetList =
        btn.buttonConditionIndexs?.filter(
          (b) => data.template?.task_status[b] && !data.template?.task_status[b].delete,
        ) ?? [];

      const popoverContent = (
        <div className="bc-popover-content">
          {targetList?.map((b) => {
            const buttonStatus = data.template!.task_status[b];
            return (
              <p
                key={buttonStatus.index}
                className="bc-popover-content-item"
                onClick={() => {
                  if (isNil(currentTemplate)) return;
                  handleStatus(currentTemplate.task_status[b], b, '状态转换', currentTemplate);
                }}
              >
                <i
                  className={`iconfont ${buttonStatus.icon}`}
                  style={{
                    fontSize: 14,
                    color: STATUS_ICON[buttonStatus.icon as keyof typeof STATUS_ICON].color,
                  }}
                />
                <span>{buttonStatus.name}</span>
              </p>
            );
          })}
        </div>
      );

      return targetList.length > 1 ? (
        <Popover
          key={btn.index}
          placement="bottomLeft"
          content={popoverContent}
          trigger="hover"
          overlayClassName="button-condition-popover"
        >
          {btnRender(true)}
        </Popover>
      ) : (
        btnRender(false, targetList[0])
      );
    });
  };

  const renderStatus = () => {
    if (!currentTemplate) return null;

    const contentStatusStyle = currentTemplate.temp_option?.contentStatusStyle
      ? currentTemplate.temp_option.contentStatusStyle
      : 'button';

    if (contentStatusStyle === 'list' || type === 'list') {
      return (
        <Popover
          trigger={trigger}
          arrowPointAtCenter={true}
          content={_ActionHandleMenuOptions}
          overlayClassName="statusMenuPop"
          placement={'rightTop'}
          open={visibleMenuPopover && !readOnlyMode}
          onOpenChange={(e) => {
            if (!e && onCancel) onCancel();
            else setVisibleMenuPopover(e);
          }}
        >
          {entrance === 'contentPage' ? contentPageButton : iconButton}
        </Popover>
      );
    }

    return (
      <div className={`${btnClassName} flowButtonsContainer`}>
        <div style={{ display: 'grid', gridTemplateColumns: '1fr auto' }}>
          <div
            style={{
              display: 'flex',
              overflowX: 'auto',
              whiteSpace: 'nowrap',
            }}
          >
            {!hiddenSwitchStatus && renderFlowButtons()}
          </div>
          {!hiddenStatusRecord && (
            <Tooltip
              placement="bottom"
              title={
                !!countersignStatus && (
                  <Space direction="vertical">
                    <Space wrap>
                      已处理：
                      {countersignStatus.submitedUsers.map((userId: string) =>
                        getUserNickName(userMap[userId]),
                      )}
                    </Space>
                    <Space wrap>
                      未处理：
                      {without(countersignStatus.submitedUsers, countersignStatus.allUsers).map(
                        (userId: string) => getUserNickName(userMap[userId]),
                      )}
                    </Space>
                  </Space>
                )
              }
            >
              <PiButton
                className={styles.history}
                type="secondary"
                size="small"
                onClick={() => {
                  setHistoryVisible(true);
                  setVisibleMenuPopover(false);
                }}
              >
                处理记录
                {countersignStatus &&
                  ':' +
                    [
                      countersignStatus.submitedUsers.length,
                      // allUsers 会出现 null，不清楚是什么原因
                      countersignStatus.allUsers.filter((u) => u).length,
                    ].join('/')}
                {countersignStatus && <RightOutlined style={{ fontSize: 12 }} />}
              </PiButton>
            </Tooltip>
          )}
        </div>
      </div>
    );
  };

  // const render = () => {
  //   if (useModal || !menuOption.length) {
  //     return null;
  //   }
  //   if (
  //     data.acl === NODE_PERMISSION.只读 ||
  //     data.nodeType === NODE_TYPE.CLEARING ||
  //     readOnly
  //   ) {
  //     return iconButton;
  //   }

  //   if (dataTemplate?.template_id === DEFAULT_TEMPLATE) {
  //     return <>{iconButton}</>;
  //   }

  //     return renderStatus();
  // };

  return data.acl === NODE_PERMISSION.只读 || data.nodeType === NODE_TYPE.CLEARING || readOnly ? (
    useModal || !menuOption.length ? null : (
      iconButton
    )
  ) : (
    <>
      {useModal || menuOption.length === 0
        ? null
        : dataTemplate?.template_id === DEFAULT_TEMPLATE
          ? iconButton
          : renderStatus()}
      <StatusModal
        modalTitle={modalTitle}
        scheduleShow={scheduleShow}
        onCancel={() => {
          if (onCancel) onCancel();
          handleStatusPromise.current.reject(null);
          resetHandleStatusPromise();
          setScheduleShow(false);
        }}
        scheduleLoading={scheduleLoading}
        onSubmit={onSubmit}
        formItems={formItems}
        editForm={form}
        node={data}
        transformType={transformType}
        orgId={spaceId}
        routeConfig={routeConfig}
      />
      {historyVisible && dataTemplate ? (
        <HistoryStatus
          node={data}
          visible={historyVisible}
          onCancel={() => {
            if (onCancel) onCancel();
            setHistoryVisible(false);
          }}
          orgId={spaceId}
          template={dataTemplate}
        />
      ) : null}
    </>
  );
};

export const StatusMenu = forwardRef(_StatusMenu);
