import { DEFAULT_TEMPLATE } from '@linkpi/core';
import { getShareDBConnectionInstance } from '@linkpi/core/web';
import { produce, setAutoFreeze } from 'immer';
import { throttle } from 'lodash';
import qs from 'query-string';
import { compose, concat, uniqBy } from 'ramda';

import {
  acceptInvite,
  addGroup,
  addGroupUser,
  addTag,
  addTemplate,
  copyTemplate,
  copyTemplates,
  deleteGroup,
  deleteTag,
  delGroupUser,
  delTemplate,
  editTemplateProp,
  fetchAddUserLink,
  fetchDistData,
  fetchGroupUserList,
  fetchProvData,
  fetchSpaceTags,
  fetchUserList,
  fetchUserPhone,
  getPropOptions,
  modifyGroupRole,
  modifyOrgNickName,
  modifyTag,
  modifyUserRole,
  organizationSetting,
  queryGroups,
  queryTemplateLibrary,
  queryTemplates,
  queryUserByGroups,
  removeCollectRule,
  setDefaultTemplate,
  setOrgInviteAndRole,
  updateCollectRule,
  updateGroup,
  updateTemplate,
} from '@/services/space';
import { queryCurrent } from '@/services/user';
import { getDvaApp } from '@@/plugin-dva/exports';

const throttleChange = throttle((put, instance) => {
  setTimeout(
    () =>
      getDvaApp()._store.runSaga(function* () {
        yield put({ type: 'handleNoticeInstance', payload: instance?.data || {} });
      }),
    Math.random() * 1000,
  );
}, 1_000);

const Model = {
  namespace: 'space',
  state: {
    currentUser: {},
    list: [],
    spaceUserList: [],
    groups: [],
    orgStruct: [],
    templates: [],
    templateLibrary: [],
    currentUserRole: -1,
    currentGroupId: '-1',
    currentTemplate: {},
    currentSpace: '',
    //空间模板属性选项
    tempPropOptions: {},
    settingUsers: [],
    settingUserMap: {},
    spaceTags: {},
    spaceGroups: {},
    // 空间配置通知
    spaceNoticeInstance: {},
    // 省市区行政区域数据
    provinceData: [],
    // 街道行政区域数据
    streetsMap: {},
    // 省市区索引数据
    provinceFlat: [],
    openTemplateHotkey: false,
    // 空间的草稿箱
    spaceDraftNodes: [],
  },
  effects: {
    *fetch({ payload }, { call, put, select }) {
      const { spaceNoticeInstance } = yield select((state) => state.space);
      const response = yield call(fetchUserList, payload);
      if (response.status === 'error') {
        return response;
      }
      const user_response = yield select((state) => state.user.currentUser);
      yield put({
        type: 'saveCurrentUser',
        payload: user_response,
      });

      let role = -1;
      response.data.forEach((item) => {
        if (item.account_id === user_response.userid && (!~role || role > item.role)) {
          role = item.role;
        }
      });
      if (~role) {
        yield put({
          type: 'queryCurrentUserRole',
          payload: role,
        });
      }
      yield put({
        type: 'setCurrentSpace',
        payload: payload.org_id,
      });

      yield put({
        type: 'setUserList',
        payload: Array.isArray(response.data) ? response.data : [],
      });

      const groupResponse = yield call(queryGroups, payload);
      yield put({
        type: 'queryGroups',
        payload: Array.isArray(groupResponse.data) ? groupResponse.data : [],
      });
      if (!spaceNoticeInstance[payload.org_id]) {
        yield put({
          type: 'initNoticeInstance',
          payload: payload.orgDocId,
        });
      }
      return response;
    },

    *updateList({ payload }, { put, select }) {
      const { spaceUserList = [], list = [] } = yield select((state) => state.space);
      const { account_id, phone } = payload;
      const phoneNum = phone && ~phone.indexOf('-') ? phone.split('-')[1] : phone;

      const _list = [...(list.length ? list : spaceUserList)].map((u) => {
        if (account_id !== u.account_id) return u;

        return {
          ...u,
          phone: phoneNum,
        };
      });

      yield put({
        type: 'setUserList',
        payload: _list,
      });

      yield put({
        type: 'querySpaceUserList',
        payload: _list,
      });

      return;
    },

    *setUserList({ payload }, { put }) {
      yield put({
        type: 'queryList',
        payload,
      });
      yield put({
        type: 'querySpaceUserList',
        payload,
      });
      yield put({
        type: 'task/parseUsersInSpace',
        payload,
      });
    },

    initNoticeInstance({ payload }, { select, put }) {
      const shareDBConnectionInstance = getShareDBConnectionInstance();
      const instance = shareDBConnectionInstance.get('testO1', payload);
      // 更新
      instance.on('op', () => {
        throttleChange(put, instance);
      });

      return new Promise((r) => {
        instance.subscribe(() => {
          r(instance);
          throttleChange(put, instance);
        });
      });
    },

    *handleNoticeInstance({ payload }, { select, put, call }) {
      const { spaceNoticeInstance, currentSpace, currentGroupId } = yield select(
        (state) => state.space,
      );

      if (
        !spaceNoticeInstance[currentSpace] ||
        spaceNoticeInstance[currentSpace].template !== payload.template
      ) {
        yield put({
          type: 'initTemplates',
          payload: { org_id: currentSpace },
        });
      }

      if (
        !spaceNoticeInstance[currentSpace] ||
        spaceNoticeInstance[currentSpace].user !== payload.user
      ) {
        yield put({
          type: 'fetchGroupUserList',
          payload: {
            org_id: currentSpace,
            group_id: currentGroupId,
            avatar: true,
            role: true,
          },
        });
        const response = yield call(fetchUserList, {
          org_id: currentSpace,
          avatar: true,
          role: true,
        });
        if (response?.status === 'ok') {
          yield put({
            type: 'setUserList',
            payload: Array.isArray(response.data) ? response.data : [],
          });
        }
      }
      yield put({
        type: 'setSpaceNoticeInstance',
        payload: { ...spaceNoticeInstance, [currentSpace]: payload },
      });
    },

    *fetchSpaceTags({ payload }, { call, put, select }) {
      const { spaceTags } = yield select((state) => state.space);
      const response = yield call(fetchSpaceTags, payload);
      if (response?.status === 'ok') {
        spaceTags[payload.org_id] = response.data || [];
      } else {
        spaceTags[payload.org_id] = [];
      }
      yield put({
        type: 'setSpaceTags',
        payload: {
          ...spaceTags,
        },
      });
      return spaceTags[payload.org_id] || [];
    },

    *addSpaceTag({ payload }, { call, put, select }) {
      const response = yield call(addTag, payload);
      if (response?.status === 'ok' && !payload.isOld) {
        const { spaceTags } = yield select((state) => state.space);
        const fetchRes = yield call(fetchSpaceTags, payload);
        if (fetchRes?.status === 'ok') {
          spaceTags[payload.org_id] = fetchRes.data || [];
          yield put({
            type: 'setSpaceTags',
            payload: {
              ...spaceTags,
            },
          });
        }
      }
      return response;
    },

    *modifySpaceTag({ payload }, { call, put, select }) {
      const response = yield call(modifyTag, payload);
      if (response?.status === 'ok') {
        const { spaceTags } = yield select((state) => state.space);
        const fetchRes = yield call(fetchSpaceTags, payload);
        if (fetchRes?.status === 'ok') {
          spaceTags[payload.org_id] = fetchRes.data || [];
          yield put({
            type: 'setSpaceTags',
            payload: {
              ...spaceTags,
            },
          });
        }
      }
      return response;
    },

    *delSpaceTag({ payload }, { call, put, select }) {
      const response = yield call(deleteTag, payload);
      if (response?.status === 'ok') {
        const { spaceTags } = yield select((state) => state.space);
        const fetchRes = yield call(fetchSpaceTags, payload);
        if (fetchRes?.status === 'ok') {
          spaceTags[payload.org_id] = fetchRes.data || [];
          yield put({
            type: 'setSpaceTags',
            payload: {
              ...spaceTags,
            },
          });
        }
      }
      return response;
    },

    *fetchUserPhone({ payload }, { call, put }) {
      const response = yield call(fetchUserPhone, payload);
      if (response && response.status === 'ok') {
        yield put({
          type: 'updateList',
          payload: { phone: response.data ?? '', account_id: payload.userid },
        });
      }
      return response;
    },

    *acceptInvite({ payload }, { call }) {
      return yield call(acceptInvite, payload);
    },

    *fetchGroupUserList({ payload }, { call, put }) {
      const response = yield call(fetchGroupUserList, payload);
      // if (response.status === 'ok') {
      yield put({
        type: 'setUserList',
        payload: Array.isArray(response.data) ? response.data : [],
      });
      yield put({
        type: 'queryCurrentGroupId',
        payload: payload.group_id,
      });
      // }
    },

    *queryUserByGroups({ payload }, { call }) {
      return yield call(queryUserByGroups, payload);
    },

    *getTemplate({ payload }, { call, put, select }) {
      const { templates } = yield select((state) => state.space);
      yield put({
        type: 'setCurrentTemplate',
        payload: Array.isArray(templates)
          ? templates.find((x) => x.template_id === payload.template_id)
          : {},
      });
    },

    *setDefaultTemplate({ payload }, { call, put }) {
      const response = yield call(setDefaultTemplate, payload);
      if (response.status === 'ok') {
        const userResponse = yield call(queryCurrent, payload);
        yield put({ type: 'user/saveCurrentUser', payload: userResponse });
      }
      return response;
    },

    *organizationSetting({ payload }, { call, put }) {
      const response = yield call(organizationSetting, payload);
      if (response.status === 'ok') {
        const userResponse = yield call(queryCurrent, payload);
        yield put({ type: 'user/saveCurrentUser', payload: userResponse });
        yield put({ type: 'workspace/setSpaceList', payload: userResponse.organization });
      }
      return response;
    },

    *setOrgInviteAndRole({ payload }, { call, put }) {
      const response = yield call(setOrgInviteAndRole, payload);
      if (response.status === 'ok') {
        const userResponse = yield call(queryCurrent, payload);
        yield put({ type: 'user/saveCurrentUser', payload: userResponse });
      }
      return response;
    },

    *fetchGroups({ payload }, { call, put }) {
      const response = yield call(queryGroups, payload);
      yield put({
        type: 'queryGroups',
        payload: Array.isArray(response.data) ? response.data : [],
      });
    },

    *fetchTemplates({ payload }, { call, put }) {
      const response = yield call(queryTemplates, payload);
      const templates = Array.isArray(response.data) ? response.data : [];
      yield put({
        type: 'queryTemplates',
        payload: templates,
      });
    },

    *initTemplates({ payload }, { call, put, select }) {
      const { currentTemplate } = yield select((state) => state.space);
      const response = yield call(queryTemplates, payload);
      const templates = Array.isArray(response.data) ? response.data : [];

      const templateId = payload?.templateId || qs.parse(location.search)?.templateId;

      yield put({
        type: 'queryTemplates',
        payload: templates,
      });

      // 过滤已删除的
      const notDelTemplates = (response?.data || []).filter((t) => t.status !== 3);
      let template = notDelTemplates.length > 0 ? notDelTemplates[0] : {};

      if (templateId && templates.find((x) => x.template_id === templateId)) {
        template = templates.find((x) => x.template_id === templateId);
      } else if (
        currentTemplate?.template_id &&
        templates.find((x) => x.template_id === currentTemplate.template_id)
      ) {
        template = templates.find((x) => x.template_id === currentTemplate.template_id);
      }

      const beforeTemplateId = localStorage.getItem('_before_template_');
      const beforeTemplate = notDelTemplates.find((t) => t.template_id === beforeTemplateId);
      if (payload.first && beforeTemplate) template = beforeTemplate;

      yield put({
        type: 'setCurrentTemplate',
        payload: template,
      });
    },

    *fetchTemplateLibrary({ payload }, { put, call, select }) {
      const { templateLibrary } = yield select((state) => state.space);

      if (!templateLibrary.length) {
        const lRes = yield call(queryTemplateLibrary, payload);
        const tLibrary = Array.isArray(lRes.data) ? lRes.data : [];
        yield put({
          type: 'queryTemplateLibrary',
          payload: tLibrary,
        });
      }
    },

    *copyTemplates({ payload }, { call, put }) {
      const copyRes = yield call(copyTemplates, payload);
      if (copyRes.status === 'ok') {
        const response = yield call(queryTemplates, payload);
        yield put({
          type: 'queryTemplates',
          payload: Array.isArray(response.data) ? response.data : [],
        });
      }
      return copyRes;
    },

    *copyTemplate({ payload }, { call, put }) {
      const copyRes = yield call(copyTemplate, payload);
      if (copyRes.status === 'ok') {
        const response = yield call(queryTemplates, payload);
        yield put({
          type: 'queryTemplates',
          payload: Array.isArray(response.data) ? response.data : [],
        });
      }
      return copyRes;
    },

    *addGroupUser({ payload }, { call, put }) {
      const addResponse = yield call(addGroupUser, payload);
      if (addResponse && addResponse.status === 'ok') {
        const response = yield call(queryGroups, payload);
        yield put({
          type: 'queryGroups',
          payload: Array.isArray(response.data) ? response.data : [],
        });
      }
      return addResponse;
    },

    *delGroupUser({ payload }, { call, put }) {
      const delResponse = yield call(delGroupUser, payload);
      if (delResponse && delResponse.status === 'ok') {
        const response = yield call(queryGroups, payload);
        yield put({
          type: 'queryGroups',
          payload: Array.isArray(response.data) ? response.data : [],
        });
        // const userResponse = yield call(fetchGroupUserList, {
        //   org_id: payload.org_id,
        //   group_id: payload.group_id,
        //   avatar: true,
        //   role: true,
        // });
        // yield put({
        //   type: 'setUserList',
        //   payload: Array.isArray(userResponse.data) ? userResponse.data : [],
        // });
      }
      return delResponse;
    },

    *modifyOrgNickName({ payload }, { call, put }) {
      const mResponse = yield call(modifyOrgNickName, payload);
      if (mResponse && mResponse.status === 'ok') {
        const response = yield call(queryGroups, payload);
        yield put({
          type: 'queryGroups',
          payload: Array.isArray(response.data) ? response.data : [],
        });
      }
      return mResponse;
    },

    *fetchAddGroup({ payload }, { call, put }) {
      const groupResponse = yield call(addGroup, payload);
      if (groupResponse && groupResponse.status === 'ok') {
        const response = yield call(queryGroups, payload);
        yield put({
          type: 'queryGroups',
          payload: Array.isArray(response.data) ? response.data : [],
        });
      }
      return groupResponse;
    },

    *addTemplate({ payload }, { call, put }) {
      const templateRes = yield call(addTemplate, payload);
      if (templateRes && templateRes.status === 'ok') {
        const response = yield call(queryTemplates, payload);
        let templates = [];
        if (Array.isArray(response.data)) {
          templates = response.data;
        }
        yield put({
          type: 'queryTemplates',
          payload: templates,
        });
        yield put({
          type: 'setCurrentTemplate',
          payload: templates.find((x) => x.template_id === templateRes.data.temp_id) || {},
        });
      }
      return templateRes;
    },

    *updateTemplate({ payload }, { call }) {
      return yield call(updateTemplate, payload);
    },

    *editTemplateProp({ payload }, { call }) {
      const { cleanProp = false, ...params } = payload;

      if (cleanProp) {
        params.cleanProps = payload.prop.map((p) => p.index);
      }

      return yield call(editTemplateProp, params);
    },

    /**
     * 会等待主题类型配置更新完成
     */
    *editTemplatePropAndWaitFetch({ payload }, { call, take }) {
      const result = yield call(editTemplateProp, payload);
      yield take('queryTemplates/@@end');
      return result;
    },

    *updateCollectRule({ payload }, { call, put, select }) {
      const templateRes = yield call(
        payload.collect_rule ? updateCollectRule : removeCollectRule,
        payload,
      );
      if (templateRes && templateRes.status === 'ok') {
        const { currentTemplate } = yield select((state) => state.space);
        const response = yield call(queryTemplates, payload);
        let templates = [];
        if (Array.isArray(response.data)) {
          templates = response.data;
        }
        yield put({
          type: 'queryTemplates',
          payload: templates,
        });
        if (Object.keys(currentTemplate).length > 0) {
          yield put({
            type: 'setCurrentTemplate',
            payload: templates?.find((x) => x.template_id === currentTemplate?.template_id) || {},
          });
        }
      }
      return templateRes;
    },

    *fetchDeleteGroup({ payload }, { call, put }) {
      const groupResponse = yield call(deleteGroup, payload);
      if (groupResponse && groupResponse.status === 'ok') {
        const response = yield call(queryGroups, payload);
        yield put({
          type: 'queryGroups',
          payload: Array.isArray(response.data) ? response.data : [],
        });
        const userResponse = yield call(fetchUserList, {
          org_id: payload.org_id,
          avatar: true,
          role: true,
        });
        yield put({
          type: 'setUserList',
          payload: Array.isArray(userResponse.data) ? userResponse.data : [],
        });
      }
      return groupResponse;
    },

    *delTemplate({ payload }, { call, put, select }) {
      const { currentTemplate } = yield select((state) => state.space);
      const templateRes = yield call(delTemplate, payload);
      if (templateRes && templateRes.status === 'ok') {
        const response = yield call(queryTemplates, payload);
        yield put({
          type: 'queryTemplates',
          payload: Array.isArray(response.data) ? response.data : [],
        });
        if (payload.template_id === currentTemplate.template_id) {
          console.log(response.data);
          yield put({
            type: 'setCurrentTemplate',
            payload: Array.isArray(response.data) && response.data.length ? response.data[0] : {},
          });
        }
      }
      return templateRes;
    },

    *fetchUpdateGroup({ payload }, { call, put }) {
      const groupResponse = yield call(updateGroup, payload);
      if (groupResponse && groupResponse.status === 'ok') {
        const response = yield call(queryGroups, payload);
        yield put({
          type: 'queryGroups',
          payload: Array.isArray(response.data) ? response.data : [],
        });
      }
      return groupResponse;
    },

    *fetchModifyGroupRole({ payload }, { call, put }) {
      const groupResponse = yield call(modifyGroupRole, payload);
      if (groupResponse && groupResponse.status === 'ok') {
        const response = yield call(queryGroups, payload);
        yield put({
          type: 'queryGroups',
          payload: Array.isArray(response.data) ? response.data : [],
        });
      }
      return groupResponse;
    },

    *modifyUserRole({ payload }, { call, put }) {
      const res = yield call(modifyUserRole, payload);
      if (res && res.status === 'ok') {
        const userResponse = yield call(fetchGroupUserList, {
          org_id: payload.org_id,
          group_id: payload.group_id,
          avatar: true,
          role: true,
        });
        yield put({
          type: 'setUserList',
          payload: Array.isArray(userResponse.data) ? userResponse.data : [],
        });
      }
      return res;
    },

    *appendFetch({ payload }, { call, put }) {
      const response = yield call(fetchUserList, {
        org_id: payload.spaceKey,
        avatar: true,
        role: true,
      });
      yield put({
        type: 'appendList',
        payload: Array.isArray(response.data) ? response.data : [],
      });
      yield put({
        type: 'setUserList',
        payload: Array.isArray(response.data) ? response.data : [],
      });
    },

    *fetchAddUserLink({ payload }, { call }) {
      return yield call(fetchAddUserLink, {
        org_id: payload.spaceKey,
      });
      // yield put({
      //   type: 'appendList',
      //   payload: Array.isArray(response.data) ? response.data : [],
      // });
    },

    *fetchPropOptions({ payload }, { call, put, select }) {
      const { tempPropOptions } = yield select((state) => state.space);
      let propOptions = [];
      if (!!payload.org_id && !!payload.temp_id && payload.temp_id !== DEFAULT_TEMPLATE) {
        if (
          payload.reFetch ||
          !tempPropOptions[payload.org_id] ||
          !tempPropOptions[payload.org_id][payload.temp_id]
        ) {
          const response = yield call(getPropOptions, payload);
          if (response && response.status === 'ok') {
            propOptions = response.data;
          }
          if (!tempPropOptions[payload.org_id]) {
            tempPropOptions[payload.org_id] = {
              [payload.temp_id]: propOptions,
            };
          } else {
            tempPropOptions[payload.org_id][payload.temp_id] = propOptions;
          }
          yield put({
            type: 'setTempPropOptions',
            payload: {
              ...tempPropOptions,
            },
          });
        } else {
          propOptions = tempPropOptions[payload.org_id][payload.temp_id];
        }
      }
      return propOptions;
    },

    *fetchUsersForSetting({ payload: { spaceId } }, { call, put }) {
      const response = yield call(fetchUserList, { org_id: spaceId, avatar: true });
      if (response?.status === 'ok') {
        yield put({
          type: 'setSettingUsers',
          payload: response.data,
        });
        yield put({
          type: 'setUserList',
          payload: response.data,
        });
        const userMap = {};
        response.data.map((x) => {
          userMap[x.account_id] = x;
        });
        yield put({
          type: 'setSettingUserMap',
          payload: userMap,
        });
      }
      return response;
    },

    *fetchProvData({ payload }, { call, put, select }) {
      let { provinceData } = yield select((state) => state.space);
      if (!provinceData.length) {
        const response = yield call(fetchProvData);
        if (response?.status === 'ok') {
          provinceData = response.data;
          yield put({
            type: 'setProvinceData',
            payload: response.data,
          });
          const provArr = [];
          provinceData.forEach((p) => {
            p.districts.forEach((c) => {
              if (c.districts?.length) {
                c.districts.forEach((d) => {
                  provArr.push({
                    prov: p,
                    city: c,
                    dist: d,
                  });
                });
              } else {
                provArr.push({
                  prov: p,
                  city: c,
                  dist: null,
                });
              }
            });
          });
          yield put({
            type: 'setProvinceFlat',
            payload: provArr,
          });
        }
      }
      return provinceData;
    },

    *fetchDistData({ payload: { adcode } }, { call, put, select }) {
      const { streetsMap } = yield select((state) => state.space);
      let streetData = streetsMap[adcode];
      if (!streetData) {
        const response = yield call(fetchDistData, { adcode: adcode });
        if (response?.status === 'ok') {
          streetData = response.data;
          yield put({
            type: 'setStreetsMap',
            payload: { ...streetsMap, [adcode]: response.data },
          });
        }
      }
      return streetData;
    },
    *queryTemplates({ payload }, { put }) {
      yield put({
        type: 'updateCurrentTemplate',
        payload,
      });
      yield put({
        type: 'updateSpaceTemplates',
        payload,
      });
    },
  },
  reducers: {
    /**
     *
     * @param {{
     *  currentUser: import('@linkpi/core').CurrentUser
     * }} state
     * @param {{
     *  payload: import('@linkpi/core').CurrentUser
     * }} action
     */
    saveCurrentUser(state, { payload }) {
      const { currentUser } = state;
      const oldOrgList = currentUser.organization || [];
      const newOrgList = compose(
        uniqBy((org) => org.orgId),
        concat(payload.organization || []),
      )(oldOrgList);

      return {
        ...state,
        currentUser: {
          ...currentUser,
          ...payload,
          organization: newOrgList,
        },
      };
    },
    setSpaceDraftNodes(state, action) {
      return { ...state, spaceDraftNodes: action.payload };
    },
    queryList(state, action) {
      return { ...state, list: action.payload };
    },
    setProvinceData(state, action) {
      return { ...state, provinceData: action.payload };
    },
    setProvinceFlat(state, action) {
      return { ...state, provinceFlat: action.payload };
    },
    setStreetsMap(state, action) {
      return { ...state, streetsMap: action.payload };
    },
    querySpaceUserList(state, action) {
      return { ...state, spaceUserList: action.payload };
    },
    querySpaceGroupUserList(state, action) {
      return { ...state, spaceGroupUserList: action.payload };
    },
    setSettingUsers(state, action) {
      return { ...state, settingUsers: action.payload };
    },
    setSpaceTags(state, { payload }) {
      return { ...state, spaceTags: payload };
    },
    setSpaceNoticeInstance(state, { payload }) {
      return { ...state, spaceNoticeInstance: payload };
    },
    setSettingUserMap(state, action) {
      return { ...state, settingUserMap: action.payload };
    },
    setCurrentTemplate(state, action) {
      return { ...state, currentTemplate: action.payload };
    },
    setCurrentSpace(state, action) {
      return { ...state, currentSpace: action.payload };
    },
    queryCurrentUserRole(state, action) {
      return { ...state, currentUserRole: action.payload };
    },
    queryCurrentGroupId(state, action) {
      return { ...state, currentGroupId: action.payload };
    },
    queryGroups(state, action) {
      return {
        ...state,
        groups: action.payload?.filter((i) => !i.org_struct),
        orgStruct: action.payload?.filter((i) => i.org_struct),
      };
    },
    updateCurrentTemplate(state, { payload }) {
      const { currentTemplate } = state;
      const newTempInfo = payload.find((i) => i.template_id === currentTemplate.template_id);

      setAutoFreeze(false);
      const result = produce(state, (draft) => {
        if (newTempInfo) {
          draft.currentTemplate = newTempInfo;
        }
      });
      setAutoFreeze(true);
      return result;
    },
    /**
     * old queryTemplates
     */
    updateSpaceTemplates(state, action) {
      return { ...state, templates: action.payload.map((i) => ({ ...i })) };
    },
    queryTemplateLibrary(state, action) {
      return { ...state, templateLibrary: action.payload };
    },
    appendList(
      state = {
        list: [],
      },
      action,
    ) {
      return { ...state, list: state.list.concat(action.payload) };
    },
    setTempPropOptions(state, { payload }) {
      return { ...state, tempPropOptions: payload };
    },
    setOpenTemplateHotkey(state, { payload }) {
      return { ...state, openTemplateHotkey: payload };
    },
  },
};
export default Model;
