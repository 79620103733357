import type { PiGridModules } from '../core';
import { checkboxConfig } from '../helper/config';
import type { RowType } from '../modules/dataManager';
import type { PositionType } from './_baseComponent';
import GridBaseComponent from './_baseComponent';
import CheckboxCell from './checkboxCell';

class CollapseCell extends GridBaseComponent {
  public data: RowType<'group'>;
  public countWidth: number = 40;
  public type: string = 'collapseCell';
  public captrue: boolean = false;

  constructor(position: PositionType, moduleInstances: PiGridModules, data: RowType<'group'>) {
    super(position, moduleInstances);
    this.data = data;
  }

  public click() {
    this.moduleInstances.DataManager.changeCollapseState(this.data.groupKey);
  }

  public drawGroupName(
    name: string,
    x: number,
    y: number,
    fittingWidth: number,
    extendColorMode: boolean,
  ) {
    const { Draw } = this.moduleInstances;
    const { styleMode } = Draw.config;

    Draw.attr({
      fillStyle: extendColorMode ? 'white' : styleMode === 'darkness' ? 'white' : '#316ef5',
      textBaseline: 'middle',
      textAlign: 'start',
      font: `${styleMode === 'darkness' ? 'bold ' : ''}${Draw.npx(14)}px  sans-serif`,
    });

    const t = Draw.newFittingString(name, fittingWidth);
    Draw.fillText(t, x, y);
  }

  public drawCount(count: number, x: number, y: number) {
    const { Draw } = this.moduleInstances;

    Draw.attr({
      fillStyle: '#767c88',
      textBaseline: 'middle',
      textAlign: 'start',
      font: `${Draw.npx(14)}px  sans-serif`,
    });

    Draw.fillText(`(${count})`, x, y);
  }

  public render() {
    const { Draw, Render, DataManager } = this.moduleInstances;
    const { size } = checkboxConfig;
    const { groupKey, groupInfo } = this.data;
    const {
      collapseState,
      config: { userMap, styleMode },
      groupMap,
      templateMap,
    } = DataManager;
    const containerBgColor = Draw.config.contentPageGeneralTableConfig?.containerBgColor || '#fff';

    const triangleHeight = 7;
    const triangleWidth = 7;

    Draw.fillRect(
      this.position.x,
      this.position.y,
      this.position.width,
      this.position.height,
      styleMode === 'darkness' ? containerBgColor : '#fff',
    );

    const textX = 34 + 2.5 * triangleWidth;
    const textY = this.position.y + this.position.height / 2;

    // 分组勾选
    const checkBoxCell = new CheckboxCell(
      {
        x: 10,
        y: textY - size / 2,
        width: size,
        height: size,
      },
      this.moduleInstances,
      { type: 'groupCheckbox', groupId: groupKey },
      (isGroupChecked: boolean) => {
        const { groupData, checkId } = this.moduleInstances.DataManager.config;
        const groupId = groupKey;
        const groupNodesIds = groupData[groupId].list.map((n) => n.id);

        let newCheckId: any[] = [...checkId];
        if (isGroupChecked) {
          newCheckId = checkId.filter((i) => !groupNodesIds.includes(i));
        } else {
          groupNodesIds.forEach((i) => {
            if (!checkId.includes(i)) newCheckId.push(i);
          });
        }

        this.moduleInstances.DataManager.config.onCheck(newCheckId, true);
      },
    );

    checkBoxCell.render(this.isHover);

    Draw.fillTriangle(
      {
        x: 34,
        y: textY - triangleHeight / 2,
        height: triangleHeight,
        width: triangleWidth,
      },
      '#6b7a96',
      collapseState[groupKey] ? 'right' : 'bottom',
    );

    let name = groupInfo.name;
    if (groupInfo.nameType === 'user' && name !== '未定义') {
      name = userMap[name]?.nick_name || '未知成员';
    }

    if (groupInfo.nameType === 'group') {
      name = groupMap[name]?.group_name || '未知成员组';
    }

    if (groupInfo.nameType === 'template') {
      name = templateMap[name]?.name || '未知主题类型';
    }
    Draw.ctx.font = `${Draw.npx(14)}px  sans-serif`
    const nameWidth = Draw.measureTextWidth(name);

    let extendColorMode = false;
    if (groupInfo.nameType === 'enum' && groupInfo.info?.prop) {
      const { extendColorMode: _extendColorMode, extendColor, extend } = groupInfo.info.prop;
      extendColorMode = _extendColorMode;
      const index = extend.findIndex((e: string) => e === groupInfo.name);
      if (extendColorMode) {
        Draw.fillRoundRect(
          {
            x: textX - 5,
            y: this.position.y + this.position.height / 2 - 20 / 2,
            width: nameWidth + (name === '(未定义)' ? 20 : 14),
            height: 20,
          },
          8,
          extendColor[index],
        );
      }
    }

    this.drawGroupName(name, textX, textY, 9999, extendColorMode);
    const countX = textX + nameWidth + 5 + (extendColorMode ? 10 : 0);
    this.drawCount(groupInfo.list.length, countX, textY);
  }
}

export default CollapseCell;
