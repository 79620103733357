import { Spin, Typography } from 'antd';
import type { FC } from 'react';
import { memo } from 'react';

const _PageLoading: FC<{ tip?: string }> = ({ tip = '加载中...' }) => (
  <div className="flex gap-4 flex-col items-center pt-24">
    <Spin size="large" />
    {tip && <Typography.Paragraph type="secondary">{tip}</Typography.Paragraph>}
  </div>
);

// https://umijs.org/plugin/umi-plugin-react.html#dynamicimport
export const PageLoading = memo(_PageLoading, (oldProps: any, newProps: any) => {
  return oldProps.tip === newProps.tip;
});

export default PageLoading;
