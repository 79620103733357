import { antdModalV5, create, show, useModal } from '@ebay/nice-modal-react';
import type { ApiResponse } from '@linkpi/core';
import type { ViewList } from '@linkpi/core';
import type { ConditionRuleConfigV2 } from '@linkpi/core';
import { useMemoizedFn, useRequest } from 'ahooks';
import type { FormInstance } from 'antd';
import { Button, Form, Modal } from 'antd';
import { cloneDeep } from 'lodash';
import { type FC, type ReactElement, useState } from 'react';

import { ConditionFilterPanel } from '@/components/ConditionFilter';
import { useCurrentTemplateSetting } from '@/hook';

import RulePanel from './RulePanel';

import styles from './styles.less';

export const STATUS = { label: '状态', value: '$status' };

const DEFAULT_NEW_FORM_VALUE = {};

const allowKey = ['prop', 'templateId', 'status'];
const allowPropTypes: ApiResponse.CurrentUser.propType[] = [
  'user',
  'enum',
  'tag',
  'text',
  'number',
  'formula',
];

export type PropRuleConditionModalProps = {
  mode?: 'create' | 'edit';
  config?: ConditionRuleConfigV2.ConditionRuleConfigV2;
  resultForm: ReactElement;
  onValuesChange?: (
    changedValues: any,
    values: ConditionRuleConfigV2.ConditionRuleConfigV2,
    form: FormInstance<ConditionRuleConfigV2.ConditionRuleConfigV2>,
  ) => void;
};

const TITLE_LABEL: {
  [k in PropRuleConditionModalProps['mode'] as string]: string;
} = {
  create: '创建',
  edit: '编辑',
};

const BUTTON_LABEL: typeof TITLE_LABEL = {
  create: '创建',
  edit: '保存',
};

const PropRuleConditionModal: FC<PropRuleConditionModalProps> = ({
  mode = 'edit',
  config,
  resultForm,
  onValuesChange,
}) => {
  const [ruleForm] = Form.useForm<ConditionRuleConfigV2.ConditionRuleConfigV2>();
  const modal = useModal();

  const [tempInfo] = useCurrentTemplateSetting();
  const templateId = tempInfo.template_id;

  const [condition, setCondition] = useState<ViewList.ViewconditionV2>(
    // @ts-ignore
    config?.condition || [
      {
        op: 'beIncluded',
        key: 'templateId',
        input: [templateId],
      },
      {},
    ],
  );

  const onValueUpdate = useMemoizedFn((changedValue, values) => {
    onValuesChange?.(changedValue, values, ruleForm);
  });

  // NOTE: 仅用作管理 laoding，虽然 usePropRules 内部有 loading 状态，但是比较难传递，姑且这里单独控制
  const { loading, run: onSubmit } = useRequest(
    async () => {
      let values: ConditionRuleConfigV2.ConditionRuleConfigV2;
      try {
        values = await ruleForm.validateFields();
        values.condition = condition;
        modal.resolve(cloneDeep(values));
        modal.hide();
      } catch (error: any) {
        console.log(error);
      }
    },
    {
      manual: true,
    },
  );

  return (
    <Modal
      title={TITLE_LABEL[mode] + '条件规则'}
      footer={false}
      centered
      width={1592}
      destroyOnClose
      {...antdModalV5(modal)}
    >
      <Form<ConditionRuleConfigV2.ConditionRuleConfigV2>
        onValuesChange={onValueUpdate}
        form={ruleForm}
        initialValues={mode === 'edit' ? config : DEFAULT_NEW_FORM_VALUE}
      >
        <div className={styles.content}>
          {/* 条件 */}
          <RulePanel mode="condition">
            <RulePanel.Card color="#F4FCF2" spaceProps={false}>
              <ConditionFilterPanel
                editable
                addText="新增条件"
                displayHeader={false}
                displayParentId={false}
                displayTemplate={false}
                allowKey={allowKey}
                allowPropTypes={allowPropTypes}
                value={condition}
                onChange={(v) => setCondition(v)}
                experimental_disableHistoryStatus
                experimental_userLiteMode
                experimental_enableUserProperty
              />
            </RulePanel.Card>
          </RulePanel>
          {/* 结果 */}
          <RulePanel mode="result">{resultForm}</RulePanel>
          <div className={styles.arrow}>
            <i className="iconfont iconchufa" />
          </div>
        </div>
      </Form>
      <div className={styles.save}>
        <Button type="primary" shape="round" size="large" onClick={onSubmit} loading={loading}>
          {BUTTON_LABEL[mode]}
        </Button>
      </div>
    </Modal>
  );
};

const ConditionRuleModal = create(PropRuleConditionModal);

export const showConditionRuleModal = <T extends any[] = any[]>(
  props: PropRuleConditionModalProps,
) => show(ConditionRuleModal, props) as Promise<ConditionRuleConfigV2.ConditionRuleConfigV2<T>>;
