import type { ApiResponse } from '@linkpi/core';
import type { CurrentUser } from '@linkpi/core';
import { STATUS_ICON } from '@linkpi/core';
import { convertTimeByModule } from '@linkpi/core';
import { getMatchingOriginProp } from '@linkpi/core';
import { Date_Format_Options } from '@linkpi/core';
import { Avatar, Cascader, DatePicker, Input, InputNumber, message, Popover, Select } from 'antd';
import type { RangePickerProps } from 'antd/es/date-picker';
import dayjs from 'dayjs';
import moment from 'moment/moment';
import { Fragment, useEffect, useMemo, useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useDispatch, useSelector } from 'umi';

import LinkPiDate from '@/components/LinkPiDate';
import { selectSearchFn } from '@/components/LinkPiForm/utils';
import { useQuoteOptions } from '@/pages/home/components/View/hooks';
import PositioningFilter from '@/pages/space/components/TemplateProp/components/QuoteFilter/PositioningFilter';
import { transRangeDate } from '@/pages/space/components/TemplateProp/components/TempPropModal/utils';
import { filterUsersByPinyin } from '@/utils/utils';
import { uniqueArr } from '@/utils/utils';

import './index.less';

const { OptGroup, Option } = Select;
const { RangePicker } = DatePicker;

const AUTO_UPDATE_OPTIONS = [
  { label: '今天', value: 0 },
  { label: '昨天', value: 'yesterday' },
  { label: '近3天', value: -2 },
  { label: '近7天', value: -7 },
  { label: '近15天', value: -14 },
  { label: '近30天', value: -29 },
  { label: '近60天', value: -59 },
  { label: '近90天', value: -89 },
  { label: '本周', value: 'week' },
  { label: '本月', value: 'month' },
  { label: '本季度', value: 'quarter' },
  { label: '本年', value: 'year' },
  { label: '去年', value: 'lastYear' },
];

const FilterKeyGroups = {
  system: {
    label: '系统属性',
    options: [
      {
        label: '创建者',
        value: 'creator',
      },
      {
        label: '创建时间',
        value: 'createTime',
      },
      {
        label: '系统标签',
        value: 'sysTag',
      },
    ],
  },
  status: {
    label: '状态相关',
    options: [
      {
        label: '状态',
        value: 'status',
      },
      {
        label: '负责人',
        value: 'status_0',
      },
      {
        label: '参与者',
        value: 'status_1',
      },
      {
        label: '开始时间',
        value: 'status_2',
      },
      {
        label: '结束时间',
        value: 'status_3',
      },
    ],
  },
  theme: {
    label: '主题属性',
    options: [],
  },
};

const FilterKeyTypeMap: Record<string, CurrentUser.ConditionType> = {
  creator: 'user',
  createTime: 'date',
  sysTag: 'enum',
  status: 'enum',
  status_0: 'user',
  status_1: 'user',
  status_2: 'date',
  status_3: 'date',
};

const FilterTypes = ['user', 'enum', 'date', 'text', 'number', 'address', 'positioning'];

type AreaData = CurrentUser.AreaData;

const QuoteFilter = (props: {
  conditions: CurrentUser.Condition[];
  setConditions: Function;
  template: CurrentUser.TemplateInfo | undefined;
  tempMap: Record<string, CurrentUser.TemplateInfo>;
  orgUsers: ApiResponse.OrgUser;
  orgId: string;
  readOnly?: boolean;
}) => {
  const { conditions, setConditions, template, tempMap, orgUsers, orgId, readOnly = false } = props;
  const dispatch = useDispatch();
  const { spaceTags } = useSelector((state: any) => state.space);
  const [provData, setProvData] = useState<AreaData[]>([]);
  const [positionVisible, setPositionVisible] = useState(false);
  const [curIndex, setCurIndex] = useState(-1);

  const [quoteOptions] = useQuoteOptions(orgId, template);

  useEffect(() => {
    fetchProvData();
  }, [1]);

  const fetchProvData = () => {
    dispatch({
      type: 'space/fetchProvData',
    }).then((res: AreaData[]) => setProvData(res));
  };

  useEffect(() => {
    if (!spaceTags[orgId]) {
      dispatch({
        type: 'space/fetchSpaceTags',
        payload: { org_id: orgId },
      });
    }
  }, [orgId]);

  // 系统标签
  const tags = useMemo(() => {
    if (orgId && spaceTags?.[orgId]) {
      return spaceTags[orgId];
    } else {
      return [];
    }
  }, [orgId, spaceTags]);

  const [filterKeyGroups, filterKeyTypeMap, templateProps] = useMemo(() => {
    if (!template || !template.prop.length) return [FilterKeyGroups, FilterKeyTypeMap, []];
    const themeOptions: {
      label: string;
      value: string;
      extend?: string[];
      type?: CurrentUser.propType;
    }[] = [];
    const keyTypeMap: Record<string, CurrentUser.ConditionType> = {
      ...FilterKeyTypeMap,
    };
    const tempProps = JSON.parse(JSON.stringify(template.prop));
    tempProps
      .map((p: CurrentUser.TemplateProp, i: number) => {
        p.index = i;
        if (!('sort' in p)) p.sort = i;
        if (p?.type) {
          switch (p.type) {
            case 'quote':
              if (p.matchingType > 0) {
                p.type = 'number';
              } else {
                // @ts-ignore
                const originProp: ApiResponse.CurrentUser.TemplateProp | null =
                  getMatchingOriginProp(p, tempMap);
                if (originProp?.type) {
                  p.type = originProp.type;
                  if (originProp.type === 'enum' || originProp.type === 'tag') {
                    if (
                      originProp.index === -1000 &&
                      originProp.quoteTempId &&
                      tempMap[originProp.quoteTempId]
                    ) {
                      p.quoteTempId = originProp.quoteTempId;
                    } else {
                      p.extend = originProp.extend || [];
                    }
                  }
                }
              }
              break;
            case 'formula':
              p.type = p.formulaFormat ? 'text' : 'number';
              break;
            case 'tag':
              p.type = 'enum';
              break;
            case 'currency':
              p.type = 'number';
              break;
            case 'datetime':
              p.type = 'date';
              break;
          }
        }
        return p;
      })
      .filter((p: CurrentUser.TemplateProp) => p?.type && FilterTypes.includes(p.type))
      .sort((b: CurrentUser.TemplateProp, a: CurrentUser.TemplateProp) => b.sort - a.sort)
      .map((p: CurrentUser.TemplateProp) => {
        // @ts-ignore
        keyTypeMap['prop_' + p.index] = p.type;
        themeOptions.push({
          label: p.name,
          value: 'prop_' + p.index,
          type: p.type,
        });
      });
    return [
      {
        ...FilterKeyGroups,
        theme: {
          ...FilterKeyGroups.theme,
          options: themeOptions,
        },
      },
      keyTypeMap,
      tempProps,
    ];
  }, [template]);

  const getDateFormatByKey = (key: string) => {
    if (key && key.startsWith('prop')) {
      const [_, index] = key.split('_');
      const prop = templateProps[Number(index)];
      return prop?.dateFormat || (prop?.type === 'date' ? 'YYYY-MM-DD' : 'YYYY-MM-DD HH:mm');
    }
    return 'YYYY-MM-DD HH:mm';
  };

  // 设置筛选条件值
  const setConditionValue = (
    index: number,
    type: CurrentUser.ConditionType | 'symbol',
    value: any,
  ) => {
    const newConditions = [...conditions];
    newConditions.splice(index, 1, { ...newConditions[index], [type]: value });
    setConditions(newConditions);
  };
  // 筛选条件运算符
  const genConditionSymbol = (condition: CurrentUser.Condition, index: number) => {
    const type = filterKeyTypeMap?.[condition.key];
    let select = null;
    switch (type) {
      case 'user':
      case 'enum':
      case 'address':
      case 'text':
        select = (
          <Select
            disabled={readOnly || condition.key === 'status' || condition.key === 'creator'}
            options={
              condition.key === 'status' || condition.key === 'creator'
                ? [
                    {
                      label: '包含',
                      value: 'INCLUDE',
                    },
                  ]
                : [
                    {
                      label: '包含',
                      value: 'INCLUDE',
                    },
                    {
                      label: '等于',
                      value: 'EQUAL',
                    },
                  ]
            }
            value={condition.symbol || 'INCLUDE'}
            getPopupContainer={(triggerNode) => triggerNode.parentNode}
            onChange={(e) => setConditionValue(index, 'symbol', e)}
          />
        );
        break;
      case 'number':
        select = (
          <Select
            disabled={readOnly}
            value={condition.number?.[0]?.symbol || 'EQUAL'}
            onChange={(e) =>
              setConditionValue(index, type, [
                condition.number?.[0]
                  ? { ...condition.number?.[0], symbol: e }
                  : { symbol: e, number: null },
              ])
            }
            getPopupContainer={(triggerNode) => triggerNode.parentNode}
          >
            <Option value={'EQUAL'}>=</Option>
            <Option value={'NOT_EQUAL'}>≠</Option>
            <Option value={'GREAT'}>{'>'}</Option>
            <Option value={'GREAT_EQUAL'}>≥</Option>
            <Option value={'LESS'}>{'<'}</Option>
            <Option value={'LESS_EQUAL'}>≤</Option>
          </Select>
        );
        break;
      case 'date':
        select = (
          <Select
            disabled={readOnly}
            value={condition.symbol || 'INSIDE'}
            onChange={(e) => setConditionValue(index, 'symbol', e)}
            getPopupContainer={(triggerNode) => triggerNode.parentNode}
          >
            <Option value={'INSIDE'}>处于</Option>
            <Option value={'EQUAL'}>等于</Option>
            <Option value={'GREAT'}>晚于</Option>
            <Option value={'GREAT_EQUAL'}>晚于等于</Option>
            <Option value={'LESS'}>早于</Option>
            <Option value={'LESS_EQUAL'}>早于等于</Option>
          </Select>
        );
        break;
    }
    return <div className={'condition-item-symbol'}>{select}</div>;
  };
  // 生成筛选条件值输入框
  const genConditionValueInput = (condition: CurrentUser.Condition, index: number) => {
    const type = filterKeyTypeMap?.[condition.key];
    switch (type) {
      case 'user':
        const map = orgUsers.reduce((res, cur) => {
          res[cur.nick_name] = cur;

          return res;
        }, {} as any);

        return (
          <Select
            disabled={readOnly}
            mode={'multiple'}
            value={condition[type]}
            showSearch
            onChange={(e) => setConditionValue(index, type, e)}
            options={orgUsers.map((user) => {
              return {
                label: (
                  <div className="user-option-label">
                    <Avatar size={16} src={user.avatar} />
                    {user.nick_name}
                  </div>
                ),
                value: user.account_id,
                nickName: user.nick_name,
              };
            })}
            filterOption={(input, option: any) => {
              return filterUsersByPinyin(input, { children: option.nickName }, map);
            }}
            placeholder={'请选择人员'}
            getPopupContainer={(triggerNode) => triggerNode.parentNode}
          />
        );
      case 'enum':
        const options: any[] = [];
        let mode: 'multiple' | 'tags' = 'multiple';
        if (condition.key === 'sysTag') {
          tags.forEach((t: any) => {
            options.push({
              label: t.tag,
              value: t.tag,
            });
          });
        } else if (condition.key === 'status') {
          template?.task_status.forEach((s, i) => {
            if (s && !s.delete) {
              options.push({
                label: (
                  <div>
                    <i
                      className={`iconfont ${s.icon}`}
                      // @ts-ignore
                      style={{ color: STATUS_ICON[s.icon]?.color, marginRight: 4 }}
                    />
                    {s.name}
                  </div>
                ),
                value: i,
              });
            }
          });
        } else {
          const propIndex = Number(condition.key.split('_')[1]);
          if (templateProps[propIndex]?.quoteTempId) {
            // 引用属性引用状态
            tempMap[templateProps[propIndex]?.quoteTempId]?.task_status.forEach((s) => {
              if (s && !s.delete) {
                options.push({
                  label: (
                    <div>
                      <i
                        className={`iconfont ${s.icon}`}
                        // @ts-ignore
                        style={{ color: STATUS_ICON[s.icon]?.color, marginRight: 4 }}
                      />
                      {s.name}
                    </div>
                  ),
                  value: s.name,
                });
              }
            });
          } else {
            mode = 'tags';
            const extend = templateProps[propIndex]?.extend || [];
            extend.push.apply(extend, quoteOptions[propIndex] || []);
            uniqueArr(extend).forEach((e: string) => {
              options.push({
                label: e,
                value: e,
              });
            });
          }
        }
        return (
          <Select
            disabled={readOnly}
            mode={mode}
            value={condition[type]}
            onChange={(e) => setConditionValue(index, type, e)}
            options={options}
            placeholder={'请选择'}
            getPopupContainer={(triggerNode) => triggerNode.parentNode}
          />
        );
      case 'text':
        return condition[type]?.map((v, i) => (
          <Input
            disabled={readOnly}
            placeholder={'请输入'}
            value={v}
            onChange={(e) => {
              const values = condition[type];
              values?.splice(i, 1, e.target.value);
              setConditionValue(index, type, values);
            }}
            addonAfter={
              // @ts-ignore
              condition[type]?.length > 1 ? (
                <i
                  className={'iconfont icona-zujian67'}
                  onClick={() => {
                    const values = condition[type];
                    values?.splice(i, 1);
                    setConditionValue(index, type, values);
                  }}
                />
              ) : null
            }
          />
        ));
      case 'date':
        const dateFormat = getDateFormatByKey(condition.key);
        const dateModule = Date_Format_Options.find((x) => x.value === dateFormat)?.module || 'day';
        const dynamic = AUTO_UPDATE_OPTIONS.find((x) => x.value === (condition[type] || [])[2]);
        if (!condition.symbol || condition.symbol === 'INSIDE') {
          return (
            <RangePicker
              picker={dateModule as RangePickerProps['picker']}
              disabled={readOnly}
              ranges={
                dateModule === 'day' || dateModule === 'meridiem' || dateModule === 'min'
                  ? {
                      今日: [moment(), moment()],
                      七天内: [moment().subtract(6, 'd'), moment()],
                      本月: [moment().startOf('month'), moment().endOf('month')],
                    }
                  : undefined
              }
              popupClassName="condition-range-picker"
              renderExtraFooter={(mode) => (
                <div className="view-range-picker-extra">
                  <div className="extra-title">自动更新：</div>
                  {AUTO_UPDATE_OPTIONS.map((r) => (
                    <div
                      key={r.value}
                      className={`extra-title btn${
                        r.value === (condition[type] || [])[2] ? ' active' : ''
                      }`}
                      onClick={() => setConditionValue(index, type, transRangeDate(r.value))}
                    >
                      {r.label}
                    </div>
                  ))}
                </div>
              )}
              showSecond={false}
              value={
                (condition[type] || []).map((x) => (x ? moment(x) : x)) as RangePickerProps['value']
              }
              onChange={(e) => {
                let result: [number | null, number | null] = [null, null];
                if (e) {
                  const [start, end] = e;

                  // 约束一天开始和结束时间
                  result = [
                    start && dayjs(start.format('YYYY-MM-DD 00:00:00')).valueOf(),
                    end && dayjs(end.format('YYYY-MM-DD 23:59:59')).valueOf(),
                  ];
                }
                setConditionValue(index, type, result);
              }}
              suffixIcon={dynamic ? <div style={{ color: '#316ef5' }}>{dynamic.label}</div> : null}
              allowClear={false}
            />
          );
        } else {
          return (
            <LinkPiDate
              dateFormat={dateFormat}
              onChange={(e: any) =>
                setConditionValue(index, type, e ? [convertTimeByModule(e, dateModule)] : [])
              }
              dateValue={(condition[type] || [])[0] ? dayjs((condition[type] || [])[0]) : undefined}
            />
            /*<DatePicker
              disabled={readOnly}
              // @ts-ignore
              value={(condition[type] || [])[0] ? moment((condition[type] || [])[0]) : undefined}
              onChange={(e) => setConditionValue(index, type, e ? [e.valueOf()] : [])}
            />*/
          );
        }
      case 'number':
        return (
          <InputNumber
            disabled={readOnly}
            value={condition[type]?.[0]?.number ?? null}
            onChange={(e) =>
              setConditionValue(index, type, [
                condition[type]?.[0]
                  ? { ...condition[type]?.[0], number: e }
                  : { symbol: 'EQUAL', number: e },
              ])
            }
            placeholder={'请输入'}
          />
        );
      case 'address':
        return (
          <Cascader
            options={provData}
            fieldNames={{ label: 'name', value: 'adcode', children: 'districts' }}
            multiple
            value={condition[type]}
            onChange={(e) => setConditionValue(index, type, e)}
            placeholder={'请选择'}
          />
        );
      default:
        return <div className={'condition-item-value-null'}>暂不支持此类型</div>;
    }
  };

  const popContent = (index: number) => {
    return (
      <PerfectScrollbar style={{ maxHeight: 328 }}>
        <div className={'quote-filter-pop-prop'}>
          {templateProps.filter(
            (o: any) =>
              filterKeyTypeMap['prop_' + o.index] === 'positioning' &&
              'prop_' + o.index !== conditions[index]?.key,
          ).length ? (
            templateProps
              .filter(
                (o: any) =>
                  filterKeyTypeMap['prop_' + o.index] === 'positioning' &&
                  'prop_' + o.index !== conditions[index]?.key,
              )
              .map((o: any, j: number) => {
                return (
                  <div
                    className={`quote-filter-pop-prop-item${
                      conditions[index].positioning?.[0]?.prop === o.index ? ' active' : ''
                    }`}
                    key={j}
                    onClick={() =>
                      setConditionValue(index, 'positioning', [
                        {
                          ...conditions[index].positioning?.[0],
                          type: 'prop',
                          prop: o.index,
                        },
                      ])
                    }
                  >
                    {o.name}
                  </div>
                );
              })
          ) : (
            <div className={`quote-filter-pop-prop-description`}>没有可以引用的属性</div>
          )}
        </div>
      </PerfectScrollbar>
    );
  };

  const genPositionFilter = (index: number) => {
    return (
      <div className={'condition-item-positioning'}>
        距离
        {conditions[index].positioning?.[0]?.type === 'prop' &&
        // @ts-ignore
        template?.prop[conditions[index].positioning?.[0]?.prop] ? (
          <Popover
            trigger={'click'}
            placement={'bottom'}
            content={() => popContent(index)}
            overlayClassName={'quote-filter-pop'}
          >
            <div className={'condition-item-positioning-map text-omit'}>
              <div className={'text-omit'}>
                {
                  // @ts-ignore
                  template.prop[conditions[index].positioning[0].prop].name
                }
              </div>
              <i
                className={`iconfont iconformula-subtract`}
                onClick={() =>
                  setConditionValue(index, 'positioning', [
                    {
                      ...conditions[index].positioning?.[0],
                      type: 'const',
                    },
                  ])
                }
              />
            </div>
          </Popover>
        ) : (
          <div
            className={`condition-item-positioning-map text-omit${
              conditions[index].positioning?.[0]?.position ? '' : ' null'
            }`}
          >
            <div
              className={'condition-item-positioning-map-name text-omit'}
              onClick={() => {
                setCurIndex(index);
                setPositionVisible(true);
              }}
            >
              {conditions[index].positioning?.[0]?.position
                ? conditions[index].positioning?.[0]?.position?.name
                : '请选择地址'}
            </div>
            {}
            <Popover
              trigger={'click'}
              placement={'bottomRight'}
              content={() => popContent(index)}
              overlayClassName={'quote-filter-pop'}
            >
              <i className={`iconfont iconButton_add`} />
            </Popover>
          </div>
        )}
        <InputNumber
          value={conditions[index].positioning?.[0]?.distance ?? null}
          precision={2}
          placeholder={'请输入距离范围'}
          className={'condition-item-positioning-distance'}
          onChange={(e) =>
            setConditionValue(index, 'positioning', [
              {
                ...conditions[index].positioning?.[0],
                distance: e,
              },
            ])
          }
        />
        公里以内
      </div>
    );
  };

  // 增加筛选条件
  const addCondition = () => {
    if (!conditions.find((x) => x.key === null)) {
      setConditions([
        ...conditions,
        {
          key: null,
        },
      ]);
    } else {
      message.warn('请先选择筛选条件');
    }
  };

  // 删除筛选条件
  const deleteCondition = (index: number) => {
    const newConditions = [...conditions];
    newConditions.splice(index, 1);
    setConditions(newConditions);
  };

  return (
    <div id={'QuoteFilter'}>
      {conditions.map((condition, index) => {
        return (
          <div className={'condition-item'} key={index}>
            <div className={'condition-item-key'}>
              <Select
                value={condition.key}
                showSearch
                filterOption={selectSearchFn}
                onChange={(e) => {
                  const newConditions = [...conditions];
                  newConditions.splice(index, 1, {
                    key: e,
                    [filterKeyTypeMap[e]]: filterKeyTypeMap[e] === 'text' ? [''] : [],
                    type: filterKeyTypeMap[e],
                    symbol:
                      filterKeyTypeMap[e] === 'number'
                        ? 'EQUAL'
                        : filterKeyTypeMap[e] === 'date'
                          ? 'INSIDE'
                          : 'INCLUDE',
                  });
                  setConditions(newConditions);
                }}
                placeholder={'请选择条件'}
                disabled={readOnly}
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
              >
                {Object.values(filterKeyGroups).map((keyGroup, ki) => (
                  <OptGroup label={keyGroup.label} key={ki}>
                    {keyGroup.options.map((o) => (
                      <Option
                        label={o.label}
                        value={o.value}
                        key={o.value}
                        children={o.label}
                        disabled={~conditions.findIndex((c, i) => c.key === o.value && i !== index)}
                      />
                    ))}
                  </OptGroup>
                ))}
              </Select>
            </div>
            {condition.key === null ? (
              <div className={'condition-item-null'}>请先选择筛选条件</div>
            ) : condition.type === 'positioning' ? (
              genPositionFilter(index)
            ) : (
              <Fragment>
                {genConditionSymbol(condition, index)}
                <div
                  className={`condition-item-value${
                    condition.type === 'text' ? ' hasAddAfter' : ''
                  }`}
                >
                  {genConditionValueInput(condition, index)}
                </div>
                {condition.type === 'text' ? (
                  <i
                    className={'iconfont iconButton_add'}
                    onClick={() =>
                      setConditionValue(index, 'text', [...(condition.text || []), ''])
                    }
                  />
                ) : null}
              </Fragment>
            )}
            {readOnly ? null : (
              <i className={'iconfont icona-zujian67'} onClick={() => deleteCondition(index)} />
            )}
          </div>
        );
      })}
      {readOnly ? null : (
        <div className={'condition-add'} onClick={addCondition}>
          <i className={'iconfont iconButton_add'} />
          新增筛选条件
        </div>
      )}
      {~curIndex && conditions[curIndex].positioning && positionVisible ? (
        <PositioningFilter
          conditionPosition={conditions[curIndex].positioning?.[0]?.position || null}
          visible={positionVisible}
          setVisible={setPositionVisible}
          onSubmit={(p) =>
            setConditionValue(curIndex, 'positioning', [
              {
                ...conditions[curIndex].positioning?.[0],
                position: p,
                type: 'const',
              },
            ])
          }
        />
      ) : null}
    </div>
  );
};

export default QuoteFilter;
