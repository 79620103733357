import NiceModal from '@ebay/nice-modal-react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import type { ReactElement } from 'react';

import { VersionInfoWrapper } from '@/components/VersionInfo';

import { CustomWidgeUtilsProvider } from './components';

import 'reflect-metadata';

const queryClient = new QueryClient();

export function rootContainer(container: ReactElement) {
  return (
    <CustomWidgeUtilsProvider>
      <QueryClientProvider client={queryClient}>
        <NiceModal.Provider>
          <VersionInfoWrapper>{container}</VersionInfoWrapper>
        </NiceModal.Provider>
      </QueryClientProvider>
    </CustomWidgeUtilsProvider>
  );
}
