import type { GetterPiNode } from '@linkpi/core/web';
import cls from 'classnames';
import { nanoid } from 'nanoid';
import { isEmpty } from 'ramda';
import type { FC } from 'react';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'umi';

import { ConditionFilterButton } from '@/components/ConditionFilter/ConditionFilterButton';
import { conditionV2ConvertCondition } from '@/components/ConditionFilter/utils';
import type { IWidgetInstanceData } from '@/components/PageModelEditor';
import {
  useCurrentUser,
  useDisabledEvents,
  useOrgInfo,
  useOrgUserMap,
  useSectionInstanceConfig,
} from '@/hook';
import { groupingNodes } from '@/pages/home/components/TabContent/utils';
import { DividerList } from '@/pages/home/components/View/ViewSettingV2/components';
import Gantt from '@/pages/pi/gantt/react';
import { loadNodes } from '@/utils/utils';

import AddButtonForView from '../../viewsettingComponents/AddButtonForView';
import GroupForView from '../../viewsettingComponents/GroupForView';
import ProgressByPropForGantt from '../../viewsettingComponents/ProgressByPropForGantt';
import { ViewerButton } from '../ComponentViewer';

import universalStyles from '../styles.less';
import styles from './styles.less';

export const ModelGantt: FC<{
  data: IWidgetInstanceData & {
    widgetId: 'Gantt';
  };
  getterPiNode: GetterPiNode;
  isDark?: boolean;
  isDrafts: boolean;
  inViewer: boolean;
  readOnly?: boolean;
}> = (props) => {
  const { data, getterPiNode, isDark, isDrafts, inViewer, readOnly } = props;
  const sectionConfig = useSectionInstanceConfig();
  const backgroundColor = sectionConfig.config.bgColor || '#fff';
  const { config, id } = data;
  const currentUser = useCurrentUser();

  const [orgInfo] = useOrgInfo();
  const userMap = useOrgUserMap();
  const { groups } = useSelector((state: any) => state.space);
  const spaceUsers = Object.values(userMap);

  const [groupData, setGroupData] = useState<any>({});
  const [nodeList, setNodeList] = useState<any>([]);
  const [curViewData, setCurViewData] = useState<any>({});
  const ganttRef = useRef<any>(null);

  // 当前的分组 百分比显示； 记录用户操作过的值
  const nowGroupRef = useRef<any>(config.group);
  const nowShowProgressByPropRef = useRef<any>(config.showProgressByProp);
  const nowConditionV2Ref = useRef<any>(config.conditions);
  const targetTheme = nowConditionV2Ref.current.find((i: any) => i.key === 'templateId')?.input[0];

  const getCurViewData = () => {
    console.log(config);
    const { conditions: condition, parentId } = conditionV2ConvertCondition(
      nowConditionV2Ref.current,
    );
    return {
      view_info: {
        condition,
        conditionV2: nowConditionV2Ref.current,
        group: nowGroupRef.current,
        orderBy: '_sys_createTime',
        hideTool: false,
        parentId,
        showProgressByProp: nowShowProgressByPropRef.current,
        hideAdd: config.hideAdd,
      },
      view_type: 4, // table
    };
  };

  const init = async () => {
    const nodes = await loadNodes(getterPiNode.value, {
      ...config,
      conditions: nowConditionV2Ref.current,
      currentUserId: currentUser.userid,
    });

    setNodeList(nodes);
    setGroupData(
      groupingNodes(
        nodes,
        nowGroupRef.current,
        spaceUsers,
        groups,
        targetTheme,
        orgInfo.templateList,
      ),
    );
    setCurViewData(getCurViewData());
  };
  const changeGroup = (g: string) => {
    const newCurViewData = { ...curViewData };
    newCurViewData.view_info.group = g;
    nowGroupRef.current = g;
    setGroupData(groupingNodes(nodeList, g, spaceUsers, groups, targetTheme, orgInfo.templateList));
    setCurViewData(newCurViewData);
  };

  const changeProgressByProp = (p: string) => {
    const newCurViewData = { ...curViewData };
    newCurViewData.view_info.showProgressByProp = p;
    nowShowProgressByPropRef.current = p;
    setCurViewData(newCurViewData);
  };

  const changeCondition = (v: any) => {
    nowConditionV2Ref.current = v;
    init();
  };

  useEffect(() => {
    init();
    getterPiNode.value.nodeManager.listen('change', init);
    return () => {
      getterPiNode.value.nodeManager.unListen('change', init);
    };
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  let uniqueCanvasId = useMemo(() => 'talbe_' + id + nanoid(), []);

  if (isDrafts) uniqueCanvasId = 'drafts_' + uniqueCanvasId;
  if (inViewer) uniqueCanvasId = 'viewer_' + uniqueCanvasId;

  const [pointerEvents, onMouseDown] = useDisabledEvents(readOnly);

  if (isEmpty(curViewData) || !orgInfo) return null;

  return (
    <div
      className={cls(styles.container, isDark ? styles.dark : '')}
      onMouseDown={onMouseDown}
      style={{ pointerEvents }}
    >
      <div className={styles.header}>
        <div className={styles.componentName + ' text-omit'}>{config.name}</div>
        <div style={{ display: 'flex', alignItems: 'center' }} className="dividerListWrapper">
          <DividerList>
            {!isDrafts && (
              <ConditionFilterButton
                className={cls(universalStyles.forModelView, isDark && universalStyles.darkBtn)}
                orgId={orgInfo.orgId}
                value={nowConditionV2Ref.current}
                onChange={changeCondition}
                enableRoot
              />
            )}
            {!isDrafts && (
              <GroupForView
                template={orgInfo.templateList.find((t: any) => t.template_id === targetTheme)}
                curViewData={curViewData}
                orgInfo={orgInfo}
                templateList={orgInfo.templateList}
                onChange={changeGroup}
                isDark={isDark}
              />
            )}
            {!isDrafts && (
              <ProgressByPropForGantt
                template={orgInfo.templateList.find((t: any) => t.template_id === targetTheme)}
                curViewData={curViewData}
                onChange={changeProgressByProp}
                isDark={isDark}
              />
            )}
            <ViewerButton />
            <AddButtonForView
              template={orgInfo.templateList.find((t: any) => t.template_id === targetTheme)}
              curViewData={curViewData}
              getterPiNode={getterPiNode}
              templateList={orgInfo.templateList}
              userMap={userMap}
            />
          </DividerList>
        </div>
      </div>
      <div style={{ flex: 1, height: 0, position: 'relative' }}>
        <Gantt
          ref={ganttRef}
          uniqueCanvasId={uniqueCanvasId}
          node={getterPiNode}
          userMap={userMap}
          orgId={orgInfo.orgId}
          templateList={orgInfo.templateList}
          groupData={groupData}
          curViewData={curViewData}
          onDraftAddOk={() => {}}
          isDark={isDark}
          backgroundColor={backgroundColor}
        />
      </div>
    </div>
  );
};
