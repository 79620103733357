import { antdModalV5, create, show, useModal } from '@ebay/nice-modal-react';
import { assertExists } from '@linkpi/utils';
import { useImmerState } from '@shrugsy/use-immer-state';
import { useMemoizedFn } from 'ahooks';
import { Divider, Modal, Space, Switch, Typography } from 'antd';
import cls from 'classnames';
import { defaultTo } from 'ramda';
import type { FC } from 'react';
import { match, P } from 'ts-pattern';
import { useDispatch } from 'umi';

import { useCurrentUser, useOrgInfo } from '@/hook';
import { organizationSetting } from '@/services/space';

import { RegularIcon } from '../IconFont';

import styles from './styles.less';

const layouts = [
  {
    icon: <RegularIcon type="iconNav_Outline_Normal" size={90} />,
    icon_active: <RegularIcon type="iconNav_Outline_Selected" size={90} />,
    title: '大纲模式',
    desc: '大纲树样式，无限查看目录',
    value: 'outline',
  },
  {
    icon: <RegularIcon type="iconNav_Type2_Normal" size={90} />,
    icon_active: <RegularIcon type="iconNav_Type2_Selected" size={90} />,
    title: '经典模式（多列）',
    desc: '多层级菜单，最多可展开 4 级',
    value: 'multipleLevelMenu',
  },
] as const;

const _OrgSidebarModal: FC = () => {
  const modal = useModal();
  const [orgInfo] = useOrgInfo();
  const currentUser = useCurrentUser();
  const dispatch = useDispatch();
  assertExists(orgInfo);

  const defaultConfig = match(orgInfo.org_option?.navMode)
    .with({ layout: P.string, userLayout: P.nullish }, (v) => v)
    .with({ layout: P.string, userLayout: { [currentUser.userid]: P.string } }, (v) => ({
      ...v,
      userLayout: v.userLayout[currentUser.userid],
    }))
    .with(
      P.string,
      (v) =>
        ({
          layout: v,
          userLayout: null,
        }) as const,
    )
    .with(
      { [currentUser.userid]: P.select() },
      (v) =>
        ({
          layout: 'outline',
          userLayout: v,
        }) as const,
    )
    .otherwise(
      () =>
        ({
          layout: 'outline',
          userLayout: null,
        }) as const,
    );

  const [config, setConfig] = useImmerState(defaultConfig);

  const onOk = useMemoizedFn(async () => {
    try {
      const navMode = match(config)
        .with({ userLayout: P.nullish }, (v) => v)
        .otherwise((v) =>
          match(orgInfo.org_option?.navMode)
            .with({ userLayout: P.intersection(P.not(P.nullish), P.not(P.string)) }, (c) => ({
              ...v,
              userLayout: {
                ...c.userLayout,
                [currentUser.userid]: v.userLayout,
              },
            }))
            .otherwise(() => ({
              ...v,
              userLayout: { [currentUser.userid]: v.userLayout },
            })),
        );

      await organizationSetting({
        org_id: orgInfo.orgId,
        org_option: {
          ...defaultTo({}, orgInfo.org_option),
          navMode,
        },
      });
      await dispatch({
        type: 'space/organizationSetting',
        payload: {
          org_id: orgInfo.orgId,
        },
      });
      modal.hide();
    } catch (error) {
      console.warn(error);
    }
  });

  const onCanel = useMemoizedFn(() => {
    modal.reject();
    modal.hide();
  });

  return (
    <Modal
      {...antdModalV5(modal)}
      width={730}
      onCancel={onCanel}
      centered
      title={'导航布局设置'}
      okText="保存"
      onOk={onOk}
    >
      <Space className="full-w" direction="vertical" size={16}>
        <Typography.Text>空间设置</Typography.Text>
        <Space size={40}>
          {layouts.map((item) => (
            <div
              key={item.value}
              className={cls(styles.card, config.layout === item.value && styles.active)}
              onClick={() =>
                setConfig((c) => {
                  c.layout = item.value;
                })
              }
            >
              {config.layout === item.value ? item.icon_active : item.icon}
              <span className={styles.title}>{item.title}</span>
              <Typography.Text type="secondary">{item.desc}</Typography.Text>
            </div>
          ))}
        </Space>
        <Typography.Text>
          <Space>
            我的设置
            <Divider type="vertical" />
            <Space align="center">
              跟随空间布局{' '}
              <Switch
                checked={!config.userLayout}
                onChange={(e) =>
                  setConfig((c) => {
                    c.userLayout = !e ? 'outline' : null;
                  })
                }
              />
            </Space>
          </Space>
        </Typography.Text>
        {config.userLayout && (
          <Space size={40}>
            {layouts.map((item) => (
              <div
                key={item.value}
                className={cls(styles.card, config.userLayout === item.value && styles.active)}
                onClick={() =>
                  setConfig((c) => {
                    c.userLayout = item.value;
                  })
                }
              >
                {config.userLayout === item.value ? item.icon_active : item.icon}
                <span className={styles.title}>{item.title}</span>
                <Typography.Text type="secondary">{item.desc}</Typography.Text>
              </div>
            ))}
          </Space>
        )}
      </Space>
    </Modal>
  );
};

const OrgSidebarModal = create(_OrgSidebarModal);

export const showOrgSidebarModal = () => {
  return show(OrgSidebarModal);
};
