import {
  BugOutlined,
  CalendarOutlined,
  CloudOutlined,
  CodeOutlined,
  CoffeeOutlined,
  ExperimentOutlined,
  RestOutlined,
  SwapOutlined,
} from '@ant-design/icons';
import { Button, Dropdown, Space } from 'antd';
import { compose, map, toPairs, zipObj } from 'ramda';
import type { FC } from 'react';
import { cloneElement } from 'react';
import { history } from 'umi';

import { getBuildNumber } from '@/components/VersionInfo/utils';
import { useCurrentUser, useDev } from '@/hook';

export const ENV_MAP = {
  'mylinkpi.com': '正式',
  'lab.mylinkpi.com': '实验室',
  'test-inner.linkerpi.com': '日发',
  'master.test.mylinkpi.com': '测试',
  'test01.test.mylinkpi.com': '实验1',
  'test02.test.mylinkpi.com': '实验2',
  'test03.test.mylinkpi.com': '实验3',
  'test04.test.mylinkpi.com': '实验4',
  'test05.test.mylinkpi.com': '实验5',
  'pre-release.linkerpi.com': '预发',
  'cfg.mylinkpi.com': '配置',
  localhost: '开发',
} as const;

export const ENV_ICON_MAP = {
  'mylinkpi.com': <CloudOutlined />,
  'lab.mylinkpi.com': <RestOutlined />,
  'test-inner.linkerpi.com': <CalendarOutlined />,
  'master.test.mylinkpi.com': <BugOutlined />,
  'test01.test.mylinkpi.com': <ExperimentOutlined />,
  'test02.test.mylinkpi.com': <ExperimentOutlined />,
  'test03.test.mylinkpi.com': <ExperimentOutlined />,
  'test04.test.mylinkpi.com': <ExperimentOutlined />,
  'test05.test.mylinkpi.com': <ExperimentOutlined />,
  'pre-release.linkerpi.com': <CoffeeOutlined />,
  'cfg.mylinkpi.com': <CoffeeOutlined />,
  localhost: <CodeOutlined />,
};

/**
 * 将 ENV_MAP 映射成以 value 为键、label 为值的选项
 */
export const toSelectOptions = compose(
  map(zipObj(['value', 'label'] as const)),
  toPairs<typeof ENV_MAP>,
);

export const ENV_MAP_OPTIONS = toSelectOptions(ENV_MAP);

export function useEnvSelectVisible() {
  const { isDev } = useDev();

  if (isDev) {
    return true;
  }

  const [_, visible] = getBuildNumber();

  if (visible) {
    return true;
  }

  return false;
}

export const switchEnv = (hostname: string) => {
  const { pathname, search } = history.location;
  window.location.href = [
    hostname === 'localhost' ? 'http://' : 'https://',
    hostname,
    hostname === 'test-inner.linkerpi.com' ? ':8008' : '',
    hostname === 'localhost' ? ':8000' : '',
    pathname,
    search,
  ].join('');
};

const EnvMenu = {
  items: ENV_MAP_OPTIONS.map((o) => ({
    key: o.value,
    label: (
      <Space align="center">
        {cloneElement(ENV_ICON_MAP[o.value as keyof typeof ENV_ICON_MAP], {
          style: { color: '#98a2b6' },
        })}
        {o.label}
      </Space>
    ),
    onClick: () => {
      switchEnv(o.value);
    },
  })),
};

type EnvSelectProp = {
  type?: 'icon';
};

export const EnvSelect: FC<EnvSelectProp> = (props) => {
  if (props.type === 'icon')
    return (
      <Dropdown menu={EnvMenu}>
        <Button icon={<SwapOutlined />} type="text" style={{ color: '#98a2b6', borderRadius: 8 }}>
          {ENV_MAP[window.location.hostname as keyof typeof ENV_MAP]}环境
        </Button>
      </Dropdown>
    );

  return (
    <>
      <i className={'iconfont icontag'} />
      <Space>
        {ENV_MAP[window.location.hostname as keyof typeof ENV_MAP] + '环境'}
        <Dropdown menu={EnvMenu} placement="topRight">
          <Button icon={<SwapOutlined />} size="small" type="text" />
        </Dropdown>
      </Space>
    </>
  );
};
