import { useMemoizedFn, useSize } from 'ahooks';
import { Button } from 'antd';
import { memo, useCallback, useRef } from 'react';
import { useDrop } from 'react-dnd';
import isEqual from 'react-fast-compare';
import { FixedSizeList as List } from 'react-window';
import { useDispatch } from 'umi';

import DraggableCard from './DraggableCard';

import styles from './BoardList.less';

const type = 'DraggableCard';

const boardDarkBgList = [
  'linear-gradient(180deg, rgba(104, 144, 235, 0.1) 0%, rgba(104,144,235,0) 100%)',
  'linear-gradient(180deg, rgba(47, 192, 255, 0.1) 0%, rgba(47,192,255,0) 100%)',
  'linear-gradient(180deg, rgba(17, 197, 167, 0.1) 0%, rgba(17,197,167,0) 100%)',
  'linear-gradient(180deg, rgba(151, 255, 47, 0.1) 0%, rgba(135,46,255,0) 100%)',
  'linear-gradient(180deg, rgba(249, 208, 83, 0.1) 0%, rgba(249,208,83,0) 100%)',
  'linear-gradient(180deg, rgba(255, 160, 47, 0.1) 0%, rgba(255,160,47,0) 100%)',
];

function getDarkBgIndex(listIndex) {
  const sequence = [0, 1, 2, 3, 4, 5, 4, 3, 2, 1];
  const index = listIndex % sequence.length;
  const bgIndex = sequence[index];
  return boardDarkBgList[bgIndex];
}

const BoardList = (props) => {
  const {
    itemKey,
    itemData,
    itemName,
    itemCount,
    groupBy,
    spaceUsers,
    showProp,
    handleAddItem,
    handleSplitScreen,
    userMap,
    currentTemplate,
    filterTemplateList,
    tempMap,
    hideAdd,
    orderBy,
    orderDesc,
    isDark,
    boardIndex,
  } = props;

  const dispatch = useDispatch();

  const [{ isOver }, drop] = useDrop({
    accept: type,
    collect: (monitor) => {
      const { key } = monitor.getItem() || {};
      return {
        isOver: monitor.isOver() && key !== itemKey,
      };
    },
    drop: (item) => {
      return { groupKey: itemKey };
    },
  });
  const hasAdd =
    !hideAdd && !!filterTemplateList.find((i) => i.template_id === currentTemplate?.template_id);

  const Row = useMemoizedFn(({ index, style, data }) => {
    const onClick = () => {
      currentTemplate?.temp_option?.viewThemeMode === 'content'
        ? dispatch({
            type: 'workspace/setCurrentSelection',
            payload: {
              selectNode: itemData.list[index]?.id,
              viewRedirect: true,
            },
          })
        : handleSplitScreen(itemData.list[index]?.id, index, itemData.list);
    };
    return (
      <div key={itemData.list[index]?.id} style={style} className={styles.card}>
        <DraggableCard
          onClick={onClick}
          key={itemData.list[index]?.id}
          node={itemData.list[index]}
          userMap={userMap}
          template={currentTemplate}
          showProp={showProp}
          spaceUsers={spaceUsers}
          groupKey={data}
          groupBy={groupBy}
          tempMap={tempMap}
        />
      </div>
    );
  });

  const renderList = useCallback(
    ({ height }) => {
      return (
        <List
          orderBy={orderBy}
          orderDesc={orderDesc}
          height={height}
          itemCount={itemCount}
          itemSize={162}
          itemData={itemKey}
        >
          {Row}
        </List>
      );
    },
    [Row, itemCount, itemKey, orderBy, orderDesc],
  );

  const renderDarkListBg = () => {
    if (!isDark) return null;
    const bg = getDarkBgIndex(boardIndex);
    const addBtn = hasAdd ? 72 : 0;
    const cardMarginBottom = 12;
    const height = addBtn + itemCount * 162 - cardMarginBottom;
    return <div className="board-list-body-darkbg" style={{ background: bg, height }} />;
  };

  let backgroundColor;
  const { info } = itemData;
  if (info?.prop) {
    const { extendColorMode, extendColor, extend } = info.prop;
    if (extendColorMode) {
      backgroundColor = extendColor[extend.findIndex((e) => e === itemName)];
    }
  }

  return (
    <div ref={drop} className={`board-list`} key={itemKey}>
      <div className="board-list-head">
        <span
          className="group-title text-omit flex items-center"
          style={
            backgroundColor
              ? { backgroundColor, height: 28, borderRadius: 8, padding: '0 8px', color: 'white' }
              : undefined
          }
        >
          {itemName}
        </span>
        <span className="group-count">({itemCount})</span>
      </div>

      <div className={`board-list-body`}>
        {renderDarkListBg()}
        {isOver ? (
          <div className="board-list-mask">
            <div className="board-list-mask-tip">松开移入此分组</div>
          </div>
        ) : (
          <></>
        )}
        <div className={`board-add-card${hasAdd ? '' : ' noadd'}`}>
          {hasAdd && (
            <Button
              className="board-add-btn"
              icon={<i className="iconfont iconadd" />}
              onClick={() => handleAddItem(itemData, itemKey)}
            >
              新增
            </Button>
          )}
        </div>
        <div className={'board-card-list'}>
          <HeightWrapper>{renderList}</HeightWrapper>
        </div>
      </div>
    </div>
  );
};

const HeightWrapper = memo(({ children }) => {
  const wrapper = useRef();
  const size = useSize(wrapper);

  return (
    <div style={{ width: '100%', height: '100%', maxHeight: '100%' }} ref={wrapper}>
      {children({ width: size?.width || 0, height: size?.height || 0 })}
    </div>
  );
}, isEqual);

export default memo(BoardList, isEqual);
