import type { Location } from 'umi';

export function parseOrgIdFromLocation(location: Location) {
  let spaceId = location.query?.spaceId as string;

  if (!spaceId) {
    const pathRegex = new RegExp(/^\/?(\w+)\/(\w+)\/\w+/g);
    const result = pathRegex.exec(location.pathname);
    /**
     * 首页通过 path 获取空间 id
     * home/:orgId/:rootId/:nodeId
     */
    if (result?.[1] === 'home' && typeof result?.[2] === 'string') spaceId = result[2];
  }

  return spaceId;
}
