import { CheckCircleFilled } from '@ant-design/icons';
import { generateAddOpId, getDefaultTempProp } from '@linkpi/core';
import { getAddNodeDataWithViewOption } from '@linkpi/core/web';
import { useSize } from 'ahooks';
import { Button, message } from 'antd';
import { isEqual } from 'lodash';
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'umi';

import DraftsModal from '@/components/DraftNodeModal/DraftsModal';
import { useOrgUserMap } from '@/hook';
import useFilterTemplateList from '@/hook/useFilterTemplateList';
import { useOrgDepartmentNodes } from '@/hook/useOrgStructure';
import { ViewContent } from '@/pages/home/components/View/ViewContent/ViewContent';
import HocDndProvider from '@/utils/HocDndProvider';
import { displayGroupName } from '@/utils/utils';

import BoardList from './BoardList';

import './index.less';

const Board = (props) => {
  const { userMap, curViewDataInfo, groupData, showNodeSaved, groups, onDraftAddOk, isDark } =
    props;

  const dispatch = useDispatch();
  const { currentSelection } = useSelector((state) => state.workspace);
  const { spaceUsers } = useSelector((state) => state.task);
  const [contentVisible, setContentVisible] = useState(false);
  const [currentNodeIndex, setCurrentNodeIndex] = useState(0);
  const [currentDataList, setCurrentDataList] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [currentTemplate, setCurrentTemplate] = useState(null);
  const [scheduleShow, setScheduleShow] = useState(false);
  const [boardHeight, setBoardHeight] = useState(() => {
    return window.innerHeight - 238;
  });
  const [showProp, setShowProp] = useState([]);
  // 新增节点的数据
  const [draftsNodeData, setDraftsNodeData] = useState({});

  const boardContainerRef = useRef();
  const size = useSize(boardContainerRef);

  useEffect(() => {
    function handler() {
      const height = window.innerHeight;
      if (height !== boardHeight + 238) {
        setBoardHeight(height - 238);
      }
    }
    handler();
    window.addEventListener('resize', handler);
    return () => window.removeEventListener('resize', handler);
  }, []);

  const currentUser = useSelector(
    (state) => state.user.currentUser,
    (prev, next) => {
      return isEqual(prev, next);
    },
  );

  const orgInfo = useMemo(() => {
    return currentUser.organization.find((org) => org.orgId === currentSelection.selectSpace);
  }, [currentUser, currentSelection.selectSpace]);

  useEffect(() => {
    setTemplates(
      currentUser.organization.find((org) => org.orgId === currentSelection.selectSpace)
        ?.templateList || [],
    );
  }, [currentUser, currentSelection.selectSpace]);

  const tempMap = useMemo(() => {
    return templates.reduce((map, temp) => {
      map[temp.template_id] = temp;
      return map;
    }, {});
  }, [templates]);

  const filterTemplateList = useFilterTemplateList(orgInfo);

  useEffect(() => {
    const { condition } = curViewDataInfo;
    if (
      condition &&
      condition.length &&
      condition.find((x) => x.key === 'templateId') &&
      templates?.length
    ) {
      const template_id = condition.find((x) => x.key === 'templateId')['value'];
      const t = templates.find((x) => x.template_id === template_id);
      setCurrentTemplate(t || null);
      setShowProp(Array.isArray(t?.show_prop) && t.show_prop.length ? t.show_prop : []);
    } else {
      setCurrentTemplate(null);
      setShowProp([]);
    }
  }, [templates, curViewDataInfo]);

  const handleSplitScreen = useCallback((id, index, list) => {
    setCurrentNodeIndex(index);
    setCurrentDataList(list);
    setContentVisible(true);
  }, []);

  const orgUserMap = useOrgUserMap();
  const departmentNodes = useOrgDepartmentNodes();

  const handleAddItem = async (groupData, groupKey = '') => {
    if (currentTemplate) {
      const dataWithViewOption = getAddNodeDataWithViewOption(
        curViewDataInfo.condition,
        curViewDataInfo.group,
        groupData,
        currentTemplate,
        groupKey,
      );
      const viewInitData = {
        initTempId: currentTemplate.template_id,
      };
      if (dataWithViewOption.tempProp !== undefined)
        viewInitData.initProp = dataWithViewOption.tempProp;
      if (dataWithViewOption.status !== undefined)
        viewInitData.initStatus = dataWithViewOption.status;
      if (dataWithViewOption.statusProp !== undefined)
        viewInitData.initStatusProp = dataWithViewOption.statusProp;
      if (dataWithViewOption.sysTag !== undefined)
        viewInitData.initSysTag = dataWithViewOption.sysTag;
      if (dataWithViewOption.sysCascade !== undefined) {
        viewInitData.initSysCascade = dataWithViewOption.sysCascade;
      }

      const newProps = getDefaultTempProp(
        {},
        currentTemplate,
        currentUser,
        null,
        orgUserMap,
        departmentNodes,
        viewInitData,
      );
      const id = generateAddOpId();
      setDraftsNodeData({
        org_id: currentSelection.selectSpace,
        parentId: currentSelection.selectNode,
        siblingId: null,
        draft: true,
        node: {
          node_id: id,
          prop: newProps,
          title: '',
        },
      });
      setScheduleShow(true);
    }
  };

  const boards = useMemo(() => {
    return Object.keys(groupData)
      .sort((a, b) => groupData[a].sort - groupData[b].sort)
      .map((groupKey, index) => {
        const groupName = displayGroupName(
          groupData[groupKey],
          userMap,
          groups,
          orgInfo.templateList,
        );

        return (
          <BoardList
            key={groupKey}
            groupBy={curViewDataInfo.group}
            spaceUsers={spaceUsers}
            userMap={userMap}
            showProp={showProp}
            itemKey={groupKey}
            itemData={groupData[groupKey]}
            itemCount={groupData[groupKey].list.length}
            itemName={groupName}
            currentTemplate={currentTemplate}
            handleAddItem={handleAddItem}
            handleSplitScreen={handleSplitScreen}
            filterTemplateList={filterTemplateList}
            tempMap={tempMap}
            hideAdd={curViewDataInfo.hideAdd}
            orderBy={curViewDataInfo.orderBy}
            orderDesc={curViewDataInfo.orderDesc}
            isDark={isDark}
            boardIndex={index}
          />
        );
      });
  }, [groupData, showProp, size, groups]);

  return (
    <div id="Board" ref={boardContainerRef} className={isDark ? 'darkBoard' : ''}>
      {Object.keys(groupData).length ? (
        <HocDndProvider key={'board'}>{boards}</HocDndProvider>
      ) : (
        <div className={'board-default-page'}>
          <img width={200} src={'https://www.mylinkpi.com/webImg/defaultPic/board_none%402x.png'} />
          <div className={'default-words'}>没有符合筛选条件的数据</div>
          <Button
            type={'primary'}
            className={'add-btn'}
            onClick={() => {
              handleAddItem({});
            }}
          >
            新增
          </Button>
        </div>
      )}
      {scheduleShow ? (
        <DraftsModal
          visible={scheduleShow}
          mode="add"
          onCancel={() => setScheduleShow(false)}
          orgId={currentSelection.selectSpace}
          initParentId={currentSelection.selectNode}
          onSuccess={() => {
            onDraftAddOk();
            message.info({
              icon: <CheckCircleFilled style={{ color: '#52c41a' }} />,
              duration: 4,
              content: (
                <>
                  <span children="新增成功！" />
                  <span
                    children="跳转到该主题"
                    style={{ textDecoration: 'underline', cursor: 'pointer', color: '#316EF5' }}
                    onClick={() => {
                      dispatch({
                        type: 'workspace/setCurrentSelection',
                        payload: { selectNode: draftsNodeData.node.node_id },
                      });
                    }}
                  />
                </>
              ),
            });
          }}
          draftsNodeData={draftsNodeData}
        />
      ) : null}
      <ViewContent
        contentVisible={contentVisible}
        setContentVisible={() => setContentVisible(false)}
        setNodeIndex={(i) => setCurrentNodeIndex(i)}
        nodeIndex={currentNodeIndex}
        dataList={currentDataList}
        showNodeSaved={showNodeSaved}
      />
    </div>
  );
};

export default memo(Board);
