import { fetchUserList as _fetchUsers } from '@/services/space';

export const fetchUsers = _fetchUsers;

const TaskModel = {
  namespace: 'task',
  state: {
    spaceUsers: [],
  },
  effects: {
    *fetchUsersInSpace({ payload: { spaceId } }, { call, put }) {
      const response = yield call(fetchUsers, { org_id: spaceId, avatar: true });
      if (response?.status === 'ok') {
        yield put({
          type: 'parseUsersInSpace',
          payload: response.data,
        });
        yield put({
          type: 'space/querySpaceUserList',
          payload: Array.isArray(response.data) ? response.data : [],
        });
      }
      return response;
    },
  },

  reducers: {
    parseUsersInSpace(state, { payload }) {
      return { ...state, spaceUsers: payload || [] };
    },
  },
};
export default TaskModel;
