import { useMemoizedFn } from 'ahooks';
import { Space } from 'antd';
import type { FC } from 'react';
import { match } from 'ts-pattern';
import { useSelector } from 'umi';

export const StarButton: FC<{
  type?: 'icon' | 'default';
  handleCollect: (viewId: string, status: boolean) => void;
  viewId: string;
}> = ({ handleCollect, viewId, type = 'default' }) => {
  const collectViewList: any[] = useSelector((state: any) => state.workspace).collectViewList;

  const toggle = useMemoizedFn(() => {
    handleCollect(viewId, !collectViewList.find((x) => x.view_id === viewId));
  });

  return match(type)
    .with('icon', () => (
      <div className={'link-pi-btn btn-lg'} onClick={toggle}>
        <i
          className={`iconfont ${
            collectViewList.find((x) => x.view_id === viewId) ? 'iconquxiaoguanzhu1' : 'icondingyue'
          }`}
        />
      </div>
    ))
    .with('default', () => (
      <Space onClick={toggle} align="center">
        <i
          className={`iconfont ${
            collectViewList.find((x) => x.view_id === viewId) ? 'iconquxiaoguanzhu1' : 'icondingyue'
          }`}
        />
        收藏
      </Space>
    ))
    .exhaustive();
};
