import { PlusOutlined } from '@ant-design/icons';
import { InlineErrorFormItem } from '@ant-design/pro-utils';
import type { ConditionRuleConfigV2, CurrentUser } from '@linkpi/core';
import { filterChecker } from '@linkpi/core';
import { Button, Form, Select } from 'antd';
import type { DefaultOptionType } from 'antd/lib/select';
import { omit, values } from 'ramda';
import type { FC } from 'react';

import { ConditionItem, SelectGroup } from '@/components/EnhanceForm';
import { selectSearchFn } from '@/components/LinkPiForm/utils';
import type {
  IConditionSetting,
  IDisplaySetting,
} from '@/components/PageModelEditor/src/components';
import { getCurrentUserInfo, useOrgTemplatesSettingInfoMap } from '@/hook';
import type { PropRuleConditionModalProps } from '@/pages/space/propConditionRule/components/RuleConditionModal';
import { showConditionRuleModal } from '@/pages/space/propConditionRule/components/RuleConditionModal';
import RulePanel from '@/pages/space/propConditionRule/components/RuleConditionModal/RulePanel';

import styles from '@/pages/space/propConditionRule/components/PropRuleConditionModal/styles.less';

const FormItem = Form.Item;
const FormList = Form.List;

const createDefaultResultValue = () => ({
  visible: 'hidden',
});

export type SubTabsRuleConditionResultFormProp = {
  tableConfig: IConditionSetting & IDisplaySetting;
};

const SubTabsRuleConditionResultForm: FC<SubTabsRuleConditionResultFormProp> = ({
  tableConfig,
}) => {
  const formIns = Form.useFormInstance();
  const tempMap = useOrgTemplatesSettingInfoMap();

  const getPropOptions = (visible: boolean) => {
    const templateId: string = tableConfig.conditions.find((i) => i.key === 'templateId')?.input[0];
    const tempConfig = tempMap[templateId];

    const showOptions: DefaultOptionType[] = [];
    const hiddenOptions: DefaultOptionType[] = [];

    let propMap: Record<`prop_${number}`, CurrentUser.TemplateProp> = tempConfig.prop
      .map((p, propIndex) => ({ ...p, propIndex: `prop_${propIndex}` }))
      .filter((p) => p?.type)
      .reduce(
        (r, p) => ({
          ...r,
          [p.propIndex]: p,
        }),
        {},
      );

    tableConfig.displayAttrs?.forEach((attrs) => {
      propMap = omit([attrs.key], propMap);
      if (attrs.disable) {
        showOptions.push({
          label: attrs.name,
          value: attrs.key,
        });
      } else {
        hiddenOptions.push({
          label: attrs.name,
          value: attrs.key,
        });
      }
    });

    values(propMap).forEach((p) => {
      hiddenOptions.push({
        label: p.name,
        // @ts-ignore
        value: p.propIndex,
      });
    });

    return visible ? showOptions : hiddenOptions;
  };

  return (
    <FormList name="results" initialValue={[createDefaultResultValue()]}>
      {(fields, { add, remove }) => [
        ...fields.map((field) => (
          <RulePanel.Card
            color="#FFFAF2"
            key={field.key}
            showDelete={fields.length > 1}
            onDelete={() => remove(field.name)}
          >
            <FormItem noStyle name={[field.name, 'type']} initialValue={'subTabCreationVisible'}>
              <Select
                className="full-w"
                allowClear={false}
                options={[
                  // TODO: 标签页
                  // { value: 'subTabVisible', label: '显示/隐藏子表格' },
                  { value: 'subTabCreationVisible', label: '显示/隐藏子表格新增' },
                  { value: 'subTabPropsVisible', label: '显示/隐藏子表格列' },
                ]}
              />
            </FormItem>
            <ConditionItem
              enble={(form) =>
                form.getFieldValue(['results', field.name, 'type']) !== 'subTabPropsVisible'
              }
            >
              <InlineErrorFormItem
                {...field}
                name={[field.name, 'visible']}
                initialValue={'hidden'}
              >
                <Select
                  className="full-w"
                  placeholder="请选择显示/隐藏"
                  options={[
                    // { value: 'show', label: '显示' },
                    { value: 'hidden', label: '隐藏' },
                  ]}
                />
              </InlineErrorFormItem>
            </ConditionItem>
            <ConditionItem
              enble={(form) =>
                form.getFieldValue(['results', field.name, 'type']) === 'subTabPropsVisible'
              }
            >
              <SelectGroup>
                <InlineErrorFormItem
                  {...field}
                  name={[field.name, 'visible']}
                  initialValue={'hidden'}
                >
                  <Select
                    className="full-w"
                    placeholder="请选择显示/隐藏"
                    onChange={() => {
                      formIns.setFieldValue(['results', field.name, 'props'], undefined);
                    }}
                    options={[
                      { value: 'show', label: '显示' },
                      { value: 'hidden', label: '隐藏' },
                    ]}
                  />
                </InlineErrorFormItem>
                <FormItem dependencies={[['results', field.name, 'visible']]}>
                  {(form) => {
                    const visible = form.getFieldValue(['results', field.name, 'visible']);
                    const options = getPropOptions(visible === 'show');

                    return (
                      <InlineErrorFormItem
                        {...field}
                        name={[field.name, 'props']}
                        rules={[{ required: true, type: 'array', message: '请选择子表格列' }]}
                      >
                        <Select
                          mode="multiple"
                          className="full-w"
                          placeholder="请选择子表格列"
                          options={options}
                          filterOption={selectSearchFn}
                        />
                      </InlineErrorFormItem>
                    );
                  }}
                </FormItem>
              </SelectGroup>
            </ConditionItem>
          </RulePanel.Card>
        )),
        <div
          key="addResult"
          className={styles['add-result']}
          onClick={() => add(createDefaultResultValue())}
        >
          <Button icon={<PlusOutlined color="#316EF5" />} type="dashed">
            添加操作
          </Button>
        </div>,
      ]}
    </FormList>
  );
};

export const showSubTableConditionRuleModal = ({
  tableConfig,
  ...props
}: Omit<PropRuleConditionModalProps, 'resultForm'> & SubTabsRuleConditionResultFormProp) =>
  showConditionRuleModal<ConditionRuleConfigV2.TableConditionResults>({
    resultForm: <SubTabsRuleConditionResultForm tableConfig={tableConfig} />,
    ...props,
  });

export const checkSubTableConditionRule = (options: {
  ruleConfig: ConditionRuleConfigV2.ConditionRuleConfigV2<ConditionRuleConfigV2.TableConditionResults>;
  // 属性值
  nodeMetaData: PiNodeMetaData | {};
}): boolean => {
  const { ruleConfig, nodeMetaData } = options;

  const currentUserInfo = getCurrentUserInfo();
  if (!('e' in nodeMetaData)) return false;
  return filterChecker(ruleConfig.condition)(nodeMetaData, {
    roles: currentUserInfo?.group_ids,
  });
};
