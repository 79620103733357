import type { CurrentUser } from '@linkpi/core';
import type { OrgGroups } from '@linkpi/core';
import type { OrgUser } from '@linkpi/core';
import type { CustomButton as CButton } from '@linkpi/core';
import { generateAddOpId } from '@linkpi/core';
import { getDefaultTempProp } from '@linkpi/core';
import { GetterPiNode } from '@linkpi/core/web';
import { message } from 'antd';
import { intersection } from 'ramda';
import type { FC } from 'react';
import { useState } from 'react';

import ChooseExportPdfDirection from '@/components/ChooseExportPdfDirection';
import CustomButton from '@/components/CustomButton';
import { triggerCopyNodeButton } from '@/components/CustomButton/CopyNodeModal';
import { showUpdateNodesPropModal } from '@/components/CustomButton/UpdateNodesPropModal';
import { addDraftNode, DraftNodeModal } from '@/components/DraftNodeModal';
import ScanAddModal from '@/components/ScanAddModal';
import CustomButtonEditPropsModal from '@/pages/home/components/CustomButtonEditPropsModal';
import request from '@/utils/request';
import {
  exportPDF,
  genPDF,
  getDefaultExportPDFDirection,
  isCustomButtonForbiddenClick,
} from '@/utils/utils';

import Styles from './index.less';

type OrgUserItem = OrgUser.OrgUserItem;

export const ModelCustomButtons: FC<{
  data: CurrentUser.CustomButton[];
  node: PiNode;
  orgInfo: CurrentUser.OrgInfo;
  currentUser: CurrentUser;
  userMap: Record<string, OrgUserItem>;
  isDrafts: boolean;
  departmentNodes: OrgGroups.OrgDepartmentNodeInfo[];
  grid: CButton['grid'];
  readOnly?: boolean;
}> = (props) => {
  const {
    data,
    node,
    orgInfo,
    currentUser,
    userMap,
    isDrafts,
    departmentNodes,
    grid = 'left',
    readOnly,
  } = props;

  const [buttonLoadingMap, setButtonLoadingMap] = useState(
    data.reduce<Record<string, boolean>>((p, d) => ({ ...p, [d.id]: false }), {}),
  );
  const [chooseExportPdfDirectionModalOpen, setChooseExportPdfDirectionModalOpen] = useState(false);
  const [genPDFLoading, setGenPDFLoading] = useState(false);
  const [customButtonEditPropsModalOpen, setCustomButtonEditPropsModalOpen] = useState(false);
  const [buttonConfig, setButtonConfig] = useState<CurrentUser.CustomButton>();
  const [scanAddModalOpen, setScanAddModalOpen] = useState(false);
  const [scanAddModalProps, setScanAddModalProps] = useState<{
    scanAddParentId: string;
    scanAddTempId: string;
  }>();

  // 新建节点
  const handleDraftNode = async (
    currentTemplate: CurrentUser.TemplateInfo,
    viewInitData: Parameters<typeof getDefaultTempProp>['7'],
    parentId: string,
  ) => {
    const newProps = getDefaultTempProp(
      {},
      currentTemplate,
      currentUser,
      null,
      userMap,
      departmentNodes,
      viewInitData,
    );
    const id = generateAddOpId();

    await addDraftNode(
      {
        mode: 'add',
        orgId: orgInfo.orgId,
        draftsNodeData: {
          org_id: orgInfo.orgId,
          parentId,
          siblingId: null,
          draft: true,
          node: {
            node_id: id,
            prop: newProps as any,
            title: '',
          },
        },
        initParentId: parentId,
      },
      node.node_id,
    );
  };

  const customButtonClick = async (config: CurrentUser.CustomButton, forbiddenClick?: boolean) => {
    if (forbiddenClick || buttonLoadingMap[config.id]) return;

    setButtonConfig(config);

    const {
      opType,
      node: { initialStatus, parent, tempId } = {},
      automationTriggerSuccessMessage,
      automationTriggerFailMessage,
    } = config;

    if (opType === 'automationTrigger') {
      setButtonLoadingMap((prev) => ({ ...prev, [config.id]: true }));
      const res = await request('/docapi/runAutomation', {
        method: 'POST',
        data: {
          org_id: orgInfo.orgId,
          auto_id: config.automationId,
          node_id: node.id,
          debug: false,
          wait: true,
        },
      });

      setButtonLoadingMap((prev) => ({ ...prev, [config.id]: false }));
      if (res.status === 'ok')
        message.success(automationTriggerSuccessMessage || '触发自动化规则成功');
      else message.error(automationTriggerFailMessage || '执行自动化规则失败');
    } else if (opType === 'exportNodes') {
      setChooseExportPdfDirectionModalOpen(true);
    } else if (opType === 'editProp') {
      setCustomButtonEditPropsModalOpen(true);
    } else if (opType === 'updateProps') {
      showUpdateNodesPropModal({
        config,
        orgId: orgInfo.orgId,
        node,
      });
    } else if (opType === 'copyTemplate') {
      triggerCopyNodeButton({
        config,
        orgId: orgInfo.orgId,
        node,
      });
    } else if (opType === 'addNode') {
      const initTempId = tempId || node.tempInfo.id;
      const parentId = parent || node.id; // 父节点

      // 扫码新增
      if (config.addMode === 2) {
        setScanAddModalOpen(true);
        setScanAddModalProps({
          scanAddParentId: parentId,
          scanAddTempId: initTempId,
        });
        return;
      }

      const addInitData = {
        templateList: orgInfo.templateList,
        userMap,
        userId: currentUser.userid,
        orgId: orgInfo.orgId,
        parentId,
        initTempId,
        initStatus: initialStatus || 0,
      };
      const template = orgInfo.templateList.find((t) => t.template_id === initTempId);
      // 新建节点
      template && handleDraftNode(template, addInitData, parentId);
    } else {
      message.error('未知按钮');
    }
  };

  const chooseExportPdfDirectionOk = async (direction: string, sheetId: string) => {
    setGenPDFLoading(true);

    const html = await exportPDF(node, orgInfo.templateList, orgInfo, userMap, sheetId);

    // html 导出方向 文件名
    await genPDF(html, direction === 'horizontal', node.title || '无标题');

    setGenPDFLoading(false);
    setChooseExportPdfDirectionModalOpen(false);
  };

  return (
    <div className={Styles['modal-custom-button-wrapper']} style={{ justifyContent: grid }}>
      {data.map((config) => {
        if (!config) return null;
        if (config.hiddenWhenAdded && isDrafts) return null;

        const forbiddenClick = isCustomButtonForbiddenClick(config, node);
        if (forbiddenClick && config.hiddenByDisableCondition) return null;

        // 按钮分组判断
        if (config.groups && !config.groups?.includes('-1')) {
          if (config.groups?.length && intersection(orgInfo.groupList, config.groups).length === 0)
            return null;
        }

        return (
          <div
            key={config.id}
            className={Styles['custom-button-wrapper']}
            onClick={() => !readOnly && customButtonClick(config, forbiddenClick)}
          >
            <CustomButton
              loading={buttonLoadingMap[config.id]}
              config={config}
              forbiddenClick={forbiddenClick}
            />
          </div>
        );
      })}

      {chooseExportPdfDirectionModalOpen && (
        <ChooseExportPdfDirection
          closeModal={() => setChooseExportPdfDirectionModalOpen(false)}
          loading={genPDFLoading}
          ok={chooseExportPdfDirectionOk}
          defaultDirection={getDefaultExportPDFDirection(node, orgInfo.templateList)}
          node={node}
          templateList={orgInfo.templateList}
        />
      )}

      {customButtonEditPropsModalOpen && (
        <CustomButtonEditPropsModal
          editProps={buttonConfig!.props}
          title={buttonConfig!.text}
          closeModal={() => setCustomButtonEditPropsModalOpen(false)}
          node={new GetterPiNode(node)}
          currentUser={currentUser}
        />
      )}

      {scanAddModalProps && (
        <ScanAddModal
          currentNodeTempId={node.metadata?.e?._sys_temp?.[0]}
          open={scanAddModalOpen}
          orgId={orgInfo.orgId}
          initParentId={scanAddModalProps.scanAddParentId}
          initTempId={scanAddModalProps.scanAddTempId}
          buttonId={buttonConfig!.id}
          onCancel={() => setScanAddModalOpen(false)}
        />
      )}
      {/* @ts-ignore */}
      <DraftNodeModal id={node.node_id} />
    </div>
  );
};
