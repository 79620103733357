import { useSelector } from 'umi';

/**
 * 获取全局的搜索关键字
 */
export const useGlobalSearchKeyWords = () => {
  const { searchKeyWords } = useSelector(({ workspace }: any) => ({
    searchKeyWords: workspace.searchKeyWords,
  }));

  return searchKeyWords as string[];
};
