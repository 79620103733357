import { LoadingOutlined } from '@ant-design/icons';
import { antdModalV5, create as ModalCreate, show, useModal } from '@ebay/nice-modal-react';
import type { ApiResponse } from '@linkpi/core';
import { DEFAULT_STATUS_PROP, STATUS_ICON } from '@linkpi/core';
import { tempValueDisplay } from '@linkpi/core';
import { useWindowSize } from '@react-hookz/web';
import { useMemoizedFn, useRequest } from 'ahooks';
import { Checkbox, Col, Input, Modal, notification, Row, Space, Spin, Typography } from 'antd';
import cls from 'classnames';
import { filter, isNil, pipe, sortBy } from 'ramda';
import type { FC } from 'react';
import { useMemo, useRef, useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import type { ListRowRenderer } from 'react-virtualized';
import { AutoSizer, List } from 'react-virtualized';
import { useSelector } from 'umi';

import { useOrgInfo } from '@/hook';
import { useOrgDepartmentNodesMap } from '@/hook/useOrgStructure';
import { propDisplay } from '@/pages/home/components/TempStatus';
import { batchUpdateProps } from '@/services/node';
import { getAttachmentValue, toRecord } from '@/utils/utils';

import { ConditionFilterButton } from '../ConditionFilter';
import { filterChecker } from './utils';

import '@/pages/home/components/QuoteSelector/index.less';
import styles from './UpdateNodesPropModal.less';

const { Text } = Typography;
const defaultWidth = 160;

const allowKey = ['prop', 'templateId', 'status'];
const allowPropTypes: ApiResponse.CurrentUser.propType[] = [
  'enum',
  'tag',
  'text',
  'number',
  'formula',
];

const parsePropIndex = (s: string) => {
  return Number(s.replace('p', ''));
};

const toNodeRecord = toRecord((node: PiNode) => ({
  [node.id]: node,
}));

async function getFilterNode(
  node: PiNode,
  config: ApiResponse.CurrentUser.CustomButtonUpdatePropsConfig,
) {
  const { template, matchCondition, filterCondition } = config;

  // 指定被匹配数据的属性
  const groupBy = matchCondition.map((item) => item.target);
  // 筛选条件
  const tempProps = filterCondition.map((item) =>
    item.value.map((v) => {
      if (item.prop === 'status') return `si-${v}`;
      else return [item.prop, v].join('-');
    }),
  );

  const filters = {
    groupBy,
    tempProps,
    temp_id: template,
    draft: node.metadata.f === -2,
  };
  return node.nodeManager.getTempFilterNode(filters);
}

interface TableHeader {
  name: string;
  width: number;
  type: string;
  index: number;
  sort: number;
  sticky?: 'left' | 'right';
  key: string;
}

type UpdateNodesPropModalProps = {
  config: ApiResponse.CurrentUser.CustomButton;
  nodeId?: string;
  orgId: string;
  node: PiNode;
};
const UpdateNodesProp: FC<UpdateNodesPropModalProps> = ({ orgId, config, node }) => {
  const modal = useModal();

  const headerScrollRef = useRef<any>(null);
  const bodyScrollRef = useRef<any>(null);
  const { height: windowHeight } = useWindowSize();
  const bodyHeight = windowHeight - 328;

  const [search, setSearch] = useState('');

  const { data: allNodeList = [] } = useRequest(async () => {
    const filterNode = await getFilterNode(node, config.updatePropsConfig!);

    // 根据当前节点，匹配数据
    const groupBy = config.updatePropsConfig!.matchCondition.map((item) => item.source);
    const nodes = filterNode.getMatchNode(node.id, groupBy, [], false);

    return sortBy((i: PiNode) => i.createTime * -1)(nodes);
  });

  const [filterValue, setFilterValue] = useState<ApiResponse.ViewList.ViewconditionV2>([
    {
      op: 'beIncluded',
      key: 'templateId',
      input: [config.updatePropsConfig?.template],
    },
  ]);

  const nodeList = useMemo(() => {
    if (filterValue.length > 1)
      return pipe(filter(filterChecker(filterValue)), toNodeRecord)(allNodeList);
    return toNodeRecord(allNodeList);
  }, [allNodeList, filterValue]);

  const { loading, run: updateNodes } = useRequest(
    async (args: Parameters<typeof batchUpdateProps>[0]) => {
      const requestKey = ['updateProps', args.orgId, args.tempId].join('@');
      notification.open({
        icon: <Spin indicator={<LoadingOutlined />} />,
        message: '正在更新属性',
        key: requestKey,
        duration: 0,
      });
      try {
        await batchUpdateProps(args);
        notification.success({
          message: '更新完成',
          key: requestKey,
          duration: 3,
        });
        modal.hide();
      } catch (error) {
        notification.error({
          message: '更新失败',
          key: requestKey,
          duration: 3,
        });
      }
    },
    {
      manual: true,
    },
  );

  const onOk = useMemoizedFn(() => {
    const sourceNodeValues: unknown[] = [];
    const targetPropIndex: number[] = [];
    config.updatePropsConfig!.updateRule.forEach((r) => {
      targetPropIndex.push(parsePropIndex(r.target));
      const sourcePropIndex = parsePropIndex(r.source);
      sourceNodeValues.push(node.tempInfo.prop[sourcePropIndex]);
    });

    return updateNodes({
      orgId,
      tempId: config.updatePropsConfig!.template,
      nodes: propValue.map((nodeId) => ({
        id: nodeId,
        propIndexes: targetPropIndex,
        values: sourceNodeValues,
      })),
    });
  });

  const [propValue, setPropValue] = useState<string[]>([]);

  const clickRow = useMemoizedFn((o: string) => {
    const index = propValue.indexOf(o);
    const newValue = [...propValue];
    if (~index) {
      newValue.splice(index, 1);
    } else {
      newValue.push(o);
    }
    setPropValue(newValue);
  });

  const selectAll = useMemoizedFn((e: boolean) => {
    if (!search) {
      setPropValue(e ? Object.keys(nodeList) : []);
    } else {
      const searchKeys = Object.keys(nodeList).filter(
        (o) => ~nodeList[o].title.toLowerCase().indexOf(search.toLowerCase()),
      );
      if (searchKeys.length) {
        setPropValue(
          e
            ? [...propValue, ...searchKeys.filter((s) => !propValue.includes(s))]
            : [...propValue.filter((x) => !searchKeys.includes(x))],
        );
      }
    }
  });

  const { spaceUserList } = useSelector((state: any) => state.workspace);
  const [orgInfo] = useOrgInfo(orgId);

  const tempMap = useMemo(() => {
    if (isNil(orgInfo)) return {};
    const templateList = orgInfo.templateList;
    return templateList.reduce(
      (acc, cur) => {
        acc[cur.template_id] = cur;
        return acc;
      },
      {} as Record<string, ApiResponse.CurrentUser.TemplateInfo>,
    );
  }, [orgInfo]);
  const departmentMap = useOrgDepartmentNodesMap();

  const matchingTemplate = useMemo(() => {
    return tempMap[config.updatePropsConfig!.template];
  }, [config.updatePropsConfig, tempMap]);

  const modalContent = useMemo(() => {
    const defaultHeaders: TableHeader[] = [
      {
        name: '状态',
        width: 120,
        type: 'status',
        index: -2,
        sort: -2,
        key: 'status',
      },
      {
        name: '标题',
        width: 200,
        type: 'text',
        index: -1,
        sort: -1,
        sticky: 'left',
        key: 'title',
      },
    ];
    if (Array.isArray(matchingTemplate?.prop)) {
      matchingTemplate.prop.forEach((p, i) => {
        if (p?.type && !p.hide) {
          defaultHeaders.push({
            name: p.name,
            width: p.type === 'text' ? 200 : defaultWidth,
            type: p.type,
            index: i,
            sort: 'sort' in p ? p.sort : i,
            key: `prop-${i}`,
          });
        }
      });
    }
    defaultHeaders.sort((b, a) => b.sort - a.sort);
    defaultHeaders.push.apply(
      defaultHeaders,
      DEFAULT_STATUS_PROP.map((s, i) => ({
        name: s.name,
        width: defaultWidth,
        type: s.type,
        index: i - 7,
        sort: i - 7,
        key: 'status-' + i,
      })),
    );

    const headers = config.updatePropsConfig?.visibleProps ? [] : defaultHeaders;

    if (config.updatePropsConfig?.visibleProps?.length) {
      config.updatePropsConfig?.visibleProps.forEach((e) => {
        const header = defaultHeaders.find((h) => h.key === e.key);
        if (header && e.checked) {
          headers.push(header);
        }
      });
    }

    let tableWidth = 32;
    headers.forEach((h) => {
      tableWidth += h.width;
    });
    const searchKeys = Object.keys(nodeList).filter(
      (o) => ~nodeList[o].title.toLowerCase().indexOf(search.toLowerCase()),
    );

    const rowRenderer: ListRowRenderer = ({ index: i, key, style }) => {
      const o = searchKeys[i];
      const n = nodeList[o];

      return (
        <div key={key} style={style}>
          <div
            onClick={() => clickRow(o)}
            className={cls(
              `quote-option-body-column`,
              propValue.includes(o) && 'active',
              styles.column,
            )}
          >
            <Checkbox
              checked={propValue.includes(o)}
              style={{ marginLeft: 6, marginTop: n ? -2 : -4 }}
            />
            <div className={'quote-option-body-column-item'}>
              {n ? (
                <div className={'quote-option-body-column-item-main'}>
                  {headers.map((h) => {
                    if (h.index < 0) {
                      if (h.index === -1) {
                        return (
                          <div
                            className={'quote-option-body-column-item-main-cell text-omit'}
                            style={{ width: h.width }}
                            key={h.key}
                          >
                            {n.title || '无标题'}
                          </div>
                        );
                      } else {
                        const curStatus = matchingTemplate?.task_status[n.tempInfo.status];
                        if (h.index === -2) {
                          // 状态
                          return curStatus && !curStatus.delete ? (
                            <div
                              className={`quote-option-body-column-item-main-cell text-omit status`}
                              style={{ width: h.width }}
                              key={h.key}
                            >
                              <i
                                className={`iconfont ${curStatus.icon}`}
                                // @ts-ignore
                                style={{ color: STATUS_ICON[curStatus.icon]?.color }}
                              />
                              {curStatus.name}
                            </div>
                          ) : (
                            <div
                              className={'quote-option-body-column-item-main-cell'}
                              style={{ width: h.width }}
                              key={h.key}
                            />
                          );
                        } else {
                          const si = h.index + 7;
                          return (
                            <div
                              className={`quote-option-body-column-item-main-cell text-omit`}
                              style={{ width: h.width }}
                              key={h.key}
                            >
                              {curStatus && curStatus.prop?.[si]?.display
                                ? propDisplay(
                                    curStatus.prop[si],
                                    n.tempInfo.statusProp[si],
                                    spaceUserList[orgId],
                                    tempMap,
                                    n.prop._sys_cascade,
                                    h.index,
                                    n,
                                    departmentMap,
                                  )
                                : null}
                            </div>
                          );
                        }
                      }
                    } else {
                      let res: any = {};
                      if (matchingTemplate?.prop[h.index].type === 'quote') {
                        res = propDisplay(
                          matchingTemplate?.prop[h.index],
                          n.tempInfo.prop[h.index],
                          spaceUserList[orgId],
                          tempMap,
                          n.prop._sys_cascade,
                          h.index,
                          n,
                          departmentMap,
                        );
                      } else if (matchingTemplate?.prop[h.index].type === 'attachment') {
                        res.value = getAttachmentValue(n, h.index);
                        res.type = 'quote-attachment';
                      } else {
                        res = tempValueDisplay({
                          propConfig: matchingTemplate?.prop[h.index],
                          propValue: n.tempInfo.prop[h.index],
                          propIndex: h.index,
                          sysCascade: n.prop._sys_cascade,
                          userMap: spaceUserList[orgId],
                          tempMap,
                          departmentMap,
                        });
                      }

                      return (
                        <div
                          className={`quote-option-body-column-item-main-cell text-omit`}
                          style={{ width: h.width }}
                          key={h.key}
                        >
                          {res?.type === 'quote-attachment' ? (
                            <Space>
                              {res.value.map((f: any) => (
                                <div
                                  key={f.src}
                                  style={{
                                    backgroundImage: `url(${f.src})`,
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center',
                                    height: 32,
                                    width: 32,
                                    marginRight: 6,
                                  }}
                                />
                              ))}
                            </Space>
                          ) : (
                            res
                          )}
                        </div>
                      );
                    }
                  })}
                </div>
              ) : null}
            </div>
          </div>
        </div>
      );
    };

    return (
      <div id={'QuoteSelector'}>
        <div className={'quote-option'}>
          <Row gutter={8} align="middle">
            <Col flex="auto">
              <Input
                prefix={
                  <i
                    className={'iconfont iconsearch'}
                    style={{ color: '#EBEDF0', width: 'auto' }}
                  />
                }
                placeholder={'请输入关键字搜索'}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </Col>
            <Col flex="none">
              <ConditionFilterButton
                orgId={orgId}
                value={filterValue}
                onChange={setFilterValue}
                allowKey={allowKey}
                allowPropTypes={allowPropTypes}
                displayParentId={false}
                displayTemplate={false}
              />
            </Col>
          </Row>
          <PerfectScrollbar
            ref={headerScrollRef}
            onScrollX={(e) => (bodyScrollRef.current._container.scrollLeft = e.scrollLeft)}
          >
            <div className={'quote-option-header'} style={{ width: tableWidth }}>
              <Checkbox
                checked={
                  !!searchKeys.length && !~searchKeys.findIndex((o) => !propValue.includes(o))
                }
                disabled={!searchKeys.length}
                style={{ marginLeft: 6 }}
                onChange={(e) => selectAll(e.target.checked)}
              />
              {headers.map((h, i) => (
                <div
                  key={i}
                  className={`quote-option-header-cell text-omit`}
                  style={{
                    width: h.width,
                  }}
                >
                  <span>{h.name}</span>
                </div>
              ))}
            </div>
          </PerfectScrollbar>
          <PerfectScrollbar
            ref={bodyScrollRef}
            onScrollX={(e) => (headerScrollRef.current._container.scrollLeft = e.scrollLeft)}
            style={{ height: bodyHeight }}
          >
            <div className={'quote-option-body'} style={{ width: tableWidth, height: bodyHeight }}>
              <AutoSizer disableHeight>
                {({ width }) => (
                  <List
                    height={bodyHeight}
                    rowCount={searchKeys.length}
                    rowHeight={58}
                    overscanRowCount={10}
                    rowRenderer={rowRenderer}
                    width={width}
                  />
                )}
              </AutoSizer>
            </div>
          </PerfectScrollbar>
        </div>
      </div>
    );
  }, [
    matchingTemplate.prop,
    matchingTemplate?.task_status,
    nodeList,
    search,
    orgId,
    filterValue,
    propValue,
    clickRow,
    spaceUserList,
    tempMap,
    departmentMap,
    selectAll,
    bodyHeight,
  ]);

  return (
    <Modal
      {...antdModalV5(modal)}
      title={
        <Space>
          选择主题
          <Text type="secondary">{`共 ${Object.keys(nodeList)?.length} 条记录/已选 ${
            propValue.length
          } 条`}</Text>
        </Space>
      }
      centered
      width={1024}
      confirmLoading={loading}
      onOk={onOk}
      okButtonProps={{ disabled: !propValue.length }}
    >
      {modalContent}
    </Modal>
  );
};

const UpdateNodesPropModal = ModalCreate(UpdateNodesProp);

export const showUpdateNodesPropModal = (props: UpdateNodesPropModalProps) =>
  show(UpdateNodesPropModal, props);
