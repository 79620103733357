import { tempValueDisplay } from '@linkpi/core';
import { Modal } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';

import { useOrgTempMap, useOrgUserMap } from '@/hook';
import { useOrgDepartmentNodesMap } from '@/hook/useOrgStructure';
import request from '@/utils/request';
interface DataType {
  q: string; //模板ID
  t: string; //操作类型
  ov: any; //历史值
  v: any; //修改值
  time: number; //操作时间
  ad: string[]; //操作人ID
  auto: string; //自动化ID
  i: number; // propIndex
  auto_mark: string;
  n?: string;
}
interface IProp {
  closeModal: () => void;
  record: DataType;
  orgId: string;
  orgConnection: any;
}
export default (prop: IProp) => {
  const [res, setRes] = useState<{ trigger: DataType; actions: DataType[] } | null>(null);
  const tempMap = useOrgTempMap(prop.orgId);
  const departmentMap = useOrgDepartmentNodesMap();
  const userMap = useOrgUserMap();

  const init = async () => {
    const v = await request('/docapi/getAutoModifyLog', {
      method: 'POST',
      data: {
        org_id: prop.orgId,
        auto_id: prop.record.auto,
        auto_mark: prop.record.auto_mark,
      },
    });
    if (v.status === 'ok') {
      setRes(v.data as any);
    }
  };
  useEffect(() => {
    init();
  }, []);
  const getPropValue = (propConfig: any, propValue: any) => {
    return tempValueDisplay({
      propConfig,
      propValue,
      userMap,
      tempMap,
      departmentMap,
    });
  };

  const columns: any = [
    {
      title: '执行主题',
      key: 'title',
      render: (record: DataType) => {
        const n: PiNode = prop.orgConnection.nodeManager.findChildren(record.n);
        return (
          <div
            onClick={() => goNodeContent(n)}
            style={{ color: '#316EF5', cursor: 'pointer', textDecoration: 'underline' }}
          >
            {n.title}
          </div>
        );
      },
      width: '20%',
    },
    {
      title: '执行内容',
      key: 'detail',
      render: (record: DataType) => {
        const { t, q, ov, v, i } = record;
        let actionName = '/';
        if (t === 'SetStatusIndex') actionName = '变更状态';
        if (t === 'InsertNode') actionName = '创建主题';
        if (t === 'SetTempProp') actionName = '变更属性值';
        if (t === 'SetTitle') actionName = '变更标题';
        if (t === 'MoveToRecycle') actionName = '删除主题';
        if (t === 'SetSysProp') actionName = '变更属性的可见权限'; //变更属性的编辑权限
        if (t === 'SetAcl') actionName = '变更主题权限';
        if (['MoveOut', 'MoveIn'].includes(t)) actionName = '移动路径';

        let newValue = '';
        // v ov
        if (record.t === 'SetStatusIndex') {
          const s = (tempMap[q].task_status || [])[v] || {};
          newValue = s.name;
        } else if (record.t === 'SetTitle') {
          newValue = v;
        } else if (record.t === 'SetTempProp') {
          const p = (tempMap[q].prop || [])[i];
          newValue = `${getPropValue(p, ov)} -> ${getPropValue(p, v)}`;
        } else {
          newValue = v;
        }

        return (
          <div style={{ display: 'flex', alignItems: 'center', color: '#242E41' }}>
            <div>{actionName}</div>
            <div style={{ margin: '0 6px', width: 2, height: 14, backgroundColor: '#767C88' }} />
            <div>{newValue}</div>
          </div>
        );
      },
      width: '60%',
    },
    {
      title: '执行时间',
      key: 'time',
      render: (record: DataType) => {
        const t = record.time;
        const v = t ? moment(t).format('YYYY/MM/DD HH:mm') : '';
        return <span style={{ color: '#242E41' }}>{v}</span>;
      },
      width: '20%',
    },
  ];
  const goAutomationConfig = () => {
    const payload: any = {
      functionTab: 'automation',
      spaceId: prop.orgId,
      tab: 'template',
      templateId: prop.record.q,
      templateKey: 'automation',
      automationId: prop.record.auto,
    };

    const u = Object.keys(payload)
      .map((k) => `${k}=${payload[k]}`)
      .join('&');

    window.open(`${window.location.origin}/space?${u}`);
  };

  const renderRunLog = () => {};
  const goNodeContent = (n: PiNode) => {
    window.open(`${window.location.origin}/home/${prop.orgId}/${n.id}/${n.id}`, '', 'noopener');
  };

  const renderTriggerLog = () => {
    if (!res) return res;
    const { trigger } = res;
    const n: PiNode = prop.orgConnection.nodeManager.findChildren(trigger.n);

    return (
      <div
        style={{ cursor: 'pointer', textDecoration: 'underline', color: '#316EF5' }}
        onClick={() => goNodeContent(n)}
      >
        {n.title}
      </div>
    );
  };
  return (
    <Modal
      open
      title="自动化日志"
      onCancel={prop.closeModal}
      footer={null}
      bodyStyle={{ padding: 0 }}
      width={1024}
    >
      <div style={{ padding: '8px 20px' }}>
        <div style={{ marginBottom: 9, color: '#767C88' }}>日志ID</div>
        <div
          onClick={goAutomationConfig}
          style={{
            cursor: 'pointer',
            color: '#316EF5',
            textDecoration: 'underline',
            marginBottom: 32,
          }}
        >
          这是自动化规则名称，点击可跳转至自动化配置页面
        </div>
        <div style={{ marginBottom: 9, color: '#767C88' }}>触发日志</div>
        <div
          style={{
            display: res ? 'flex' : 'none',
            alignItems: 'center',
            color: '#242E41',
            marginBottom: 32,
          }}
        >
          {renderTriggerLog()}
        </div>
        <div style={{ marginBottom: 9, color: '#767C88' }}>执行日志</div>
        <div style={{ paddingTop: 20 }}>
          <div
            style={{
              height: 40,
              borderRadius: 8,
              color: '#767C88',
              backgroundColor: '#F9FAFC',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {columns.map((i: any) => (
              <div style={{ width: i.width, paddingLeft: 12 }} key={i.key}>
                {i.title}
              </div>
            ))}
          </div>
          {(res?.actions || []).map((i) => (
            <div
              key={i.time}
              style={{
                backgroundColor: '#F9FAFC',
                borderRadius: 8,
                padding: '10px 0',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {columns.map((j: any) => (
                <div
                  style={{
                    width: j.width,
                    display: 'flex',
                    alignItems: 'center',
                    paddingLeft: 12,
                  }}
                  key={j.key}
                >
                  {j.render(i)}
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </Modal>
  );
};
