import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import type { ApiResponse } from '@linkpi/core';
import {
  generateAddOpId,
  getDefaultTempProp,
  getQuoteOriginProp,
  STATUS_ICON,
  tempValueDisplay,
} from '@linkpi/core';
import type { PiConnection } from '@linkpi/core/web';
import type { FC } from 'react';
import { useEffect, useMemo, useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useSelector } from 'umi';

import DraftsModal from '@/components/DraftNodeModal/DraftsModal';
import LinkPiModal from '@/components/LinkPiModal';
import { useOrgTempMap, useOrgUserMap } from '@/hook';
import { useOrgDepartmentNodes, useOrgDepartmentNodesMap } from '@/hook/useOrgStructure';
import { propDisplay } from '@/pages/home/components/TempStatus';
import { getSorterByPropType } from '@/pages/pi/grid/react/useGridAttrs';
import orderBy from '@/utils/orderBy';
import request from '@/utils/request';
import { getAttachmentValue, isQuotePropCanCount } from '@/utils/utils';

import './index.less';
interface propsType {
  visible: boolean;
  onCancel: () => void;
  node: any;
  propIndex: number;
  orgConnection: PiConnection;
  orgId: string;
  toNode?: (id: string) => void;
  addQuoteNodeCallback?: any;
  originNodes: any[] | null;
}
interface tableHeader {
  name: string;
  width: number;
  type: string;
  index: number;
  sort: number;
  sortIndex: string;
  sticky?: 'left' | 'right';
  key: string;
  sorter: any;
}

const QuoteNodes: FC<propsType> = (props) => {
  const {
    visible,
    onCancel,
    node,
    propIndex,
    orgConnection,
    orgId,
    toNode,
    addQuoteNodeCallback,
    originNodes = null,
  } = props;
  const { spaceUserList } = useSelector((state: any) => state.workspace);
  const currentUser: ApiResponse.CurrentUser = useSelector(
    (rootState: any) => rootState.user.currentUser,
  );
  const tempMap = useOrgTempMap();

  const departmentMap = useOrgDepartmentNodesMap();
  const [showDraftsModal, setShowDraftsModal] = useState(false);
  const [parentId, setParentId] = useState('');
  const [draftsNodeData, setDraftsNodeData] = useState({});
  const [flag, setFlag] = useState(0);
  const [showShadow, setShowShadow] = useState(false);
  const [sortInfo, setSortInfo] = useState<any>({});
  const [columns, setColumns] = useState<any[]>([]);
  const [quoteNodes, setQuoteNodes] = useState<any[]>([]);
  const [notSortNodes, setNotSortNodes] = useState<any[]>([]);

  const currentTemplate = tempMap[node?.prop?._sys_temp[0]] || null;

  // 从接口获取节点
  useEffect(() => {
    if (visible) {
      if (originNodes) {
        setNotSortNodes(originNodes);
        setQuoteNodes(originNodes);
        return;
      }
      fetchNodes();
    }
  }, [visible]);

  const fetchNodes = async () => {
    const res = await request('/docapi/execCalcFormula', {
      method: 'POST',
      data: {
        org_id: orgId,
        node_id: node.id,
        index: propIndex,
        getNodeId: true,
      },
    });
    if (res.status === 'ok') {
      const nodes = res.data
        .map((id: any) => {
          const nodeId =
            typeof id === 'string' ? id : 'v' in id && typeof id.v === 'string' ? id.v : '';
          return nodeId ? orgConnection.nodeManager.findChildren(nodeId) : null;
        })
        .filter((n: any) => !!n);
      setNotSortNodes(nodes);
      setQuoteNodes(nodes);
    }
  };

  useEffect(() => {
    if (notSortNodes.length) setQuoteNodes(getSortDataByOrder(notSortNodes, sortInfo, columns));
  }, [flag, sortInfo]);

  const matchingTemplate = useMemo(() => {
    if (currentTemplate?.prop?.[propIndex]?.matchingTemplate) {
      return tempMap[currentTemplate?.prop?.[propIndex]?.matchingTemplate] || null;
    }
    return null;
  }, [currentTemplate, propIndex, tempMap]);

  const getSortDataByOrder = (nodeList: any[], sortInfo: any, columns: any[]) => {
    const sortColumnsKey = columns.map((x: any) => x.key);
    const sorters = Object.keys(sortInfo)
      .sort((a, b) => {
        return sortColumnsKey.indexOf(a) - sortColumnsKey.indexOf(b);
      })
      .map((x) => {
        const column = columns.find((y) => y.key === x);
        return column && column.sorter && column.sorter[sortInfo[x].sort]
          ? column.sorter[sortInfo[x].sort]
          : () => {};
      });

    return orderBy(
      nodeList,
      sorters.map((x) => (y: any) => y),
      sorters,
    );
  };

  const handleScroll = (e: any) => {
    setShowShadow(e.scrollLeft > 120);
  };

  const clickRow = (_node: PiNode) => {
    if (toNode) toNode(_node.id);
  };

  const changeSortInfo = (attr: tableHeader) => {
    const newSortInfo = { ...sortInfo };

    const sort = newSortInfo[attr.sortIndex]?.sort;
    if (!sort) newSortInfo[attr.sortIndex] = { sort: 'asc' };

    if (sort === 'asc') newSortInfo[attr.sortIndex].sort = 'desc';

    if (sort === 'desc') delete newSortInfo[attr.sortIndex];

    setSortInfo(newSortInfo);
  };

  const modalContent = useMemo(() => {
    let tableWidth = 320;
    let headers: Array<tableHeader> = [
      {
        name: '状态',
        width: 120,
        type: 'status',
        index: -2,
        sort: -2,
        sortIndex: 'status',
        key: 'status',
        sorter: {
          asc: (a: any, b: any) => a.tempInfo.status - b.tempInfo.status,
          desc: (a: any, b: any) => b.tempInfo.status - a.tempInfo.status,
        },
      },
      {
        name: '标题',
        width: 200,
        type: 'text',
        index: -1,
        sort: -1,
        sticky: 'left',
        sortIndex: 'title',
        key: 'title',
        sorter: {
          asc: (a: any, b: any) => String(a.title).localeCompare(String(b.title), 'zh-CN'),
          desc: (a: any, b: any) => String(b.title).localeCompare(String(a.title), 'zh-CN'),
        },
      },
    ];
    if (matchingTemplate && Array.isArray(matchingTemplate.prop) && matchingTemplate.prop.length) {
      matchingTemplate.prop.forEach((p, i) => {
        if (p?.type && !p.hide) {
          tableWidth += p.type === 'text' ? 200 : 160;
          headers.push({
            name: p.name,
            width: p.type === 'text' ? 200 : 160,
            type: p.type,
            index: i,
            sort: 'sort' in p ? p.sort : i,
            sortIndex: `prop_${i}`,
            key: `prop_${i}`,
            sorter: getSorterByPropType(p.type, (currentNode) => {
              const data = propDisplay(
                p,
                currentNode.tempInfo.prop[i],
                spaceUserList[orgId],
                tempMap,
                currentNode.prop._sys_cascade,
                i,
                currentNode,
              );
              if (p.type === 'user' || p.type === 'cascade') return data;
              let isNumberProp = false;
              const originData = currentNode.tempInfo.prop[i];
              if (p.type === 'quote')
                isNumberProp = isQuotePropCanCount(p, getQuoteOriginProp(p, tempMap));
              return {
                data,
                isNumberProp,
                propConfig: p,
                originData,
              };
            }),
          });
        }
      });
    }
    headers = headers.sort((b, a) => b.sort - a.sort);
    headers.push.apply(headers, [
      {
        name: '负责人',
        width: 160,
        type: 'user',
        index: -6,
        sort: -6,
        sortIndex: 'statusProp_common_0',
        key: 'statusProp_common_0',
        sorter: getSorterByPropType('user', (node) => node.tempInfo.statusProp[0]),
      },
      {
        name: '参与者',
        width: 160,
        type: 'user',
        index: -5,
        sort: -5,
        sortIndex: 'statusProp_common_1',
        key: 'statusProp_common_1',
        sorter: getSorterByPropType('user', (node) => node.tempInfo.statusProp[1]),
      },
      {
        name: '开始时间',
        width: 160,
        type: 'datetime',
        index: -4,
        sort: -4,
        sortIndex: 'statusProp_common_2',
        key: 'statusProp_common_2',
        sorter: getSorterByPropType('datetime', (node) => node.tempInfo.statusProp[2]),
      },
      {
        name: '结束时间',
        width: 160,
        type: 'datetime',
        index: -3,
        sort: -3,
        sortIndex: 'statusProp_common_3',
        key: 'statusProp_common_3',
        sorter: getSorterByPropType('datetime', (node) => node.tempInfo.statusProp[3]),
      },
    ]);
    tableWidth += 640;
    setColumns(headers);
    return (
      <div id={'QuoteNodes'}>
        <PerfectScrollbar onScrollX={handleScroll} style={{ height: '100%' }}>
          <div className={'quote-table'} style={{ height: quoteNodes.length * 56 + 48 }}>
            <div className={'quote-table-header'} style={{ width: tableWidth }}>
              {headers.map((h, i) => (
                <div
                  onClick={() => changeSortInfo(h)}
                  key={i}
                  className={`quote-table-header-cell text-omit ${
                    h.sticky ? (showShadow ? 'sticky shadow' : 'sticky') : ''
                  }`}
                  style={{
                    width: h.width,
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    cursor: 'pointer',
                  }}
                >
                  <span>{h.name}</span>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <CaretUpOutlined
                      style={{
                        fontSize: 9,
                        color:
                          sortInfo[h.sortIndex] && sortInfo[h.sortIndex].sort === 'asc'
                            ? '#0083fa'
                            : 'inherit',
                      }}
                    />
                    <CaretDownOutlined
                      style={{
                        fontSize: 9,
                        color:
                          sortInfo[h.sortIndex] && sortInfo[h.sortIndex].sort === 'desc'
                            ? '#0083fa'
                            : 'inherit',
                      }}
                    />
                  </div>
                </div>
              ))}
            </div>
            <div className={'quote-table-body'} style={{ width: tableWidth }}>
              {quoteNodes.map((n: PiNode) => {
                return (
                  <div key={n.id} onClick={() => clickRow(n)} className={'quote-table-body-column'}>
                    {headers.map((h) => {
                      if (h.index < 0) {
                        const curStatus = matchingTemplate?.task_status[n.tempInfo.status];
                        if (h.index === -2) {
                          // 状态
                          return curStatus && !curStatus.delete ? (
                            <div
                              className={`quote-table-body-column-cell text-omit status`}
                              style={{ width: h.width }}
                              key={h.key}
                            >
                              <i
                                className={`iconfont ${curStatus.icon}`}
                                // @ts-ignore
                                style={{ color: STATUS_ICON[curStatus.icon]?.color }}
                              />
                              {curStatus.name}
                            </div>
                          ) : (
                            <div
                              className={'quote-table-body-column-cell'}
                              style={{ width: h.width }}
                              key={h.key}
                            />
                          );
                        } else if (h.index === -1) {
                          return (
                            <div
                              className={`quote-table-body-column-cell text-omit sticky ${
                                showShadow ? 'shadow' : ''
                              }`}
                              style={{ width: h.width }}
                              key={h.key}
                            >
                              {n.title || '无标题'}
                            </div>
                          );
                        } else {
                          const si = h.index + 6;
                          return (
                            <div
                              className={`quote-table-body-column-cell text-omit`}
                              style={{ width: h.width }}
                              key={h.key}
                            >
                              {curStatus && curStatus.prop?.[si]?.display
                                ? propDisplay(
                                    curStatus.prop[si],
                                    n.tempInfo.statusProp[si],
                                    spaceUserList[orgId],
                                    tempMap,
                                    n.prop._sys_cascade,
                                    h.index,
                                    n,
                                    departmentMap,
                                  )
                                : null}
                            </div>
                          );
                        }
                      } else {
                        let res: any = {};
                        if (matchingTemplate?.prop[h.index].type === 'quote') {
                          res = propDisplay(
                            matchingTemplate?.prop[h.index],
                            n.tempInfo.prop[h.index],
                            spaceUserList[orgId],
                            tempMap,
                            n.prop._sys_cascade,
                            h.index,
                            n,
                            departmentMap,
                          );
                        } else if (matchingTemplate?.prop[h.index].type === 'attachment') {
                          res.value = getAttachmentValue(n, h.index);
                          res.type = 'quote-attachment';
                        } else {
                          res = tempValueDisplay({
                            propConfig: matchingTemplate?.prop[h.index],
                            propValue: n.tempInfo.prop[h.index],
                            propIndex: h.index,
                            sysCascade: n.prop._sys_cascade,
                            userMap: spaceUserList[orgId],
                            tempMap,
                            departmentMap,
                          });
                        }

                        return (
                          <div
                            className={`quote-table-body-column-cell text-omit`}
                            style={{ width: h.width }}
                            key={h.key}
                          >
                            {res?.type === 'quote-attachment'
                              ? res.value.map((f: any, index: number) => (
                                  <div
                                    key={index}
                                    style={{
                                      backgroundImage: `url(${f.src})`,
                                      backgroundSize: 'cover',
                                      backgroundPosition: 'center',
                                      height: 32,
                                      width: 32,
                                      marginRight: 6,
                                    }}
                                  />
                                ))
                              : res}
                          </div>
                        );
                      }
                    })}
                  </div>
                );
              })}
            </div>
          </div>
        </PerfectScrollbar>
      </div>
    );
  }, [quoteNodes, matchingTemplate, showShadow, spaceUserList, tempMap]);

  const userMap = useOrgUserMap();
  const departmentNodes = useOrgDepartmentNodes();

  const addQuoteNode = async () => {
    const quoteNode = quoteNodes[0];
    const parent_id =
      quoteNode && quoteNode.parent
        ? quoteNode.parent.id
        : currentTemplate?.prop[propIndex].matchingSource;
    const id = generateAddOpId();
    if (!currentTemplate?.prop[propIndex] || !matchingTemplate) return;
    const { conditionProp, quoteProp } = currentTemplate?.prop[propIndex] || {};

    // 新节点的属性
    const newProps = getDefaultTempProp(
      {},
      matchingTemplate,
      currentUser,
      null,
      userMap,
      departmentNodes,
      null,
    );

    // 条件匹配的值
    (quoteProp || []).forEach((i: string, index: number) => {
      const [_, pIndex] = i.split('-');
      newProps._sys_temp[1][Number(pIndex)] = node.tempInfo.prop[conditionProp[index]];
    });

    // 新建草稿节点
    setDraftsNodeData({
      org_id: orgId,
      parentId: parent_id,
      siblingId: null,
      draft: true,
      node: {
        node_id: id,
        prop: newProps,
        title: '',
      },
    });
    setParentId(parent_id);
    setShowDraftsModal(true);
  };

  return (
    <>
      <LinkPiModal
        width={1024}
        visible={visible}
        modalContent={modalContent}
        onCancel={onCancel}
        title={
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <span>主题详情</span>
              <span style={{ color: '#656A72', fontSize: 13, paddingLeft: 8 }}>
                (共 {quoteNodes.length} 条记录)
              </span>
            </div>
            <div onClick={addQuoteNode} className="add-quote-node-btn">
              <i className="iconfont iconButton_add" />
              <span> 新增主题</span>
            </div>
          </div>
        }
        noFooter={true}
        className="quote-nodes-modal"
      />
      {showDraftsModal && (
        <DraftsModal
          visible={showDraftsModal}
          mode="add"
          onCancel={() => setShowDraftsModal(false)}
          orgId={orgId}
          draftsNodeData={draftsNodeData}
          initParentId={parentId}
          onSuccess={() => {
            setTimeout(() => {
              setFlag(flag + 1);
              if (!originNodes) fetchNodes();
              if (addQuoteNodeCallback) addQuoteNodeCallback();
            }, 500);
          }}
        />
      )}
    </>
  );
};

export default QuoteNodes;
