// @ts-nocheck
import React from 'react';
import { ApplyPluginsType, dynamic } from '/home/xufan/build/run_build/node_modules/.pnpm/@umijs+runtime@3.5.42_react@16.14.0/node_modules/@umijs/runtime';
import * as umiExports from './umiExports';
import { plugin } from './plugin';
import LoadingComponent from '@/components/PageLoading/index';

export function getRoutes() {
  const routes = [
  {
    "path": "/",
    "exact": true,
    "redirect": "/home"
  },
  {
    "path": "/noAccess",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__noAccess' */'/home/xufan/build/run_build/src/pages/noAccess'), loading: LoadingComponent}),
    "exact": true
  },
  {
    "path": "/_developer_",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__developerOnly' */'/home/xufan/build/run_build/src/pages/developerOnly'), loading: LoadingComponent}),
    "exact": true
  },
  {
    "path": "/user/invite",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__user__invite' */'/home/xufan/build/run_build/src/pages/user/invite'), loading: LoadingComponent}),
    "exact": true
  },
  {
    "path": "/user",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__UserLayout' */'/home/xufan/build/run_build/src/layouts/UserLayout'), loading: LoadingComponent}),
    "routes": [
      {
        "path": "/user/login",
        "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'/home/xufan/build/run_build/src/layouts/UserSecurityLayout'), loading: LoadingComponent})],
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__user__login' */'/home/xufan/build/run_build/src/pages/user/login'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/user/register-result",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__user__register-result' */'/home/xufan/build/run_build/src/pages/user/register-result'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/user/register",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__user__register' */'/home/xufan/build/run_build/src/pages/user/register'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/user/mobile",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__user__mobile' */'/home/xufan/build/run_build/src/pages/user/mobile'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/user/reset-password",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__user__reset-password' */'/home/xufan/build/run_build/src/pages/user/reset-password'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/home/xufan/build/run_build/src/pages/404'), loading: LoadingComponent}),
        "exact": true
      }
    ]
  },
  {
    "path": "/share",
    "routes": [
      {
        "path": "/share/content",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__share__content' */'/home/xufan/build/run_build/src/pages/share/content'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/share/view",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__share__view' */'/home/xufan/build/run_build/src/pages/share/view'), loading: LoadingComponent}),
        "exact": true
      }
    ]
  },
  {
    "path": "/invalidation",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__user__invalidation' */'/home/xufan/build/run_build/src/pages/user/invalidation'), loading: LoadingComponent}),
    "exact": true
  },
  {
    "path": "/mobile",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__MobileLayout' */'/home/xufan/build/run_build/src/layouts/MobileLayout'), loading: LoadingComponent}),
    "routes": [
      {
        "path": "/mobile/share",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__share__mobile' */'/home/xufan/build/run_build/src/pages/share/mobile'), loading: LoadingComponent}),
        "exact": true
      }
    ]
  },
  {
    "path": "/",
    "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'/home/xufan/build/run_build/src/layouts/SecurityLayout'), loading: LoadingComponent})],
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__HomeLayout' */'/home/xufan/build/run_build/src/layouts/HomeLayout'), loading: LoadingComponent}),
    "name": "homeLayout",
    "routes": [
      {
        "name": "linkpihome",
        "path": "/home/:spaceId?/:focusId?/:nodeId?",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__home' */'/home/xufan/build/run_build/src/pages/home'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "name": "space",
        "path": "/space/(.*)",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__space' */'/home/xufan/build/run_build/src/pages/space'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "name": "space",
        "path": "/space",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__space' */'/home/xufan/build/run_build/src/pages/space'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "name": "spaceMember",
        "path": "/space/member",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__space__member' */'/home/xufan/build/run_build/src/pages/space/member'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "name": "spaceTitleRecommend",
        "path": "/space/title",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__space__title' */'/home/xufan/build/run_build/src/pages/space/title'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "name": "invite",
        "path": "/invite",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__invite' */'/home/xufan/build/run_build/src/pages/invite'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "name": "settings",
        "path": "/settings",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__account__settings' */'/home/xufan/build/run_build/src/pages/account/settings'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "name": "linkpiSpace",
        "path": "/linkpi/space",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__space' */'/home/xufan/build/run_build/src/pages/space'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/home/xufan/build/run_build/src/pages/404'), loading: LoadingComponent}),
        "exact": true
      }
    ]
  },
  {
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/home/xufan/build/run_build/src/pages/404'), loading: LoadingComponent}),
    "exact": true
  }
];

  // allow user to extend routes
  plugin.applyPlugins({
    key: 'patchRoutes',
    type: ApplyPluginsType.event,
    args: { routes },
  });

  return routes;
}
