import { InfoCircleOutlined } from '@ant-design/icons';
import { antdModalV5, create, hide, show, useModal } from '@ebay/nice-modal-react';
import type { CurrentUser } from '@linkpi/core';
import { useMemoizedFn } from 'ahooks';
import { message, Modal } from 'antd';
import { isEqual } from 'lodash';
import { isNil, values } from 'ramda';
import { type FC, useMemo, useState } from 'react';
import { match, P } from 'ts-pattern';
import { useDispatch } from 'umi';

import {
  useCurrentOrgId,
  useCurrentUserInfo,
  useNodeTreeData,
  useOrgTemplatesSettingInfoMap,
  useOrgUserGroup,
} from '@/hook';
import { getTemplateEmitter } from '@/hook/useTemplateEvent';
import request from '@/utils/request';
import { delay } from '@/utils/utils';

import TempPropModal from '../../TemplateProp/components/TempPropModal';
import type { BasicProp } from '../../TemplateProp/components/TempPropModal/types';

type TempPropType = {
  templateId: string;
  needAddOneBtn?: boolean;
  isLock?: boolean;
  isDebugMode?: boolean;
  allowSwitchProps?: boolean;
  autoClose?: boolean;
} & (
  | {
      type: 'edit';
      propIndex: number;
    }
  | { type: 'add' }
);

const _TemplatePropModal: FC<TempPropType> = ({
  templateId,
  allowSwitchProps,
  needAddOneBtn,
  ...restProps
}) => {
  const modal = useModal();
  const niceModalProps = antdModalV5(modal);

  const currentOrgId = useCurrentOrgId();

  const tempMap = useOrgTemplatesSettingInfoMap();
  const userInfo = useCurrentUserInfo();

  const [_propState, setPropState] = useState<typeof restProps | undefined>();

  /**
   * _TemplatePropModal 弹窗只有
   */
  const propState = _propState || restProps;

  const currentTemplate = useMemo(() => {
    return tempMap[templateId];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // 开启弹窗后，不再更新主题类型配置
    niceModalProps.open,
    // 除非当前属性变了
    _propState,
  ]);

  const templateList = useMemo(() => {
    return values(tempMap);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // 开启弹窗后，不再更新主题类型配置
    niceModalProps.open,
    // 除非当前属性变了
    _propState,
  ]);

  const propInfo = useMemo(
    () => {
      return match(propState)
        .with({ type: 'add' }, () => null)
        .with(
          { type: 'edit', propIndex: P.select() },
          (propIndex) => ({ ...currentTemplate.prop[propIndex], index: propIndex }) as BasicProp,
        )
        .exhaustive();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [niceModalProps.open, propState],
  );

  const groups = useOrgUserGroup();
  const nodeTreeData = useNodeTreeData();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const propUpdationConfirm = useMemoizedFn((propName: string) => {
    const content = (
      <div className="prop-confirm-title">
        <InfoCircleOutlined />
        该主题类型的「{propName || '引用/属性匹配'}」有修改，是否更新已有主题的属性值
        <a
          onClick={async () => {
            const res = await request('/docapi/reMatchTitle', {
              method: 'POST',
              data: {
                org_id: currentOrgId,
                temp_id: templateId,
                reMatchProp: [],
                exMatchProp: [],
              },
            });
            if (res?.status === 'ok') {
              message.success('更新成功');
            } else {
              message.error('更新失败');
            }
          }}
        >
          立即更新
        </a>
      </div>
    );

    return message.warn({
      content,
    });
  });

  const insertProp = useMemoizedFn(
    async (newProp: any, template: CurrentUser.TemplateInfo, addOne = false) => {
      newProp.sort = template.prop.length;
      newProp.insert = true;
      const res = await dispatch({
        type: addOne ? 'space/editTemplateProp' : 'space/editTemplatePropAndWaitFetch',
        payload: {
          org_id: currentOrgId,
          template_id: templateId,
          prop: [newProp],
        },
      });

      if (res?.status === 'ok') {
        delete newProp.insert;
        newProp.index =
          Array.isArray(res.newProps) && typeof res.newProps[0] === 'number'
            ? res.newProps[0]
            : template.prop.length;
        if ('newFormulaProps' in res && res.newFormulaProps.length) {
          request('/docapi/calcPropFormula', {
            method: 'POST',
            data: {
              org_id: currentOrgId,
              temp_id: template.template_id,
              index: res.newFormulaProps,
            },
          }).then((r) => console.log(r));
        }

        if (addOne) {
          await delay(300);
          setPropState({ type: 'add' });
        } else {
          setPropState({ type: 'edit', propIndex: newProp.index as number });
        }
      }
      // 判断分享的属性是不是所有
      // isSharePropConfigAll(template, spaceKey, dispatch);
    },
  );

  const saveProp = useMemoizedFn(async (newProp: any, andOne = false) => {
    setLoading(true);
    return match(propState)
      .with({ type: 'add' }, async () => {
        await insertProp(newProp, currentTemplate, andOne);
        setLoading(false);
        return;
      })
      .with({ type: 'edit', propIndex: P.select() }, async (propIndex) => {
        let typeChanged = false;
        const newProps = currentTemplate.prop.map((prop, i) => {
          if (propIndex === i) {
            let newType = newProp.type;
            if (newType === 'tag') newType = 'enum';

            let oldType = prop.type;
            if (oldType === 'tag') oldType = 'enum';

            if (newType !== oldType) {
              typeChanged = true;
            }
            newProp.iMatch = prop?.iMatch || 0;
            if ('sort' in prop) newProp.sort = prop.sort;
            return newProp;
          }
          return prop;
        });

        let cleanProp = false;
        if (typeChanged) {
          cleanProp = await new Promise((resolve) => {
            Modal.confirm({
              type: 'warning',
              title: '属性类型已经修改，是否需要清空属性值？',
              okText: '清空',
              cancelText: '不清空',
              onOk: () => {
                resolve(true);
                return Promise.resolve(true);
              },
              onCancel: () => {
                resolve(false);
                return Promise.resolve(true);
              },
            });
          });
        }

        const res = await dispatch({
          type: 'space/editTemplateProp',
          payload: {
            org_id: currentOrgId,
            template_id: currentTemplate.template_id,
            prop: [newProp],
            cleanProp,
          },
        });

        if (res.status === 'ok') {
          if (!isEqual(currentTemplate.prop[newProp.index]?.defAuxProp, newProp.defAuxProp)) {
            request('/api/updateAuxiliaryProp', {
              method: 'POST',
              data: {
                org_id: currentOrgId,
                temp_id: currentTemplate.template_id,
                index: newProp.index,
              },
            }).then((r) => console.log(r));
          }
          currentTemplate.prop = newProps;

          if (newProp.conditionMatching && newProp.type !== 'quote') {
            propUpdationConfirm(newProp.name);
          } else {
            if (newProp.type === 'formula' || newProp.type === 'quote') {
              request('/docapi/calcPropFormula', {
                method: 'POST',
                data: {
                  org_id: currentOrgId,
                  temp_id: currentTemplate.template_id,
                  index: [propIndex],
                },
              }).then((r) => console.log(r));
            }
            message.success(restProps.type === 'add' ? '新增成功' : '修改成功');
          }
          if (andOne) setPropState({ type: 'add' });
        } else {
          message.error(restProps.type === 'add' ? '新增失败' : '修改失败');
        }
        setLoading(false);
      })
      .exhaustive();
  });

  const onSubmit = useMemoizedFn((_propInfo: CurrentUser.TemplateProp, addOne: boolean) => {
    return saveProp(_propInfo, addOne).then((res) => {
      modal.resolve(res);
      return res;
    });
  });

  const setEditingProp = useMemoizedFn((_: any, propIndex: number) => {
    if (isNil(_)) {
      setPropState({ type: 'add' });
      return;
    }

    setPropState({ type: 'edit', propIndex });
  });

  const isDebugMode = useMemo(() => {
    if (currentTemplate?.temp_option && 'debugMode' in currentTemplate.temp_option) {
      return currentTemplate.temp_option.debugMode;
    } else {
      return false;
    }
  }, [currentTemplate?.temp_option]);

  const onCancel = useMemoizedFn(() => {
    setPropState(undefined);
    niceModalProps.onCancel();
  });

  return (
    <TempPropModal
      isDebugMode={isDebugMode}
      matchingSources={nodeTreeData as any}
      groups={groups}
      curUserId={userInfo.account_id}
      spaceKey={currentOrgId}
      templates={templateList}
      currentTemplate={currentTemplate}
      editingProp={propInfo}
      loading={loading}
      afterClose={niceModalProps.afterClose}
      visible={niceModalProps.open}
      onSubmit={onSubmit}
      onCancel={onCancel}
      setEditingProp={setEditingProp}
      needAddOneBtn={needAddOneBtn}
      allowSwitchProps={allowSwitchProps}
    />
  );
};

const EditTemplatePropNiceModal = create(_TemplatePropModal);

export const showTemplatePropModal = (options: TempPropType) => {
  const TemplateEmitter = getTemplateEmitter();
  TemplateEmitter.emit('setCurrentTemplate', options.templateId);

  return show(EditTemplatePropNiceModal, options).then((res) => {
    if (options.autoClose) {
      hide(EditTemplatePropNiceModal);
    }
    return res;
  });
};
