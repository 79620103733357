import { getQuoteOriginProp } from '@linkpi/core';
import type { FC } from 'react';
import { useMemo } from 'react';

import type { CheckboxSelectProp } from '@/components';
import { CheckboxSelect } from '@/components';
import { useCurrentOrgId, useOrgTempMap } from '@/hook';
import { useCurrentTemplateSetting } from '@/hook/useOrgSetting';

export function useStatusUserCustomPropSelectOptions(config?: {
  templateId?: string;
  disabledIndex?: number;
}) {
  const orgId = useCurrentOrgId();
  const { templateId, disabledIndex = -1 } = config || {};
  const [currentTemplateFromSpace] = useCurrentTemplateSetting();

  const templatesMap = useOrgTempMap(orgId);
  const currentTemplate = (templateId ? templatesMap[templateId] : currentTemplateFromSpace) as
    | TemplateInfo
    | undefined;

  const options = useMemo(() => {
    const propOptions =
      currentTemplate?.prop
        ?.map((prop, index) => {
          if (prop?.type && prop.type === 'quote') {
            const originProp = getQuoteOriginProp(prop, templatesMap);
            if (typeof originProp !== 'string' && originProp?.type === 'user') {
              return {
                label: prop.name,
                value: 'prop' + index,
                sort: 'sort' in prop ? prop.sort : index,
              };
            }
          }

          if (prop?.type && prop.type !== 'quote' && !prop.disabled) {
            if (prop.type === 'user') {
              return {
                label: prop.name,
                value: 'prop' + index,
                sort: 'sort' in prop ? prop.sort : index,
              };
            }
          }

          return null;
        })
        .filter((i) => i)
        .sort((b, a) => b!.sort - a!.sort) || [];

    const statusOptions = (key = 'status') =>
      currentTemplate?.task_status
        ?.map((x, i) => {
          if (x && !x.delete) {
            return {
              label: x.name,
              value: key + i,
              sort: 'sort' in x ? x.sort : i,
              disabled: i === disabledIndex,
            };
          }
          return null;
        })
        .filter((i) => !!i)
        .sort((b, a) => b!.sort - a!.sort) ?? [];

    const result = [
      {
        label: '系统属性',
        options: [
          { label: '创建者', value: 'creator' },
          {
            label: '修改者',
            value: 'updater',
          },
        ],
      },
      {
        label: '自定义人员属性',
        options: propOptions || [],
      },
      {
        label: '状态负责人',
        options: statusOptions('status'),
      },
      {
        label: '状态参与者',
        options: statusOptions('pStatus'),
      },
    ];

    return result.filter((i) => i.options.length);
  }, [currentTemplate?.prop, currentTemplate?.task_status, templatesMap]);

  return options as {
    label: string;
    options: [{ label: string; value: string }];
  }[];
}

export const StatusUserCustomPropSelect: FC<
  React.PropsWithChildren<CheckboxSelectProp & { disabledIndex?: number }>
> = (props) => {
  const { disabledIndex, ...rest } = props;

  const options = useStatusUserCustomPropSelectOptions({ disabledIndex });

  return (
    <div style={{ padding: '0px 20px', height: '100%', overflow: 'auto' }}>
      <CheckboxSelect {...rest} options={options} />
    </div>
  );
};
