import { DownOutlined } from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Dropdown, Popover, Space, Switch } from 'antd';
import cls from 'classnames';
import type { FC, PropsWithChildren } from 'react';
import { Children, isValidElement, memo, useMemo } from 'react';

import { RegularIcon } from '@/components';

import { openViewToolLayoutSetting } from './LayoutSetting';

import styles from './MoreButtons.less';

const nodeOpenTypes = [
  { label: '弹窗打开', value: 'modal' },
  { label: '内容页打开', value: 'content' },
  { label: '新开页面', value: 'newWindow' },
];
const _MoreButtons: FC<
  PropsWithChildren<{
    disable?: boolean;
    onEditLayoutClick: () => void;
    pagination?: boolean;
    nodeOpenType?: string;
    hideTableCheckbox?: any;
    onChangePagination: (v: boolean) => void;
    onChangeNodeOpenType: (v: string) => void;
    onChangeHideTableCheckbox: (v: boolean) => void;
    
  }>
> = ({
  children,
  disable,
  onEditLayoutClick,
  pagination,
  onChangePagination,
  nodeOpenType,
  onChangeNodeOpenType,
  hideTableCheckbox,
  onChangeHideTableCheckbox
}) => {
  const buttons = useMemo(() => {
    const result: MenuProps['items'] = [];

    Children.forEach(children, (c, index) => {
      if (c && isValidElement(c)) {
        result.push({
          label: c,
          key: c.key || index,
        });
      }
    });
    return result;
  }, [children]);

  const items = useMemo(() => {
    const mock: MenuProps['items'] = [
      {
        type: 'group',
        label: '更多功能',
        children: buttons,
      },
    ];

    if (!disable) {
      mock.push({ type: 'divider' });
      if (typeof pagination === 'boolean') {
        mock.push({
          label: (
            <div className="flex justify-between items-center">
              <span>
                <RegularIcon className="mr-2" type="iconView_Paging" size={14} color="#95A0B4" />
                分页
              </span>
              <Switch
                size="small"
                checked={pagination}
                onChange={(checked, e) => {
                  e.stopPropagation();
                  onChangePagination(checked);
                }}
              />
            </div>
          ),
          key: 'pagination',
        });

        // 表格支持批量操作
        mock.push({
          label: (
            <div className="flex justify-between items-center">
              <span>
                <RegularIcon className="mr-2" type="icona-BatchOperation" size={14} color="#95A0B4" />
                批量操作
              </span>
              <Switch
                size="small"
                checked={!hideTableCheckbox}
                onChange={(checked, e) => {
                  e.stopPropagation();
                  onChangeHideTableCheckbox(!checked);
                }}
              />
            </div>
          ),
          key: 'hideTableCheckbox',
        });

        // 节点打开方式
        mock.push({
          label: (
            <Popover
              placement="left"
              overlayClassName={styles.popover}
              content={
                <div>
                  {nodeOpenTypes.map((t, index) => (
                    <div
                      onClick={(e) => {
                        e.stopPropagation();
                        onChangeNodeOpenType(t.value);
                      }}
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        marginTop: index === 0 ? 0 : 12,
                        cursor: 'pointer',
                        height: 24,
                      }}
                      key={t.value}
                    >
                      <div>{t.label}</div>
                      {nodeOpenType === t.value && (
                        <i style={{ color: '#316EF5' }} className="iconfont iconselected" />
                      )}
                    </div>
                  ))}
                </div>
              }
            >
              <div className="flex justify-between items-center">
                <span>
                  <i
                    className="icona-openingmode iconfont"
                    style={{ color: '#95A0B4', marginRight: 8 }}
                  />
                  主题打开方式
                </span>
                <i className="iconzhankaishouqi iconfont" />
              </div>
            </Popover>
          ),
          key: 'nodeOpenType',
        });
      }
      mock.push({
        label: (
          <Space onClick={onEditLayoutClick}>
            <RegularIcon type="iconneirongyepeizhi-zidingyishuxing" size={14} color="#95A0B4" />
            编辑工具栏
          </Space>
        ),
        key: 'editTools',
      });
    }

    return mock;
  }, [buttons, disable, onChangePagination, onEditLayoutClick, pagination]);

  return (
    <Dropdown menu={{ items, className: styles.wrapper }} trigger={['click']}>
      <Space align="center" className={cls('link-pi-btn', 'btn-lg')} style={{ fontSize: 12 }}>
        更多
        <DownOutlined />
      </Space>
    </Dropdown>
  );
};

export const MoreButton = memo(_MoreButtons);
