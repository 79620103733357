import { Popover } from 'antd';
import { useEffect, useState } from 'react';
import { useSelector } from 'umi';

import { uniqueArr } from '@/utils/utils';

import './index.less';

const FlowOption = (props) => {
  const { status, curFlowOption, setCurFlowOption } = props;
  const { groups } = useSelector((state) => state.space);
  const [fromStatus, setFromStatus] = useState(-1);
  const [targetStatus, setTargetStatus] = useState(-1);
  const [flowGroups, setFlowGroups] = useState([]);
  const [flowOperator, setFlowOperator] = useState(-1);
  const [flowCount, setFlowCount] = useState(-1);
  const [flowText, setFlowText] = useState(<></>);
  const [flowTitle, setFlowTitle] = useState('点击设置属性值');

  const getFlowOperator = (f) => {
    switch (f) {
      case 0:
        return '=';
      case 1:
        return '>';
      case 2:
        return '≥';
      case 3:
        return '<';
      case 4:
        return '≤';
    }
  };

  useEffect(() => {
    if (curFlowOption) {
      setFromStatus(curFlowOption.fromStatus);
      setTargetStatus(curFlowOption.targetStatus);
      setFlowGroups(curFlowOption.flowGroups || []);
      setFlowOperator(curFlowOption.flowOperator);
      setFlowCount(curFlowOption.flowCount);
    } else {
      setFromStatus(-1);
      setTargetStatus(-1);
      setFlowGroups([]);
      setFlowOperator(-1);
      setFlowCount(-1);
      setFlowText(<>点击设置属性值</>);
      setFlowTitle('点击设置属性值');
    }
  }, [curFlowOption]);

  useEffect(() => {
    if (~fromStatus || ~targetStatus || (~flowOperator && ~flowCount) || flowGroups.length) {
      setFlowText(
        <div className={'flow-text'}>
          <div className={~fromStatus ? '' : 'flow-text-unset'}>
            {~fromStatus ? status[fromStatus]?.name : '未设置'}
            {' -> '}
          </div>
          <div className={~targetStatus ? '' : 'flow-text-unset'}>
            {~targetStatus ? status[targetStatus]?.name : '未设置'}，
          </div>
          <div className={~flowOperator && ~flowCount ? '' : 'flow-text-unset'}>
            {~flowOperator && ~flowCount
              ? `${getFlowOperator(flowOperator)}${flowCount}`
              : '未设置'}
            ，
          </div>
          <div className={flowGroups.length ? '' : 'flow-text-unset'}>
            {flowGroups?.length
              ? flowGroups
                  .map((g) => groups.find((group) => group.group_id === g).group_name)
                  .join('，')
              : '未设置'}
          </div>
        </div>,
      );
      setFlowTitle(
        `${~fromStatus ? status[fromStatus]?.name : '未设置'} -> ${
          ~targetStatus ? status[targetStatus]?.name : '未设置'
        }，${
          ~flowOperator && ~flowCount ? `${getFlowOperator(flowOperator)}${flowCount}` : '未设置'
        }，${
          flowGroups?.length
            ? flowGroups
                .map((g) => groups.find((group) => group.group_id === g).group_name)
                .join('，')
            : '未设置'
        }`,
      );
    }
  }, [fromStatus, targetStatus, flowOperator, flowCount, flowGroups]);

  const setFlowOption = (type, value) => {
    const newFlowOption = curFlowOption
      ? JSON.parse(JSON.stringify(curFlowOption))
      : {
          fromStatus: -1,
          targetStatus: -1,
          flowGroups: [],
          flowOperator: -1,
          flowCount: -1,
        };
    newFlowOption[type] = value;
    setCurFlowOption(newFlowOption);
  };

  const flowOptions = (
    <div className={'formOptions'}>
      <Popover
        trigger="hover"
        placement={'right'}
        content={status.map((x, i) => {
          return (
            <div
              className={`status-option ${i === targetStatus ? 'status-disabled' : ''} ${
                i === fromStatus ? 'status-selected' : ''
              }`}
              onClick={() => i !== targetStatus && setFlowOption('fromStatus', i)}
              key={i}
            >
              {x.name}
            </div>
          );
        })}
        overlayClassName={'flowPopover'}
      >
        <div className={'formStatus'}>
          <div className={`form-label ${~fromStatus ? 'label-active' : ''}`}>
            <i className={'iconfont iconqidiandequan'} style={{ color: '#C1E6FF' }} />
            <div>{~fromStatus ? status[fromStatus]?.name : '选择状态'}</div>
          </div>
          <i className={'iconfont iconxiala'} />
        </div>
      </Popover>
      <i className={'iconfont iconjiantou'} style={{ color: '#BDC0C5' }} />
      <Popover
        trigger="hover"
        placement={'right'}
        content={status.map((x, i) => {
          return (
            <div
              className={`status-option ${i === fromStatus ? 'status-disabled' : ''} ${
                i === targetStatus ? 'status-selected' : ''
              }`}
              onClick={() => i !== fromStatus && setFlowOption('targetStatus', i)}
              key={i}
            >
              {x.name}
            </div>
          );
        })}
        overlayClassName={'flowPopover'}
      >
        <div className={'formStatus'} style={{ marginBottom: 3 }}>
          <div className={`form-label ${~targetStatus ? 'label-active' : ''}`}>
            <i className={'iconfont iconjieshudequan'} style={{ color: '#FFDEC1' }} />
            <div>{~targetStatus ? status[targetStatus]?.name : '选择目标状态'}</div>
          </div>
          <i className={'iconfont iconxiala'} />
        </div>
      </Popover>
      <Popover
        trigger="hover"
        placement={'right'}
        content={
          <div>
            <div className={'operator-count'}>
              <div className={'operator-count-label'}>规则</div>
              <div className={'operator-count-label'}>次数</div>
            </div>
            <div className={'operator-count'}>
              <div
                className={'operator-count-options'}
                style={{ borderRight: '1px solid #EEEEEE' }}
              >
                <div
                  className={`operator-count-options-item ${
                    0 === flowOperator ? 'status-selected' : ''
                  }`}
                  onClick={() => setFlowOption('flowOperator', 0)}
                >
                  等于
                </div>
                <div
                  className={`operator-count-options-item ${
                    1 === flowOperator ? 'status-selected' : ''
                  }`}
                  onClick={() => setFlowOption('flowOperator', 1)}
                >
                  大于
                </div>
                <div
                  className={`operator-count-options-item ${
                    2 === flowOperator ? 'status-selected' : ''
                  }`}
                  onClick={() => setFlowOption('flowOperator', 2)}
                >
                  大于等于
                </div>
                <div
                  className={`operator-count-options-item ${
                    3 === flowOperator ? 'status-selected' : ''
                  }`}
                  onClick={() => setFlowOption('flowOperator', 3)}
                >
                  小于
                </div>
                <div
                  className={`operator-count-options-item ${
                    4 === flowOperator ? 'status-selected' : ''
                  }`}
                  onClick={() => setFlowOption('flowOperator', 4)}
                >
                  小于等于
                </div>
              </div>
              <div className={'operator-count-options'}>
                <div
                  className={`operator-count-options-item ${
                    1 === flowCount ? 'status-selected' : ''
                  }`}
                  onClick={() => setFlowOption('flowCount', 1)}
                >
                  1
                </div>
                <div
                  className={`operator-count-options-item ${
                    2 === flowCount ? 'status-selected' : ''
                  }`}
                  onClick={() => setFlowOption('flowCount', 2)}
                >
                  2
                </div>
                <div
                  className={`operator-count-options-item ${
                    3 === flowCount ? 'status-selected' : ''
                  }`}
                  onClick={() => setFlowOption('flowCount', 3)}
                >
                  3
                </div>
                <div
                  className={`operator-count-options-item ${
                    4 === flowCount ? 'status-selected' : ''
                  }`}
                  onClick={() => setFlowOption('flowCount', 4)}
                >
                  4
                </div>
                <div
                  className={`operator-count-options-item ${
                    5 === flowCount ? 'status-selected' : ''
                  }`}
                  onClick={() => setFlowOption('flowCount', 5)}
                >
                  5
                </div>
                <div
                  className={`operator-count-options-item ${
                    6 === flowCount ? 'status-selected' : ''
                  }`}
                  onClick={() => setFlowOption('flowCount', 6)}
                >
                  6
                </div>
              </div>
            </div>
          </div>
        }
        overlayClassName={'flowPopover'}
      >
        <div className={'form-status formStatus'}>
          <div className={`form-option-label ${~flowOperator && ~flowCount ? 'label-active' : ''}`}>
            转换次数
            {~flowOperator && ~flowCount ? (
              <div className={'option-label'}>
                {getFlowOperator(flowOperator)}
                {flowCount}
              </div>
            ) : (
              ''
            )}
          </div>
          <i className={'iconfont iconxiala'} />
        </div>
      </Popover>
      <Popover
        trigger="hover"
        placement={'right'}
        content={groups.map((x, i) => {
          return (
            <div
              className={`status-option ${
                ~flowGroups?.indexOf(x.group_id) ? 'status-selected' : ''
              }`}
              onClick={() => {
                if (~flowGroups.indexOf(x.group_id)) {
                  const f = JSON.parse(JSON.stringify(flowGroups));
                  f.splice(flowGroups.indexOf(x.group_id), 1);
                  setFlowOption('flowGroups', f);
                } else {
                  setFlowOption('flowGroups', uniqueArr([...flowGroups, x.group_id]));
                }
              }}
              key={i}
            >
              {x.group_name}
            </div>
          );
        })}
        overlayClassName={'flowPopover'}
      >
        <div className={'form-status formStatus'}>
          <div className={`form-option-label ${flowGroups.length ? 'label-active' : ''}`}>
            转换人
            <div className={'option-labels'}>
              {flowGroups.map((g) => (
                <div
                  className={'option-label text-omit'}
                  key={g}
                  title={groups.find((group) => group.group_id === g)?.group_name}
                >
                  {groups.find((group) => group.group_id === g)?.group_name}
                </div>
              ))}
            </div>
          </div>
          <i className={'iconfont iconxiala'} />
        </div>
      </Popover>
    </div>
  );

  return (
    <div className={'flowOption'}>
      <Popover
        trigger={'click'}
        placement={'bottomLeft'}
        content={flowOptions}
        overlayClassName={'flowPopover'}
      >
        <div
          className={`flow-select text-omit ${curFlowOption ? '' : 'flow-select-unset'}`}
          title={flowTitle}
        >
          {flowText}
        </div>
      </Popover>
    </div>
  );
};

export default FlowOption;
