import { useSelector } from 'umi';

type ICurrentSelection = {
  // 当前空间
  selectSpace: null | string;
  // 当前专注节点
  selectFocus: null | string;
  // 当前节点
  selectNode: null | string;
  // 当前视图
  // content 正文主题树;其他 视图id
  selectViewId: string;
  // 当前side mode
  selectSideMode: string;
};
export const useCurrentSelection = () => {
  const { currentSelection } = useSelector((state: any) => state.workspace);

  return currentSelection as ICurrentSelection;
};
