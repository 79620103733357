import { SIDE_MODE, type ViewList } from '@linkpi/core';
import { usePiNode } from '@linkpi/core/web';
import { type FC, useRef } from 'react';
import { useDispatch, useSelector } from 'umi';

import { useCurrentSelection, useCurrentUser, useOrgConnection, useOrgInfo } from '@/hook';
import { useWorkbenchViewList } from '@/hook/useViewList';
import WorkbenchContent from '@/pages/home/Workbench/WorkbenchContent';

type ViewComponentProp = {
  nodeId: string;
  viewId: string;
};

/**
 * 渲染视图的组件
 */
export const NodeViewComponent: FC<ViewComponentProp> = ({ nodeId, viewId }) => {
  const dispatch = useDispatch();
  const tabContentRef = useRef<any>({});
  const [orgInfo] = useOrgInfo();
  const orgConnection = useOrgConnection();
  const [node] = usePiNode(orgConnection, nodeId);
  const currentSelection = useCurrentSelection();
  const currentUser = useCurrentUser();
  const { readOnlyMode } = useSelector((state: any) => state.workspace);

  const [viewList, getViewList, authMap] = useWorkbenchViewList(
    node!,
    orgInfo!.orgId,
    orgInfo!.role,
    currentUser.userid,
    (list: ViewList) => {
      if (!list.length) return;
      // 如果视图列表没有该视图，重置到第一个
      const hasView = list.find((x) => x.view_id === currentSelection.selectViewId);
      if (!hasView) {
        dispatch({
          type: 'workspace/setCurrentSelection',
          payload: {
            selectViewId: list[0].view_id,
          },
        });
      }
    },
    [],
  );

  return (
    <div
      style={{
        flex: 1,
        height: 0,
        display: 'flex',
        // 放置全屏时，背景颜色消失
        backgroundColor: '#fff',
      }}
      id="WorkbenchContent"
    >
      <WorkbenchContent
        node={node!}
        orgInfo={orgInfo}
        selectViewId={viewId}
        viewList={viewList}
        leftViewCollapse={true}
        leftViewWidth={400}
        showMessage={false}
        getViewList={getViewList}
        authMap={authMap}
        readOnly={readOnlyMode}
        isDrafts={currentSelection.selectSideMode === SIDE_MODE.DRAFTSBOX}
        linkRef={tabContentRef}
      />
    </div>
  );
};
