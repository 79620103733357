import type { PiGridModules } from '../core';
import { checkboxConfig } from '../helper/config';
import type { RowType } from '../modules/dataManager';
import type { PositionType } from './_baseComponent';
import GridBaseComponent from './_baseComponent';

interface CheckboxData {
  type: 'headerCheckbox' | 'nodeCheckbox' | 'groupCheckbox';
  node?: RowType<'node'>;
  groupId?: string;
}

class CheckboxCell extends GridBaseComponent {
  public type: string = 'checkboxCell';
  public data: CheckboxData;
  public STYLE = {
    selectBg: '#f0f6ff',
    hoverBg: '#fafafa',
  };
  private onClickHandler: any;
  public captrue = false;

  constructor(
    position: PositionType,
    moduleInstances: PiGridModules,
    data: CheckboxData,
    onClick: any,
  ) {
    super(position, moduleInstances);
    this.onClickHandler = onClick;
    this.data = data;
  }

  public click() {
    const {
      DataManager,
      Event: { shiftDown },
    } = this.moduleInstances;
    if (DataManager.config.viewInfo.hideTableCheckbox)return
    let { nodeIds, preCheckId, config, childIdMap, checkedNodesDetail } = DataManager;

    if (!shiftDown) {
      if (this.data.type === 'groupCheckbox') {
        this.onClickHandler && this.onClickHandler(this.getIsGroupChecked());
        return;
      }

      if (this.data.node)
        DataManager.preCheckId =
          preCheckId === this.data.node.node.id || config.checkId.includes(this.data.node.node.id)
            ? null
            : this.data.node.node.id;
      this.onClickHandler && this.onClickHandler();
      return;
    }

    if (!DataManager.preCheckId) return;
    if (!this.data.node) return;

    // 子节点的shift处理
    if (typeof checkedNodesDetail === 'object') {
      nodeIds = childIdMap[checkedNodesDetail.parentId];
    }

    const preIndex = nodeIds.indexOf(DataManager.preCheckId);
    const nowIndex = nodeIds.indexOf(this.data.node.node.id);

    const min = Math.min(preIndex, nowIndex);
    const max = Math.max(preIndex, nowIndex);
    // 截取
    DataManager.config.onCheck(nodeIds.slice(min, max + 1));
  }

  public hover() {}

  // 画选中的checkbox
  private renderSelectedCheckbox() {
    const { Draw } = this.moduleInstances;
    const { bg, radius } = checkboxConfig;
    const { x, y, height, width } = this.position;

    Draw.attr({
      fillStyle: 'white',
      textBaseline: 'middle',
      textAlign: 'center',
      font: `${Draw.npx(14)}px iconfont`,
    });

    Draw.fillRoundRect(this.position, radius, bg);
    Draw.fillText(Draw.iconFont('&#xe703;'), x + width / 2, y + height / 2);
  }

  // 判断分组是否全部选中
  public getIsGroupChecked() {
    const groupId = this.data.groupId;
    const { groupData, checkId } = this.moduleInstances.DataManager.config;
    const groupNodesIds = groupData[groupId as string].list.map((n) => n.id);
    let isGroupChecked = true;

    groupNodesIds.forEach((i) => {
      if (!checkId.includes(i)) isGroupChecked = false;
    });

    return isGroupChecked;
  }

  public render(flag: boolean = false) {
    const { Draw, DataManager, Render } = this.moduleInstances;
    if (DataManager.config.viewInfo.hideTableCheckbox)return

    const { radius, bg } = checkboxConfig;
    const { x, y, height, width } = this.position;
    let { borderColor } = checkboxConfig;
    const styleMode = Draw.config.styleMode;
    if (styleMode === 'darkness') borderColor = 'rgba(214,224,255,0.3)';

    const { type, node } = this.data;
    const {
      config: { checkId },
    } = Draw;

    if (this.isHover) {
      Render.setCursor('pointer');
    }

    if (type === 'nodeCheckbox') {
      // 节点checkbox
      const id = node?.node.id;
      if (id && checkId.includes(id)) {
        this.renderSelectedCheckbox();
        return;
      }
    }

    if (type === 'groupCheckbox') {
      if (this.getIsGroupChecked()) return this.renderSelectedCheckbox();
    }

    if (
      type === 'headerCheckbox' &&
      checkId.length === DataManager.nodeSum &&
      DataManager.nodeSum !== 0
    ) {
      // header checkbox
      this.renderSelectedCheckbox();
      return;
    }

    if (flag) {
      Draw.strokeRoundRect(this.position, radius, 1, this.isHover ? bg : borderColor);
      if (styleMode !== 'darkness')
        Draw.fillRoundRect(
          { x: x + 1, y: y + 1, height: height - 2, width: width - 2 },
          radius,
          '#fff',
        );
    }
  }
}

export default CheckboxCell;
