import type { GetterPiNode } from '@linkpi/core/web';
import { usePiNode } from '@linkpi/core/web';
import { CURRENT_NODE_ID_IN_SHAERED_STATE, useWidgetSharedState } from '@mylinkpi/widget-react';
import { Empty } from 'antd';
import cls from 'classnames';
import { type FC, useMemo } from 'react';
import { match } from 'ts-pattern';

import type { IWidgetInstanceData } from '@/components/PageModelEditor';
import { useCurrentUser, useOrgConnection } from '@/hook';
import NewNodeProp from '@/pages/home/components/NewNodeProp';

import styles from './styles.less';

export const ModelCustomProps: FC<{
  data: IWidgetInstanceData & { widgetId: 'CustomProps' };
  getterPiNode: GetterPiNode;
  isDrafts: boolean;
  draftParentId?: string;
}> = (props) => {
  const { data, getterPiNode, isDrafts, draftParentId } = props;
  const { config } = data;

  const currentUser = useCurrentUser();

  /**
   * 节点来自于 sharedState
   *
   * TODO 封装 hook
   * 如果其他组件也要添加该功能时，一定要封装
   */
  const [sharedState] = useWidgetSharedState();
  const sharedStateNodeId = sharedState[CURRENT_NODE_ID_IN_SHAERED_STATE] as string;
  const orgConnection = useOrgConnection();
  const [sharedStateGetterPiNode] = usePiNode(orgConnection, sharedStateNodeId);
  const node = useMemo(
    () =>
      match(config)
        .with({ nodeSource: 'sharedState' }, () => {
          const nodeId = sharedState[CURRENT_NODE_ID_IN_SHAERED_STATE];
          if (!nodeId) return null;

          return sharedStateGetterPiNode;
        })
        .otherwise(() => getterPiNode),
    [config, getterPiNode, sharedState, sharedStateGetterPiNode],
  );

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.componentName}>{config.name}</div>
      </div>
      <div className={cls(styles.content, 'hover-scroll-bar')} style={{ flex: 1, height: 0 }}>
        {node ? (
          <NewNodeProp
            node={node}
            isSimpleThemeTree={false}
            currentUser={currentUser}
            simpleThemeTreeWidth={0}
            setEditedProps={() => {}}
            mode="edit"
            isDrafts={isDrafts}
            draftParentId={draftParentId}
            nodePropConfig={config}
          />
        ) : (
          <Empty description="暂无数据" />
        )}
      </div>
    </div>
  );
};
