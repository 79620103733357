import { CheckCircleFilled, FullscreenOutlined } from '@ant-design/icons';
import type { CurrentUser } from '@linkpi/core';
import {
  checkNodePropVisibleByGroup,
  FUNCTION_SET,
  generateAddOpId,
  getPropVisibleMap,
  moveNode,
  propIsNull,
  SIDE_MODE,
} from '@linkpi/core';
import { usePiNode } from '@linkpi/core/web';
import { useMemoizedFn, useSafeState as useState, useToggle } from 'ahooks';
import type { ModalProps } from 'antd';
import { Button, Divider, message, Modal, Space, Spin, Tooltip, TreeSelect } from 'antd';
import cls from 'classnames';
import { defaultTo, values } from 'ramda';
import type { FC } from 'react';
import { lazy, Suspense, useEffect, useMemo, useRef } from 'react';
import { match, P } from 'ts-pattern';
import { useDispatch } from 'umi';

import { PiButton, StatusSwitchButton } from '@/components';
import { useCurrentUser, useCurrentUserInfo } from '@/hook';
import { useOrgConnection, useOrgInfo } from '@/hook';
import { useOrgTempMap } from '@/hook/useTemplate';
import { showNodeImportModal } from '@/pages/home/components/NodeImport';
import { defaultButtonSetting } from '@/pages/space/components/FunctionSet/DraftModalButtonSetting';
import request from '@/utils/request';

import { RegularIcon } from '../../IconFont';

import './index.less';

const TabContentWrapper = lazy(() =>
  import('@/pages/home/Workbench/NodePageModel').then((res) => ({
    default: res.TabContentWrapper,
  })),
);
const TabContent = lazy(() => import('@/pages/home/components/TabContent'));

interface TreeNode {
  value: string;
  title: string;
  children: TreeNode[];
}

export type DraftsModalProps = {
  orgId: string;
  initParentId: string;
  initSiblingId?: string;
  visible: boolean;
  draftsNodeData: {
    org_id: string;
    parentId: string;
    siblingId: null | string;
    draft: true;
    node: {
      node_id: string;
      prop: PiNode['prop'];
      content?: string;
      title: string;
    };
  };
  mode: 'add' | 'edit';
  onCancel: () => void;
  onSuccess: (node: DraftsModalProps['draftsNodeData']) => void;
} & Pick<ModalProps, 'afterClose'>;

/**
 * 只缓存了挂在的值
 */
const DraftsModal: FC<DraftsModalProps> = (props) => {
  const {
    orgId,
    initParentId,
    visible,
    onCancel,
    onSuccess,
    draftsNodeData,
    mode = 'edit',
    initSiblingId = null,
    afterClose,
  } = props;
  const currentUser = useCurrentUser();
  const [loading, setLoading] = useState(false);
  const [parentId, setParentId] = useState(initParentId);
  const [data, setData] = useState<TreeNode[]>([]);
  const [editedProps, setEditedProps] = useState<number[]>([]);
  const orgConnection = useOrgConnection(orgId);
  const [orgInfo] = useOrgInfo(orgId);
  const dispatch = useDispatch();
  const templatesMap = useOrgTempMap(orgId);

  // 给 tabcontent 组件的ref，用来获取一些需要的东西
  const tabContentRef = useRef<any>({});

  // 因为现在存在 复制新增 保存新增 保证顺序不乱的话 需要创建后就修改
  const [nowSiblingId, setNowSiblingId] = useState(initSiblingId);
  // 连接pinode
  const [newNodeId, setNewNodeId] = useState<string | null>(null);
  const [getterNode] = usePiNode(orgConnection, newNodeId);
  const [rootNode] = usePiNode(orgConnection, orgConnection?.nodeManager._rootId);

  useEffect(() => {
    const getChildren = (root: PiNode) => {
      const children: TreeNode[] = [];
      if (Array.isArray(root.children) && root.children.length) {
        root.children.map((f) => {
          // if (f.nodeType !== 2) {
          children.push({
            value: f.key,
            title: f.title || '无标题',
            children: getChildren(f),
          });
          // }
        });
      }
      return children;
    };
    if (visible) {
      if (orgConnection) {
        setData([
          {
            value: orgConnection.nodeManager._rootId,
            title: '根目录',
            children: getChildren(orgConnection.nodeManager.getRoot()),
          },
        ]);
        if (!orgConnection.nodeManager.findChildren(parentId)) {
          setParentId(
            (orgConnection.nodeManager.getRoot().prop._sys_function & FUNCTION_SET.CLEARING) === 0
              ? orgConnection.nodeManager._rootId
              : orgConnection.nodeManager._toBeSortId,
          );
        }
      } else {
        getHasToBeSort();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgConnection, visible, orgId]);

  // 新增节点
  useEffect(() => {
    addDraftsNode(draftsNodeData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgConnection]);

  const addDraftsNode = async (_draftsNodeData: DraftsModalProps['draftsNodeData']) => {
    if (!orgConnection) return;
    if (!_draftsNodeData.node.content) {
      const template =
        orgConnection.nodeManager._template[_draftsNodeData.node.prop._sys_temp?.[0]];
      if (
        template?.temp_option?.contentTemplate?.open &&
        template.temp_option.contentTemplate.temp
      ) {
        _draftsNodeData.node.content = template.temp_option.contentTemplate.temp;
      }
    }

    const parentNode = orgConnection.nodeManager.getNode(_draftsNodeData.parentId);
    await parentNode.insertNode(
      0,
      { ..._draftsNodeData.node, id: _draftsNodeData.node.node_id },
      null,
      true,
    );

    setNewNodeId(_draftsNodeData.node.node_id);
  };

  const getHasToBeSort = async () => {
    if (orgConnection) {
      const newData = [
        {
          value: orgConnection.nodeManager._rootId,
          title: '根目录',
          children: [],
        },
      ];
      const res = await request<PiNodeMetaData[]>('/docapi/getNodeNew', {
        method: 'POST',
        data: {
          org_id: orgId,
          node_id: orgConnection.nodeManager._rootId,
        },
      });
      if (res.status === 'ok' && res.data.length) {
        if ((res.data[0].e._sys_function & FUNCTION_SET.CLEARING) !== 0 && orgInfo) {
          newData.push({
            value: orgInfo.toBeSortId,
            title: '待整理',
            children: [],
          });
        }
      }
      setData(newData);
      setParentId(
        newData.length === 1 || !orgInfo ? orgConnection.nodeManager._rootId : orgInfo.toBeSortId,
      );
    }
  };

  // 改成
  // 1. move 为真实节点
  // 2. 用这个节点数据 新增草稿节点
  const copyAdd = useMemoizedFn(async () => {
    if (!getterNode) return;
    if (!checkPropRequire(getterNode.value).sure) return message.error('必填属性不得为空');
    setLoading(true);

    // await delay(300);

    // move到真是节点
    await moveNode(request, {
      org_id: orgId,
      nodeId: getterNode?.value.id,
      parentId: parentId,
      siblingId: nowSiblingId,
    });

    // 复制新节点
    const newId = generateAddOpId();
    const newNode = await request('/docapi/node/insert', {
      method: 'POST',
      data: {
        draft: true,
        org_id: orgId,
        parentId,
        siblingId: nowSiblingId,
        copyId: [{ org_id: orgId, node_id: getterNode?.value.id, new_node_id: newId }],
      },
    });
    console.log({ newNode });
    setNowSiblingId(getterNode?.value.id);
    setNewNodeId(newId);
    message.success('主题新增成功');

    // @ts-ignore
    // let unique = (getterNode.value.template?.temp_option?.unique || []).flat();
    // if (unique.length) {
    //   unique = Array.from(new Set(unique));
    //   await updateProp(request, {
    //     org_id: orgId,
    //     node_id: getterNode.value.id,
    //     index: unique,
    //     temp_id: getterNode.value.tempInfo.id,
    //     value: unique.map(() => null),
    //   });
    // }

    setLoading(false);
    // setNowSiblingId(draftsNodeData.node.node_id);
  });

  const currentUserInfo = useCurrentUserInfo();

  // check 必填属性
  const checkPropRequire = (node: PiNode) => {
    // @ts-ignore
    const { tempInfo } = node;
    let sure = true;
    let prop: any = {};
    const template = templatesMap[node.tempInfo.id];

    const propVisibleMap = getPropVisibleMap(node, template!, currentUserInfo);

    (template?.prop || [])
      .map((p: CurrentUser.TemplateProp, propIndex) => {
        let hide = p.hide;

        const groupVisible = checkNodePropVisibleByGroup(p);
        if (groupVisible) {
          const propVisible = propVisibleMap[propIndex];
          if (typeof propVisible !== 'undefined') hide = !propVisible;
        }
        return {
          ...p,
          hide,
        };
      })
      .forEach((p: any, index: number) => {
        if (p.type && !p.hide && p.required) {
          if (propIsNull(tempInfo.prop[index])) {
            sure = false;
            prop = p;
          }
        }
      });
    return { sure, prop };
  };

  // 子表格行内新增 必填属性校验
  const checkChildTableAttrRequired = () => {
    if (tabContentRef.current.generalTableMap) {
      let res = '';
      const generalTableConfigs: any = Object.values(tabContentRef.current.generalTableMap);

      for (let i = 0; i < generalTableConfigs.length; i++) {
        const config = generalTableConfigs[i];
        const {
          nodes,
          tabConfig: { addType },
          tabName,
        } = config;

        if (addType !== 'inline') continue;
        if (res !== '') break;

        for (let j = 0; j < nodes.length; j++) {
          const node = nodes[j];
          const { prop, sure } = checkPropRequire(node);
          if (!sure) {
            res = `${tabName}子表格，${prop.name}属性，为必填项`;
            break;
          }
        }
      }
      return res;
    }
    return '';
  };

  const checkBeforeChangeStatus = useMemoizedFn(() => {
    if (!getterNode) return false;
    if (!checkPropRequire(getterNode.value).sure) {
      message.error('必填属性不得为空');
      return false;
    }
    if (tabContentRef.current.checkChildThemeRequired) {
      if (!tabContentRef.current.checkChildThemeRequired()) return false;
    }
    const checkChildTableAttrRequiredRes = checkChildTableAttrRequired();
    if (checkChildTableAttrRequiredRes !== '') {
      message.error(checkChildTableAttrRequiredRes);
      return false;
    }
    return true;
  });

  // and one 是否要清空
  const handleAddNode = useMemoizedFn(async (andOne: boolean = false) => {
    if (!getterNode) return;
    // 校验必填属性
    if (!checkPropRequire(getterNode.value).sure) return message.error('必填属性不得为空');
    // 子表格条数必填校验
    if (tabContentRef.current.checkChildThemeRequired) {
      if (!tabContentRef.current.checkChildThemeRequired()) return;
    }
    // 子表格行内新增 必填属性校验
    const checkChildTableAttrRequiredRes = checkChildTableAttrRequired();
    if (checkChildTableAttrRequiredRes !== '') return message.error(checkChildTableAttrRequiredRes);

    setLoading(true);
    if (getterNode.value.parent?.id !== parentId) {
      await moveNode(request, {
        org_id: orgId,
        nodeId: getterNode?.value.id,
        parentId: parentId,
        siblingId: nowSiblingId,
      });
    }
    setLoading(false);
    setNowSiblingId(getterNode.value.id);
    if (andOne) {
      const newId = generateAddOpId();
      const newDraftsNodeData = { ...draftsNodeData };
      newDraftsNodeData.node.node_id = newId;
      addDraftsNode(newDraftsNodeData);
    } else {
      onCancel?.();
      onSuccess?.();
    }
  });

  const printNode = useMemoizedFn(() => {
    if (window.location.host !== 'mylinkpi.com') {
      console.log(getterNode);
    }
  });

  const handleSaveToDraft = useMemoizedFn(() => {
    message.info({
      icon: <CheckCircleFilled style={{ color: '#52c41a' }} />,
      duration: 4,
      content: (
        <span>
          <span>已保存到草稿箱！</span>
          <span
            style={{ textDecoration: 'underline', cursor: 'pointer', color: '#316EF5' }}
            onClick={() => {
              dispatch({
                type: 'workspace/setCurrentSelection',
                payload: {
                  selectSpace: orgId,
                  selectFocus: orgConnection?.nodeManager._rootId,
                  selectNode: getterNode?.value.id,
                  selectViewId: 'content',
                  selectSideMode: SIDE_MODE.DRAFTSBOX,
                },
              });
            }}
          >
            跳转到该主题
          </span>
        </span>
      ),
    });
    onCancel?.();
  });

  const [fullScreen, { toggle: toggleFullScreen }] = useToggle();

  const showTabContent = useMemoizedFn(() => getterNode);

  const footer = useMemo(() => {
    const buttonSetting = defaultTo(defaultButtonSetting)(rootNode?.value.prop._sys_draft_button);
    return buttonSetting
      .filter((s) => s.visible)
      .map((setting) => {
        switch (setting.key) {
          case '1':
            return (
              <Button
                key={setting.key}
                type={setting.buttonType}
                disabled={!showTabContent()}
                onClick={() => {
                  // @ts-ignore
                  orgConnection?.nodeManager.delete(getterNode?.value.id, true);
                  onCancel();
                }}
              >
                取消
              </Button>
            );
          case '2':
            // 新增 不关弹窗 清空数据
            return (
              <Button
                key={setting.key}
                type={setting.buttonType}
                onClick={() => handleAddNode(true)}
                loading={loading}
              >
                保存并新增
              </Button>
            );
          case '3':
            // 新增 不关弹窗 不清空数据
            return (
              <Button
                key={setting.key}
                type={setting.buttonType}
                onClick={() => copyAdd()}
                loading={loading}
              >
                保存并复制新增
              </Button>
            );
          case '4':
            return (
              <Button
                key={setting.key}
                type={setting.buttonType}
                onClick={handleSaveToDraft}
                loading={loading}
              >
                暂存
              </Button>
            );
          case '5':
            return (
              getterNode?.value && (
                <StatusSwitchButton
                  key={setting.key}
                  type={setting.buttonType}
                  loading={loading}
                  orgInfo={orgInfo}
                  node={getterNode?.value}
                  onFinish={() => handleAddNode(false)}
                  checkBeforeChangeStatus={() => checkBeforeChangeStatus()}
                />
              )
            );
          case '6':
            return (
              <Button
                key={setting.key}
                type={setting.buttonType}
                onClick={() => handleAddNode(false)}
                loading={loading}
              >
                保存
              </Button>
            );
        }
      });
  }, [
    checkBeforeChangeStatus,
    copyAdd,
    getterNode?.value,
    handleAddNode,
    handleSaveToDraft,
    loading,
    onCancel,
    orgConnection?.nodeManager,
    orgInfo,
    rootNode?.value.prop._sys_draft_button,
    showTabContent,
  ]);

  /**
   * 模态窗口属性
   */
  const modalProps: ModalProps = useMemo(
    () => ({
      afterClose,
      onCancel: () => {
        if (loading) return;
        if (!showTabContent()) return;
        getterNode?.value.id && orgConnection?.nodeManager.delete(getterNode?.value.id, true);
        onCancel();
      },
      keyboard: false,
      visible,
      maskClosable: false,
      centered: true,
      height: fullScreen ? '100%' : undefined,
      width: fullScreen ? '100vw' : '80%',
      maskStyle: { backgroundColor: 'rgba(0, 0, 0, 0.2)' },
      closable: true,
      closeIcon: <i className="iconfont iconButton_cancel" />,
      wrapClassName: cls('drafts-node-modal', fullScreen && 'fullScreen'),
      style: { top: 'calc((100% - 720px) / 2 + 32px)', left: 32 },
      title: (
        <div>
          <div className="node-modal-title">
            <div className="node-modal-title-des">添加至</div>
            <div className="node-modal-title-main" onClick={printNode}>
              {orgInfo?.orgName}
            </div>
            <Divider
              type="vertical"
              style={{
                margin: '2px 0 0 12px',
                borderColor: '#C9D0D9',
              }}
            />
            <TreeSelect
              treeData={data}
              bordered={false}
              style={{ width: 214 }}
              suffixIcon={<i className={'iconfont iconzhankai1'} />}
              value={parentId}
              onChange={(e) => setParentId(e)}
              dropdownMatchSelectWidth={500}
              showSearch={true}
              filterTreeNode={(e, treeNode) =>
                typeof treeNode?.title === 'string' &&
                !!~treeNode.title.toLowerCase().indexOf(e.toLowerCase())
              }
              treeDefaultExpandedKeys={[parentId]}
            />

            <Tooltip title="最大化">
              <PiButton
                type="secondary"
                shape="circle"
                style={{ position: 'absolute', right: 94, top: 10 }}
                icon={
                  <FullscreenOutlined type="iconContent_Download" style={{ color: '#7b8aa3' }} />
                }
                onClick={toggleFullScreen}
              />
            </Tooltip>
            <Tooltip title="导入主题">
              <PiButton
                type="secondary"
                shape="circle"
                style={{ position: 'absolute', right: 54, top: 10 }}
                icon={<RegularIcon type="iconContent_Download" />}
                onClick={() => {
                  if (!getterNode?.value.id || !orgInfo) return;

                  showNodeImportModal({
                    parentId: getterNode.value.id,
                    node: getterNode,
                    templateList: values(templatesMap),
                    orgInfo,
                  });
                }}
              />
            </Tooltip>
          </div>
          <div style={{ marginTop: '5px', borderBottom: '1px solid #f0f0f0' }} />
        </div>
      ),
      footer: <Space size={24}>{footer}</Space>,
    }),
    [
      afterClose,
      data,
      footer,
      fullScreen,
      getterNode,
      loading,
      onCancel,
      orgConnection?.nodeManager,
      orgInfo,
      parentId,
      printNode,
      showTabContent,
      templatesMap,
      toggleFullScreen,
      visible,
    ],
  );

  return (
    <Modal {...modalProps}>
      {match(getterNode)
        .with({ value: { template: { custom_view: { contentType: 'pageModelConfig' } } } }, () => (
          <Suspense fallback={<Spin size="large" className="full-w" />}>
            <TabContentWrapper
              key={getterNode?.value.id || 'defaultId'}
              data={getterNode!}
              isDrafts
              draftParentId={parentId}
              mode={mode}
              linkRef={tabContentRef}
            />
          </Suspense>
        ))
        .with(P.not(P.nullish), () => (
          <Suspense fallback={<Spin size="large" className="full-w" />}>
            <TabContent
              data={getterNode}
              currentUser={currentUser}
              isDrafts
              draftParentId={parentId}
              mode={mode}
              setEditedProps={(p: number) =>
                !editedProps.includes(p) && setEditedProps([...editedProps, p])
              }
              linkRef={tabContentRef}
            />
          </Suspense>
        ))
        .otherwise(() => (
          <div className="spin-container">
            <Spin size="large" />
          </div>
        ))}
    </Modal>
  );
};

export default DraftsModal;
