/**
 * request 网络请求工具
 * 更详细的 api 文档: https://github.com/umijs/umi-request
 */
import { ErrorCodeMapKey } from '@linkpi/core';
import { message } from 'antd';
import { stringify } from 'querystring';
import { history } from 'umi';
import type { RequestMethod, ResponseError } from 'umi-request';
import { extend } from 'umi-request';
import { v4 as uuid } from 'uuid';

import { getBuildNumber } from '@/components/VersionInfo/utils';
import { ENV_CONFIG } from '@/consts';

import { parseOrgIdFromLocation } from './parseOrgIdFromLocation';
import sendWebError from './sendWebError';

const device_id = uuid();

/**
 * 异常处理程序
 */
const errorHandler = (error: ResponseError) => {
  const { response, request } = error;

  sendWebError({
    title: '请求响应异常',
    desc: `${request.url}  \n${request.options.body}`,
  });

  if (!response) {
    return { status: 'error' };
  }
  return response;
};
/**
 * 配置request请求时的默认参数
 */

const request: RequestMethod<
  | {
      status: 'error';
      message: string;
    }
  | {
      status?: 'ok';
      [k: string]: unknown;
    }
> = extend({
  errorHandler,
  // 默认错误处理
  credentials: 'omit',
});

// NOTE: global interceptors
request.interceptors.request.use((url, option) => {
  const ad = window.localStorage.getItem('ad');
  const token = window.localStorage.getItem('token');

  const orgId = parseOrgIdFromLocation(history.location);
  if (orgId) {
    option.headers = {
      ...option.headers,
      ['LJPORGID']: orgId,
    };
  }

  if (['post', 'put'].includes(option.method?.toLocaleLowerCase() as string)) {
    if (
      (url.indexOf('fcapp.run') >= 0 && option.data.props) ||
      (ENV_CONFIG.CLOUD_FN_URL && url === ENV_CONFIG.CLOUD_FN_URL)
    ) {
      option.data.props.device_id = device_id;
      option.data.props.ad = ad;
      option.data.props.token = token;
    } else {
      if (!option.data) {
        option.data = { device_id: device_id };
      } else if (typeof option.data === 'object' && !Array.isArray(option.data)) {
        option.data.device_id = device_id;
      }
      option.data.ad = ad;
      if (!option.data.token) {
        option.data.token = token;
      }
    }
  } else if (option.method?.toLocaleLowerCase() === 'get') {
    option.useCache = true;
    option.ttl = 3600000;
    console.log('use cache');
  }
  option.metadata = { startTime: Date.now() };
  return {
    url,
    option,
  };
});

// NOTE: global interceptors
request.interceptors.response.use(async (res, option) => {
  if (res.headers.get('content-type')?.includes('text/plain')) {
    return res.body as unknown as any;
  }
  const resData = await res.clone().json();
  // 100001 跳转到登录页
  // 并记录当前页面 redirect
  if ((resData.errCode && resData.errCode === 100001) || resData.currentAuthority === 'guest') {
    window.localStorage.removeItem('ad');
    window.localStorage.removeItem('token');

    if (window.location.pathname === '/user/login') {
      history.push(window.location.pathname + window.location.search);
      return;
    }

    const redirectString = stringify({
      redirect: window.location.href,
    });
    history.push('/user/login?' + redirectString);
    return;
  }

  if (resData.errCode && resData.errCode === ErrorCodeMapKey.Logical_ConditionMismatch) {
    if (resData.message) message.error('状态转换失败：' + resData.message);
    else message.error('状态转换失败');
  }

  if (res.status !== 200) {
    sendWebError({
      title: `请求返回状态异常：${res.status}`,
      desc: `${res.url}  \n${option.body}`,
    });
  }
  return res;
});

export type ResponseResult<T extends unknown = unknown> =
  | {
      status: 'error';
      message: string;
      err?: string;
    }
  | (unknown extends T
      ? {
          status?: 'ok';
          [k: string]: unknown;
        }
      : {
          status: 'ok';
          data: T;
        });

export default function WrappedRequest<
  T extends unknown = unknown,
  P extends ResponseResult<T> = ResponseResult<T>,
>(...args: Parameters<typeof request>) {
  return request<P>(...args);
}

const fnUrl = {
  prod: 'https://fc-http-g-fc-pyjmofcrlb.cn-hangzhou.fcapp.run',
  dev: 'https://fc-http-g-fc-qdpkoecdnb.cn-hangzhou.fcapp.run',
  iso1: 'https://lab.mylinkpi.com/api/mockFc',
  digitalFuture: 'https://fc-http-g-fc-lsosypxtea.cn-hangzhou.fcapp.run',
};

export const cloudFnRequest: typeof WrappedRequest = (...args) => {
  let url;

  const [, visible, type] = getBuildNumber();

  if (ENV_CONFIG.CLOUD_FN_URL) {
    url = ENV_CONFIG.CLOUD_FN_URL;
  } else if (type === 'iso1') {
    url = ENV_CONFIG.CLOUD_FN_URL_ISO1;
  } else if (visible) {
    url = ENV_CONFIG.CLOUD_FN_URL_DEV;
  } else {
    url = ENV_CONFIG.CLOUD_FN_URL_PROD;
  }
  if (window.location.host.includes('idealhangzhoucity.com')) {
    url = fnUrl.digitalFuture;
  }

  const options = {
    ...args[1],
    data: {
      func: args[0],
      props: args[1]?.data,
    },
  };

  return WrappedRequest(url, options);
};
