import { SwapRightOutlined } from '@ant-design/icons';
import { STATUS_ICON, THEME_STATUS } from '@linkpi/core';
import { propIsNull } from '@linkpi/core';
import {
  Button,
  DatePicker,
  InputNumber,
  message,
  Popover,
  Radio,
  Select,
  Switch,
  Tooltip,
} from 'antd';
import dayjs from 'dayjs';
import moment from 'moment';
import { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useSelector } from 'umi';

import LinkPiPop from '@/components/LinkPiPop';
import {
  getTemplateParticipant,
  getUserAvatar,
  validateUsers,
} from '@/pages/home/components/TempStatus';
import request from '@/utils/request';

import './index.less';
const { Option } = Select;
const RepeatTask = (props) => {
  const { node, orgId, visible, onOk, onCancel, onlyReturnData, visibleBtn, templateList } = props;
  const { spaceUserList } = useSelector((state) => state.workspace);
  const startHourRef = useRef(null);
  const startMinuteRef = useRef(null);
  const endHourRef = useRef(null);
  const endMinuteRef = useRef(null);

  const [repeatType, setRepeatType] = useState('day');
  const [repeatCountWeek, setRepeatCountWeek] = useState([]);
  const [countWeekValue, setCountWeekValue] = useState(null);
  const [daySelect, setDaySelect] = useState([]);
  const [monthSelect, setMonthSelect] = useState([]);
  const [edType, setEDType] = useState(0);
  const [beforehand, setBeforeHand] = useState(0);
  const [endTime, setEndTime] = useState(Date.now());
  const [startTime, setStartTime] = useState(Date.now());
  const [repeatOpen, setRepeatOpen] = useState(false);
  const [repeatTemplate, setRepeatTemplate] = useState(null);
  const [repeatStatusProp, setRepeatStatusProp] = useState([]);
  const [repeatStatus, setRepeatStatus] = useState(0);
  const [templatePopVisible, setTemplatePopVisible] = useState(false);
  const [statusPopVisible, setStatusPopVisible] = useState(false);
  const [ownerVisible, setOwnerVisible] = useState(false);

  const [rangeEnd, setRangeEnd] = useState({
    min: 59,
    hour: 23,
    day: '0',
  });
  const countWeekOptions = [
    {
      label: '第一周',
      value: 1,
    },
    {
      label: '第二周',
      value: 2,
    },
    {
      label: '第三周',
      value: 3,
    },
    {
      label: '第四周',
      value: 4,
    },
    {
      label: '第五周',
      value: 5,
    },
    {
      label: '最后一周',
      value: -1,
    },
  ];
  const countWeekDayOptions = [
    {
      label: '星期一',
      alias: '一',
      value: 1,
    },
    {
      label: '星期二',
      alias: '二',
      value: 2,
    },
    {
      label: '星期三',
      alias: '三',
      value: 3,
    },
    {
      label: '星期四',
      alias: '四',
      value: 4,
    },
    {
      label: '星期五',
      alias: '五',
      value: 5,
    },
    {
      label: '星期六',
      alias: '六',
      value: 6,
    },
    {
      label: '星期日',
      alias: '日',
      value: 7,
    },
  ];
  const repeatTypeOptions = [
    {
      label: '每天',
      value: 'day',
    },
    {
      label: '每周',
      value: 'week',
    },
    {
      label: '每月',
      value: 'month',
    },
    {
      label: '每年',
      value: 'year',
    },
  ];
  const durationOptions = {
    0: '当天',
    1: '第二天',
    2: '第三天',
    3: '第四天',
    4: '第五天',
    5: '第六天',
  };
  const [errItem, setErrorItem] = useState(null);
  const [startMinute, setStartMinute] = useState(dayjs().minute());
  const [startHour, setStartHour] = useState(dayjs().hour());

  useEffect(() => {
    if (visible) {
      setRepeatOpen(!!node.value?.prop._sys_periodic);
      let periodic = node.value?.prop._sys_periodic;
      if (!periodic) {
        let nodePeriodic = window.localStorage.getItem('nodePeriodic');
        if (nodePeriodic) {
          nodePeriodic = JSON.parse(nodePeriodic);
          if (nodePeriodic[node.value?.id]) periodic = nodePeriodic[node.value?.id];
        }
      }
      let curTemplate = node.value?.prop._sys_temp?.[0],
        curStatus = node.value?.prop._sys_task_status?.index,
        curStatusProp = [
          node.value?.prop._sys_task_status?.prop?.[0] || null,
          node.value?.prop._sys_task_status?.prop?.[1] || [],
          null,
          null,
        ];
      if (periodic) {
        setBeforeHand(periodic.beforehand / 60);
        setStartTime(periodic.startTime || Date.now());
        setEndTime(periodic.endTime || Date.now());
        setEDType(periodic.endTime ? 1 : 0);
        let type = 'day',
          select = [],
          mSelect = [],
          countWeek = [],
          weekValue = null,
          min = dayjs().minute(),
          hour = dayjs().hour(),
          re = {
            min: 59,
            hour: 23,
            day: '0',
          };
        if (Array.isArray(periodic.repeat)) {
          min = periodic.repeat[0].min;
          hour = periodic.repeat[0].hours;
          if (Array.isArray(periodic.repeat[0].countWeek) && periodic.repeat[0].countWeek.length) {
            countWeek = periodic.repeat[0].countWeek;
          }
          if (periodic.repeat[0].month) {
            type = 'year';
            mSelect = periodic.repeat[0].month;
            select = periodic.repeat[0].day || [];
            if (countWeek.length) weekValue = periodic.repeat[0].week;
          } else if (periodic.repeat[0].day) {
            type = 'month';
            select = periodic.repeat[0].day;
          } else if (periodic.repeat[0].week) {
            if (countWeek.length) {
              type = 'month';
              weekValue = periodic.repeat[0].week;
            } else {
              type = 'week';
              select = periodic.repeat[0].week;
            }
          }
        }
        if (typeof periodic.duration === 'number') {
          const duration = periodic.duration + hour * 60 + min;
          const h = Math.floor(duration / 60);
          const m = duration % 60;
          re['day'] = Math.floor(h / 24).toString();
          re['hour'] = h % 24;
          re['min'] = m;
        }
        setStartMinute(min);
        setStartHour(hour);
        setRepeatType(type);
        setDaySelect(select);
        setMonthSelect(mSelect);
        setRepeatCountWeek(countWeek);
        setCountWeekValue(weekValue);
        setRangeEnd(re);
        // 主题类型信息
        if (periodic._sys_temp) curTemplate = periodic._sys_temp[0];
        if (periodic._sys_task_status) {
          curStatus = periodic._sys_task_status.index;
          curStatusProp[0] = periodic._sys_task_status.prop[0];
          curStatusProp[1] = periodic._sys_task_status.prop[1];
        }
      } else {
        setBeforeHand(0);
        setStartTime(Date.now());
        setEndTime(Date.now());
        setEDType(0);
        setRepeatType('day');
        setDaySelect([]);
        setMonthSelect([]);
        setRepeatCountWeek([]);
        setCountWeekValue(null);
        setStartMinute(dayjs().minute());
        setStartHour(dayjs().hour());
        setRangeEnd({
          min: 59,
          hour: 23,
          day: '0',
        });
        setRepeatOpen(false);
      }
      setRepeatTemplate(curTemplate);
      setRepeatStatus(curStatus);
      setRepeatStatusProp(curStatusProp);
      setErrorItem(null);
    }
  }, [visible]);

  const currentTemplate = useMemo(() => {
    return templateList?.find((x) => x.template_id === repeatTemplate) || null;
  }, [templateList, repeatTemplate]);

  const currentStatus = useMemo(() => {
    return currentTemplate?.task_status?.[repeatStatus] || null;
  }, [currentTemplate, repeatStatus]);

  const statusOptions = useMemo(() => {
    const options = [];
    currentTemplate?.task_status?.forEach((item, i) => {
      if (!item || item.delete) return;
      options.push({
        sort: 'sort' in item ? item.sort : i,
        index: i,
        name: item.name,
        prop: item.prop,
        icon: item.icon,
      });
    });
    options.sort((b, a) => b.sort - a.sort);
    return options;
  }, [currentTemplate]);

  const onDaySelect = (day) => {
    const ds = ~daySelect.indexOf(day)
      ? [...daySelect.filter((x) => x !== day)]
      : [...daySelect, day];
    setDaySelect(ds);
    validForm({
      ds: ds,
    });
  };

  const onMonthSelect = (month) => {
    const ms = ~monthSelect.indexOf(month)
      ? [...monthSelect.filter((x) => x !== month)]
      : [...monthSelect, month];
    setMonthSelect(ms);
    validForm({
      ms: ms,
    });
  };

  const validForm = ({
    rt = repeatType,
    st = startTime,
    et = endTime,
    edt = edType,
    ds = daySelect,
    cw = repeatCountWeek,
    wv = countWeekValue,
    ms = monthSelect,
  }) => {
    const errMsg = {};
    switch (rt) {
      case 'month':
        if (ds.length === 0 && (!cw.length || !wv)) errMsg['repeatType'] = '请选择重复日期';
        break;
      case 'week':
        if (ds.length === 0) errMsg['repeatType'] = '请选择重复日期';
        break;
      case 'year':
        if (!ms.length) errMsg['repeatType'] = '请选择重复月份';
        break;
      default:
    }
    if (!startTime) {
      errMsg['startTime'] = '请选择重复开始时间';
    }
    if (edt === 1) {
      if (!et) {
        errMsg['endTime'] = '请选择截止时间';
      } else if (et <= startTime) {
        errMsg['endTime'] = '截止时间不得早于开始时间';
      }
    }
    setErrorItem(errMsg);
    return errMsg;
  };

  // 保存重复任务配置
  const sendRepeatTask = async () => {
    const errMsg = validForm({});
    let sysPeriodic = null;
    const statusProp = repeatStatusProp.map((p, i) => {
      if (currentStatus?.prop[i] && currentStatus.prop[i].display) return p;
      return null;
    });
    if (Object.keys(errMsg).length === 0) {
      // 存配置到本地
      const duration =
        (rangeEnd.day * 24 + rangeEnd.hour) * 60 + rangeEnd.min - startHour * 60 - startMinute;
      sysPeriodic = {
        _sys_temp: [
          repeatTemplate,
          repeatTemplate === node.value?.prop._sys_temp?.[0] ? node.value?.prop._sys_temp?.[1] : [],
        ],
        _sys_task_status: {
          index: repeatStatus,
          prop: statusProp,
        },
        startTime,
        duration: duration,
        endTime: edType ? endTime : null,
        beforehand: beforehand * 60,
        repeat: [
          {
            month: repeatType === 'year' ? monthSelect : null,
            day:
              (repeatType === 'month' || repeatType === 'year') &&
              daySelect.length &&
              !repeatCountWeek.length
                ? daySelect
                : null,
            week:
              repeatType === 'week' ? daySelect : repeatCountWeek.length ? countWeekValue : null,
            hours: startHour,
            min: startMinute,
            countWeek: repeatCountWeek,
          },
        ],
      };
    }
    if (!repeatOpen) {
      if (onlyReturnData) {
        onlyReturnData({});
        return;
      }
      if (sysPeriodic) {
        // 保存配置到本地
        let nodePeriodic = window.localStorage.getItem('nodePeriodic');
        if (nodePeriodic) {
          nodePeriodic = JSON.parse(nodePeriodic);
          nodePeriodic[node.value.id] = sysPeriodic;
        } else {
          nodePeriodic = {
            [node.value.id]: sysPeriodic,
          };
        }
        window.localStorage.setItem('nodePeriodic', JSON.stringify(nodePeriodic));
      }
      const response = await request('/docapi/removePeriodic', {
        method: 'POST',
        data: {
          node_id: node.value.id,
          org_id: orgId,
        },
      });
      onOk();
      if (response.status === 'ok') {
        message.success('设置成功');
      } else {
        message.error('设置失败');
      }
      return;
    }
    if (!sysPeriodic) return;
    if (onlyReturnData) {
      onlyReturnData(sysPeriodic);
      return;
    }
    const response = await request('/docapi/setPeriodic', {
      method: 'POST',
      data: {
        node_id: node.value?.id,
        org_id: orgId,
        _sys_periodic: sysPeriodic,
      },
    });

    onOk();
    if (response.status === 'ok') {
      message.success('设置成功');
    } else {
      message.error('设置失败');
    }
  };

  const repeatMonthTypeChange = (type) => {
    if (type === 0) {
      setRepeatCountWeek([]);
      setDaySelect([]);
      setCountWeekValue(null);
      validForm({
        cw: [],
        ds: [],
        wv: null,
      });
    } else {
      setRepeatCountWeek([1]);
      setDaySelect([]);
      setCountWeekValue([1]);
      validForm({
        cw: [1],
        ds: [],
        wv: [1],
      });
    }
  };

  const monthSelector = () => {
    const domes = [];
    for (let d = 1; d < 32; d++) {
      domes.push(
        <div
          key={d}
          className={`month-select ${~daySelect.indexOf(d) ? 'active' : ''} ${
            d % 7 === 0 ? 'weekend' : ''
          }`}
          onClick={() => !repeatCountWeek.length && onDaySelect(d)}
        >
          {d < 10 ? '0'.concat(d.toString()) : d}
        </div>,
      );
    }
    return domes;
  };

  const yearSelect = () => {
    const domes = [];
    for (let d = 1; d < 13; d++) {
      domes.push(
        <div
          key={d}
          className={`year-select ${~monthSelect.indexOf(d) ? 'active' : ''} ${
            d % 4 === 0 ? 'monthEnd' : ''
          }`}
          onClick={() => onMonthSelect(d)}
        >
          {d}月
        </div>,
      );
    }
    return domes;
  };

  const weekSelect = (
    <div className={'form-item'}>
      {countWeekDayOptions.map((x) => (
        <div
          key={x.value}
          className={`week-select ${~daySelect.indexOf(x.value) ? 'active' : ''}`}
          onClick={() => onDaySelect(x.value)}
        >
          {x.alias}
        </div>
      ))}
    </div>
  );

  const repeatTypeChange = (e) => {
    setRepeatType(e);
    setDaySelect([]);
    setRepeatCountWeek([]);
    setCountWeekValue(null);
    setMonthSelect([]);
    validForm({
      rt: e,
      ds: [],
      ms: [],
    });
  };

  const setStartRange = (hour, minute) => {
    if (hour !== startHour) {
      setStartHour(hour);
      const last = startHourRef.current._ps.lastScrollTop;
      const containerHeight = startHourRef.current._ps.containerHeight;
      const hourPosition = hour * 32;
      if (hourPosition > last + containerHeight - 16 || hourPosition < last + 16)
        startHourRef.current._container.scrollTop = hourPosition;
    }
    if (minute !== startMinute) {
      setStartMinute(minute);
      const last = startMinuteRef.current._ps.lastScrollTop;
      const containerHeight = startMinuteRef.current._ps.containerHeight;
      const minutePosition = minute * 32;
      if (minutePosition > last + containerHeight - 16 || minutePosition < last + 16)
        startMinuteRef.current._container.scrollTop = minutePosition;
    }
  };

  const setEndRange = (hour, minute, day) => {
    const re = {
      hour,
      min: minute,
      day,
    };
    if (hour !== rangeEnd.hour) {
      const last = endHourRef.current._ps.lastScrollTop;
      const containerHeight = endHourRef.current._ps.containerHeight;
      const hourPosition = hour * 32;
      if (hourPosition > last + containerHeight - 16 || hourPosition < last + 16)
        endHourRef.current._container.scrollTop = hourPosition;
    }
    if (minute !== rangeEnd.min) {
      const last = endMinuteRef.current._ps.lastScrollTop;
      const containerHeight = endMinuteRef.current._ps.containerHeight;
      const minutePosition = minute * 32;
      if (minutePosition > last + containerHeight - 16 || minutePosition < last + 16)
        endMinuteRef.current._container.scrollTop = minutePosition;
    }
    setRangeEnd(re);
  };

  const endPop = (endLabel) => {
    const hourDomes = [],
      minDomes = [];
    for (let i = 0; i < 24; i++) {
      const disabled =
        rangeEnd.day === '0' && (i < startHour || (rangeEnd.min <= startMinute && i === startHour));
      hourDomes.push(
        <div
          key={i}
          className={`number-item ${rangeEnd.hour === i ? 'active' : ''} ${
            disabled ? 'disabled' : ''
          }`}
          onClick={() => !disabled && setEndRange(i, rangeEnd.min, rangeEnd.day)}
        >
          {i < 10 ? '0'.concat(i.toString()) : i}
        </div>,
      );
    }
    for (let i = 0; i < 60; i++) {
      const disabled = rangeEnd.day === '0' && rangeEnd.hour === startHour && i <= startMinute;
      minDomes.push(
        <div
          key={i}
          className={`number-item ${rangeEnd.min === i ? 'active' : ''} ${
            disabled ? 'disabled' : ''
          }`}
          onClick={() => !disabled && setEndRange(rangeEnd.hour, i, rangeEnd.day)}
        >
          {i < 10 ? '0'.concat(i.toString()) : i}
        </div>,
      );
    }
    return (
      <div className={'date'}>
        <div className={'date-title'}>{endLabel}</div>
        <div className="date-main">
          <div className={'date-main-item day'}>
            {Object.keys(durationOptions).map((d) => (
              <div
                key={d}
                className={`number-item ${rangeEnd.day === d ? 'active' : ''}`}
                onClick={() => setEndRange(rangeEnd.hour, rangeEnd.min, d)}
              >
                {durationOptions[d]}
              </div>
            ))}
          </div>
          <PerfectScrollbar options={{ suppressScrollX: true }} ref={endHourRef}>
            <div className={'date-main-item'}>{hourDomes}</div>
          </PerfectScrollbar>
          <PerfectScrollbar options={{ suppressScrollX: true }} ref={endMinuteRef}>
            <div className={'date-main-item'}>{minDomes}</div>
          </PerfectScrollbar>
        </div>
        <div className={'date-bottom'} onClick={() => setEndRange(23, 59, '0')}>
          当天结束
        </div>
      </div>
    );
  };

  const startPop = (startLabel) => {
    const hourDomes = [],
      minDomes = [];
    for (let i = 0; i < 24; i++) {
      const disabled =
        rangeEnd.day === '0' &&
        (i > rangeEnd.hour || (startMinute >= rangeEnd.min && i === rangeEnd.hour));
      hourDomes.push(
        <div
          key={i}
          className={`number-item ${startHour === i ? 'active' : ''} ${disabled ? 'disabled' : ''}`}
          onClick={() => !disabled && setStartRange(i, startMinute)}
        >
          {i < 10 ? '0'.concat(i.toString()) : i}
        </div>,
      );
    }
    for (let i = 0; i < 60; i++) {
      const disabled = rangeEnd.day === '0' && rangeEnd.hour === startHour && i >= rangeEnd.min;
      minDomes.push(
        <div
          key={i}
          className={`number-item ${startMinute === i ? 'active' : ''} ${
            disabled ? 'disabled' : ''
          }`}
          onClick={() => !disabled && setStartRange(startHour, i)}
        >
          {i < 10 ? '0'.concat(i.toString()) : i}
        </div>,
      );
    }
    return (
      <div className={'date'}>
        <div className={'date-title'}>{startLabel}</div>
        <div className="date-main">
          <PerfectScrollbar options={{ suppressScrollX: true }} ref={startHourRef}>
            <div className={'date-main-item'}>{hourDomes}</div>
          </PerfectScrollbar>
          <PerfectScrollbar options={{ suppressScrollX: true }} ref={startMinuteRef}>
            <div className={'date-main-item'}>{minDomes}</div>
          </PerfectScrollbar>
        </div>
        <div
          className={'date-bottom'}
          onClick={() => setStartRange(dayjs().hour(), dayjs().minute())}
        >
          此刻
        </div>
      </div>
    );
  };

  const rangePicker = () => {
    const startLabel =
      (startHour < 10 ? '0' + startHour : startHour) +
      ':' +
      (startMinute < 10 ? '0' + startMinute : startMinute);
    const endLabel =
      durationOptions[rangeEnd.day] +
      ' ' +
      (rangeEnd.hour < 10 ? '0' + rangeEnd.hour : rangeEnd.hour) +
      ':' +
      (rangeEnd.min < 10 ? '0' + rangeEnd.min : rangeEnd.min);
    return repeatOpen ? (
      <div className={'form-item-main'}>
        <div className={'range'}>
          <Popover
            overlayClassName={'repeat-range-pop'}
            content={startPop(startLabel)}
            onVisibleChange={(e) => {
              if (e) {
                setTimeout(() => {
                  if (startHourRef.current) {
                    startHourRef.current._container.scrollTop = startHour * 32;
                  }
                  if (startMinuteRef.current) {
                    startMinuteRef.current._container.scrollTop = startMinute * 32;
                  }
                }, 500);
              }
            }}
          >
            <div className={'range-start'}>{startLabel}</div>
          </Popover>
          <SwapRightOutlined />
          <Popover
            overlayClassName={'repeat-range-pop'}
            content={endPop(endLabel)}
            onVisibleChange={(e) => {
              if (e) {
                setTimeout(() => {
                  if (endHourRef.current) {
                    endHourRef.current._container.scrollTop = rangeEnd.hour * 32;
                  }
                  if (endMinuteRef.current) {
                    endMinuteRef.current._container.scrollTop = rangeEnd.min * 32;
                  }
                }, 500);
              }
            }}
          >
            <div className={'range-end'}>{endLabel}</div>
          </Popover>
        </div>
        <i className={'iconfont iconTime'} />
      </div>
    ) : (
      <div className={'form-item-main disabled'}>
        <div className={'range'}>
          <div className={'range-start'}>{startLabel}</div>
          <SwapRightOutlined />
          <div className={'range-end'}>{endLabel}</div>
        </div>
        <i className={'iconfont iconTime'} />
      </div>
    );
  };
  // 重新设置模板
  const resetTemplate = (template) => {
    setRepeatTemplate(template.template_id);
    setTemplatePopVisible(false);
    if (
      Array.isArray(template.task_status) &&
      template.task_status.filter((s) => s && !s.delete).length
    ) {
      const states = template.task_status.map((s, i) => {
        if (s && !s.delete) {
          const newStatus = JSON.parse(JSON.stringify(s));
          newStatus.index = i;
          newStatus.sort = 'sort' in s ? s.sort : i;
          return newStatus;
        }
        return null;
      });
      setRepeatStatus(states.filter((s) => !!s).sort((b, a) => b.sort - a.sort)[0].index);
    } else {
      setRepeatStatus(0);
    }
    // setRepeatStatusProp([null, [], null, null]);
  };

  const resetStatus = (index) => {
    setRepeatStatus(index);
    setStatusPopVisible(false);
    // setRepeatStatusProp([null, [], null, null]);
  };

  const templateSelector = useMemo(() => {
    if (!repeatOpen)
      return (
        <div className="repeat-template disabled">
          <div className={'text-omit'}>
            <i className={'iconfont icontopic_type'} />
            {currentTemplate?.name || '未知主题类型'}
          </div>
        </div>
      );
    return (
      <LinkPiPop
        trigger={'click'}
        visible={templatePopVisible}
        onVisibleChange={(e) => setTemplatePopVisible(e)}
        menuWidth={200}
        insideDom={
          <div className="repeat-template">
            <div className={'text-omit'}>
              <i className={'iconfont icontopic_type'} />
              {currentTemplate?.name || '未知主题类型'}
            </div>
            <i className={'iconfont iconattribute_fold'} />
          </div>
        }
        placement={'bottomRight'}
        menus={[
          {
            isSubtitle: true,
            label: '主题类型',
          },
          ...(templateList
            ?.filter((t) => t.status === THEME_STATUS.init || t.status === THEME_STATUS.lock)
            ?.map((template) => {
              if (template.template_id === repeatTemplate) {
                return {
                  label: template.name,
                  active: true,
                  labelAddon: <i className="iconfont iconselected" />,
                };
              }
              return {
                label: template.name,
                handler: () => resetTemplate(template),
              };
            }) || []),
        ]}
        menuHeight={220}
        noPadding={true}
      />
    );
  }, [templateList, currentTemplate, templatePopVisible, repeatOpen]);

  const genInputItem = (statusIndex) => {
    const handleEditConfirm = (value, index) => {
      const newProp = [...repeatStatusProp];
      newProp[index] = value;
      setRepeatStatusProp(newProp);
    };
    if (currentStatus) {
      const prop = currentStatus.prop[statusIndex];
      if (prop?.type) {
        let inputDom;
        let option = {},
          domId = 'task' + statusIndex;
        if (prop.multiple) {
          option = { mode: 'multiple' };
        }
        let defaultValue = repeatStatusProp[statusIndex] || null;
        let userSelect = Object.values(spaceUserList[orgId]);
        if (Array.isArray(prop.extend) && prop.extend.length && !~prop.extend.indexOf('-1')) {
          userSelect = userSelect?.filter(
            (user) =>
              Array.isArray(user.group_ids) &&
              !!user.group_ids.find((id) => ~prop.extend.indexOf(id)),
          );
        }
        if (!defaultValue) {
          defaultValue = prop.multiple ? [] : '';
        } else {
          defaultValue = validateUsers(defaultValue, prop, userSelect);
        }
        const userSelectMap = userSelect.reduce((res, cur) => {
          res[cur.nick_name] = cur;

          return res;
        }, {});
        inputDom = (
          <Select
            id={domId}
            placeholder={'请选择' + prop.name}
            className="edit-select"
            value={defaultValue}
            allowClear={!prop.required}
            {...option}
            showArrow={false}
            showSearch={true}
            open={true}
            onChange={(e) => handleEditConfirm(e, statusIndex)}
            filterOption={(input, option) => {
              const nickName = option?.children?.toLowerCase() || '';
              const u = userSelectMap[option.children] || {};
              const pin = u.pinyin || [];
              const allPin = (pin[0] || '').toLowerCase();
              const firstPin = (pin[1] || '').toLowerCase();

              const check1 = nickName.indexOf(input.toLowerCase()) >= 0;
              const check2 = allPin.indexOf(input.toLowerCase()) >= 0;
              const check3 = firstPin.indexOf(input.toLowerCase()) >= 0;

              // @ts-ignore
              return check1 || check2 || check3;
            }}
            getPopupContainer={(triggerNode) => triggerNode.parentNode}
          >
            {userSelect.map((x, index) => (
              <Option key={index} value={x.account_id}>
                {x.nick_name}
              </Option>
            ))}
          </Select>
        );
        return inputDom;
      }
    }
  };

  const statusForm = useMemo(() => {
    if (currentStatus) {
      const owner = propIsNull(repeatStatusProp[0])
        ? ''
        : spaceUserList[orgId]?.[repeatStatusProp[0]]?.nick_name || '未知成员';

      const users = propIsNull(repeatStatusProp[1])
        ? ''
        : Array.isArray(repeatStatusProp[1])
          ? repeatStatusProp[1]
              .map((x) => spaceUserList[orgId]?.[x]?.nick_name || '未知成员')
              .join('、')
          : spaceUserList[orgId]?.[repeatStatusProp[1]]?.nick_name || '未知成员';
      const ownerDom = (
        <div
          className={`status-info-item-owner ${
            currentStatus.prop[1]?.display ? '' : 'only-owner'
          } ${ownerVisible ? 'adding' : ''}`}
        >
          <div className={'status-info-item-top description'}>负责人</div>
          {propIsNull(repeatStatusProp[0]) ? (
            <i className={'iconfont iconparticipants_add_n'} />
          ) : (
            getUserAvatar(repeatStatusProp[0], orgId, spaceUserList, 24)
          )}
        </div>
      );
      const userDom = (
        <div className={'status-info-item-participant'}>
          <div className={'status-info-item-top description'}>参与者</div>
          {propIsNull(repeatStatusProp[1]) ? (
            <i className={'iconfont iconparticipants_add_n'} />
          ) : (
            <div className={'status-info-item-participant-avatar'}>
              {getTemplateParticipant(repeatStatusProp[1], orgId, spaceUserList, 24)}
              <i className={'iconfont iconparticipants_add_n'} />
            </div>
          )}
        </div>
      );
      return (
        <div className={`status-info${repeatOpen ? ' enabled' : ''}`}>
          {currentStatus.prop[0]?.display || currentStatus.prop[1]?.display ? (
            <div className="status-info-item avatar">
              {currentStatus.prop[0]?.display ? (
                repeatOpen ? (
                  <Popover
                    placement={'bottomLeft'}
                    trigger={'click'}
                    overlayClassName={'status-item-pop'}
                    overlayInnerStyle={{ width: 308 }}
                    content={genInputItem(0)}
                    visible={ownerVisible}
                    onVisibleChange={setOwnerVisible}
                  >
                    <Tooltip
                      title={'负责人：' + owner}
                      placement={'bottomLeft'}
                      overlayStyle={{ padding: 0, maxWidth: 320 }}
                    >
                      {ownerDom}
                    </Tooltip>
                  </Popover>
                ) : (
                  ownerDom
                )
              ) : null}
              {currentStatus.prop[1]?.display ? (
                repeatOpen ? (
                  <Popover
                    placement={'bottomLeft'}
                    trigger={'click'}
                    overlayClassName={'status-item-pop'}
                    overlayInnerStyle={{ width: 308 }}
                    content={genInputItem(1)}
                  >
                    <Tooltip
                      title={'参与者：' + users}
                      placement={'bottomLeft'}
                      overlayStyle={{ padding: 0, maxWidth: 320 }}
                    >
                      {userDom}
                    </Tooltip>
                  </Popover>
                ) : (
                  userDom
                )
              ) : null}
            </div>
          ) : (
            <div />
          )}
          <div className="status-info-item">
            <div className={'status-info-item-top description'}>状态</div>
            {repeatOpen ? (
              <LinkPiPop
                trigger={'click'}
                visible={statusPopVisible}
                onVisibleChange={(e) => setStatusPopVisible(e)}
                menuWidth={200}
                insideDom={
                  <i
                    className={`iconfont ${currentStatus.icon}`}
                    style={{ color: STATUS_ICON[currentStatus.icon].color, fontSize: 20 }}
                  />
                }
                placement={'bottomRight'}
                menus={[
                  {
                    isSubtitle: true,
                    label: '切换状态',
                  },
                  ...statusOptions.map((status, i) => {
                    const item = {
                      label: status.name,
                      icon: status.icon,
                      iconColor: STATUS_ICON[status.icon].color,
                    };
                    if (status.index === repeatStatus) {
                      item['active'] = true;
                      item['labelAddon'] = <i className="iconfont iconselected" />;
                    } else {
                      item['handler'] = () => resetStatus(status.index);
                    }
                    return item;
                  }),
                ]}
                menuHeight={220}
                noPadding={true}
              />
            ) : (
              <i
                className={`iconfont ${currentStatus.icon}`}
                style={{ color: STATUS_ICON[currentStatus.icon].color, fontSize: 20 }}
              />
            )}
          </div>
        </div>
      );
    }
    return null;
  }, [currentStatus, repeatStatusProp, ownerVisible, statusPopVisible, repeatOpen]);

  const errTips = useMemo(() => {
    if (statusOptions.length === 0) return '该主题类型没有配置状态，无法生成重复任务';
    if (!currentStatus) return '该状态不存在或已被删除，无法生成重复任务';
    if (!currentStatus.prop?.[2]?.display) return '该状态没有配置开始时间，无法生成重复任务';
    // if (!currentStatus.prop?.[0]?.display && !currentStatus.prop?.[1]?.display)
    //   return '该状态没有配置负责人或参与者，无法生成重复任务';
    return null;
  }, [statusOptions, currentStatus]);

  const content = () => {
    return (
      <div className={'repeat-form'}>
        <div className={'form-item header'}>
          <div className={'form-item-label'}>设置重复：</div>
          <Switch checked={repeatOpen} onChange={setRepeatOpen} />
          {templateSelector}
        </div>
        {statusForm}
        {errTips ? (
          <div className={`err-tips${currentStatus ? ' has-status' : ''}`}>
            <div>{errTips}</div>
            <div>请重新选择</div>
          </div>
        ) : (
          <Fragment>
            <div className={'form-item'}>
              <div className={'form-item-label'}>重复类型：</div>
              <Select
                disabled={!repeatOpen}
                className={errItem?.['repeatType'] ? 'err-item' : ''}
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
                onChange={repeatTypeChange}
                showSearch={true}
                options={repeatTypeOptions}
                value={repeatType}
              />
            </div>
            {repeatType === 'week' ? weekSelect : <></>}
            {repeatType === 'month' ? (
              <>
                <Radio onChange={() => repeatMonthTypeChange(0)} checked={!repeatCountWeek.length}>
                  日期
                </Radio>
                <div className={'form-item'}>{monthSelector()}</div>
                <Radio onChange={() => repeatMonthTypeChange(1)} checked={!!repeatCountWeek.length}>
                  在
                </Radio>
                <div className={'form-item'}>
                  <Select
                    style={{ width: 'calc(50% - 4px)', marginRight: 8 }}
                    onChange={(e) => setRepeatCountWeek([e])}
                    value={repeatCountWeek}
                    options={countWeekOptions}
                    disabled={!repeatCountWeek.length}
                  />
                  <Select
                    style={{ width: 'calc(50% - 4px)' }}
                    onChange={(e) => setCountWeekValue([e])}
                    value={countWeekValue}
                    options={countWeekDayOptions}
                    disabled={!repeatCountWeek.length}
                  />
                </div>
              </>
            ) : (
              <></>
            )}
            {repeatType === 'year' ? (
              <>
                <div className={'form-item'}>{yearSelect()}</div>
                <Radio onChange={() => repeatMonthTypeChange(0)} checked={!repeatCountWeek.length}>
                  日期
                </Radio>
                <div className={'form-item'}>{monthSelector()}</div>
                <Radio onChange={() => repeatMonthTypeChange(1)} checked={!!repeatCountWeek.length}>
                  在
                </Radio>
                <div className={'form-item'}>
                  <Select
                    style={{ width: 'calc(50% - 4px)', marginRight: 8 }}
                    onChange={(e) => setRepeatCountWeek([e])}
                    value={repeatCountWeek}
                    options={countWeekOptions}
                    disabled={!repeatCountWeek.length}
                  />
                  <Select
                    style={{ width: 'calc(50% - 4px)' }}
                    onChange={(e) => setCountWeekValue([e])}
                    value={countWeekValue}
                    options={countWeekDayOptions}
                    disabled={!repeatCountWeek.length}
                  />
                </div>
              </>
            ) : (
              <></>
            )}
            <div className={'form-item'}>
              <div className={'form-item-label'}>指定时间：</div>
              {rangePicker()}
            </div>
            <div className={'form-item'}>
              <div className={'form-item-label'}>开始日期：</div>
              <DatePicker
                disabled={!repeatOpen}
                className={`${errItem?.['startTime'] ? 'err-item' : ''}`}
                value={moment(startTime)}
                onChange={(e) => {
                  setStartTime(e.valueOf());
                  validForm({
                    st: e.valueOf(),
                  });
                }}
                getPopupContainer={(triggerNode) => {
                  return triggerNode.parentNode;
                }}
                format={'YYYY/MM/DD'}
                placeholder={'请选择开始日期'}
                allowClear={false}
              />
            </div>
            <div className={'form-item'}>
              <div className={'form-item-label'}>结束重复：</div>
              <Select
                disabled={!repeatOpen}
                options={[
                  {
                    label: '永不',
                    value: 0,
                  },
                  {
                    label: '于日期',
                    value: 1,
                  },
                ]}
                showSearch={true}
                value={edType}
                onChange={(e) => {
                  setEDType(e);
                  validForm({
                    edt: e,
                  });
                }}
                getPopupContainer={(triggerNode) => triggerNode.parentNode.parentNode}
              />
            </div>
            {edType === 1 ? (
              <div className={'form-item'}>
                <div className={'form-item-label'}>截止日期：</div>
                <DatePicker
                  disabled={!repeatOpen}
                  className={`effective-date ${errItem?.['endTime'] ? 'err-item' : ''}`}
                  value={moment(endTime)}
                  onChange={(e) => {
                    setEndTime(e.valueOf());
                    validForm({
                      et: e.valueOf(),
                    });
                  }}
                  getPopupContainer={(triggerNode) => {
                    return triggerNode.parentNode;
                  }}
                  placeholder={'请选择截止日期'}
                  allowClear={false}
                  format={'YYYY/MM/DD'}
                />
              </div>
            ) : (
              <></>
            )}
            <div className={'form-item'}>
              <div className={'form-item-label'}>提前多久生成任务：</div>
              <InputNumber
                disabled={!repeatOpen}
                min={0}
                max={24}
                value={beforehand}
                onChange={setBeforeHand}
              />
              小时
            </div>
          </Fragment>
        )}
        <div className={'form-btn'}>
          <Button type={'primary'} disabled={!!errTips} onClick={sendRepeatTask}>
            确定
          </Button>
        </div>
      </div>
    );
  };

  return content();
};

export default RepeatTask;
