/**
 * TL; DR
 *
 * 类型报错过多，原因如下：
 * - 原始文件是 js
 * - 经过多人修改，且缺乏重构，有大量不知所云的代码
 * - 统计视图业务复杂导致类型不可靠，且包含大量联合类型
 *
 * 将部分变量改为 any，减少噪音
 */

import type { ChartsInfo, CurrentUser, OrgUser, VerticalAxisObject, ViewList } from '@linkpi/core';
import { generateAddOpId, getQuoteOriginProp, getQuoteOriginPropInfo } from '@linkpi/core';
import type { GetterPiNode } from '@linkpi/core/web';
import { assertExists } from '@linkpi/utils';
import { useMemoizedFn, useSafeState as useState } from 'ahooks';
import type { TableColumnsType } from 'antd';
import { Table } from 'antd';
import dayjs from 'dayjs';
import Decimal from 'decimal.js';
import type { EChartOption } from 'echarts';
import { cloneDeep } from 'lodash';
import {
  compose,
  defaultTo,
  drop,
  flatten,
  head,
  isEmpty,
  isNil,
  keys,
  last,
  length,
  uniq,
  without,
} from 'ramda';
import type { FC } from 'react';
import React, { useEffect, useRef } from 'react';
import { match, P } from 'ts-pattern';

import { useOrgTempMap } from '@/hook';
import { fetchUserName } from '@/pages/home/components/TempStatus';
import request from '@/utils/request';
import { getRealPropInfo, hexToRgba, isDateProp, toFixed } from '@/utils/utils';

import {
  EASY_DEEP_COLOR,
  getBorderRadiusMap,
  getNewSeriesData,
  TEXT_COLOR,
  xAxisOption,
} from './echartsConfig';

import './index.less';

type ViewData = ViewList.ViewListItem<3>;
type OrgInfo = CurrentUser.OrgInfo;
type OrgUserItem = OrgUser.OrgUserItem;
type TemplateInfo = CurrentUser.TemplateInfo;
type VerticalAxis = ChartsInfo['verticalAxis'];

async function loadEcharts() {
  /**
   * PATCH:
   *
   * 修复 Echarts 类型错误
   */
  const _echarts = await import('echarts');
  const echarts: typeof _echarts.default = _echarts;
  return echarts;
}

const checkDateValue = (v: unknown) => {
  if (typeof v === 'number' && Number.isInteger(v)) return true;
  if (
    typeof v === 'string' &&
    Number.isInteger(Number(v)) &&
    // @example '2023'
    v.length > 6
  )
    return true;
  return false;
};

/**
 * 折线图专属，根据创建时间筛选
 */
const CONST_TIME_PROP = -2;

const color = ['#316EF5', '#6DC79E', '#FFC9C7', '#FFAF57', '#FD644A'];

const isDateType = (_axis: unknown[] | unknown) => {
  const axis = Array.isArray(_axis) ? _axis[0] : _axis;

  return [
    CONST_TIME_PROP,
    // 开始时间
    -5,
  ].includes(axis);
};

const INTERVAL_TO_DAYJS_FORMAT: Record<string, string> = {
  //'year' | 'mon' | 'hour'| 'day';
  year: 'YYYY',
  mon: 'YYYY/MM',
  day: 'YYYY/MM/DD',
  hour: 'YYYY/MM/DD HH:00',
};

const sortArr = (a: any, b: any, sorts: any, yArray: any): number => {
  const s = sorts[0];
  let aValue, bValue;
  if (s.index >= yArray.length) {
    aValue = Math.round((a.yAxis[Number(s.index % yArray.length) + 1] * 100) / a.yAxis[0]);
    bValue = Math.round((b.yAxis[Number(s.index % yArray.length) + 1] * 100) / b.yAxis[0]);
  } else {
    aValue = a.yAxis[Number(s.index % yArray.length) + 1];
    bValue = b.yAxis[Number(s.index % yArray.length) + 1];
  }
  sorts.shift();
  if (sorts.length && aValue === bValue) {
    return sortArr(a, b, sorts, yArray);
  } else {
    return s.ascend ? aValue - bValue : bValue - aValue;
  }
};

const Charts: FC<{
  curViewData: ViewData;
  showTable: boolean;
  showTools: boolean;
  showDataZoom: boolean;
  isDark: boolean;
  height: number;
  contentWidth: number;
  orgInfo: OrgInfo;
  userMap: Record<string, OrgUserItem>;
  node: GetterPiNode;
  handleViewSet: () => void;
}> = (props) => {
  const {
    orgInfo,
    curViewData,
    contentWidth,
    handleViewSet,
    userMap,
    isDark = false,
    showTable = true,
    showTools = true,
    showDataZoom = true,
    height = 600,
    node,
  } = props;

  const tempMap = useOrgTempMap(orgInfo.orgId);
  const [dataSource, setDataSource] = useState<any[]>([]);
  const [columns, setColumns] = useState<TableColumnsType>([]);
  const [tableLoading, setTableLoading] = useState(false);
  const [viewPercent, setViewPercent] = useState(false);

  const chartId = useRef('chartView_' + generateAddOpId());

  const [sortOrder, setSortOrder] = useState([]);
  const [sort, setSort] = useState([]);
  const [data, setData] = useState<{
    xArray: string[];
    yArray: string[];
    xyArray: number[][];
    // xyArray 中对应的节点
    nodeArray: string[][];
  }>({
    xArray: [],
    yArray: [],
    xyArray: [],
    // xyArray 中对应的节点
    nodeArray: [],
  });
  const [chartWidth, setChartWidth] = useState(contentWidth);
  /**
   * @type {[ChartsInfo , React.Dispatch<ChartsInfo>]}
   */
  const [chartsInfo, setChartsInfo] = useState<null | ChartsInfo>(null);
  /**
   * @type {React.Ref<TemplateInfo>}
   */
  const curTemplate = useRef<TemplateInfo>();
  const showOthers = useRef(false);

  // 初始化数据
  useEffect(() => {
    if (curViewData?.charts_info && Object.keys(userMap)?.length) {
      if (JSON.stringify(chartsInfo) === JSON.stringify(curViewData.charts_info)) return;
      setChartsInfo(curViewData.charts_info);
      if (curViewData.template_id) {
        curTemplate.current = orgInfo.templateList?.find(
          (x) => x.template_id.toUpperCase() === curViewData.template_id.toUpperCase(),
        );
        if (curTemplate.current && Object.keys(curViewData.charts_info).length) {
          initData(curViewData.charts_info);
          return;
        }
      }
    }
    setSortOrder([]);
    setChartsInfo(null);
    curTemplate.current = undefined;
    setData({
      xArray: [],
      yArray: [],
      xyArray: [],
      nodeArray: [],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [curViewData?.charts_info, userMap]);

  /**
   * @description 处理图表渲染数据
   */
  useEffect(() => {
    (async () => {
      if (isNil(chartsInfo)) return;
      if (chartsInfo?.type === 4 && !chartsInfo?.displayLineChart) return;
      let { xArray, yArray, xyArray } = data;

      if (!xArray.length || !yArray.length || !xyArray.length) return;
      if (sort.length) {
        const xSeries = xArray.map((x, i) => {
          return {
            xAxis: x,
            yAxis: xyArray[i],
          };
        });
        xSeries.sort((a, b) => {
          const sorts = [...sort];
          return sortArr(a, b, sorts, yArray);
        });
        xArray = xSeries.map((x) => x.xAxis);
        xyArray = xSeries.map((x) => x.yAxis);
      }

      const echarts = await loadEcharts();

      let myChart = echarts.getInstanceByDom(document.getElementById(chartId.current)!);
      const borderRadiusMap = getBorderRadiusMap(xyArray);
      assertExists(curTemplate.current);

      if (!myChart) {
        myChart = echarts.init(document.getElementById(chartId.current));
      } else {
        myChart.clear();
      }
      assertExists(myChart);
      myChart.showLoading();

      const series: EChartOption.Series[] = [];

      /**
       * @type {VerticalAxis}
       */
      const verticalAxis = chartsInfo?.verticalAxis;
      let stack = '';
      if (Array.isArray(verticalAxis)) {
        const key = verticalAxis?.[0]?.conditions?.[0].key;
        switch (key) {
          case 'SysTag':
            stack = '系统标签';
            break;

          default:
            break;
        }
      } else if (verticalAxis === -1) {
        stack = '状态';
      } else if (typeof verticalAxis === 'number') {
        stack = curTemplate.current.prop[chartsInfo.verticalAxis as number]?.name;
      }
      let zoomEnd = 100;
      if (chartsInfo.type !== 3 && chartsInfo.type !== 4 && xArray.length > 0) {
        zoomEnd = Math.round(((contentWidth / 30) * 100) / xArray.length);
      }
      const option: EChartOption = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: 'shadow', // 默认为直线，可选为：'line' | 'shadow'
          },
        },
      };
      if (showDataZoom) {
        option.dataZoom = [
          {
            type: 'slider',
            start: 0,
            end: zoomEnd,
          },
        ];
      }

      if (chartsInfo.type === 1) {
        //柱状图

        option.xAxis = [
          {
            ...xAxisOption,
            type: 'category',
            data: xArray,
          },
        ];
        option.yAxis = {
          type: 'value',
          splitLine: {
            show: false,
            lineStyle: {
              type: 'dashed',
              color: '#F6F6F6',
            },
          },
          axisLabel: {
            color: !isDark ? TEXT_COLOR.LIGHT.AXIS_TEXT : TEXT_COLOR.DARK.AXIS_TEXT,
          },
        };

        // option.series = [{}];
      } else if (chartsInfo.type === 2) {
        //条形图
        option.yAxis = {
          ...xAxisOption,
          type: 'category',
          data: xArray,
          splitLine: {
            show: false,
          },
        };
        option.xAxis = {
          type: 'value',
          splitLine: {
            show: false,
            lineStyle: {
              type: 'dashed',
              color: '#F6F6F6',
            },
          },
          axisLabel: {
            color: !isDark ? TEXT_COLOR.LIGHT.AXIS_TEXT : TEXT_COLOR.DARK.AXIS_TEXT,
          },
        };
        if (showDataZoom) {
          option.dataZoom = [
            {
              type: 'slider',
              yAxisIndex: 0,
              start: 0,
              end: zoomEnd,
            },
          ];
        }
      } else if (chartsInfo.type === 3 || chartsInfo.type === 4) {
        //折线图
        if (chartsInfo.horizontalAxis === CONST_TIME_PROP) {
          //时间类型
          option.xAxis = {
            ...xAxisOption,
            type: 'category',
          };
        } else {
          option.xAxis = [
            {
              type: 'category',
              boundaryGap: false,
              data: xArray,
              axisLine: { lineStyle: { color: '#F6F6F6' } },
              axisTick: { show: false },
              axisLabel: {
                color: !isDark ? TEXT_COLOR.LIGHT.AXIS_TEXT : TEXT_COLOR.DARK.AXIS_TEXT,
                overflow: 'truncate',
                width: 80,
              },
            },
          ];
        }
        option.yAxis = {
          type: 'value',
          axisLabel: {
            color: !isDark ? TEXT_COLOR.LIGHT.AXIS_TEXT : TEXT_COLOR.DARK.AXIS_TEXT,
          },
          splitLine: {
            show: false,
            lineStyle: {
              type: 'dashed',
              color: '#F6F6F6',
            },
          },
        };
      } else if (chartsInfo.type === 5) {
        option.legend = {
          top: '5%',
          left: 'center',
        };

        series.push({
          name: defaultTo('视图名')(curViewData.view_name),
          label: {
            formatter: '{b}: {c}\n{d}%',
            color: !isDark ? TEXT_COLOR.LIGHT.AXIS_TEXT : TEXT_COLOR.DARK.AXIS_TEXT,
          },
          type: 'pie',
          radius: '50%',
          data:
            xArray?.map((name, index) => ({
              name,
              value: xyArray[index]?.[1] || 0,
            })) || [],
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)',
            },
          },
          itemStyle: {
            normal: {
              color: function (params: any) {
                return new echarts.graphic.LinearGradient(1, 0, 0, 1, [
                  {
                    offset: 1,
                    color: EASY_DEEP_COLOR[params.dataIndex % 8], //colorOffset[i % colorOffset.length],
                  },
                  {
                    offset: 0,
                    color: hexToRgba(EASY_DEEP_COLOR[params.dataIndex % 8], 0.4).rgba,
                  },
                ]);
              },
            },
          },
        });
      }

      if (chartsInfo.viewPercent) {
        if (chartsInfo.type === 2) {
          (option.xAxis as EChartOption.XAxis).axisLabel = {
            interval: 'auto',
            formatter: '{value} %',
            color: !isDark ? TEXT_COLOR.LIGHT.AXIS_TEXT : TEXT_COLOR.DARK.AXIS_TEXT,
          };
          option.xAxis!.max = 100;
        } else {
          (option.yAxis as EChartOption.XAxis).axisLabel = {
            interval: 'auto',
            formatter: '{value} %',
            color: !isDark ? TEXT_COLOR.LIGHT.AXIS_TEXT : TEXT_COLOR.DARK.AXIS_TEXT,
          };
          option.yAxis!.max = 100;
        }
        option.tooltip!.formatter = function (params: any) {
          return `<div style="font-size:14px;margin-bottom: 10px;color:#666;font-weight:400;line-height:1;">${
            params[0].name
          }</div>
                  ${params
                    .map((param: any, i: number) => {
                      const newData =
                        typeof param.data === 'object' ? param.data.value : param.data;
                      const point = `<span style="display:inline-block;margin-right:4px;border-radius:10px;width:10px;height:10px;background-color: ${param.color}"></span>`;
                      const name = `<span style="font-size:14px;color:#666;font-weight:400;margin-left:2px">${param.seriesName}</span>`;
                      const value = `<span style="float:right;margin-left:20px;font-size:14px;color:#666;font-weight:900">${
                        xyArray[param.dataIndex][i + 1]
                      }（${
                        Array.isArray(newData) ? newData[newData.length - 1] : newData
                      }%）</span>`;
                      return point + name + value;
                    })
                    .join('<br/>')}`;
        };
      }

      if (chartsInfo.type !== 5) {
        yArray.forEach((x, i) => {
          const seriesData =
            chartsInfo.horizontalAxis === CONST_TIME_PROP
              ? xyArray.map((y, j) => [
                  xArray[j],
                  chartsInfo.viewPercent
                    ? y[0] === 0
                      ? 0
                      : Math.round((y[i + 1] * 100) / y[0])
                    : y[i + 1],
                ])
              : xyArray.map((y) => {
                  if (chartsInfo.viewPercent) {
                    return y[0] === 0 ? 0 : Math.round((y[i + 1] * 100) / y[0]);
                  }
                  return y[i + 1];
                });

          const s: EChartOption.SeriesBar | EChartOption.SeriesLine = {
            name: defaultTo('未知')(x),
            type: chartsInfo.type === 3 || chartsInfo.type === 4 ? 'line' : 'bar',
            // barMaxWidth: Math.round(contentWidth / 50),
            barWidth: 20,

            emphasis: {
              focus: 'series',
            },
            areaStyle: {
              // opacity: 0.2,
              color: new echarts.graphic.LinearGradient(0.3, 0.3, 0.3, 1, [
                {
                  offset: 1,
                  color:
                    showOthers.current && i === yArray.length - 1
                      ? 'rgba(209, 213, 219, 20)'
                      : 'rgba(255, 255, 255, 0)', //colorOffset[i % colorOffset.length],
                },
                {
                  offset: 0.5,
                  color:
                    showOthers.current && i === yArray.length - 1
                      ? '#D1D5DB'
                      : hexToRgba(EASY_DEEP_COLOR[i % 8], 0.1).rgba,
                },
                {
                  offset: 0,
                  color:
                    showOthers.current && i === yArray.length - 1
                      ? '#D1D5DB'
                      : hexToRgba(EASY_DEEP_COLOR[i % 8], 0.2).rgba,
                },
              ]),
            },
            showSymbol: 'auto',
            smooth: true,
            lineStyle: {
              width: 3,
              color: EASY_DEEP_COLOR[i % 8],
            },
            itemStyle: {
              color:
                showOthers.current && i === yArray.length - 1 ? '#D1D5DB' : EASY_DEEP_COLOR[i % 8],
            },
            data: getNewSeriesData(seriesData, chartsInfo.type, i, borderRadiusMap, echarts),
          };

          if (chartsInfo.type !== 3 && chartsInfo.type !== 4) {
            if (
              chartsInfo.type === 1 &&
              !isNil(chartsInfo.verticalTermsGroupMap) &&
              !isEmpty(chartsInfo.verticalTermsGroupMap)
            ) {
              const stackMap: Record<string, string> = {};
              keys(chartsInfo.verticalTermsGroupMap).forEach((groupName, index) => {
                chartsInfo.verticalTermsGroupMap[groupName].forEach((v) => {
                  stackMap[v as string] = [groupName, index].join('@');
                });
              });

              let stackMapKey = chartsInfo.verticalTerms[i];

              // 多级选值特殊处理
              if (
                typeof chartsInfo.verticalAxis === 'number' &&
                match(
                  getRealPropInfo(curTemplate.current!.prop[chartsInfo.verticalAxis], tempMap)
                    ?.type,
                )
                  .with('cascade', 'enum', 'tag', () => true)
                  .otherwise(() => false)
              ) {
                stackMapKey = s.name;
              }

              s.stack = stackMap[stackMapKey];
            } else s.stack = stack;
          }

          series.push(s);
        });
      }
      if (chartsInfo.type === 1) {
        // const formatter = defaultSeriesFormatTooltip(option);
      }

      // x 轴是时间类型的时候，默认向右显示
      if ([1, 2, 3, 4].includes(chartsInfo.type) && showDataZoom) {
        const xPropIndex = Array.isArray(chartsInfo.horizontalAxis)
          ? chartsInfo.horizontalAxis[0]
          : chartsInfo.horizontalAxis;
        if (typeof xPropIndex === 'number') {
          /**
           * @type {import('@linkpi/core').CurrentUser.TemplateProp}
           */
          const propConfig = curTemplate.current!.prop[xPropIndex];
          if (
            // 开始时间
            [-5].includes(xPropIndex) ||
            isDateProp(propConfig, tempMap)
          ) {
            option.dataZoom![0].start = 100 - zoomEnd;
            option.dataZoom![0].end = 100;
          }
        }
      }

      if (Array.isArray(yArray)) {
        if (yArray.length > 1 || head(yArray) !== '[未定义]') {
          option.legend = {
            data: yArray,
          };
        }

        if ((chartsInfo.type == 3 || chartsInfo.type == 4) && option.legend) {
          option.legend.icon = 'circle';
          option.legend.itemWidth = 12;
          option.legend.itemHeight = 12;
          option.legend.itemGap = 8;
        }
      }

      const finalOption = {
        color: color,
        title: {
          text: '',
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '10%',
          containLabel: true,
        },
        series,
        ...option,
      };

      if (finalOption.legend) {
        finalOption.legend.textStyle = {
          color: !isDark ? TEXT_COLOR.LIGHT.LEGEND_TEXT : TEXT_COLOR.DARK.LEGEND_TEXT,
        };
      } else {
        finalOption.legend = {
          textStyle: {
            color: !isDark ? TEXT_COLOR.LIGHT.LEGEND_TEXT : TEXT_COLOR.DARK.LEGEND_TEXT,
          },
        };
      }

      if (finalOption.legend) {
        finalOption.legend.itemWidth = 10;
        finalOption.legend.itemHeight = 10;
        // finalOption.legend.padding = [0, 0, 30, 0];
        finalOption.legend.left = 'left';
        if (option.legend) option.legend.icon = 'circle';
      }

      myChart.setOption(finalOption);
      myChart.hideLoading();
    })();
  }, [data, sort]);

  /**
   * @description 处理表格渲染数据
   */
  useEffect(() => {
    const { xArray, yArray, xyArray, nodeArray } = data;
    const chartInfo = curViewData.charts_info;

    const xAxisPropInfo = curTemplate.current?.prop[chartInfo.horizontalAxis as number];

    const isDate = (() => {
      if (!xAxisPropInfo) return false;
      return isDateProp(xAxisPropInfo, tempMap);
    })();

    const newColumns: TableColumnsType = [
      {
        title: '序号',
        dataIndex: 'index',
        key: 'index',
        width: 80,
        ellipsis: true,
        className: 'view-row-head',
        render: (t, record, i) => <span>{i + 1}</span>,
      },
      {
        title: xAxisPropInfo?.name || '图例项',
        dataIndex: 'charts_title',
        key: 'charts_title',
        width: 150,
        ellipsis: true,
        className: 'view-row-head',
        sorter: isDate
          ? {
              compare: (a: any, b: any) => {
                if (a.key === 'charts_summary') {
                  return false;
                } else if (b.key === 'charts_summary') {
                  return true;
                }
                return true;
              },
              multiple: sortOrder[0] ? yArray.length - sortOrder[0] : 0,
            }
          : undefined,
      },
    ];
    const yl = viewPercent && chartInfo.type !== 4 ? yArray.length * 2 : yArray.length;
    // 多维统计
    if (chartInfo.type === 4) {
      chartInfo.verticalAxis?.map((va, i) => {
        newColumns.push({
          title: va.percent ? va.name.concat('（%）') : va.name,
          dataIndex: va.name,
          key: i,
          width: 120,
          ellipsis: true,
          sorter: {
            compare: (a, b) => {
              if (a.key === 'charts_summary') {
                return false;
              } else if (b.key === 'charts_summary') {
                return true;
              }
              return a[va.name] - b[va.name];
            },
            multiple: sortOrder[i] ? yl - sortOrder[i] : 0,
          },
          render: (text, record) => {
            if (va.percent && record.key !== 'charts_summary') {
              return text + '%';
            } else {
              return text;
            }
          },
        });
      });
    } else {
      // 折线，柱状，条形统计
      yArray.forEach((x, i) => {
        newColumns.push({
          title: x,
          dataIndex: x,
          key: i,
          width: 120,
          ellipsis: true,
          sorter: {
            compare: (a: any, b: any): boolean | number => {
              if (a.key === 'charts_summary') {
                return false;
              } else if (b.key === 'charts_summary') {
                return true;
              }
              return a[x] - b[x];
            },
            multiple: sortOrder[i] ? yl - sortOrder[i] : 0,
          },
        });
        if (viewPercent) {
          newColumns.push({
            title: x.concat('（%）'),
            dataIndex: x.concat('（%）'),
            key: i + yArray.length,
            width: 120,
            ellipsis: true,
            sorter: {
              compare: (a: any, b: any): boolean | number => {
                if (a.key === 'charts_summary') {
                  return false;
                } else if (b.key === 'charts_summary') {
                  return true;
                }
                return a[x.concat('（%）')] - b[x.concat('（%）')];
              },
              multiple: sortOrder[i + yArray.length] ? yl - sortOrder[i + yArray.length] : 0,
            },
            render: (text) => {
              if (Number.isFinite(text)) {
                return text + '%';
              } else {
                return text;
              }
            },
          });
        }
      });
    }
    if (chartInfo.type !== 4 && chartInfo.yAxisStatisticsType?.type !== 'calculateAttr') {
      newColumns.push({
        title: '主题总数',
        dataIndex: 'charts_summary',
        key: 'charts_summary',
        width: 120,
        ellipsis: true,
      });
    }

    setColumns(newColumns);

    // 计算 x 轴普通数据
    const tableData = xArray.map((x, i) => {
      let chartsTitle =
        isDateType(chartInfo.horizontalAxis) && checkDateValue(x)
          ? dayjs(Number(x)).format('YYYY-MM-DD')
          : x;

      if (typeof chartInfo.horizontalAxis === 'number' && chartInfo.horizontalAxis >= 0) {
        const propConfig = curTemplate.current!.prop[chartInfo.horizontalAxis];
        if (isDateProp(propConfig, tempMap) && checkDateValue(x)) {
          chartsTitle = dayjs(Number(x)).format(
            INTERVAL_TO_DAYJS_FORMAT[chartInfo.horizInterval] || 'YYYY-MM-DD',
          );
        }
      }

      // 普通视图，需要根据节点数量统计总数
      const dataSummary =
        chartInfo.type === 4
          ? xyArray[i][0]
          : compose(length, uniq, flatten, drop(1))(nodeArray[i]);

      const data: any = {
        key: i,
        charts_title: chartsTitle,
        charts_summary: dataSummary,
      };
      if (chartInfo.type === 4) {
        (chartInfo.verticalAxis as VerticalAxisObject[])?.map((va, j) => {
          if (va.percent) {
            data[va.name] =
              xyArray[i][0] === 0 ? 0 : Math.round((xyArray[i][j + 1] * 100) / xyArray[i][0]);
          } else {
            data[va.name] = xyArray[i][j + 1] ?? 0;
          }
        });
      } else {
        yArray.map((y, j) => {
          data[y] = xyArray[i][j + 1];

          // 未定义的计数需要累加
          if (!nodeArray[i][j + 1] && data[y] > 0) {
            data.charts_summary = Decimal.add(data.charts_summary, data[y]).toNumber();
          }

          if (viewPercent) {
            data[y.concat('（%）')] =
              xyArray[i][0] === 0 ? 0 : Math.round((xyArray[i][j + 1] * 100) / xyArray[i][0]);
          }
        });
      }
      return data;
    });

    // 计算 x 轴合计
    const summary: any = { key: 'charts_summary', charts_title: '总计' };
    if (chartInfo.type === 4) {
      (chartInfo.verticalAxis as VerticalAxisObject[])?.map((va, j) => {
        let total = 0;
        xyArray.map((a) => {
          total = Decimal.add(total, a[j + 1] || 0).toNumber();
        });
        summary[va.name] = total;
      });
    } else {
      yArray.map((y, j) => {
        let total = 0;
        xyArray.map((a) => {
          total = Decimal.add(total, a[j + 1] || 0).toNumber();
        });
        summary[y] = total;
      });
    }

    const summaryTotal = tableData.reduce((r, i) => i.charts_summary + r, 0);
    summary.charts_summary = summaryTotal;

    if (
      chartInfo.horizontalAxis !== CONST_TIME_PROP &&
      chartInfo.yAxisStatisticsType?.type !== 'calculateAttr'
    ) {
      tableData.push(summary);
    }
    setDataSource(tableData);
  }, [data, sortOrder, curViewData]);

  /**
   * @description 获取图表数据
   */
  useEffect(() => {
    (async () => {
      setChartWidth(contentWidth);
      // setChartWidth(contentWidth > 800 ? contentWidth : 800);
      if (chartsInfo?.type !== 4) {
        const echarts = await loadEcharts();

        const dom = document.getElementById(chartId.current);
        if (!dom) return;
        const myChart = echarts.getInstanceByDom(dom);
        if (!myChart) return;

        myChart.resize({
          width: contentWidth,
          animation: {
            duration: 0.3,
            easing: true,
          },
        });
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentWidth, height]);

  const initData = useMemoizedFn(async (chartInfo: ChartsInfo) => {
    const echarts = await loadEcharts();

    /**
     * @type {ChartsInfo}
     */
    let myChart =
      chartInfo.type === 4
        ? null
        : echarts.getInstanceByDom(document.getElementById(chartId.current)!);
    if (!myChart) {
      myChart =
        chartInfo.type === 4 ? null : echarts.init(document.getElementById(chartId.current));
    } else {
      myChart.clear();
    }
    if (chartInfo.type !== 4 && !myChart) return;

    myChart?.showLoading();

    // init
    let xArray: string[] = [];
    let yArray: string[] = [];
    let xyArray = [];
    let nodeArray = [];

    setTableLoading(true);

    // target 是当前主题类型。 就是当前节点的属性index
    const tempProps = node.value.tempInfo.prop;
    const matchings = curViewData.charts_info.matchings || [];
    const conditionV2 = matchings.map((m) => {
      const { origin, target } = m;
      const propValue = tempProps[target];
      return {
        op: 'intersect',
        key: 'prop',
        input: Array.isArray(propValue) ? propValue : [propValue],
        index: origin,
      };
    });

    const res: any = await request('/docapi/getChartsViewData', {
      method: 'POST',
      data: {
        view_id: curViewData.view_id,
        org_id: orgInfo.orgId,
        charts_info: {
          ...curViewData.charts_info,
          conditionV2,
        },
      },
    });
    if (!chartInfo) return;

    if (chartInfo.type !== 4) myChart?.hideLoading();
    setTableLoading(false);

    if (res?.status === 'ok') {
      setViewPercent(!!chartInfo.viewPercent);
      assertExists(curTemplate.current);

      /**
       * NOTE
       * verticalTerms： 图例项
       *
       * 检查数据返回的图例项中，是否有多余的数据。
       *
       * 如有，进行修剪
       *
       * FIXME
       * TL; DR
       * 当前实现前端并未对接后端的图例项功能，
       * 接口会返回所有的 Y 轴结果，前端根据配置自行修剪，
       * 存在潜在的性能问题以及逻辑错误，后续酌情考虑对接后端的图例项功能
       */
      const needPruneDataByVerticalTerms = (() => {
        assertExists(curTemplate.current);
        /**
         * 没有配置图例项，不需要修剪
         */
        if (!Array.isArray(chartInfo.verticalTerms)) return false;

        /**
         * 返回结果大余图例项结果，需要修剪
         */
        if (
          chartInfo.verticalTerms.length > 0 &&
          chartInfo.verticalTerms.length < res.data.y.length
        )
          return true;

        /**
         * 保险的判断，检查是否存在多余的图例项
         */
        const redundantCount = chartInfo.verticalTerms
          .map((term) =>
            res.data.y?.findIndex((x) =>
              chartInfo.verticalAxis === -1
                ? x === curTemplate.current.task_status[term]?.name
                : x === term,
            ),
          )
          .filter((i) => i < 0).length;

        return redundantCount > 0;
      })();

      if (needPruneDataByVerticalTerms) {
        const termIndex = chartInfo.verticalTerms.map((term) =>
          res.data.y?.findIndex((x) =>
            chartInfo.verticalAxis === -1
              ? x === curTemplate.current!.task_status[term]?.name
              : x === term,
          ),
        );
        res.data.y =
          chartInfo.verticalAxis === -1
            ? chartInfo.verticalTerms.map((x) => curTemplate.current!.task_status[x]?.name || '')
            : cloneDeep(chartInfo.verticalTerms.filter(Boolean));

        if (chartInfo.others) {
          showOthers.current = true;
          res.data.y.push('其他');
        }

        // 修建 xy 对应的节点列表
        res.data.dn = res.data.dn.map((nodeIdsList) => {
          let others = uniq(flatten(nodeIdsList));
          const total = [...others];
          const res = [total];

          termIndex.forEach((index) => {
            if (~index) {
              res.push(nodeIdsList[index] || []);
              others = without(nodeIdsList[index] || [])(others);
            } else {
              res.push([]);
            }
          });
          if (chartInfo.others) {
            res.push(others);
          }
          return res;
        });

        // 修建 xy
        res.data.xy = res.data.xy.map((data: any, yIndex: number) => {
          return match(res.charts_info)
            .with(
              { yAxisStatisticsType: { statisticsType: P.union('statisticsAttr', 'node') } },
              () => {
                const result = [data[0]];
                termIndex.forEach((index: number) => {
                  if (~index) {
                    result.push(data[index + 1] || 0);
                  } else {
                    result.push(0);
                  }
                });
                if (chartInfo.others) {
                  result.push(last(res.data.dn[yIndex])?.length || 0);
                }
                return result;
              },
            )
            .otherwise(() => {
              let others = data[0];
              const result = [data[0]];
              termIndex.forEach((index) => {
                if (~index) {
                  result.push(data[index + 1] || 0);
                  others -= data[index + 1] || 0;
                } else {
                  result.push(0);
                }
              });
              if (chartInfo.others) {
                result.push(others);
              }
              return result;
            });
        });
      } else {
        // 修建 xy 对应的节点列表
        res.data.dn = res.data.dn.map((nodeIdsList) => {
          let others = uniq(flatten(nodeIdsList));
          const total = [...others];
          const result = [total];

          res.data.x.forEach((_: any, index: number) => {
            result.push(nodeIdsList[index] || []);
            others = without(nodeIdsList[index] || [])(others);
          });

          if (chartInfo.others) {
            result.push(others);
          }
          return result;
        });
      }
      if (isDateType(chartInfo.horizontalAxis)) {
        if (chartInfo.dateRange > 0) {
          //时间范围类型
          const slice = 0 - chartInfo.dateRange;
          res.data.x = res.data.x.slice(slice);
          res.data.xy = res.data.xy.slice(slice);
        }
        // 处理时间戳
        res.data.x = res.data.x.map((value: unknown) =>
          checkDateValue(value) ? dayjs(Number(value)).format('YYYY-MM-DD') : value,
        );
      }
      //时间属性
      if (chartInfo.horizontalAxis >= 0) {
        const propConfig = curTemplate.current.prop[chartInfo.horizontalAxis];
        if (isDateProp(propConfig, tempMap)) {
          res.data.x = res.data.x.map((value: unknown) =>
            checkDateValue(value)
              ? dayjs(Number(value)).format(
                  INTERVAL_TO_DAYJS_FORMAT[chartInfo.horizInterval] || 'YYYY-MM-DD',
                )
              : value,
          );
        }
      }

      xArray = res.data.x;
      yArray = res.data.y;
      nodeArray = res.data.dn;

      match(chartInfo)
        .with(
          {
            yAxisStatisticsType: {
              calculateProp: P.select(),
              statisticsType: 'calculateAttr',
            },
          },
          (propIndex) => {
            const propConfig = curTemplate.current!.prop[propIndex];

            const realPropInfo = getRealPropInfo(propConfig, tempMap);

            const precision = match(realPropInfo)
              .with({ numberExtend: P.select() }, (numberConfig) => numberConfig.precision)
              .with({ number: P.select() }, (numberConfig) => numberConfig.precision)
              .otherwise(() => undefined);

            xyArray = res.data.xy.map((x: number[]) => x.map((i) => toFixed(i, precision)));
          },
        )
        .otherwise(() => {
          xyArray = res.data.xy.map((x: number[]) => x.map((i) => toFixed(i)));
        });

      // 把有horizontalAxis，并且是user类型的； xArray的  account_id转成nick_name
      const xArrayUserIndex: number[] = [];
      if (Array.isArray(chartInfo.horizontalAxis)) {
        if (
          ~chartInfo.horizontalAxis.findIndex(
            // TODO: 判断引用
            (index) =>
              getRealPropInfo(curTemplate.current!.prop[index as number], tempMap)?.type === 'user',
          )
        ) {
          chartInfo.horizontalAxis.map((index, i) => {
            // TODO: 判断引用
            if (
              getRealPropInfo(curTemplate.current!.prop[index as number], tempMap)?.type === 'user'
            ) {
              xArrayUserIndex.push(i);
            }
          });
        }
      } else if (
        getRealPropInfo(curTemplate.current.prop[chartInfo.horizontalAxis as number], tempMap)
          ?.type === 'user'
      ) {
        xArrayUserIndex.push(0);
      }

      if (xArrayUserIndex.length) {
        xArray = xArray.map((x) => {
          const xItems = x.split('-');
          return xItems
            .map((item, i) => {
              if (item && item.length >= 32 && ~xArrayUserIndex.indexOf(i)) {
                return fetchUserNameWithSymbol(item, userMap);
              }
              return item;
            })
            .join('-');
        });
      }

      // verticalAxis为人员类型的index
      const yArrayType = yArray.map((x) => 'text');

      // 多维视图 yarray里的数据 可能是不同的类型
      if (Array.isArray(chartInfo.verticalAxis)) {
        const originTypes = chartInfo.verticalAxis.map(
          (x) => getQuoteOriginPropInfo(curTemplate.current!.prop[x], tempMap).type || 'text',
        );
        originTypes.map((type, i) => {
          let cascadeNodes = [];
          if (type === 'cascade') {
            const originProp = getQuoteOriginProp(
              curTemplate.current!.prop[chartInfo.verticalAxis[i] as number],
              tempMap,
            );
            cascadeNodes = originProp?.cascade?.nodesMap;
          }
          yArray[i] = getValueDisplay({
            propType: type,
            value: yArray[i],
            userMap,
            verticalGranularity: chartInfo.verticalGranularity,
            cascadeNodes,
          });
        });
      }
      // 其他
      else {
        const originType =
          getQuoteOriginPropInfo(curTemplate.current.prop[chartInfo.verticalAxis], tempMap).type ||
          'text';
        let cascadeNodes: any = null;
        if (originType === 'cascade') {
          const originProp = getQuoteOriginProp(
            curTemplate.current.prop[chartInfo.verticalAxis],
            tempMap,
          );
          cascadeNodes = originProp?.cascade?.nodesMap;
        }

        yArray = yArray.map((x) => {
          return getValueDisplay({
            propType: originType,
            value: x,
            userMap,
            verticalGranularity: chartInfo.verticalGranularity,
            cascadeNodes,
          });
        });
      }
    }

    setData({
      xArray,
      yArray,
      xyArray,
      nodeArray,
    });
  });

  const download = useMemoizedFn(async () => {
    if (tableLoading) return;
    const res = await request('/api/charts/export', {
      method: 'POST',
      data: {
        org_id: orgInfo.orgId,
        view_id: curViewData.view_id,
        headers: columns.map((column) => column.title),
        rows: dataSource.map((d, i) => {
          return columns.map((column, j) =>
            column.dataIndex === 'index'
              ? (i + 1).toString()
              : d[column.dataIndex]
                  ?.toString()
                  .concat(
                    d.key !== 'charts_summary' &&
                      Number.isFinite(column.key) &&
                      (column.key >= data.yArray.length ||
                        chartsInfo.verticalAxis[column.key]?.percent)
                      ? '%'
                      : '',
                  ),
          );
        }),
      },
    });
    if (res?.status === 'ok') {
      window.open(res.data);
    }
  });

  const tools = showTools ? (
    <div className="charts-export-button">
      <div className={`handles-set ${tableLoading ? 'disabled' : ''}`} onClick={download}>
        <i className="iconfont icondoachu" />
        导出统计表
      </div>
      <div className="handles-set" onClick={handleViewSet}>
        <i className="iconfont iconset" />
        图表设置
      </div>
    </div>
  ) : null;

  const tableScrollConfig = { x: chartWidth - 106, y: undefined };

  if (chartsInfo?.type === 4) {
    // 表头
    tableScrollConfig.y = height - 55 - 20;
  }

  const finalShowTable = chartsInfo?.type !== 5 && showTable;

  const table = finalShowTable ? (
    <div
      className={!isDark ? 'view-charts-table' : 'view-dark-charts-table'}
      style={{ paddingBottom: 8, paddingTop: 16 }}
    >
      <Table
        className={!isDark ? 'view-table' : 'view-dark-table'}
        dataSource={dataSource}
        columns={columns}
        loading={tableLoading}
        pagination={false}
        scroll={tableScrollConfig}
        rowClassName={'view-table-row'}
        onChange={(pagination, filters, sorter) => {
          if (!sorter) return;
          const order = [];
          if (Array.isArray(sorter)) {
            sorter.map((x, i) => {
              order.push({
                index: x.columnKey,
                order: i,
                ascend: x.order === 'ascend',
              });
            });
          } else if (sorter.order) {
            order.push({
              index: sorter.columnKey,
              order: 0,
              ascend: sorter.order === 'ascend',
            });
          }
          const newOrders = [],
            l = data.yArray.length;
          data.yArray.map((_) => {
            if (viewPercent) {
              newOrders.push.apply(newOrders, [l * 2, l * 2]);
            } else {
              newOrders.push(l);
            }
          });
          order.map((x) => {
            newOrders[Number(x.index)] = x.order + 1;
          });
          setSortOrder(newOrders);
          setSort(order);
        }}
      />
    </div>
  ) : null;

  return (
    <div
      style={{
        width: chartWidth,
        // ??? 要滚动表格？ overflowX: 'auto'
      }}
    >
      {tools}
      {chartsInfo?.type === 4 ? (
        !chartsInfo?.displayLineChart ? null : (
          <div
            id={chartId.current}
            style={{
              width: chartWidth - 24,
              height: height,
            }}
          />
        )
      ) : (
        <div
          id={chartId.current}
          style={{
            width: chartWidth - 24,
            height: height,
          }}
        />
      )}
      {table}
    </div>
  );
};

export default Charts;

const getValueDisplay = ({ propType, value, userMap, verticalGranularity, cascadeNodes }: any) => {
  if (value === 'SysTag') {
    return '系统标签';
  }
  if (propType === 'user') {
    const xItems = value.split('-');
    return xItems
      .map((item, i) => {
        if (item && item.length >= 32) {
          return fetchUserNameWithSymbol(item, userMap);
        }
        return item;
      })
      .join('-');
  }
  if (propType === 'date' || propType === 'datetime') {
    const intervalType =
      typeof verticalGranularity === 'string' ? verticalGranularity.split('-')[1] : 'day';
    // 转日期format
    const format = INTERVAL_TO_DAYJS_FORMAT[intervalType];
    return checkDateValue(value) ? dayjs(Number(value)).format(format) : value;
  }
  if (propType === 'cascade' && cascadeNodes) {
    if (Array.isArray(value)) return value.map((key) => cascadeNodes[key]?.title || '').join('/');

    return cascadeNodes[value]?.title || value;
  }

  return value;
};

// userId可能是 userId/userId/userId/...
// 获取用户的名称
// userId 也可能是 userId/userId/userId
export const fetchUserNameWithSymbol = (userId: string, userMap: Record<string, OrgUserItem>) => {
  if (!userMap) return '';

  const users = userId.split('/');
  return users.map((x) => fetchUserName(x, userMap)).join('/');
};
