import type { ApiResponse, CurrentUser } from '@linkpi/core';
import {
  DEFAULT_AVATAR,
  getDateBeforePresent,
  getMatchingOriginProp,
  getPropHandledValue,
  propIsNull,
  STATUS_ICON,
  tempValueDisplay,
} from '@linkpi/core';
import type { GetterPiNode } from '@linkpi/core/web';
import { assertExists } from '@linkpi/utils';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import { btnConfig } from '@/pages/space/components/PageFix/btnConfig';
import {
  elecwattConfig,
  getAuxiliaryPropValue,
  getCustomBtn,
  getQuoteOptionVisible,
  getQuoteOrMatchingInfo,
  hexToRgba,
  isCustomButtonForbiddenClick,
  isPropCanEdit,
  isPropSupportGraphicCode,
} from '@/utils/utils';

import CustomBtn from '../commonComponents/btn';
import ConditionMatchingAttr from '../commonComponents/conditionMatchingAttr';
import ExportGraphicCodeIcon from '../commonComponents/exportGraphicCodeIcon';
import OpenQuoteListIcon from '../commonComponents/openQuoteListIcon';
import QuoteItem from '../commonComponents/quoteItem';
import type { HeaderCellType } from '../modules/dataManager';
import type { PositionType } from './_baseComponent';
import GridBaseComponent from './_baseComponent';
import Attachment from './attachment';
import AttachmentUploadIcon from './attachmentUploadIcon';
import MainbodyAttachment from './mainbodyAttachment';
import MainbodyContent from './mainbodyContent';
import type NodeRow from './nodeRow';

dayjs.extend(utc);

class NodeCol extends GridBaseComponent {
  public type: string = 'nodeCol';
  public STYLE = {
    selectBg: '#f0f6ff',
    hoverBg: '#fafafa',
  };

  private colConfig: HeaderCellType;
  private nodeRow: NodeRow;
  private rowHover: boolean;

  constructor(
    position: PositionType,
    nodeRow: NodeRow,
    colConfig: HeaderCellType,
    rowHover: boolean,
  ) {
    super(position, nodeRow.moduleInstances);

    this.nodeRow = nodeRow;
    this.colConfig = colConfig;
    this.rowHover = rowHover;
  }
  public captrue: boolean = false;

  public click() {
    this.moduleInstances.ChildNodesLoader.config.setCellInfo(null);
    const { groupKey, index, node } = this.nodeRow.data;
    const { key, type } = this.colConfig;
    if (this.colConfig.type === 'index') return;
    // text number 点击直接编辑
    if (['text', 'number'].includes(type)) {
      const { DataManager, Render } = this.moduleInstances;
      DataManager.selectRow = `${groupKey}/${index}`;
      DataManager.selectNodeAttr = {
        groupKey,
        indexInGroup: index,
        headerKey: key,
        nodeId: node.node_id,
      };
      DataManager.isEdittingProp = true;
      DataManager.config.tableCellEdit({
        editNode: this.nodeRow.data.node,
        cellConfig: this.colConfig,
        position: this.position,
      });
      Render.render();
      return;
    }

    // 如果是蓝框，再次点击就开始进入编辑状态
    const { indexInGroup, headerKey } = this.moduleInstances.DataManager.selectNodeAttr;
    if (
      indexInGroup === index &&
      headerKey === key &&
      groupKey === this.moduleInstances.DataManager.selectNodeAttr.groupKey
    ) {
      this.moduleInstances.DataManager.isEdittingProp = true;
      this.moduleInstances.DataManager.config.tableCellEdit({
        editNode: this.nodeRow.data.node,
        cellConfig: this.colConfig,
        position: this.position,
      });
      return;
    }
    this.moduleInstances.DataManager.isEdittingProp = false;
    this.moduleInstances.DataManager.selectRow = `${groupKey}/${index}`;
    this.moduleInstances.DataManager.selectNodeAttr = {
      groupKey,
      indexInGroup: index,
      headerKey: key,
      nodeId: node.node_id,
    };
    this.moduleInstances.Render.render();
  }

  public drawNickname(name: string, x: number, y: number, gap?: number) {
    const { Draw } = this.moduleInstances;
    const { width } = this.position;

    const colorAndBold = this.getColorAndBold(this.colConfig.key);

    Draw.attr({
      fillStyle: colorAndBold.color,
      textBaseline: 'middle',
      textAlign: 'start',
      font: `${colorAndBold.bold ? 'bold ' : ''}${Draw.npx(14)}px  sans-serif`,
    });

    const g = gap || 55;
    const t = Draw.newFittingString(name, width - g);
    Draw.fillText(t, x, y);
  }

  public drawDateBeforePresent(
    str: string,
    x: number,
    y: number,
    color: string,
    restGap: number,
    textAlign?: string,
  ) {
    const { Draw } = this.moduleInstances;

    Draw.ctx.save();
    Draw.attr({
      fillStyle: color,
      textBaseline: 'middle',
      textAlign: textAlign || 'start',
      font: `${Draw.npx(14)}px  sans-serif`,
    });

    const t = Draw.newFittingString(str, restGap);
    Draw.fillText(t, x, y);
  }

  public drawStatusIcon(x: number, y: number, color: string, unicode: string) {
    const { Draw } = this.moduleInstances;
    const { ctx } = Draw;

    ctx.save();
    Draw.attr({
      fillStyle: color,
      textBaseline: 'middle',
      textAlign: 'start',
      font: `${Draw.npx(15)}px iconfont`,
    });

    if (unicode === '&#xe649;') unicode = '&#xe620;';
    Draw.fillText(Draw.iconFont(unicode), x, y);
    ctx.restore();
  }

  public drawAttrText(
    text: string,
    ellipsis: boolean,
    textAlign?: string,
    options?: {
      backgroundColor: string;
      color: string;
      offsetX: number;
    },
  ) {
    const { Draw } = this.moduleInstances;
    const { x: _x, y, height, width } = this.position;
    const padding = 10;
    const { backgroundColor, color, offsetX = 0 } = options ?? {};
    const x = _x + offsetX;
    const textY = y + height / 2;

    const colorAndBold = this.getColorAndBold(this.colConfig.key);

    Draw.attr({
      fillStyle: color ?? colorAndBold.color,
      textBaseline: 'middle',
      textAlign: 'start',
      font: `${colorAndBold.bold ? 'bold ' : ''}${Draw.npx(14)}px  sans-serif`,
    });

    if (textAlign === 'right') {
      Draw.ctx.textAlign = 'end';
      if (ellipsis) text = Draw.newFittingString(text, width - 2 * padding);

      const textWidth = Draw.measureTextWidth(text);
      if (backgroundColor) {
        Draw.fillRoundRect(
          {
            x: x + width - padding - 5,
            y: y + (height - 20) / 2,
            width: textWidth + 14,
            height: 20,
          },
          8,
          backgroundColor,
        );
      }

      Draw.fillText(text || '', x + width - padding, textY);
      return textWidth + 14;
    }

    if (textAlign === 'center') {
      if (ellipsis) text = Draw.newFittingString(text, width - 2 * padding);
      const textWidth = Draw.measureTextWidth(text);

      if (backgroundColor) {
        Draw.fillRoundRect(
          {
            x: x + (width - textWidth) / 2 - 5,
            y: y + (height - 20) / 2,
            width: textWidth + 14,
            height: 20,
          },
          8,
          backgroundColor,
        );
      }
      Draw.fillText(text || '', x + (width - textWidth) / 2, textY);
      return textWidth + 14;
    }

    if (ellipsis) {
      const t = Draw.newFittingString(text || '', width - 2 * padding);

      const textWidth = Draw.measureTextWidth(text);
      if (backgroundColor) {
        Draw.fillRoundRect(
          {
            x: x + padding - 5,
            y: y + (height - 20) / 2,
            width: textWidth + 14,
            height: 20,
          },
          8,
          backgroundColor,
        );
      }
      Draw.fillText(t, x + padding, textY);
      return textWidth + 14;
    }

    const textWidth = Draw.measureTextWidth(text);
    if (backgroundColor) {
      Draw.fillRoundRect(
        {
          x: x + padding - 5,
          y: y + (height - 20) / 2,
          width: textWidth + 14,
          height: 20,
        },
        8,
        backgroundColor,
      );
    }
    Draw.fillText(text || '', x + padding, textY);
    return textWidth + 14;
  }

  public drawImg(src: string, x: number, y: number) {
    const { Draw, ImageLoader } = this.moduleInstances;

    const img = ImageLoader.loadImage(src || DEFAULT_AVATAR, 'avatar');
    Draw.drawRoundImg(30, x, y, 30, 30, img);
  }

  public renderConditionMatchingAttr = (data: any) => {
    if ([null, undefined].includes(data.data)) return this.drawAttrText('', false, 'right');
    const { Draw } = this.moduleInstances;
    const { ctx } = Draw;
    const { x, y, width, height } = this.position;
    let textWidth = Draw.measureTextWidth(data.data);

    // 是否支持二维码
    const { isSupportGraphicCode, propIndex } = data;
    // 右边需要展示的icon的宽度也得计算
    if (data.type === 'conditionMatch_title') textWidth += 16;
    if (isSupportGraphicCode) textWidth += 16;

    const componentHeight = 14;
    const componentWidth = textWidth > width - 20 ? width - 20 : textWidth;

    if (data.type === 'conditionMatch_title') {
      // 写字
      const colorAndBold = this.getColorAndBold(this.colConfig.key);
      ctx.save();
      Draw.attr({
        fillStyle: colorAndBold.color,
        textBaseline: 'top',
        textAlign: data.contentAlign === 'right' ? 'end' : 'start',
        font: `${colorAndBold.bold ? 'bold ' : ''}${Draw.npx(14)}px  sans-serif`,
      });

      // 内容对齐
      let strStartX = x + 10;
      // 整体的宽度要减去icon的宽度来算文本宽度
      let strWidth = componentWidth - 16;
      if (isSupportGraphicCode) strWidth -= 16;

      const fittingStr = Draw.newFittingString(data.data, strWidth);
      let iconStartX = strStartX + Draw.measureTextWidth(fittingStr) + 2;
      let graphicCodeIconStartX = iconStartX + 16;

      if (textWidth < width - 20 && data.contentAlign === 'center') {
        strStartX = x + (width - 20 - textWidth) / 2;
        iconStartX = strStartX + Draw.measureTextWidth(fittingStr) + 2;
        graphicCodeIconStartX = iconStartX + 16;
      }

      if (textWidth < width - 20 && data.contentAlign === 'right') {
        graphicCodeIconStartX = x + width - 24;
        iconStartX = isSupportGraphicCode ? graphicCodeIconStartX - 2 : width + x - 10;
        strStartX = iconStartX - 16;
      }

      Draw.fillText(fittingStr, strStartX, y + (height - componentHeight) / 2);
      ctx.restore();

      const openQuoteListIcon = new OpenQuoteListIcon(
        {
          x: iconStartX,
          y: y + (height - componentHeight) / 2,
          height: 14,
          width: 14,
        },
        this.moduleInstances,
        { ...data, node: this.nodeRow.data.node },
      );
      openQuoteListIcon.render();

      if (isSupportGraphicCode) {
        const exportGraphicCodeIcon = new ExportGraphicCodeIcon(
          {
            x: graphicCodeIconStartX,
            y: y + (height - componentHeight) / 2,
            height: 14,
            width: 14,
          },
          this.moduleInstances,
          {
            node: this.nodeRow.data.node,
            prop: Draw.config.viewTemplate.prop[propIndex],
            text: data.data,
          },
        );
        exportGraphicCodeIcon.render();
      }
      return;
    }

    // 计数就不展示二维码了 没有意义
    const conditionMatchingAttr = new ConditionMatchingAttr(
      {
        x: x + 10, //x + width - componentWidth - 10,
        y: y + (height - componentHeight) / 2,
        height: componentHeight,
        width: componentWidth,
      },
      this.moduleInstances,
      { ...data, node: this.nodeRow.data.node },
    );
    conditionMatchingAttr.render();
  };

  public handleAttachment(srcAry: any[], type: string, contentAlign: string) {
    let { x, y, height, width } = this.position;
    const { Draw } = this.moduleInstances;
    let g = 10;

    const contentWidth = type === 'attachment' ? srcAry.length * 42 + 32 : srcAry.length * 42 - 10;

    if (contentAlign === 'center' && contentWidth < width - 20) {
      x = x + (width - contentWidth) / 2;
      g = 0;
    }

    if (contentAlign === 'right' && contentWidth < width - 20) {
      x = x + width - contentWidth - 10;
      g = 0;
    }

    for (let i = 0; i < srcAry.length; i++) {
      const attachment = new Attachment(
        {
          height: 32,
          width: 32,
          x: x + g,
          y: (height - 32) / 2 + y,
        },
        this.moduleInstances,
        srcAry[i],
        {
          node: this.nodeRow.data.node,
          propIndex: this.colConfig.propIndex,
          propConfig: this.colConfig.config,
        },
      );
      attachment.render();
      g += 42;
      // 是否还有空间画下一张图片
      if (width - g < 84 && i !== srcAry.length - 1) {
        // 展示附件数量
        this.drawNickname(`+${srcAry.length}`, x + g, y + height / 2);
        g = g + 10 + Draw.measureTextWidth(`+${srcAry.length}`);
        break;
      }
    }

    // 上传icon
    const attachmentUploadIcon = new AttachmentUploadIcon(
      {
        height: 32,
        width: 32,
        x: x + g,
        y: (height - 32) / 2 + y,
      },
      this.moduleInstances,
      {
        node: this.nodeRow.data.node,
        propIndex: this.colConfig.propIndex,
        propConfig: this.colConfig.config,
      },
    );
    if (this.isHover && type === 'attachment') attachmentUploadIcon.render();
  }

  public getColorAndBold(key: string) {
    const attrsStyle = this.moduleInstances.Draw.config.viewInfo.attrsStyle || {};
    const styleMode = this.moduleInstances.ChildNodesLoader.config.styleMode;
    if (!attrsStyle[key])
      return { color: styleMode === 'darkness' ? 'white' : '#242D3F', bold: false };
    return {
      color: attrsStyle[key].content.color,
      bold: attrsStyle[key].content.bold,
    };
  }

  public renderQuoteOptionPropCol(value: any[], type: 'quote' | 'matching') {
    const { propIndex, config, key } = this.colConfig;
    const { x, y, width, height } = this.position;
    const { Draw } = this.moduleInstances;
    const { attrContentAlign } = Draw.config;
    const { node } = this.nodeRow.data;
    const v = getQuoteOrMatchingInfo(config as ApiResponse.CurrentUser.TemplateProp);
    const rightGap = v === 1 || v === 2 ? 10 + 22 : 10; // 剩余的渲染空间
    let gap = 10;
    let propValues: any = node.tempInfo.prop[Number(propIndex)];
    propValues = Array.isArray(propValues) ? propValues : [propValues];
    this.drawNickname('', 0, 0);
    const colorAndBold = this.getColorAndBold(key);

    let startX = x;

    let contentWidth =
      (value || []).reduce((w: number, str: string) => {
        w = w + Draw.measureTextWidth(str) + 8;
        return w;
      }, 0) - 8;
    if (v === 1 || v === 2) contentWidth += 22;

    if (contentWidth < width - 20) {
      if (attrContentAlign[key] === 'center') {
        startX = (width - contentWidth) / 2 + x - 10;
      }

      if (attrContentAlign[key] === 'right') {
        startX = x + width - contentWidth - 10 - 10;
      }
    }

    (value || []).forEach((str, index) => {
      if (!str) return;
      const strWidth = Draw.measureTextWidth(str);
      if (gap + rightGap === width) return;
      let quoteItem = null;
      const originValue = propValues[index];

      if (width - gap - rightGap > strWidth) {
        quoteItem = new QuoteItem(
          {
            height: 14,
            width: strWidth,
            x: startX + gap,
            y: (height - 14) / 2 + y,
          },
          this.moduleInstances,
          {
            propIndex,
            prop: config,
            value: str,
            type,
            node: this.nodeRow.data.node,
            originValue,
            colorAndBold,
          },
        );
        gap += strWidth + 8;
      } else {
        const restWidth = width - gap - rightGap;
        const fittingStr = Draw.newFittingString(str, restWidth);
        quoteItem = new QuoteItem(
          {
            height: 14,
            width: restWidth,
            x: startX + gap,
            y: (height - 14) / 2 + y,
          },
          this.moduleInstances,
          {
            propIndex,
            prop: config,
            value: fittingStr,
            type,
            node: this.nodeRow.data.node,
            originValue,
            colorAndBold,
          },
        );
        gap += restWidth;
      }
      quoteItem.render();
    });

    if (v === 1 || v === 2) {
      // 查看引用列表
      const openQuoteListIcon = new OpenQuoteListIcon(
        {
          x: startX + gap,
          y: y + (height - 14) / 2,
          height: 14,
          width: 14,
        },
        this.moduleInstances,
        { node: this.nodeRow.data.node, propIndex },
      );
      openQuoteListIcon.render();
    }
  }

  public render() {
    const { Draw, DataManager } = this.moduleInstances;
    const { selectNodeAttr } = DataManager;
    const { userMap, attrContentAlign } = DataManager.config;
    const { x, y, height, width } = this.position;
    const { groupKey, index, node } = this.nodeRow.data;
    const { key, type } = this.colConfig;
    const borderWidth = 2;

    const styleMode = Draw.config.styleMode;
    const containerBgColor = Draw.config.contentPageGeneralTableConfig?.containerBgColor || '#fff';
    const nodeBg = styleMode === 'darkness' ? containerBgColor : '#fff';

    // 格子底色
    const attrsStyle = Draw.config.viewInfo.attrsStyle || {};
    let bgColor = attrsStyle[key] ? attrsStyle[key].content.backgroundColor : nodeBg;
    if (DataManager.selectRow === `${groupKey}/${index}`)
      bgColor = styleMode === 'darkness' ? containerBgColor : '#f0f6ff';

    if (this.rowHover && Draw.config.navMode === 'elecwatt')
      bgColor = elecwattConfig.tableRowHoverBg;

    Draw.fillRect(x, y, width, height, bgColor);

    // 可编辑的属性 hover 有底色
    if (key.slice(0, 4) === 'prop' && this.isHover && Draw.config.navMode !== 'elecwatt') {
      const propCanEdit = isPropCanEdit(
        DataManager.config.viewTemplate.prop[Number(key.slice(5))],
        { value: node } as GetterPiNode,
        Number(key.slice(5)),
        DataManager.config.orgInfo,
        node.parent,
      );
      if (propCanEdit)
        Draw.fillRect(
          x,
          y,
          width,
          height,
          styleMode === 'darkness' ? 'rgba(190,206,255,0.05)' : '#f0f6ff',
        );
    }

    // 选中
    if (
      selectNodeAttr.groupKey === groupKey &&
      selectNodeAttr.headerKey === key &&
      selectNodeAttr.indexInGroup === index
    ) {
      Draw.strokeRoundRect(
        {
          x: x,
          y: y + borderWidth,
          width: width - borderWidth * 2,
          height: height - borderWidth * 2,
        },
        0,
        borderWidth,
        'blue',
      );
      // 如果此时正在编辑， 改变表单的位置
      this.moduleInstances.DataManager.config.changeTableCellEditPosition(x, y);
    }

    if (this.isHover && key.slice(0, 4) === 'prop') {
      const auxiliaryPropValue = getAuxiliaryPropValue(
        this.colConfig.config as ApiResponse.CurrentUser.TemplateProp,
        DataManager.config.viewTemplate,
        node,
      );
      if (auxiliaryPropValue && auxiliaryPropValue.value) {
        const { position, value } = auxiliaryPropValue;

        const d = tempValueDisplay({
          propConfig: this.colConfig.config,
          propValue: node.tempInfo.prop[key.slice(5)],
          departmentMap: {},
          userMap: {},
          tempMap: {},
        });

        const v = position > 0 ? `${value}-${d}` : `${d}-${value}`;
        this.moduleInstances.Render.addToTriggerList(() => {
          const rectStartY = y - 40;
          Draw.fillRoundRect(
            {
              x,
              y: rectStartY,
              width: 200,
              height: 35,
            },
            5,
            '#4a4b4f',
          );

          Draw.drawText(
            Draw.newFittingString(v, 180),
            'white',
            'middle',
            'left',
            13,
            x + 10,
            rectStartY + 35 / 2,
          );
        });
      }
    }

    // 引用 匹配 可以查看主题
    if (key.slice(0, 4) === 'prop') {
      const tempMap = DataManager.templateMap;
      const prop = this.colConfig.config as ApiResponse.CurrentUser.TemplateProp;
      const quoteOptionVisible = getQuoteOptionVisible(prop, tempMap);
      if (quoteOptionVisible === 3) {
        // 引用
        const originProp = getMatchingOriginProp(prop, tempMap);
        const quoteValue = getPropHandledValue(
          originProp,
          this.colConfig.propIndex as number,
          this.nodeRow.data.node.tempInfo.prop[this.colConfig.propIndex as number],
          userMap,
          this.nodeRow.data.node.prop._sys_cascade,
        );
        return this.renderQuoteOptionPropCol(quoteValue || [], 'quote');
      }

      if (quoteOptionVisible === 1 || quoteOptionVisible === 2) {
        // 匹配
        const propValue = this.nodeRow.data.node.tempInfo.prop[this.colConfig.propIndex as number];
        const matchingValue = Array.isArray(propValue) ? propValue : [propValue];
        return this.renderQuoteOptionPropCol(matchingValue, 'matching');
      }
    }

    if (type === 'index')
      this.drawAttrText(node.prop._sys_node_seq, false, attrContentAlign.index || 'left');
    if (type === 'order')
      this.drawAttrText(this.nodeRow.data.index + 1 + '', false, attrContentAlign.order || 'left');
    if (key === 'title')
      return this.drawAttrText(node.title || '-', true, attrContentAlign.title || 'left');

    // 状态
    if (key === 'status') {
      this.drawNickname('', 0, 0);
      const status = this.colConfig.getData(node);
      const statusInfo = DataManager.templateMap[node.tempInfo.id]?.task_status?.[status];
      if (statusInfo && !statusInfo.delete) {
        const iconInfo = STATUS_ICON[statusInfo.icon as keyof typeof STATUS_ICON];
        if (iconInfo) {
          let startIconX = x + 10;
          let startStatusX = x + 30;
          const contentWidth = Draw.measureTextWidth(statusInfo.name) + 20;
          if (attrContentAlign.status === 'center') {
            startIconX = x + (width - contentWidth) / 2;
            startStatusX = startIconX + 20;
          }
          if (attrContentAlign.status === 'right') {
            startIconX = x + width - 10 - contentWidth;
            startStatusX = startIconX + 20;
          }
          this.drawStatusIcon(startIconX, y + height / 2, iconInfo.color, iconInfo.unicode);
          this.drawNickname(statusInfo.name || '-', startStatusX, y + height / 2, 30);
        }
      }
    }

    if (key === 'treeLevelId') {
      this.drawAttrText(node.treeLevelId, false, attrContentAlign.treeLevelId || 'left');
    }

    // 正文
    if (key === 'mainbody') {
      // 正文内容
      const mainbodyContent = new MainbodyContent(
        {
          height,
          width: width - 30 - 55 /* 附件宽度 */,
          x: x + 10,
          y,
        },
        this.moduleInstances,
        {
          node: this.nodeRow.data.node,
        },
      );
      mainbodyContent.render();

      // 正文附件
      const mainbodyAttachment = new MainbodyAttachment(
        {
          height: 24,
          width: 55 /* 附件宽度 */,
          x: x + width - 65,
          y: y + (height - 24) / 2,
        },
        this.moduleInstances,
        {
          node: this.nodeRow.data.node,
        },
      );
      mainbodyAttachment.render();
    }

    // 时间类型
    if (type === 'date' || type === 'datetime') {
      let ellipsis;
      if (this.colConfig.ellipsis instanceof Function) {
        ellipsis = this.colConfig.ellipsis(node);
      } else {
        ellipsis = this.colConfig.ellipsis;
      }

      if (ellipsis) {
        this.drawAttrText('-', false, attrContentAlign[key] || 'left');
        return;
      }

      const format = (this.colConfig.config || {}).dateFormat || 'YYYY/MM/DD';
      const res = this.colConfig.getData(node) || '-';
      const value = res.constructor === Object ? res.data : res;
      const time = value
        ? (format === 'HH:mm' ? dayjs(Number(value)).utc() : dayjs(Number(value))).format(format)
        : '-';

      let dateBeforePresent = '';
      let dateBeforePresentColor = '';
      // 距今时间
      if ((this.colConfig.config as ApiResponse.CurrentUser.TemplateProp)?.showInterval && value) {
        const beforePresent = getDateBeforePresent({
          prop: this.colConfig.config as ApiResponse.CurrentUser.TemplateProp,
          value: value,
        });
        if (beforePresent.display) {
          dateBeforePresent = beforePresent.toString;
          dateBeforePresentColor = beforePresent.color;
        }
      }

      // 内容不是左对齐
      if (attrContentAlign[key] && attrContentAlign[key] !== 'left') {
        if (!dateBeforePresent) return this.drawAttrText(time, true, attrContentAlign[key]);

        const timeStrWidth = Draw.measureTextWidth(time);
        const iconWidth = 14;
        const dateBeforePresentWidth = Draw.measureTextWidth(dateBeforePresent);
        const contentWidth = timeStrWidth + 10 + iconWidth + 5 + dateBeforePresentWidth;

        if (contentWidth < width - 10) {
          if (attrContentAlign[key] === 'right') {
            const startX = x + width - 10;
            this.drawDateBeforePresent(
              dateBeforePresent,
              startX,
              y + height / 2,
              hexToRgba(dateBeforePresentColor, 1).rgba,
              9999,
              'end',
            ); // 距今的时间
            Draw.drawIcon(
              startX - 5 - dateBeforePresentWidth,
              y + height / 2,
              hexToRgba(dateBeforePresentColor, 1).rgba,
              '&#xe731;',
              14,
              'middle',
              'end',
            ); // icon
            Draw.drawText(
              time,
              'black',
              'middle',
              'end',
              14,
              startX - dateBeforePresentWidth - 29,
              y + height / 2,
            ); // 时间
          }

          if (attrContentAlign[key] === 'center') {
            const startX = (width - contentWidth) / 2 + x;
            Draw.drawText(time, 'black', 'middle', 'start', 14, startX, y + height / 2); // 时间
            Draw.drawIcon(
              startX + timeStrWidth + 10,
              y + height / 2,
              hexToRgba(dateBeforePresentColor, 1).rgba,
              '&#xe731;',
              14,
            ); // icon
            this.drawDateBeforePresent(
              dateBeforePresent,
              startX + timeStrWidth + 10 + iconWidth + 5,
              y + height / 2,
              hexToRgba(dateBeforePresentColor, 1).rgba,
              9999,
            ); // 距今的时间
          }
          return;
        }
        // 如果长度已经超了直接按照左对齐处理
      }

      this.drawAttrText(time, true); // 日期

      // dateBeforePresent
      const timeStrWidth = Draw.measureTextWidth(time);
      const restGap = width - 30 - timeStrWidth;

      if (restGap > 15 && dateBeforePresent) {
        Draw.drawIcon(
          x + 20 + timeStrWidth,
          y + height / 2,
          hexToRgba(dateBeforePresentColor, 1).rgba,
          '&#xe731;',
          14,
        );
        // text
        if (restGap > 20) {
          this.drawDateBeforePresent(
            dateBeforePresent,
            x + 20 + timeStrWidth + 14 + 5,
            y + height / 2,
            hexToRgba(dateBeforePresentColor, 1).rgba,
            restGap - 20,
          );
        }
      }
    }

    // tag
    if (type === 'sysTag') {
      const data = this.colConfig.getData(node);
      const t = data instanceof Array ? data.join('、') : data;
      this.drawAttrText(t, true, attrContentAlign.sysTag || 'left');
    }

    // positioning
    if (type === 'positioning') {
      const t = this.colConfig.getData(node);
      this.drawAttrText(t || '-', true, attrContentAlign[key] || 'left');
    }

    // address
    if (type === 'address') {
      const t = this.colConfig.getData(node).data;
      this.drawAttrText(t || '-', true, attrContentAlign[key] || 'left');
    }

    // cascade
    if (type === 'cascade') {
      const t = this.colConfig.getData(node);
      this.drawAttrText(t || '-', true, attrContentAlign[key] || 'left');
    }

    // repeatTask
    if (type === 'repeatTask') {
      const t = this.colConfig.getData(node);
      this.drawAttrText(t || '-', true);
    }

    // 自增id
    if (type === 'auto_inc') {
      const t = this.colConfig.getData(node).data;
      this.drawAttrText(t || '-', true, attrContentAlign[key] || 'left');
    }

    // enum
    if (type === 'tag' || type === 'enum') {
      let { data, type, propIndex } = this.colConfig.getData(node);
      const config = this.colConfig.config as ApiResponse.CurrentUser.TemplateProp;
      const isMatchingStat = this.colConfig.isMatchingStat;

      if (isMatchingStat && type) {
        this.renderConditionMatchingAttr({
          data: data instanceof Array ? data.join('、') : data,
          type,
          propIndex,
          contentAlign: attrContentAlign[key] || 'left',
        });
        return;
      } // right data

      if (propIsNull(data)) {
        if (config.multiple || config.type === 'tag') {
          data = [];
        } else {
          data = '';
        }
      }

      const { extendColorMode, extend, extendColor } = config;
      if (extendColorMode) {
        const _data = Array.isArray(data) ? data : [data];
        _data.reduce((prev, d) => {
          const _index = extend.findIndex((e: string) => e === d);

          return this.drawAttrText(d, true, attrContentAlign[key] || 'left', {
            backgroundColor: extendColor![_index],
            color: 'white',
            offsetX: prev ? prev + 5 : prev,
          });
        }, 0);
      } else {
        const t = data instanceof Array ? data.join('、') : data;
        this.drawAttrText(t || '-', true, attrContentAlign[key] || 'left');
        if (isPropSupportGraphicCode(config) && t) {
          const exportGraphicCodeIcon = new ExportGraphicCodeIcon(
            {
              x: x + 14 + Draw.measureTextWidth(t || '-'),
              y: this.position.y + this.position.height / 2 - 14 / 2,
              height: 14,
              width: 14,
            },
            this.moduleInstances,
            { node: this.nodeRow.data.node, prop: config, text: t || '' },
          );
          exportGraphicCodeIcon.render();
        }
      }
    }

    // currency
    if (type === 'currency') {
      const { data, type, propIndex } = this.colConfig.getData(node);
      if (data === null) return;
      const config = this.colConfig.config as ApiResponse.CurrentUser.TemplateProp;
      const isMatchingStat = this.colConfig.isMatchingStat;

      if (isMatchingStat) {
        type
          ? this.renderConditionMatchingAttr({
              data,
              type,
              propIndex,
              contentAlign: attrContentAlign[key] || 'left',
            })
          : this.drawAttrText(data, false, 'right');
        return;
      } // right data

      this.drawAttrText(
        tempValueDisplay({
          propConfig: config,
          propValue: data,
          userMap: {},
          tempMap: {},
          departmentMap: {},
        }) as string,
        false,
        'right',
      );
    }

    // number
    if (type === 'number') {
      const { data, type, propIndex } = this.colConfig.getData(node);
      if (data === null) return;
      const config = this.colConfig.config as ApiResponse.CurrentUser.TemplateProp;
      const isMatchingStat = this.colConfig.isMatchingStat;

      if (isMatchingStat && type) {
        this.renderConditionMatchingAttr({
          data,
          type,
          propIndex,
          contentAlign: attrContentAlign[key] || 'left',
        });
        return;
      }
      this.drawAttrText(
        tempValueDisplay({
          propConfig: config,
          propValue: data,
          userMap: {},
          tempMap: {},
          departmentMap: {},
        }) as string,
        false,
        attrContentAlign[key] || 'left',
      );
    }

    // formula
    if (type === 'formula' || type === 'department') {
      const { data } = this.colConfig.getData(node);
      const config = this.colConfig.config as ApiResponse.CurrentUser.TemplateProp;
      const t = tempValueDisplay({
        propConfig: config,
        propValue: data,
        userMap: {},
        tempMap: {},
        departmentMap: DataManager.config.departmentMapRef.current,
      });
      this.drawAttrText(String(t) || '-', true, attrContentAlign[key] || 'left');
    }

    // str text
    if (type === 'str' || type === 'text') {
      const { data, type, propIndex } = this.colConfig.getData(node);
      const isMatchingStat = this.colConfig.isMatchingStat;
      const isSupportGraphicCode = isPropSupportGraphicCode(this.colConfig.config) && data;

      let ellipsis;
      if (this.colConfig.ellipsis instanceof Function) {
        ellipsis = this.colConfig.ellipsis(node);
      } else {
        ellipsis = this.colConfig.ellipsis;
      }

      if (ellipsis) {
        this.drawAttrText('-', false, 'center');
        return;
      }

      if (isMatchingStat && type) {
        this.renderConditionMatchingAttr({
          data,
          type,
          propIndex,
          contentAlign: attrContentAlign[key] || 'left',
          isSupportGraphicCode,
        });
        return;
      } // right data
      this.drawAttrText(data || '-', true, attrContentAlign[key] || 'left');
      if (isSupportGraphicCode) {
        const exportGraphicCodeIcon = new ExportGraphicCodeIcon(
          {
            x: x + 14 + Draw.measureTextWidth(data || ''),
            y: y + (height - 14) / 2,
            height: 14,
            width: 14,
          },
          this.moduleInstances,
          { node: this.nodeRow.data.node, prop: this.colConfig.config, text: data || '' },
        );
        exportGraphicCodeIcon.render();
      }
    }

    // 引用数表的属性
    if (type === 'quote' && this.colConfig.config.table_id) {
      const data = this.colConfig.getData(node);
      this.drawAttrText(data || '-', true, attrContentAlign[key] || 'left');
    }

    // quote
    if (type === 'quote' && !this.colConfig.config.table_id) {
      const { data, type, propIndex } = this.colConfig.getData(node);
      if (type === 'quote-attachment')
        return this.handleAttachment(data, type, attrContentAlign[key] || 'left');
      if (type)
        return this.renderConditionMatchingAttr({
          data,
          type,
          propIndex,
          contentAlign: attrContentAlign[key] || 'left',
        });
      const propIsCanCount = this.colConfig.propIsCanCount;
      if (propIsCanCount)
        return this.drawAttrText(data || '-', false, attrContentAlign[key] || 'left');
      this.drawAttrText(data || '-', true, attrContentAlign[key] || 'left');
    }

    // attachment
    if (type === 'attachment') {
      const srcAry = this.colConfig.getData(node);
      this.handleAttachment(srcAry, type, attrContentAlign[key] || 'left');

      return;
    }

    // user
    if (type === 'user') {
      let { data, type, propIndex } = this.colConfig.getData(node);

      let config;
      if (this.colConfig.config instanceof Function) {
        config = this.colConfig.config(node);
      } else {
        config = this.colConfig.config;
      }

      let ellipsis;
      if (this.colConfig.ellipsis instanceof Function) {
        ellipsis = this.colConfig.ellipsis(node);
      } else {
        ellipsis = this.colConfig.ellipsis;
      }

      if (ellipsis) {
        this.drawAttrText('-', false, 'center');
        return;
      }

      const isMatchingStat = this.colConfig.isMatchingStat;

      if (isMatchingStat && type) {
        this.renderConditionMatchingAttr({
          data,
          type,
          propIndex,
          contentAlign: attrContentAlign[key] || 'left',
        });
        return;
      }

      if (propIsNull(data)) return;

      data = data ? (Array.isArray(data) ? data : [data]) : [];
      this.drawNickname('', 0, 0);

      if (data.length < 2) {
        const user = getUser(data[0], userMap);

        let startNicknameX = x + 45;
        let startAvatarX = x + 10;
        const contentWidth = Draw.measureTextWidth(user.nick_name) + 35;
        if (attrContentAlign[key] === 'center' && contentWidth < width - 20) {
          startAvatarX = x + (width - contentWidth) / 2;
          startNicknameX = startAvatarX + 35;
        }
        if (attrContentAlign[key] === 'right' && contentWidth < width - 20) {
          startAvatarX = x + width - 10 - contentWidth;
          startNicknameX = startAvatarX + 35;
        }
        this.drawNickname(user.nick_name, startNicknameX, y + height / 2);
        this.drawImg(user.avatar, startAvatarX, y + (height - 30) / 2);
      } else {
        let startX = x;
        const contentWidth = data.length * 33 - 3;
        if (attrContentAlign[key] === 'center' && contentWidth < width - 20) {
          startX = x + (width - contentWidth) / 2 - 10;
        }
        if (attrContentAlign[key] === 'right' && contentWidth < width - 20) {
          startX = x + width - contentWidth - 10 - 10;
        }
        // 不只一个人
        for (let i = 0; i < data.length; i++) {
          const user = getUser(data[i], userMap);
          const g = 10 + i * 33;
          if (g > width - 55) {
            // 展示人数
            this.drawNickname(` +${data.length - i}`, startX + g, y + height / 2);
            break;
          }
          this.drawImg(user.avatar, startX + g, y + (height - 30) / 2);
        }
      }
    }

    // TODO 操作-自定义按钮渲染
    if (type === 'actions') {
      const templateInfo = DataManager.config.viewTemplate;
      const btnIds = (this.moduleInstances.Draw.config.viewInfo.actionButtons || []) as string[];

      const baseBtnConfigs: CurrentUser.CustomButton[] = [];
      if (btnIds.includes('system_button_view'))
        baseBtnConfigs.push({
          type: 'text',
          pictureUrl: '',
          id: 'system_button_view',
          hideWebText: false,
          color: '#316ef5',
          text: '查看',
          icon: null,
          size: 'middle',
          opType: 'viewNode',
        } as any);
      if (btnIds.includes('system_button_edit'))
        baseBtnConfigs.push({
          type: 'text',
          pictureUrl: '',
          id: 'system_button_edit',
          hideWebText: false,
          text: '编辑',
          color: '#316ef5',
          icon: null,
          size: 'middle',
          opType: 'viewNode',
        } as any);
      if (btnIds.includes('system_button_delete'))
        baseBtnConfigs.push({
          type: 'text',
          pictureUrl: '',
          id: 'system_button_delete',
          hideWebText: false,
          color: '#f5222d',
          text: '删除',
          icon: null,
          size: 'middle',
          opType: 'deleteNode',
        } as any);

      const btnConfigs = btnIds
        .map((id) =>
          getCustomBtn(
            templateInfo.template_id,
            id,
            this.moduleInstances.DataManager.config.templateList,
          ),
        )
        .filter((i) => !!i);
      const chunks = [...baseBtnConfigs, ...btnConfigs].map((config) => {
        assertExists(config);
        const { size, text, pictureUrl, icon, hideWebText } = config;

        const payload: any = { width: 0, config, type: 'btn', btnHeight: 0, btnWidth: 0 };

        const { padding, height } = btnConfig.rectangle[size];
        payload.btnWidth = 2 * padding;
        if (!hideWebText) payload.btnWidth += Draw.measureTextWidth(text);
        if (icon) payload.btnWidth += hideWebText ? 22 : 24;
        if (pictureUrl) payload.btnWidth += hideWebText ? 22 : 24;
        payload.btnHeight = height;

        payload.width = payload.btnWidth;
        return payload;
      });
      let x = this.position.x + 8;
      chunks.forEach((chunk) => {
        const forbiddenClick = isCustomButtonForbiddenClick(chunk.config, node) || false;

        if (chunk.config.hiddenByDisableCondition && forbiddenClick) {
          return;
        }

        const fixBtn = new CustomBtn(
          {
            x,
            y: this.position.y + (this.position.height - chunk.btnHeight) / 2,
            width: chunk.btnWidth,
            height: chunk.btnHeight,
          },
          this.moduleInstances,
          chunk,
          node,
        );
        fixBtn.render();
        x += chunk.width + 8;
      });
    }
  }
}

const getUser = (id: string, userMap: Record<string, ApiResponse.OrgUser.OrgUserItem>) => {
  return (
    userMap[id] || {
      avatar: DEFAULT_AVATAR,
      nick_name: '未知用户',
    }
  );
};

export default NodeCol;
