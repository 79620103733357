import { EXTERNAL_OWNER } from '@linkpi/core';
import { setNodeAction } from '@linkpi/core';
import { tempValueDisplay } from '@linkpi/core';
import { Avatar as _Avatar, Form, message, Tooltip, Typography } from 'antd';
import dayjs from 'dayjs';
import { memo, useMemo, useState } from 'react';
import { useDrag } from 'react-dnd';
import isEqual from 'react-fast-compare';
import { useDispatch, useSelector } from 'umi';

import { useOrgDepartmentNodesMap } from '@/hook/useOrgStructure';
import { StatusMenu } from '@/pages/home/components/StatusMenu/StatusMenu';
import StatusModal from '@/pages/home/components/StatusModal';
import { getStatusItems } from '@/pages/home/components/TempStatus';
import request from '@/utils/request';

const avatarStyle = { backgroundColor: '#316ef5', verticalAlign: 'middle' };
const Avatar = memo(_Avatar);

const type = 'DraggableCard';
const DraggableCard = (props) => {
  const dispatch = useDispatch();
  const [editForm] = Form.useForm();
  const {
    node,
    userMap,
    onClick,
    template,
    showProp = [],
    spaceUsers,
    groupKey,
    groupBy,
    tempMap,
  } = props;
  const { currentSelection, spaceUserList } = useSelector((state) => state.workspace);
  const { currentUser } = useSelector((state) => state.user);
  const [modalTitle, setModalTitle] = useState(<span>{node.title}</span>);
  const [scheduleShow, setScheduleShow] = useState(false);
  const [scheduleLoading, setScheduleLoading] = useState(false);
  const [formItems, setFormItems] = useState([]);
  const [transformStatus, setTransformStatus] = useState({});
  const [transformIndex, setTransformIndex] = useState(-1);
  const currentOrg = currentUser.organization.find(
    (org) => org.orgId === currentSelection.selectSpace,
  );
  const departmentMap = useOrgDepartmentNodesMap();

  const avatar = useMemo(() => {
    const owner = userMap[node.tempInfo.own];
    if (owner) {
      return (
        <Tooltip title={'负责人：'.concat(owner.nick_name)} placement="top">
          {owner.avatar ? (
            <Avatar src={owner.avatar} />
          ) : (
            <Avatar style={avatarStyle}>
              {owner.nick_name?.substring(owner.nick_name.length - 1)}
            </Avatar>
          )}
        </Tooltip>
      );
    } else if (node.tempInfo.own === EXTERNAL_OWNER) {
      return (
        <Tooltip title={'负责人：待认领'} placement="top">
          <Avatar
            style={{ color: '#666D7C', background: 'none' }}
            icon={<i className={'iconfont iconconfirming'} style={{ fontSize: 20 }} />}
            title={'待认领'}
          />
        </Tooltip>
      );
    }
    return <div className="avatar-white" />;
  }, [node]);

  const sendAvatar = useMemo(() => {
    const sender = userMap[node.tempInfo.sender];
    if (sender) {
      return (
        <Tooltip title={'发送者：'.concat(sender.nick_name)} placement="top">
          {sender.avatar ? (
            <Avatar src={sender.avatar} />
          ) : (
            <Avatar style={{ backgroundColor: '#316ef5', verticalAlign: 'middle' }}>
              {sender.nick_name?.substring(sender.nick_name.length - 1)}
            </Avatar>
          )}
        </Tooltip>
      );
    }
    return <div className="avatar-white" />;
  }, [node]);

  const endDate = useMemo(() => {
    if (node.tempInfo.endDate) {
      return dayjs(node.tempInfo.endDate).year() === new Date().getFullYear()
        ? dayjs(node.tempInfo.endDate).format('MM/DD HH:mm')
        : dayjs(node.tempInfo.endDate).format('YYYY/MM/DD HH:mm');
    } else {
      return null;
    }
  }, [node]);

  const statusMenu = useMemo(() => {
    return node.prop._sys_temp ? (
      <StatusMenu
        btnClassName="card-status-btn"
        spaceId={currentSelection.selectSpace}
        data={node}
        type="list"
      />
    ) : null;
  }, [node.prop._sys_temp]);

  const currentStatus = useMemo(() => {
    if (node.prop._sys_task_status && template && Array.isArray(template.task_status)) {
      return template.task_status[node.prop._sys_task_status.index] || null;
    }
    return null;
  }, [node, template]);

  const dragCard = async (key) => {
    if (key === 'default') return;
    const actionReq = {
      org_id: currentSelection.selectSpace,
      node: [node.id],
      temp_id: template.template_id,
    };
    const curStatus = node.prop._sys_task_status?.index;
    const curStatusProp = node.prop._sys_task_status?.prop || [];
    const curProp = node.prop._sys_temp?.[1] || [];
    const statusProp =
      Array.isArray(template.task_status) && template.task_status[curStatus]
        ? template.task_status[curStatus].prop
        : null;
    const prop = template.prop || [];
    if (groupBy === 'templateStatus') {
      const statusIndex = Number(key.split('_')[1]);
      if (
        Number.isFinite(statusIndex) &&
        template.task_status[statusIndex] &&
        !template.task_status[statusIndex].delete
      ) {
        const disabled =
          (currentOrg.role === 7 || !template.flow) &&
          Array.isArray(template.task_status[curStatus].flow) &&
          !~template.task_status[curStatus].flow.indexOf(statusIndex);
        if (disabled) {
          message.error('暂无权限');
          return;
        }
        const matchConfig = await dispatch({
          type: 'workspace/fetchMatchProps',
          payload: { org_id: currentSelection.selectSpace, temp_id: template.template_id },
        });
        const tagExtends = await dispatch({
          type: 'space/fetchPropOptions',
          payload: { org_id: currentSelection.selectSpace, temp_id: template.template_id },
        });
        let historyStatus = [];
        const res = await request('/api/status/query', {
          method: 'POST',
          data: {
            node_id: node.id,
            temp_id: template.template_id,
            org_id: currentSelection.selectSpace,
          },
        });
        if (res?.status === 'ok') {
          historyStatus = res.data.reverse();
        }
        const propItems = getStatusItems(
          currentSelection.selectSpace,
          template.task_status[statusIndex],
          spaceUsers,
          spaceUserList[currentSelection.selectSpace],
          null,
          null,
          node,
          currentUser,
          true,
          null,
          null,
          {},
          false,
          prop,
          matchConfig,
          tagExtends,
          editForm,
          template,
          historyStatus,
        );
        if (propItems.length) {
          setModalTitle(
            <div className="status-header" style={{ display: 'flex' }}>
              <div style={{ maxWidth: '50%' }} className="text-omit" title={node.title}>
                {node.title}
              </div>
              <span style={{ color: '#EBEDF0', margin: '0 5px' }}> | </span>{' '}
              {template.task_status[statusIndex].name}
            </div>,
          );
          setFormItems(propItems);
          setScheduleShow(true);
          setTransformStatus(template.task_status[statusIndex]);
          setTransformIndex(statusIndex);
          return;
        } else {
          Object.assign(actionReq, {
            status: statusIndex,
            owner: null,
            user: null,
            startTime: null,
            endTime: null,
            record: true,
          });
        }
      } else {
        message.error('状态不存在');
        return;
      }
    } else if (groupBy.startsWith('templateStatusProp')) {
      if (statusProp) {
        const index = Number(
          groupBy.startsWith('templateStatusPropCustom_')
            ? groupBy.split('_')[3]
            : groupBy.split('_')[1],
        );
        if (statusProp[index].multiple) {
          curStatusProp[index] = curStatusProp[index] ? [key].concat(curStatusProp[index]) : [key];
        } else {
          curStatusProp[index] = key;
        }
        if (index === 0) {
          actionReq['owner'] = curStatusProp[index];
        } else if (index === 1) {
          actionReq['user'] = curStatusProp[index];
        } else if (index === 4 && typeof curStatusProp[4] === 'string') {
          actionReq['statusCommit'] = curStatusProp[4];
        }
      } else {
        message.error('状态属性不存在');
        return;
      }
    } else if (groupBy.startsWith('templateProp_')) {
      const index = Number(groupBy.split('_')[2]);
      if (prop[index]) {
        while (curProp.length < index) {
          curProp.push(null);
        }
        if (prop[index].multiple) {
          curProp[index] = curProp[index] ? [key].concat(curProp[index]) : [key];
        } else {
          curProp[index] = key;
        }
        actionReq['temp_prop'] = curProp;
      } else {
        message.error('不支持的类型');
        return;
      }
    } else if (groupBy === '_sys_tag') {
      if (node.prop._sys_tag && key in node.prop._sys_tag) {
        message.warn('当前主题已包含此标签');
        return;
      }
      dispatch({
        type: 'space/addSpaceTag',
        payload: {
          org_id: currentSelection.selectSpace,
          node_id: node.id,
          tag: key,
          delete: false,
          isOld: true,
        },
      });
      return;
    } else {
      message.error('不支持的类型');
      return;
    }
    setNodeAction(request, actionReq).then(([err, res]) => {
      if (err || res?.status !== 'ok') {
      }
    });
  };

  const onSubmit = async (values) => {
    // const oldProps = data?.prop ? JSON.parse(JSON.stringify(data?.prop)) : {};
    let status_prop = [],
      hasTempProp = false;
    transformStatus.prop.map(() => {
      status_prop.push(null);
    });
    const newTempProp = JSON.parse(JSON.stringify(node.prop._sys_temp[1]));
    if (status_prop.length < 4) return;
    setScheduleLoading(true);
    const matchConfig = await dispatch({
      type: 'workspace/fetchMatchProps',
      payload: { org_id: currentSelection.selectSpace, temp_id: template.template_id },
    });
    Object.keys(values).map((x) => {
      if (values[x]) {
        if (x.startsWith('prop')) {
          const index = x.split('prop')[1];
          status_prop[index] = transformStatus.prop[index]?.type?.startsWith('date')
            ? values[x].valueOf()
            : values[x];
        }
        if (x.startsWith('tempProp') && Array.isArray(newTempProp)) {
          hasTempProp = true;
          const index = Number(x.split('tempProp')[1]);
          const changedFields = [index];
          while (newTempProp.length <= index) {
            newTempProp.push(null);
          }
          newTempProp[index] = template.prop[index]?.type.startsWith('date')
            ? values[x].valueOf()
            : values[x];
          /**
           * 22/11/23 前端不再处理条件匹配相关
           */
          /*[newTempProp, changedFields] = conditionMatchPropsData({
            handleDataIndex: index, // 当前操作的属性index
            propData: newTempProp, // 当前的属性数据
            userMap: spaceUserList[currentSelection.selectSpace], // 用户信息map { userid:{ nick_name:xxxx } }
            template: template, // 当前模板
            templateList: [], // 空间模板列表
            matchConfig, // 可选，已经通过getConditionMatchProps匹配的数据
            creator: node.prop._sys_creator, //创建者
            title: node.title,
            changedFields,
            createTime: node.prop._sys_createTime,
            currentUserId: currentUser.userid,
          });*/
        }
      }
    });

    const actionReq = {
      org_id: currentSelection.selectSpace,
      node: [node.id],
      temp_id: template.template_id,
      status: transformIndex,
      owner: status_prop[0],
      user: status_prop[1],
      startTime: status_prop[2],
      endTime: status_prop[3],
      statusCommit: typeof status_prop[4] === 'string' ? status_prop[4] : '',
      record: true,
    };
    if (hasTempProp) {
      actionReq['temp_prop'] = newTempProp;
    }
    const [err, res] = await setNodeAction(request, actionReq);
    if (res?.status === 'ok') {
      setScheduleLoading(false);
      setScheduleShow(false);
    } else {
      setScheduleLoading(false);
      message.error('修改失败');
    }
  };

  const tempProp =
    Array.isArray(node.prop._sys_temp) && Array.isArray(node.prop._sys_temp[1])
      ? node.prop._sys_temp[1]
      : [];
  let defaultHeight = 136;
  if (endDate) defaultHeight -= 32;
  if (node.tempInfo.own) defaultHeight -= 32;
  if (showProp.length) defaultHeight -= 32;
  if (defaultHeight < 136) {
    defaultHeight -= 8;
  }
  const lines = Math.floor(defaultHeight / 22);

  const [{ isDragging }, drag] = useDrag({
    item: { type, node, key: groupKey },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    end: (item, monitor) => {
      const result = monitor.getDropResult();
      if (result && result.groupKey) {
        if (groupKey === result.groupKey) {
          // message.warn('无效');
        } else {
          dragCard(result.groupKey);
        }
      }
    },
  });

  const showPropDom = () => {
    const children = [];
    showProp.map((propIndex) => {
      const display = tempValueDisplay({
        propConfig: template.prop[propIndex],
        propValue: tempProp[propIndex],
        propIndex: propIndex,
        userMap: spaceUserList[currentSelection.selectSpace],
        tempMap: tempMap,
        departmentMap,
      });
      const { type: _type, extend, extendColor, extendColorMode } = template.prop[propIndex];
      if (_type === 'enum' && extendColorMode) {
        const values = Array.isArray(tempProp[propIndex])
          ? tempProp[propIndex]
          : [tempProp[propIndex]];

        values.forEach((v) => {
          children.push(
            <span
              key={propIndex + v}
              className="mr-1 px-2 text-white rounded-full"
              style={{
                backgroundColor: extendColor[extend.findIndex((e) => e === v)],
              }}
            >
              {v}
            </span>,
          );
        });

        return children;
      }
      if (display) {
        children.push(
          <div className={'show-prop-item text-omit'} key={propIndex} title={display}>
            {display}
          </div>,
        );
      }
    });
    return children;
  };

  return (
    <div className="board-card-wrapper">
      <div
        ref={drag}
        className={`board-card  ${isDragging ? 'board-card-dragging' : ''}`}
        onClick={onClick}
      >
        <div
          className={`card-title ${defaultHeight < 136 ? '' : 'title-only'}`}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <Tooltip title={node.title || '无标题'}>
            <Typography.Paragraph className="title" onClick={onClick} ellipsis={{ rows: lines }}>
              {node.title || '无标题'}
            </Typography.Paragraph>
          </Tooltip>
          {statusMenu}
        </div>
        <div style={{ marginBottom: 8 }}>
          {endDate ? (
            <div
              className={`endDate ${new Date(node.tempInfo?.endDate) <= new Date() && 'overdue'}`}
            >
              <i className="iconfont iconProperty_time" />
              <span>{endDate} 截止</span>
            </div>
          ) : null}
          {showProp.length ? <div className={'show-prop'}>{showPropDom()}</div> : null}
          {Array.isArray(currentStatus?.prop) &&
          currentStatus.prop[0]?.display &&
          node.tempInfo.own ? (
            <div className="foot">
              <div className="send own">{sendAvatar}</div>
              <i className="iconfont iconassign" />
              {/*<RightOutlined className="send-icon" />*/}
              <div className="own">{avatar}</div>
            </div>
          ) : null}
        </div>
      </div>
      <StatusModal
        modalTitle={modalTitle}
        scheduleShow={scheduleShow}
        onCancel={(_) => setScheduleShow(false)}
        scheduleLoading={scheduleLoading}
        onSubmit={onSubmit}
        formItems={formItems}
        editForm={editForm}
        node={node}
        transformType="状态转换"
      />
    </div>
  );
};

export default memo(DraggableCard, isEqual);
