import type { CurrentUser } from '@linkpi/core';
import type { GetterPiNode } from '@linkpi/core/web';
import { Modal } from 'antd';

import { useCurrentUser } from '@/hook';
import NewNodeProp from '@/pages/home/components/NewNodeProp';

interface IProps {
  editProps: string[];
  title: string;
  closeModal: () => void;
  node: GetterPiNode;
  currentUser: CurrentUser;
}

export default (props: IProps) => {
  const { editProps, title, closeModal, node } = props;
  const currentUser = useCurrentUser();

  return (
    <Modal width={1000} title={title} visible onCancel={closeModal} footer={null}>
      <NewNodeProp
        node={node}
        isSimpleThemeTree={false}
        currentUser={currentUser}
        simpleThemeTreeWidth={0}
        setEditedProps={() => {}}
        mode="edit"
        isDrafts={false}
        nodePropConfig={{
          showGroup: false,
          style: 'default',
          propGrid: false,
        }}
        editPartPropsInNode={editProps.map((p) => Number(p.slice(5)))}
      />
    </Modal>
  );
};
