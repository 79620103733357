import type { ApiResponse } from '@linkpi/core';
import { DEFAULT_AVATAR } from '@linkpi/core';
import { debounce } from 'lodash';

import { triggerCopyNodeButton } from '@/components/CustomButton/CopyNodeModal';
import { showUpdateNodesPropModal } from '@/components/CustomButton/UpdateNodesPropModal';
import { btnConfig, classToUnicode } from '@/pages/space/components/PageFix/btnConfig';
import { isCustomButtonForbiddenClick } from '@/utils/utils';

import type { PositionType } from '../components/_baseComponent';
import GridBaseComponent from '../components/_baseComponent';
import type { PiGridModules } from '../core';

class CustomBtn extends GridBaseComponent {
  public data: {
    config: ApiResponse.CurrentUser.CustomButton;
    [k: string]: any;
  };
  public node: PiNode;
  public forbiddenClick: boolean = false;
  public type: string = 'fixBtn';
  constructor(position: PositionType, moduleInstances: PiGridModules, data: any, node: PiNode) {
    super(position, moduleInstances);

    this.data = data;
    this.node = node;

    this.forbiddenClick = isCustomButtonForbiddenClick(this.data.config, node) || false;
  }
  public captrue: boolean = false;

  private async _click() {
    if (this.forbiddenClick) return;
    const { node, opType, automationId } = this.data.config;
    if (opType === 'automationTrigger')
      this.moduleInstances.DataManager.config.automationTriggerFromCustomBtn(
        automationId!,
        this.node,
        this.data.config,
      );
    if (opType === 'exportNodes')
      this.moduleInstances.DataManager.config.exportPDFFromCustomBtn(this.node);
    if (opType === 'addNode')
      this.moduleInstances.DataManager.config.addNodeFromCustomBtn(node, this.node);
    if (opType === 'editProp')
      this.moduleInstances.DataManager.config.editNodeFromCustomBtn(this.data.config, {
        value: this.node,
      });
    if (opType === 'updateProps') {
      showUpdateNodesPropModal({
        orgId: this.moduleInstances.DataManager.config.orgInfo.orgId,
        node: this.node,
        config: this.data.config,
      });
    }
    if (opType === 'copyTemplate') {
      triggerCopyNodeButton({
        config: this.data.config,
        orgId: this.moduleInstances.DataManager.config.orgInfo.orgId,
        node: this.node,
      });
    }
    if (opType === 'viewNode') {
      this.moduleInstances.Render.config.onPreviewNode(this.node.id);
    }
    if (opType === 'deleteNode') {
      this.moduleInstances.Render.config.onNodeDelete(this.node.id);
    }
  }

  public click = debounce(this._click, 300);

  public drawImg(src: string, x: number, y: number, side: number) {
    const { Draw, ImageLoader } = this.moduleInstances;

    const img = ImageLoader.loadImage(src || DEFAULT_AVATAR, 'avatar');
    Draw.drawRoundImg(4, x, y, side, side, img);
  }

  public drawIcon(x: number, y: number, color: string, unicode: string) {
    const { Draw } = this.moduleInstances;
    const { ctx } = Draw;

    ctx.save();
    Draw.attr({
      fillStyle: color,
      textBaseline: 'start',
      textAlign: 'start',
      font: `${Draw.npx(22)}px iconfont`,
    });

    Draw.fillText(Draw.iconFont(unicode), x, y);
    ctx.restore();
  }

  public drawText(text: string, color: string, x: number, y: number) {
    const { Draw } = this.moduleInstances;

    Draw.attr({
      fillStyle: color,
      textBaseline: 'top',
      textAlign: 'start',
      font: `${Draw.npx(14)}px  sans-serif`,
    });

    Draw.fillText(text, x, y);
  }

  public render() {
    const { Render, Draw } = this.moduleInstances;
    const { forbiddenClick } = this;
    const { x, y } = this.position;
    const btnHeight = this.position.height;
    const btnWidth = this.position.width;
    const { config, unitBackgroundColor = 'transparent' } = this.data;
    const {
      text, // 按钮 文案
      size, // 按钮大小
      pictureUrl, // 按钮icon 是否是图片
      color, // 按钮背景色
      icon, // icon
      hideWebBackground,
      hideWebText,
    } = config;
    const showPre = icon || pictureUrl;

    if (this.isHover) {
      Render.setCursor(forbiddenClick ? 'not-allowed' : 'pointer');
    }

    Draw.fillRoundRect(
      this.position,
      4,
      forbiddenClick ? '#F5F5F5' : hideWebBackground ? unitBackgroundColor : color,
    );

    if (forbiddenClick) {
      Draw.strokeRoundRect(this.position, 4, 1, '#D9D9D9');
    }

    const { padding } = btnConfig.rectangle[size];
    if (pictureUrl) {
      this.drawImg(pictureUrl, x + padding, (btnHeight - 22) / 2 + y, 22);
    }

    if (icon) {
      this.drawIcon(
        x + padding,
        (btnHeight - 22) / 2 + y,
        forbiddenClick ? '#BFBFBF' : hideWebBackground ? color : 'white',
        classToUnicode[icon],
      );
    }

    if (!hideWebText)
      this.drawText(
        text,
        forbiddenClick ? '#BFBFBF' : hideWebBackground ? color : 'white',
        x + (showPre ? padding + 24 : padding),
        (btnHeight - 14) / 2 + y,
      );
  }
}

export default CustomBtn;
