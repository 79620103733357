import type { ApiResponse, CurrentUser } from '@linkpi/core';
import type { PiConnection } from '@linkpi/core/web';
import { useMemoizedFn } from 'ahooks';
import { useCallback, useMemo } from 'react';
import { useSelector, useStore } from 'umi';

import { isAdmin } from '@/utils/utils';

import { useOrgTempMap } from './useTemplate';

const LinkPiOrgId = 'B6835ADFF64711EA9F1D7085C2D76860';

export const useOrgConnection = (_orgId?: string): PiConnection | null => {
  const currentOrgId = useCurrentOrgId();
  const orgId = _orgId || currentOrgId;
  const { piConnectionMap } = useSelector((state: any) => state.workspace);
  return piConnectionMap[orgId] || null;
};

export const useLinkPiOrg = () => {
  const [linkPiOrg] = useOrgInfo(LinkPiOrgId);

  return [linkPiOrg, !!linkPiOrg && isAdmin(linkPiOrg)] as const;
};

export const useOrgInfo = (
  /**
   * 不传则获取当前空间信息
   */
  _orgId?: string,
): [null | ApiResponse.CurrentUser.OrgInfo, (orgId: string) => ApiResponse.CurrentUser.OrgInfo] => {
  const currentOrgId = useCurrentOrgId();
  const orgId = _orgId || currentOrgId;

  const { currentUser } = useSelector((state: any) => state.user);

  const getOrgInfo = useCallback(
    (targetOrgId: string) => {
      return currentUser.organization.find(
        (org: ApiResponse.CurrentUser.OrgInfo) => org.orgId === targetOrgId,
      );
    },
    [currentUser],
  );

  const orgInfo: null | ApiResponse.CurrentUser.OrgInfo = useMemo(() => {
    return getOrgInfo(orgId) || null;
  }, [getOrgInfo, orgId]);

  return [orgInfo, getOrgInfo];
};

/**
 * 单纯获取数据，不会触发渲染
 */
export const useOrgStore = () => {
  const currentOrgId = useCurrentOrgId();
  const store = useStore();

  const getOrgInfo = useMemoizedFn((_orgId?: string) => {
    const orgId = _orgId ?? currentOrgId;
    const currentUser: CurrentUser = store.getState().user.currentUser;

    return currentUser.organization.find((org) => org.orgId === orgId);
  });

  return { getOrgInfo };
};

export const useOrgTempInfo = (templateId: string, orgId?: string) => {
  const currentOrgId = useCurrentOrgId();
  const finalOrgId = orgId || currentOrgId;

  const tempMap = useOrgTempMap(finalOrgId);

  const tempInfo = useMemo(() => {
    return tempMap[templateId];
  }, [templateId, tempMap]);

  return tempInfo;
};

export const useOrgList = () => {
  const currentUser: ApiResponse.CurrentUser = useSelector((s: any) => s.user.currentUser);

  return currentUser.organization;
};

export const useOrgAdminList = () => {
  const orgList = useOrgList();

  const orgAdminList = useMemo(
    () => orgList.filter((o) => o.orgId !== 'ED798ADA0C6111EB9A4A7CD30AB8A430' && isAdmin(o)),
    [orgList],
  );

  return orgAdminList;
};

export const useCurrentOrgId = () => {
  const orgId = useSelector(
    (s: any) => s.workspace?.currentSelection?.selectSpace || s.space?.currentSpace,
  );

  return orgId as string;
};

/**
 * 获取空间连接 loading 状态
 */
export const useOrgConnectionLoading = () => {
  const { connectionLoading } = useSelector(({ workspace }: any) => ({
    connectionLoading: workspace.connectionLoading,
  }));
  return connectionLoading as boolean;
};
